import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from './tabs.module.scss';

export function Tabs(props) {
  const { tabsList, tabVisible, handleTabVisible } = props;

  return (
    <div className={styles.tabList}>
      {tabsList.map(({ id, text, disabled, renderingConditions, icon }) => {
        return (
          renderingConditions && (
            <div
              key={id}
              data-tab={id}
              className={classNames(styles.tabListItem, tabVisible === id && styles.tabListItemActive, disabled && styles.disabled)}
              onClick={(event) => !disabled && handleTabVisible(event)}
            >
              {icon}
              <span className={styles.tabListItemText}>{text}</span>
            </div>
          )
        );
      })}
    </div>
  );
}

Tabs.propTypes = {
  tabsList: PropTypes.array.isRequired,
  tabVisible: PropTypes.number.isRequired,
  handleTabVisible: PropTypes.func.isRequired,
};
