import { currentUTCDate } from '../CurrentUTCDate.mjs'

const OPTION_VALUE = 0
const OPTION_TIMESTAMP = 1

class ParameterValue {

    constructor(parameter, value = null, timestamp = currentUTCDate()) {
        this.parameter = parameter
        this.value = value
        this.timestamp = timestamp
    }
}

ParameterValue.OPTION_VALUE = OPTION_VALUE
ParameterValue.OPTION_TIMESTAMP = OPTION_TIMESTAMP

export {

    ParameterValue
}
