import classNames from 'classnames';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import Utils from '../../utils/Utils.mjs';
import { useKernel } from '../../context/ContextKernel.mjs';
import { PreloadList } from '../components.mjs';
import { IconTreeConfiguration } from '../../hoc/hoc.mjs';
import { ConfigurationService } from '../../services/ConfigurationServices.mjs';
import { ErrorsApp } from '../../utils/errors-app/ErrorsApp.mjs';
import { useActiveConfigurationId } from '../../hooks/useActiveConfigurationId.mjs';
import { PARAMETER_CLASS_VALUE } from '../../global/variables/objectTypeVariables.mjs';
import { INSERT_TREE_NODE, INSERT_TREE_NODE_AS_LINK } from '../../global/variables/insertTreeNodeVariables.mjs';

import { preloadList } from './model/preloadList.mjs';
import { copyTreeNode, pasteTreeNode } from './model/helpers.mjs';
import styles from './treeConfiguration.module.scss';

export default memo(function TreeConfiguration(props) {
  const {
    configuration,
    setConfiguration,
    componentsTypeId,
    setComponentsTypeId,
    setSelectedParameter,
    removeObject,
    setRemoveObject,
    restoreObject,
    setRestoreObject,
    moveNodeInConfigurationTree,
    updateConfiguration,
    parameterDisabled = false,
    setNamePopUpVisible,
    sortedElement,
    sortOrderId,
  } = props;

  const configurationClassify = configuration?.classify();
  const location = useLocation();
  const pathName = location?.pathname;

  const [sortedZoneActive, setSortedZoneActive] = useState({ top: false, bottom: false });
  const [showChildren, setShowChildren] = useState();
  const [dragZone, setDragZone] = useState({ canDrop: false, movementCheck: false, ctrlKey: false });
  const {
    kernel,
    setContextMenu,
    currentLicense,
    addNotification,
    stateElementConfigurationId,
    setStateElementConfigurationId,
    setShowInformationParameter,
  } = useKernel(); //работа с global state
  const { activeConfigurationId } = useActiveConfigurationId();
  const ref = useRef();

  const {
    hasAccessDeleteObjects = undefined,
    hasAccessRecoveryObject = undefined,
    hasAccessMovingObjects = undefined,
    hasAccessAssignRightsObjects = undefined,
    hasAccessDeleteParameters = undefined,
    hasAccessRecoveryParameters = undefined,
  } = currentLicense;

  const setStyleParameterDisabled = () => {
    if (parameterDisabled) {
      return configurationClassify !== PARAMETER_CLASS_VALUE ? false : [0, 11, 12, 13, 14, 15, 16].includes(configuration.typeNode.systemType.type);
    }
    return false;
  };

  const handleClick = useCallback(
    (id) => {
      setComponentsTypeId(0);
      kernel.removeHandleDataPageAllState();

      kernel.setElementConfigurationId(BigInt(id));
      setStateElementConfigurationId(kernel.elementConfigurationId);
    },
    [kernel, setComponentsTypeId, setStateElementConfigurationId],
  );

  const handleDoubleClick = useCallback(() => {
    const id = configuration?.id;
    kernel.openElementTree.set(id, !showChildren);
    kernel.openElementTreeTmp.set(id, !showChildren);
    setShowChildren(!showChildren);
  }, [showChildren]);

  const handleContextMenu = useCallback(
    (event) => {
      const { currentTarget, clientX, clientY } = event;
      const id = currentTarget.dataset.id;

      if (pathName === '/property-editor' || pathName === '/users') {
        event.preventDefault();

        kernel.setElementConfigurationId(BigInt(id));
        const parameter = kernel.getParameterById(id); //получение параметра на котором было вызвано контекстное меню
        const isDeleted = parameter?.isDeleted;

        let contextMenuSettingsTreeEditor = [
          {
            id: 0,
            text: 'Переименовать конфигурацию',
            renderElement: BigInt(id) === 0n,
            isActive: false,
            onClick: () => {
              setNamePopUpVisible(true);
            },
          },
          {
            id: 1,
            text: 'Добавить',
            renderElement: true, // добавить права на добавление (проверять)
            isActive: componentsTypeId === 2,
            onClick: () => {
              setSelectedParameter(parameter);
              setStateElementConfigurationId(BigInt(id));
              setComponentsTypeId(2);
            },
          },
          {
            id: 2,
            text: 'Копировать',
            renderElement: BigInt(id) !== 0n,
            isActive: false,

            onClick: async () => {
              try {
                const parameterId = kernel.elementConfigurationId;
                kernel.copiedElementConfigurationId = parameterId;
                const parameterStructExtended = kernel.getParameterById(parameterId);
                const { hasDeletedParameters } = await copyTreeNode(parameterStructExtended);

                if (hasDeletedParameters.status)
                  addNotification({
                    messageText: 'В скопированном узле имеются удаленные элементы. Удаленные элементы не копируются!',
                    statusType: 3,
                  });

                // небольшая задержка для красоты
                setTimeout(() => {
                  addNotification({ messageText: 'Вы успешно скопировали узел дерева конфигурации!', statusType: 1 });
                }, 300);
              } catch (error) {
                addNotification({ messageText: error.message, statusType: error.status });
              }
            },
          },
          {
            id: 3,
            text: 'Вставить',
            renderElement: true,
            isActive: false,
            onClick: async () => {
              try {
                const parameterId = kernel.elementConfigurationId;
                const parameterStructExtended = kernel.getParameterById(parameterId);
                const path = parameterStructExtended.path;
                const insertedElementIsParent = path.find((item) => item.id === kernel.copiedElementConfigurationId) ? true : false;

                if (kernel.copiedElementConfigurationId === parameterId || insertedElementIsParent) {
                  throw new ErrorsApp('Нельзя вставлять узел дерева внутрь самого себя!', 2);
                }

                const { arrayBuffer, parameterListStructStruct } = await pasteTreeNode();
                const controllerIdParameterListStruct = parameterListStructStruct.filter((parameterStruct) => parameterStruct.systemType === 44);

                setConfiguration(null);
                setStateElementConfigurationId(null);

                // можно реализовать проверку при вставке в базу, возвращать флаг операции (были ли заменены id контроллера или нет)
                for (let index = 0; index < controllerIdParameterListStruct.length; index++) {
                  const { rawValue } = controllerIdParameterListStruct[index];

                  const listControllerId = await ConfigurationService.checkControllerId(activeConfigurationId, { controllerId: rawValue.toString() });

                  if (listControllerId.length > 0) {
                    addNotification({ messageText: 'Устройство с таким id уже существует, id устройства был заменен на 0!', statusType: 3 });
                    break;
                  }
                }

                const configId = JSON.parse(localStorage.getItem('activeIdConfig'))?.id;
                await ConfigurationService.pasteTreeNode(parameterId, arrayBuffer, configId, INSERT_TREE_NODE);

                const { tree } = await kernel.buildTree(location, activeConfigurationId);
                setConfiguration(tree);
                setStateElementConfigurationId(parameterId);

                addNotification({ messageText: 'Вы успешно вставили узел в дерево конфигурации', statusType: 1 });
              } catch (error) {
                setConfiguration(null);
                setStateElementConfigurationId(null);

                const parameterId = kernel.elementConfigurationId;
                const { tree } = await kernel.buildTree(location, activeConfigurationId);

                setConfiguration(tree);
                setStateElementConfigurationId(parameterId);

                addNotification({ messageText: error.message, statusType: error.status });
              }
            },
          },
          {
            id: 4,
            text: 'Вставить как ссылку',
            renderElement: true,
            isActive: false,
            onClick: async () => {
              try {
                const parameterId = kernel.elementConfigurationId;
                const parameterStructExtended = kernel.getParameterById(parameterId);
                const path = parameterStructExtended.path;
                const insertedElementIsParent = path.find((item) => item.id === kernel.copiedElementConfigurationId) ? true : false;

                if (kernel.copiedElementConfigurationId === parameterId || insertedElementIsParent) {
                  throw new ErrorsApp('Нельзя вставлять узел дерева внутрь самого себя!', 2);
                }

                const { arrayBuffer, parameterListStructStruct } = await pasteTreeNode();

                const checkParametersListForLinksProperties = parameterListStructStruct.find((parameterStruct) => parameterStruct.systemType === 351);
                if (checkParametersListForLinksProperties) {
                  addNotification({ messageText: 'Нельзя вставлять ссылочные параметры как ссылку!', statusType: 2 });
                  return;
                }

                setConfiguration(null);
                setStateElementConfigurationId(null);
                const configId = JSON.parse(localStorage.getItem('activeIdConfig'))?.id;
                await ConfigurationService.pasteTreeNode(parameterId, arrayBuffer, configId, INSERT_TREE_NODE_AS_LINK);

                const { tree } = await kernel.buildTree(location, activeConfigurationId);
                setConfiguration(tree);
                setStateElementConfigurationId(parameterId);

                addNotification({ messageText: 'Вы успешно вставили узел в дерево конфигурации', statusType: 1 });
              } catch (error) {
                setConfiguration(null);
                setStateElementConfigurationId(null);

                const parameterId = kernel.elementConfigurationId;
                const { tree } = await kernel.buildTree(location, activeConfigurationId);

                setConfiguration(tree);
                setStateElementConfigurationId(parameterId);

                addNotification({ messageText: error.message, statusType: error.status });
              }
            },
          },
          {
            id: 5,
            text: 'Справка',
            renderElement: true,
            isActive: false,
            onClick: async () => {
              setShowInformationParameter({ open: true, id: kernel.elementConfigurationId });
            },
          },
          {
            id: 6,
            text: 'Редактировать',
            renderElement: true,
            isActive: componentsTypeId === 0,
            onClick: () => {
              setComponentsTypeId(0);
              setStateElementConfigurationId(kernel.elementConfigurationId);
            },
          },
          {
            id: 7,
            text: 'Пометить на удаление',
            renderElement: configurationClassify !== PARAMETER_CLASS_VALUE && parameter.id !== 0n && !isDeleted && hasAccessDeleteObjects === 'true',
            isActive: false,
            onClick: () => {
              setRemoveObject({
                ...removeObject,
                objectId: parameter.id,
                isVisible: true,
                message: `Вы точно хотите пометить на удаление объект "${parameter.displayName}"?`,
              });
            },
          },
          {
            id: 8,
            text: 'Восстановить',
            renderElement: configurationClassify !== PARAMETER_CLASS_VALUE && parameter.id !== 0n && isDeleted && hasAccessRecoveryObject === 'true',
            isActive: false,
            onClick: () => {
              setRestoreObject({
                ...restoreObject,
                objectId: parameter.id,
                isVisible: true,
                message: `Вы точно хотите восстановить объект "${parameter.displayName}"?`,
              });
            },
          },
          {
            id: 9,
            text: 'Обновить конфигурацию',
            renderElement: true,
            isActive: false,
            onClick: () => {
              updateConfiguration();
            },
          },
          {
            id: 10,
            text: 'Установить права',
            renderElement: hasAccessAssignRightsObjects === 'true',
            isActive: componentsTypeId === 3,
            onClick: () => {
              setComponentsTypeId(3);
              setStateElementConfigurationId(kernel.elementConfigurationId);
            },
          },
          {
            id: 11,
            text: 'Пометить на удаление',
            renderElement:
              configurationClassify === PARAMETER_CLASS_VALUE && parameter.id !== 0n && !isDeleted && hasAccessDeleteParameters === 'true',
            isActive: false,
            onClick: () => {
              setRemoveObject({
                ...removeObject,
                objectId: parameter.id,
                isVisible: true,
                message: `Вы точно хотите пометить на удаление параметр "${parameter.displayName}"?`,
              });
            },
          },
          {
            id: 12,
            text: 'Восстановить',
            renderElement:
              configurationClassify === PARAMETER_CLASS_VALUE && parameter.id !== 0n && isDeleted && hasAccessRecoveryParameters === 'true',
            isActive: false,
            onClick: () => {
              setRestoreObject({
                ...restoreObject,
                objectId: parameter.id,
                isVisible: true,
                message: `Вы точно хотите восстановить параметр "${parameter.displayName}"?`,
              });
            },
          },
          {
            id: 13,
            text: 'Местоположение параметра',
            renderElement: false,
            isActive: false,
            onClick: () => {},
          },
        ];

        const contextMenuSettingsActiveItemState = contextMenuSettingsTreeEditor.find((item) => item.isActive);

        if (contextMenuSettingsActiveItemState.renderElement) {
          contextMenuSettingsActiveItemState.onClick();
        } else {
          setComponentsTypeId(0);
          setStateElementConfigurationId(kernel.elementConfigurationId);

          contextMenuSettingsTreeEditor = contextMenuSettingsTreeEditor.map((itemState) => {
            if (itemState.id === 3) {
              return {
                ...itemState,
                isActive: true,
              };
            }
            return itemState;
          });
        }

        setContextMenu(contextMenuSettingsTreeEditor, [clientX, clientY], parameter, currentTarget, 'mouseRight');
      }
    },
    [setContextMenu, componentsTypeId],
  );

  const dragStartHandler = (event) => {
    event.stopPropagation();
    const nodeId = +event.currentTarget.dataset.id;
    const dragStartSortOrderId = +event.currentTarget.dataset.sortOrderId;

    kernel.setDragStartNodeId(nodeId);
    kernel.setDragStartSortOrderId({ nodeId, dragStartSortOrderId });
  };

  const dragOverHandler = (event) => {
    event.stopPropagation();
    event.preventDefault();
    const nodeId = +event.currentTarget.dataset.id;
    const dragStartNodeId = kernel.getDragStartNodeId();
    const treeNodeDrag = kernel.getParameterById(dragStartNodeId); // элемент который перетаскивают
    const treeNodeDragOver = kernel.getParameterById(nodeId);

    const sortOrderCheck = Utils.sortOrderCheck(treeNodeDrag, treeNodeDragOver, event);
    setSortedZoneActive(sortOrderCheck);

    const dragZoneCheck = Utils.dragZoneCheck(treeNodeDrag, treeNodeDragOver, kernel, event);
    setDragZone(dragZoneCheck);
  };

  const dragLeaveHandler = (event) => {
    event.stopPropagation();
    setSortedZoneActive({ top: false, bottom: false });
    setDragZone({ canDrop: false, movementCheck: false, ctrlKey: event.ctrlKey });
  };

  const dragDropHandler = (event) => {
    const { ctrlKey, currentTarget } = event;
    event.preventDefault();
    event.stopPropagation();

    if (ctrlKey) {
      sortedElement(event, sortedZoneActive);
    } else {
      const dragOverNodeId = currentTarget.dataset.id;
      const dragStartNodeId = kernel.getDragStartNodeId();
      const treeNodeDrag = kernel.getParameterById(dragStartNodeId); // элемент который перетаскивают
      const treeNodeDragOver = kernel.getParameterById(dragOverNodeId);

      setDragZone({ canDrop: false, movementCheck: false, ctrlKey });

      if (treeNodeDrag.id !== treeNodeDragOver.id) {
        const moveNodeCheck = Utils.moveNodeCheck(treeNodeDrag, treeNodeDragOver, kernel);
        if (moveNodeCheck.status === true) {
          moveNodeInConfigurationTree(treeNodeDrag, treeNodeDragOver);
        } else {
          addNotification({ messageText: moveNodeCheck.message, statusType: 2 });
        }
      }
    }
    setSortedZoneActive({ top: false, bottom: false });
  };

  useEffect(() => {
    if (configuration) {
      const parameterStruct = kernel.getParameterById(stateElementConfigurationId);
      const path = parameterStruct.path;
      const configurationId = BigInt(configuration?.id);
      const isParent = path.find((item) => item.id === configurationId);

      const isOpen = kernel.openElementTree.get(configuration?.id);
      isParent || isOpen ? setShowChildren(true) : setShowChildren(false);
    }

    return () => {};
  }, [configuration]);

  // выделение элемента и фокус на нем
  useEffect(() => {
    if (configuration) {
      if (configuration.id === BigInt(stateElementConfigurationId)) {
        const element = ref.current;
        element.scrollIntoView({ block: 'start', behavior: 'smooth' });
      }
    }

    return () => {};
  }, []);

  return (
    <div className={classNames(styles.tree)}>
      {configuration ? (
        configuration.isTypeNode ? (
          <></>
        ) : (
          <div className={classNames(styles.treeWrapper)} data-id={configuration.id}>
            {configuration.id !== 0n && (
              <span className={classNames(styles.sortedZone, styles.sortedZoneTop, sortedZoneActive.top && styles.sortedZoneActive)}></span>
            )}
            <div
              ref={ref}
              className={classNames(
                styles.parentElem,
                BigInt(stateElementConfigurationId) === configuration.id && styles.parentElemActive,
                dragZone.canDrop && !dragZone.ctrlKey ? (dragZone.movementCheck ? styles.dragZone : styles.dragZoneError) : null,
              )}
              onContextMenu={handleContextMenu}
              onClick={(event) => handleClick(event.currentTarget.dataset.id)}
              onDoubleClick={(event) => handleDoubleClick(event.currentTarget.dataset.id)}
              data-id={configuration.id}
              data-sort-order-id={sortOrderId}
              draggable={pathName === '/property-editor' && hasAccessMovingObjects === 'true'}
              onDragStart={dragStartHandler}
              onDragLeave={dragLeaveHandler}
              onDragOver={dragOverHandler}
              onDrop={dragDropHandler}
            >
              <IconTreeConfiguration
                showChildren={showChildren}
                typeElementTree={configurationClassify}
                isDeleted={configuration.isDeleted || setStyleParameterDisabled()}
                isLink={configuration.isLink}
              />

              <span
                className={classNames(
                  styles.parentElemText,
                  configuration.isDeleted && styles.isDeleted,
                  setStyleParameterDisabled() && styles.isDeleted,
                )}
              >
                {configuration.displayName} {kernel.getUser().settings.hasParameterIdInTree && `#${configuration.id.toString()}`}
              </span>
            </div>
            <span className={classNames(styles.sortedZone, styles.sortedZoneBottom, sortedZoneActive.bottom && styles.sortedZoneActive)}></span>
            <div
              className={
                configuration.children.length > 0
                  ? !showChildren
                    ? styles.childrenElem
                    : classNames(styles.childrenElem, styles.childrenElemActive)
                  : styles.childrenElem
              }
            >
              {showChildren &&
                configuration.children.map((data, index) =>
                  data.isTypeNode ? (
                    <></>
                  ) : (
                    <TreeConfiguration
                      key={data.id}
                      configuration={data}
                      setConfiguration={setConfiguration}
                      componentsTypeId={componentsTypeId}
                      setComponentsTypeId={setComponentsTypeId}
                      setSelectedParameter={setSelectedParameter}
                      removeObject={removeObject}
                      setRemoveObject={setRemoveObject}
                      restoreObject={restoreObject}
                      setRestoreObject={setRestoreObject}
                      moveNodeInConfigurationTree={moveNodeInConfigurationTree}
                      updateConfiguration={updateConfiguration}
                      parameterDisabled={parameterDisabled}
                      setNamePopUpVisible={setNamePopUpVisible}
                      sortedElement={sortedElement}
                      sortOrderId={index}
                    />
                  ),
                )}
            </div>
          </div>
        )
      ) : (
        <PreloadList preloadSizeElement={preloadList()} />
      )}
    </div>
  );
});
