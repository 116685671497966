import { Struct } from '../../../Scada/Parser/Struct.mjs';

import { ParameterSortStruct } from  './ParameterSortStruct.mjs';


class ParameterSortListStruct extends Struct {
  constructor(parameterSortList = []) {
    super();
    this.items = parameterSortList;
  }

  readFrom(dataView, startOffset, isLittleEndian = true) {
    let endOffset = startOffset;
    const numItems = dataView.getUint32(endOffset, isLittleEndian);
    endOffset += 4;
    this.items = [];
    for (let i = 0; i < numItems; i++) {
      const itemStruct = new ParameterSortStruct();
      endOffset = itemStruct.readFrom(dataView, endOffset, isLittleEndian);
      this.items.push(itemStruct);
    }
    return endOffset;
  }

  writeTo(dataView, startOffset, isLittleEndian = true) {
    let endOffset = startOffset;
    dataView.setUint32(endOffset, this.items.length, isLittleEndian);
    endOffset += 4;
    for (let i = 0; i < this.items.length; i++) {
      endOffset = this.items[i].writeTo(dataView, endOffset, isLittleEndian);
    }
    return endOffset;
  }

  get numItems() {
    return this.items.length;
  }

  getCurrentSize() {
    return 0
      + 4 // numItems
      + this.items.reduce((size, itemStruct) => size + itemStruct.getCurrentSize(), 0); // items
  }

  clone() {
    const clone = new ParameterSortListStruct(
      this.items.map(itemStruct => itemStruct.clone())
    );
    clone.setParserOptions(this.getParserOptions());
    return clone;
  }
}


export {

  ParameterSortListStruct,
};