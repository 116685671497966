import { useState } from 'react';
import { FaFileCode } from 'react-icons/fa';
import { IoMdCodeWorking } from 'react-icons/io';
import { LuSettings2 } from 'react-icons/lu';

import { Tabs } from '../components.mjs';

import { TaskEditorArguments, TaskEditorScript, TaskGeneralSettings } from './ui/index.mjs';
import styles from './taskEditor.module.scss';

const createTabList = (task) => {
  return [
    {
      id: 1,
      icon: <LuSettings2 className="tabListIcon" />,
      text: 'Общие',
      renderingConditions: true,
    },
    {
      id: 2,
      icon: <IoMdCodeWorking className="tabListIcon" />,
      text: 'Параметры задачи',
      renderingConditions: true,
    },
    {
      id: 3,
      icon: <FaFileCode className="tabListIcon" />,
      text: 'Скрипт',
      renderingConditions: task?.activeTask?.typeTask?.value === 1,
    },
  ];
};

export default function TaskEditor(props) {
  const { task, setTask, sendingData, dataSentSuccessfully, setDataSentSuccessfully, setSendingData, configuration } = props;

  const [tabVisible, setTabVisible] = useState(1);
  const [codeTaskValue, setCodeTaskValue] = useState({ code: '', codeLength: 0 });

  const handleTabVisible = (event) => {
    const tabId = parseInt(event.currentTarget.dataset.tab);
    setTabVisible(tabId);
  };

  return (
    <div className={styles.taskEditor}>
      <Tabs tabsList={createTabList(task)} tabVisible={tabVisible} handleTabVisible={handleTabVisible} />
      <div className={styles.taskEditorWrap}>
        {tabVisible === 1 && (
          <TaskGeneralSettings
            sendingData={sendingData}
            dataSentSuccessfully={dataSentSuccessfully}
            setSendingData={setSendingData}
            setDataSentSuccessfully={setDataSentSuccessfully}
            setTask={setTask}
            task={task}
          />
        )}
        {tabVisible === 2 && (
          <TaskEditorArguments
            sendingData={sendingData}
            dataSentSuccessfully={dataSentSuccessfully}
            activeTask={task.activeTask}
            setSendingData={setSendingData}
            setDataSentSuccessfully={setDataSentSuccessfully}
            setTask={setTask}
            task={task}
            configuration={configuration}
          />
        )}
        {tabVisible === 3 && (
          <TaskEditorScript
            codeTaskValue={codeTaskValue}
            setCodeTaskValue={setCodeTaskValue}
            sendingData={sendingData}
            dataSentSuccessfully={dataSentSuccessfully}
            activeTask={task.activeTask}
            setDataSentSuccessfully={setDataSentSuccessfully}
            setSendingData={setSendingData}
            setTask={setTask}
            task={task}
          />
        )}
      </div>
    </div>
  );
}
