import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import { Tooltip } from '../components.mjs';
import { useKernel } from '../../context/ContextKernel.mjs';

import { dataFromState, valueParse } from './model/helpers.mjs';
import styles from './opcuaExternalTag.module.scss';

export default function OpcuaExternalTag(props) {
  const {
    value,
    setValue,
    getValues,
    trigger,
    placeholder,
    canEdit,
    isWritable,
    isDeleted,
    register,
    unregister,
    name,
    handleFocus,
    errors,
    showTooltip,
    validationScheme,
    setHandleChangeComponentTableRow,
    sendingData,
  } = props;

  const [state, setState] = useState({ inputValue: '', selectValue: '' });
  const dataUpdateStatus = useRef({ changeInput: false, changeSelect: false });
  const initialValue = useRef(value);
  const { kernel } = useKernel();

  const handleChangeInput = ({ target }) => {
    const initialInputValue = valueParse(initialValue.current).inputValue;

    setState({
      ...state,
      inputValue: target.value,
    });
    setValue(name, `${state.selectValue};${target.value}`);
    trigger(name);

    if (target.value.toString() === initialInputValue.toString()) {
      dataUpdateStatus.current.changeSelect = false;
    } else {
      dataUpdateStatus.current.changeSelect = true;
    }
  };

  const handleChangeSelect = ({ target }) => {
    const initialSelectValue = valueParse(initialValue.current).selectValue;

    setState({
      ...state,
      selectValue: target.value,
    });
    setValue(name, `${target.value};${state.inputValue}`);
    trigger(name);

    if (target.value.toString() === initialSelectValue.toString()) {
      dataUpdateStatus.current.changeSelect = false;
    } else {
      dataUpdateStatus.current.changeSelect = true;
    }
  };

  useEffect(() => {
    const resParseValue = valueParse(value);
    setState(resParseValue);
    setValue(name, value);

    return () => {
      unregister([name]);
    };
  }, []);

  useEffect(() => {
    initialValue.current = value;
    dataUpdateStatus.current = { changeInput: false, changeSelect: false }; // при изменении сохранении измененного состояния сбрасываем флаги изменения
    return () => {};
  }, [value]);

  useEffect(() => {
    if (dataUpdateStatus.current.changeInput || dataUpdateStatus.current.changeSelect) {
      setHandleChangeComponentTableRow(true);
    } else {
      setHandleChangeComponentTableRow(false);
    }
    return () => {};
  }, [state.selectValue, state.inputValue]);

  return (
    <div className={styles.opcuaExternalTagContainer}>
      <div className={styles.opcuaExternalTagForm}>
        <input type="text" value={getValues(name)} style={{ display: 'none' }} {...register(name, validationScheme)} />
        <select
          value={state.selectValue}
          className={classNames(
            styles.selectOpcuaExternalTag,
            errors[name] && styles.error,
            sendingData && styles.selectOpcuaExternalTagSending,
            canEdit && styles.selectOpcuaExternalTagSending,
            !isWritable && styles.selectOpcuaExternalTagSending,
            isDeleted && styles.selectOpcuaExternalTagSending,
          )}
          onChange={handleChangeSelect}
          disabled={sendingData || canEdit || !isWritable || isDeleted}
        >
          {dataFromState(kernel).map((dataFromState) => {
            return (
              <option key={dataFromState.value} value={dataFromState.value} className={styles.selectOpcuaExternalTagOption}>
                {dataFromState.text}
              </option>
            );
          })}
        </select>

        <input
          type="text"
          value={state.inputValue}
          className={classNames(
            styles.inputText,
            errors[name] && styles.error,
            sendingData && styles.inputTextSending,
            canEdit && styles.inputTextSending,
            !isWritable && styles.inputTextSending,
            isDeleted && styles.inputTextSending,
          )}
          placeholder={placeholder}
          onFocus={handleFocus}
          onChange={handleChangeInput}
          readOnly={sendingData || canEdit || !isWritable || isDeleted}
        />
        {showTooltip && errors[name] && <Tooltip message={`${errors[name]?.message || 'Ошибка заполнения!'} `} />}
      </div>
    </div>
  );
}
