import classNames from 'classnames';

import { optionDayOfWeekSelect } from '../../model/option.mjs';

import styles from './buildDayOfWeekSelect.module.scss';

export const BuildDayOfWeekSelect = (props) => {
  const { sendingData, isWritable,isDeleted, dayOfWeekSelectValue, handleChangeDayOfWeekSelect, canEdit } = props;

  return (
    <select
      className={classNames(
        styles.dayOfWeekSelect,
        styles.reportingDaySelect,
        sendingData && styles.reportingDaySending,
        canEdit && styles.reportingDaySending,
        !isWritable && styles.reportingDaySending,
        isDeleted && styles.reportingDaySending,
      )}
      value={dayOfWeekSelectValue}
      onInput={handleChangeDayOfWeekSelect}
      disabled={sendingData || canEdit || !isWritable || isDeleted}
    >
      {optionDayOfWeekSelect.map((item) => {
        return (
          <option className={classNames(styles.reportingDaySelectOption, styles.dayOfWeekSelectOption)} key={item.value} value={item.value}>
            {item.text}
          </option>
        );
      })}
    </select>
  );
};
