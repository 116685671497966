import { Preload } from '../components.mjs';

import styles from './logsPreload.module.scss';

export default function LogsPreload() {
  const list = [0, 1, 2, 3, 4, 5];

  return (
    <>
      {list.map((items) => {
        return (
          <div key={items} className={styles.row}>
            <div data-name-column="columnDate" className={styles.column}>
              <Preload width="93%" height="21px" backgroundColor="#494765" />
            </div>
            <div data-name-column="columnHour" className={styles.column}>
              <Preload width="93%" height="21px" backgroundColor="#494765" />
            </div>
            <div ata-name-column="columnAlarmType" className={styles.column}>
              <Preload width="93%" height="21px" backgroundColor="#494765" />
            </div>
            <div data-name-column="columnEventLocation" className={styles.column}>
              <Preload width="93%" height="21px" backgroundColor="#494765" />
            </div>
            <div data-name-column="columnUsername" className={styles.column}>
              <Preload width="93%" height="21px" backgroundColor="#494765" />
            </div>
            <div data-name-column="columnIpAddress" className={styles.column}>
              <Preload width="93%" height="21px" backgroundColor="#494765" />
            </div>
            <div data-name-column="columnMessage" className={styles.column}>
              <Preload width="93%" height="21px" backgroundColor="#494765" />
            </div>
          </div>
        );
      })}
    </>
  );
}
