import { useState } from 'react';
import { AiOutlineCloseCircle } from 'react-icons/ai';

import { useKernel } from '../../context/ContextKernel.mjs';
import { LicensesService } from '../../services/services.mjs';
import { Loading } from '../components.mjs';

import styles from './selectActionReplaceLicense.module.scss';

export default function SelectActionReplaceLicense(props) {
  const { setComponentsTypeId, licensesTree, setLicensesTree } = props;

  const [sendingData, setSendingData] = useState(false);

  const { kernel, addNotification } = useKernel();

  const handleNotChangeLicense = () => {
    setComponentsTypeId(0);
  };

  const handleReplaceLicense = async () => {
    try {
      setSendingData(true);

      const testName = await LicensesService.checkLicensesName(kernel.licensesFile?.name, kernel.licensesFile?.uuid);

      if (!testName) {
        addNotification({ messageText: 'Лицензия с таким именем уже существует!', statusType: 2});
        setComponentsTypeId(1);
      } else {
        const response = await LicensesService.updatingExistingLicense(kernel.licensesFile);
        const newLicense = kernel.changingLicensesInLicensesTree(response); //обновление данных в kernel

        setTimeout(() => {
          setSendingData(false);
          setLicensesTree(
            licensesTree.map((licenseType) => {
              if (licenseType.id === newLicense.id) {
                licenseType.children.map((license) => {
                  if (license.id === newLicense.id) {
                    return {
                      ...newLicense,
                    };
                  }
                  return license;
                });
              }
              return licenseType;
            }),
          );

          addNotification({ messageText: 'Лицензия успешно изменена!', statusType: 1 });

          setComponentsTypeId(0);
        }, 1100);
      }
    } catch (error) {
      setComponentsTypeId(0);
      setSendingData(false);

      addNotification({ messageText: error.message, statusType: error.status });
    }
  };

  return (
    <>
      {sendingData ? (
        <Loading />
      ) : (
        <div className={styles.selectActionReplaceLicense}>
          <div className={styles.messagePopup}>
            <AiOutlineCloseCircle className={styles.messageClose} onClick={handleNotChangeLicense} />
            <span className={styles.messageText}>
              Лицензия с идентификатором <span className={styles.uuidText}>{kernel.licensesFile?.uuid}</span> уже существует. Заменить ее?
            </span>
            <div className={styles.controlBlock}>
              <button className={styles.messageButtonNo} onClick={handleNotChangeLicense}>
                Нет
              </button>
              <button className={styles.messageButtonYes} onClick={handleReplaceLicense}>
                Да
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
