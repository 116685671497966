import { Struct } from './Struct.mjs'

class ColorStruct extends Struct {

    constructor(r = 0, g = 0, b = 0, a = 0) {
        super()
        this.r = r
        this.g = g
        this.b = b
        this.a = a
    }

    getAlpha() {
        return this.a/255.0
    }

    toString(withAlpha = true) {
        const hex = x =>
            (x < 16 ? '0' : '') + x.toString(16)
        return withAlpha
            ? `rgba(${this.r},${this.g},${this.b},${this.getAlpha()})`
            // ? `#${hex(this.r)}${hex(this.g)}${hex(this.b)}${hex(this.a)}`
            : `#${hex(this.r)}${hex(this.g)}${hex(this.b)}`
    }

    toInt() {
        let color = 0
        color = color * 256 + Math.round(this.r) % 256
        color = color * 256 + Math.round(this.g) % 256
        color = color * 256 + Math.round(this.b) % 256
        color = color * 256 + Math.round(this.a) % 256
        return color
    }

    static random() {
        return new ColorStruct(
            Math.floor(256 * Math.random()),
            Math.floor(256 * Math.random()),
            Math.floor(256 * Math.random()),
            255
        )
    }

    static fromInt(color) {
        let a = color & 0xff
        color /= 256
        let b = color & 0xff
        color /= 256
        let g = color & 0xff
        color /= 256
        let r = color & 0xff
        return new ColorStruct(r, g, b, a)
    }

    static fromString(color) {
        const clamp = x => Math.max(0, Math.min(255, Math.round(x)))
        const parseAndClamp = x => clamp(parseFloat(x))
        let m = null
        if ((m = color.match(/^\#([0-9a-fA-F])([0-9a-fA-F])([0-9a-fA-F])([0-9a-fA-F])?$/))) { // #ffff, #fff
            return new ColorStruct(
                parseInt(`${m[1]}${m[1]}`, 16),
                parseInt(`${m[2]}${m[2]}`, 16),
                parseInt(`${m[3]}${m[3]}`, 16),
                (typeof m[4] === 'undefined' ? 255 : parseInt(`${m[4]}${m[4]}`, 16))
            )
        } else if ((m = color.match(/^\#([0-9a-fA-F]{2})([0-9a-fA-F]{2})([0-9a-fA-F]{2})([0-9a-fA-F]{2})?$/))) { // #ffffffff, #ffffff
            return new ColorStruct(
                parseInt(m[1], 16),
                parseInt(m[2], 16),
                parseInt(m[3], 16),
                (typeof m[4] === 'undefined' ? 255 : parseInt(m[4], 16))
            )
        } else if ((m = color.match(/^rgb\(\s*(\d+(?:\.\d+)?|\.\d+)\s*\,?\s*(\d+(?:\.\d+)?|\.\d+)\s*\,?\s*(\d+(?:\.\d+)?|\.\d+)\s*\)$/))) { // rgb(255, 255, 255), rgb(255 255 255)
            return new ColorStruct(
                parseAndClamp(m[1]),
                parseAndClamp(m[2]),
                parseAndClamp(m[3]),
                255
            )
        } else if ((m = color.match(/^rgba\(\s*(\d+(?:\.\d+)?|\.\d+)\s*\,?\s*(\d+(?:\.\d+)?|\.\d+)\s*\,?\s*(\d+(?:\.\d+)?|\.\d+)\s*\,?\s*(\d+(?:\.\d+)?|\.\d+)\)$/))) { // rgba(255, 255, 255, 255), rgba(255, 255, 255, 0.5)
            let a = parseFloat(m[4])
            return new ColorStruct(
                parseAndClamp(m[1]),
                parseAndClamp(m[2]),
                parseAndClamp(m[3]),
                clamp(a <= 1.0 ? Math.min(Math.round(255 * a), 255) : Math.round(a))
            )
        } else if ((m = color.match(/^rgb\(\s*(\d+(?:\.\d+)?|\.\d+)\s*(\d+(?:\.\d+)?|\.\d+)\s*(\d+(?:\.\d+)?|\.\d+)\s*\/\s*(\d+(?:\.\d+)?|\.\d+)(\%?)\)$/))) { // rgb(255 255 255 / 50%), rgb(255 255 255 / 0.5)
            let a = parseFloat(m[4])
            if (m[5] === '%') {
                a /= 100.0
            }
            return new ColorStruct(
                parseAndClamp(m[1]),
                parseAndClamp(m[2]),
                parseAndClamp(m[3]),
                clamp(a <= 1.0 ? Math.min(Math.round(255 * a), 255) : Math.round(a))
            )
        }
        return null
    }

    readFrom(dataView, startOffset, isLittleEndian = true) {
        let endOffset = startOffset
        this.r = dataView.getUint8(endOffset)
        endOffset ++
        this.g = dataView.getUint8(endOffset)
        endOffset ++
        this.b = dataView.getUint8(endOffset)
        endOffset ++
        this.a = dataView.getUint8(endOffset)
        endOffset ++
        return endOffset
    }

    writeTo(dataView, startOffset, isLittleEndian = true) {
        let endOffset = startOffset
        dataView.setUint8(endOffset, this.r)
        endOffset ++
        dataView.setUint8(endOffset, this.g)
        endOffset ++
        dataView.setUint8(endOffset, this.b)
        endOffset ++
        dataView.setUint8(endOffset, this.a)
        endOffset ++
        return endOffset
    }

    getCurrentSize() {
        return 0
            + 1 // r
            + 1 // g
            + 1 // b
            + 1 // a
    }

    clone() {
        let clone = new ColorStruct(this.r, this.g, this.b, this.a)
        clone.setParserOptions(this.getParserOptions())
        return clone
    }
}

export {

    ColorStruct
}
