import { SimulatorTaskListStruct } from "../quantum-lib/Arm/Parser/SimulatorServer/SimulatorTaskListStruct.mjs";
import { SimulatorTaskStruct } from "../quantum-lib/Arm/Parser/SimulatorServer/SimulatorTaskStruct.mjs";
import { ErrorsApp } from "../utils/errors-app/ErrorsApp.mjs";

import { setWorkingMode } from "./WorkingModeServices.mjs";

export class SimulatorServerService {
  static async getSimulatorTaskListStruct(configurationId) { // SimulatorTaskListStruct
    const response = await fetch(`${setWorkingMode()}/get-simulator-task-list-struct/${configurationId}`, {
      method: 'get',
      cache: "reload",
      credentials: "omit",
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });

    if (!response.ok) {
      throw new ErrorsApp("Ошибка загрузки списка задач!", 2);
    }
    const arrayBuffer = await response.arrayBuffer();

    const isLittleEndian = true;
    const offset = 0;
    const dataView = new DataView(arrayBuffer);

    const simulatorTaskListStruct = new SimulatorTaskListStruct();
    simulatorTaskListStruct.readFrom(dataView, offset, isLittleEndian);

    return simulatorTaskListStruct;
  }

  static async createSimulatorTask(configurationId, params) { // SimulatorTaskStruct
    const response = await fetch(`${setWorkingMode()}/create-simulator-task/${configurationId}`, {
      method: 'post',
      cache: "reload",
      credentials: "omit",
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify(params),
    });

    if (!response.ok) {
      throw new ErrorsApp("Ошибка создания новой задачи!", 2);
    }
    const arrayBuffer = await response.arrayBuffer();

    const isLittleEndian = true;
    const offset = 0;
    const dataView = new DataView(arrayBuffer);

    const simulatorTaskStruct = new SimulatorTaskStruct();
    simulatorTaskStruct.readFrom(dataView, offset, isLittleEndian);

    return simulatorTaskStruct;
  }

  static async changeSimulatorTaskArgumentsByTaskId(configurationId, params) { // SimulatorTaskStruct
    const response = await fetch(`${setWorkingMode()}/change-simulator-task-arguments-by-task-id/${configurationId}`, {
      method: 'put',
      cache: "reload",
      credentials: "omit",
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify(params),
    });

    if (!response.ok) {
      throw new ErrorsApp("Ошибка изменения данных по задаче!", 2);
    }
    const arrayBuffer = await response.arrayBuffer();

    const isLittleEndian = true;
    const offset = 0;
    const dataView = new DataView(arrayBuffer);

    const simulatorTaskStruct = new SimulatorTaskStruct();
    simulatorTaskStruct.readFrom(dataView, offset, isLittleEndian);

    return simulatorTaskStruct;
  }

  static async changeSimulatorTaskOptionsByTaskId(configurationId, params) { // SimulatorTaskStruct
    const response = await fetch(`${setWorkingMode()}/change-simulator-task-options-by-task-id/${configurationId}`, {
      method: 'put',
      cache: "reload",
      credentials: "omit",
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify(params),
    });

    if (!response.ok) {
      throw new ErrorsApp("Ошибка изменения данных по задаче!", 2);
    }
    const arrayBuffer = await response.arrayBuffer();

    const isLittleEndian = true;
    const offset = 0;
    const dataView = new DataView(arrayBuffer);

    const simulatorTaskStruct = new SimulatorTaskStruct();
    simulatorTaskStruct.readFrom(dataView, offset, isLittleEndian);

    return simulatorTaskStruct;
  }

  static async deleteSimulatorTaskByTaskId(configurationId, params) {
    const response = await fetch(`${setWorkingMode()}/delete-simulator-task-by-task-id/${configurationId}`, {
      method: 'delete',
      cache: "reload",
      credentials: "omit",
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify(params),
    });

    if (!response.ok) {
      throw new ErrorsApp("Ошибка при удалении задачи!", 2);
    }
  }
};
