import classNames from 'classnames';
import { useCallback } from 'react';

import styles from './tableBodyRow.module.scss';

const ERROR_STATUS = 0;
const SUCCESS_STATUS = 1;

const styleListByStatusState = {
  [ERROR_STATUS]: styles.error,
  [SUCCESS_STATUS]: styles.success,
};

export function TableBodyRow(props) {
  const { row, columnTableHeadState } = props;

  const classNameByStatusState = useCallback(
    (columnName, statusState) => (columnName === 'columnAlarmType' ? styleListByStatusState[statusState] : null),
    [],
  );

  return (
    <div className={styles.row}>
      {columnTableHeadState.map(({ id, columnName, isVisible }) => {
        return (
          isVisible && (
            <div key={id} data-name-column={columnName} className={styles.column}>
              <span className={classNames(styles.textBody, classNameByStatusState(columnName, row.statusOperation))} title={row[columnName]}>
                {row[columnName]}
              </span>
            </div>
          )
        );
      })}
    </div>
  );
}
