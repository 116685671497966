import { AlarmListStruct } from '../quantum-lib/Arm/Parser/Alarm/AlarmListStruct.mjs';
import { AlarmStruct } from '../quantum-lib/Arm/Parser/Alarm/AlarmStruct.mjs';
import { ErrorsApp } from '../utils/errors-app/ErrorsApp.mjs';

import { setWorkingMode } from './services.mjs';


export class AlarmService {

  static async downloadingAlarmListStruct(idConfiguration) {
    const response = await fetch(`${setWorkingMode()}/get-alarm-list-struct/${idConfiguration}`, {
      method: 'get',
      cache: 'reload',
      credentials: 'omit',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });

    if (!response.ok) {
      throw new ErrorsApp("Ошибка загрузки списка тревог!", 2);
    }
    const arrayBuffer = await response.arrayBuffer();

    const isLittleEndian = true;
    const offset = 0;
    const dataView = new DataView(arrayBuffer);

    const alarmListStruct = new AlarmListStruct();
    alarmListStruct.readFrom(dataView, offset, isLittleEndian);

    return alarmListStruct;
  }

  static async createAlarm(idConfiguration, parameterId) {
    const response = await fetch(`${setWorkingMode()}/create-alarm/${idConfiguration}`, {
      method: 'post',
      cache: 'reload',
      credentials: 'omit',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify({ parameterId }),
    });

    if (!response.ok) {
      throw new ErrorsApp("Не удалось создать тревогу! Произошла ошибка!", 2);
    }
    const arrayBuffer = await response.arrayBuffer();

    const isLittleEndian = true;
    const offset = 0;
    const dataView = new DataView(arrayBuffer);

    const alarmStruct = new AlarmStruct();
    alarmStruct.readFrom(dataView, offset, isLittleEndian);

    return alarmStruct;
  }

  static async changeAlarmArguments(idConfiguration, params) {
    const response = await fetch(`${setWorkingMode()}/change-alarm-arguments/${idConfiguration}`, {
      method: 'put',
      cache: 'reload',
      credentials: 'omit',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify(params),
    });

    if (!response.ok) {
      throw new ErrorsApp("Не удалось изменить тревогу! Произошла ошибка!", 2);
    }
    const arrayBuffer = await response.arrayBuffer();

    const isLittleEndian = true;
    const offset = 0;
    const dataView = new DataView(arrayBuffer);

    const alarmStruct = new AlarmStruct();
    alarmStruct.readFrom(dataView, offset, isLittleEndian);

    return alarmStruct;
  }

  static async deleteAlarm(idConfiguration, alarmId) {
    const response = await fetch(`${setWorkingMode()}/delete-alarm/${idConfiguration}`, {
      method: 'delete',
      cache: 'reload',
      credentials: 'omit',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify({ alarmId }),
    });

    if (!response.ok) {
      throw new ErrorsApp("Не удалось удалить тревогу! Произошла ошибка!", 2);
    }
  }
}