const currentUTCDate = () => {
    const dt = new Date()
    dt.setTime(dt.getTime() - dt.getTimezoneOffset() * 60 * 1000)
    return dt
}

export {

    currentUTCDate
}
