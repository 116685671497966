import { BsPlusSquareDotted } from 'react-icons/bs';

import { SimulatorTaskListItem } from '../index.mjs';

import styles from './simulatorTaskList.module.scss';

export function SimulatorTaskList(props) {
  const { state, handleChangeSimulatorList, setPopUpVisible, deleteSimulatorTask, canDeleteSimulatorTask = true, canAddSimulatorTask = true } = props;

  return (
    <div className={styles.simulatorTaskList}>
      <div className={styles.simulatorTaskListContainer}>
        <div className={styles.simulatorTaskListWrap}>
          {state.taskList.length > 0 ? (
            state.taskList.map((simulatorTaskStruct) => {
              return (
                <SimulatorTaskListItem
                  item={simulatorTaskStruct}
                  state={state}
                  text={simulatorTaskStruct.nameTask}
                  handleChangeList={handleChangeSimulatorList}
                  deleteCallBack={deleteSimulatorTask}
                  canDelete={canDeleteSimulatorTask}
                />
              );
            })
          ) : (
            <span className={styles.simulatorTaskListEmptyText}>Для данного параметра еще нет задач, создайте их!</span>
          )}
        </div>
      </div>
      {canAddSimulatorTask && (
        <div className={styles.simulatorTaskListButtonContainer} onClick={() => setPopUpVisible(true)}>
          <BsPlusSquareDotted className={styles.simulatorTaskListButtonIcon} />
          <span className={styles.simulatorTaskListButtonText}>Добавить задачу</span>
        </div>
      )}
    </div>
  );
}
