import { Struct } from '../../../Scada/Parser/Struct.mjs';
import { StringStruct } from '../../../Scada/Parser/StringStruct.mjs';

class LicensesStruct extends Struct {

  constructor(
    licType = 0,
    dataBlockType = 0,
    closeSign = new StringStruct(''),
    uuid = new StringStruct(''),
    name = new StringStruct(''),
    statusState = new StringStruct(''),
    publicContent = new StringStruct(''),
    privateContent = new StringStruct(''),
  ) {
    super();
    this.licType = licType;
    this.dataBlockType = dataBlockType;
    this.closeSign = closeSign;
    this.uuid = uuid;
    this.name = name;
    this.statusState = statusState;
    this.publicContent = publicContent;
    this.privateContent = privateContent;
  }

  readFrom(dataView, startOffset, isLittleEndian = true) {
    let endOffset = startOffset;
    this.licType = dataView.getUint32(endOffset, isLittleEndian);
    endOffset += 4;
    this.dataBlockType = dataView.getUint32(endOffset, isLittleEndian);
    endOffset += 4;
    endOffset = this.closeSign.readFrom(dataView, endOffset, isLittleEndian);
    endOffset = this.uuid.readFrom(dataView, endOffset, isLittleEndian);
    endOffset = this.name.readFrom(dataView, endOffset, isLittleEndian);
    endOffset = this.statusState.readFrom(dataView, endOffset, isLittleEndian);
    endOffset = this.publicContent.readFrom(dataView, endOffset, isLittleEndian);
    endOffset = this.privateContent.readFrom(dataView, endOffset, isLittleEndian);

    return endOffset;
  }

  writeTo(dataView, startOffset, isLittleEndian = true) {
    let endOffset = startOffset;
    dataView.setUint32(endOffset, this.licType, isLittleEndian);
    endOffset += 4;
    dataView.setUint32(endOffset, this.dataBlockType, isLittleEndian);
    endOffset += 4;
    endOffset = this.closeSign.writeTo(dataView, endOffset, isLittleEndian);
    endOffset = this.uuid.writeTo(dataView, endOffset, isLittleEndian);
    endOffset = this.name.writeTo(dataView, endOffset, isLittleEndian);
    endOffset = this.statusState.writeTo(dataView, endOffset, isLittleEndian);
    endOffset = this.publicContent.writeTo(dataView, endOffset, isLittleEndian);
    endOffset = this.privateContent.writeTo(dataView, endOffset, isLittleEndian);

    return endOffset;
  }

  getCurrentSize() {
    return 0
      + 4 //licType
      + 4 //dataBlockType
      + this.closeSign.getCurrentSize() // closeSign
      + this.uuid.getCurrentSize() // uuid
      + this.name.getCurrentSize() // name
      + this.statusState.getCurrentSize() // statusState
      + this.publicContent.getCurrentSize() // name
      + this.privateContent.getCurrentSize(); // name
  }

  clone() {
    const clone = new LicensesStruct(
      this.licType,
      this.dataBlockType,
      this.closeSign.clone(),
      this.uuid.clone(),
      this.name.clone(),
      this.statusState.clone(),
      this.publicContent.clone(),
      this.privateContent.clone(),
    );
    clone.setParserOptions(this.getParserOptions());
    return clone;
  }

  get id() {
    return this.uuid.value;
  }
}

export {

  LicensesStruct,
};
