export const EnumTypeTask = {
  1: { value: 1, text: "Выполнить скрипт" },
  2: { value: 2, text: "Очистить лог старше 7 дней" },
  3: { value: 3, text: "Перенос тревог в архивы" },
  4: { value: 4, text: "Квитировать тревоги" },
  5: { value: 5, text: "Очистить историю по параметру старше ПАРАМЕТР 1 дней" },
  6: { value: 6, text: "Очистить историю по подчиненным параметрам старше ПАРАМЕТР 1 дней" },
  7: { value: 7, text: "Дочитка архивов с вычислителей" },
};

export const EnumTypeTaskImplementation = {
  0: { value: 0, text: "Не инициализирован" },
  1: { value: 1, text: "Выполнить скрипт" },
  2: { value: 2, text: "Ежедневно в 'время 1'" },
  3: { value: 3, text: "Еженедельно в 'время 1' ночи по 'время 2' дням недели" },
  4: { value: 4, text: "Ежемесячно в 'время 2' числа в 'время 1' часов" },
  5: { value: 5, text: "1.01 1.04 1.07 1.10 в 'время 1' часов" },
  6: { value: 6, text: "1.01 1.07  в 'время 1' часов" },
  7: { value: 7, text: "1.01  в 'время 1' часов" },
  8: { value: 8, text: "Однократно в 'время 1'" },
};
