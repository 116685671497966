export const getItemValue = (optionsList, itemsList, valueId, isMulti) => {

  if (isMulti) {
    const newOptionsList = optionsList.filter(({ value }) => value != valueId);
    const valueItem = optionsList.find(({ value }) => value == valueId);

    return { newOptionsList, valueItems: [...itemsList, valueItem] };
  } else {
    const valueItem = optionsList.find(({ value }) => value == valueId);

    return { newOptionsList: optionsList, valueItems: [valueItem] };
  }
};