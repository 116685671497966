import {
  RangeEditor,
  TableRowBoardAddressesModule,
  TableRowBoardAddressesModuleRs,
  TableRowConnectionTypeModuleTcp,
  TableRowDeadZone,
  TableRowDeviceAddressModuleRs,
  TableRowInterfaceSpeedModuleRs,
  TableRowPipelineNumberModuleRs,
  TableRowReportingHourModuleRs,
  TableRowSensorTypeModuleTcp,
  TableRowWorkingModeModuleAiDi,
} from '../components/components.mjs';
import { useKernel } from '../context/ContextKernel.mjs';
import Utils from '../utils/Utils.mjs';

export function VirtualTableRow(props) {
  const { selectedParameter } = props;
  const { kernel, stateElementConfigurationId } = useKernel();

  const selectedParameterBlockTypeId = selectedParameter?.blockType?.id;
  const selectedParameterBlockTypeIdParent = selectedParameter?.parent?.blockType?.id;
  const selectedParameterBlockTypeIdParentParent = selectedParameter?.parent?.parent?.blockType?.id;
  const renderTableRowDeadZone = selectedParameter?.properties.filter((property) => property.systemTypeId === 75).length > 0 ?? false;
  const renderTableRowRs =
    selectedParameter?.properties.filter((selectedParameterProperty) => selectedParameterProperty.systemTypeId === 39).length > 0 ?? false;
  const renderTableRowRangeEditor = Utils.getRangeProperties(selectedParameter?.properties).length === 8 ?? false;

  const renderVirtualComponents = [
    {
      items: <TableRowWorkingModeModuleAiDi selectedParameter={selectedParameter} />,
      renderCondition: selectedParameter?.parent?.blockType?.blockTypeStruct?.id === 41,
    },
    {
      items: <TableRowBoardAddressesModule selectedParameter={selectedParameter} />,
      renderCondition: selectedParameterBlockTypeId === 41 || selectedParameterBlockTypeId === 42 || selectedParameterBlockTypeId === 43,
    },
    {
      items: <TableRowSensorTypeModuleTcp selectedParameter={selectedParameter} />,
      renderCondition: selectedParameter?.parent?.blockType?.blockTypeStruct?.id === 42,
    },
    {
      items: <TableRowConnectionTypeModuleTcp selectedParameter={selectedParameter} />,
      renderCondition: selectedParameter?.parent?.blockType?.blockTypeStruct?.id === 42,
    },
    {
      items: <TableRowBoardAddressesModuleRs selectedParameter={selectedParameter} />,
      renderCondition: selectedParameter?.blockType?.id === 44 && renderTableRowRs,
    },
    {
      items: <TableRowDeviceAddressModuleRs selectedParameter={selectedParameter} />,
      renderCondition:
        [45, 46, 47, 48, 32, 31, 49, 33, 36, 50, 51, 34, 52].includes(selectedParameterBlockTypeId) &&
        stateElementConfigurationId &&
        stateElementConfigurationId !== null &&
        kernel.getParameterById(stateElementConfigurationId).path.find((parameter) => parameter.blockType?.id === 40) !== undefined,
    },
    {
      items: <TableRowInterfaceSpeedModuleRs selectedParameter={selectedParameter} />,
      renderCondition:
        [45, 46, 47, 48, 32, 31, 49, 33, 36, 50, 51, 34, 52].includes(selectedParameterBlockTypeId) &&
        stateElementConfigurationId &&
        stateElementConfigurationId !== null &&
        kernel.getParameterById(stateElementConfigurationId).path.find((parameter) => parameter.blockType?.id === 40) !== undefined,
    },
    {
      items: <TableRowPipelineNumberModuleRs selectedParameter={selectedParameter} />,
      renderCondition:
        [32, 31, 49, 33, 36, 50, 51, 34, 52].includes(selectedParameterBlockTypeId) &&
        stateElementConfigurationId &&
        stateElementConfigurationId !== null &&
        kernel.getParameterById(stateElementConfigurationId).path.find((parameter) => parameter.blockType?.id === 40) !== undefined,
    },
    {
      items: <TableRowReportingHourModuleRs selectedParameter={selectedParameter} />,
      renderCondition:
        [32, 31, 49, 33, 36, 50, 51, 34, 52].includes(selectedParameterBlockTypeId) &&
        stateElementConfigurationId &&
        stateElementConfigurationId !== null &&
        kernel.getParameterById(stateElementConfigurationId).path.find((parameter) => parameter.blockType?.id === 40) !== undefined,
    },
    {
      items: <TableRowDeadZone selectedParameter={selectedParameter} />,
      renderCondition:
        ([40].includes(selectedParameterBlockTypeIdParent) || [40].includes(selectedParameterBlockTypeIdParentParent)) &&
        renderTableRowDeadZone &&
        stateElementConfigurationId &&
        stateElementConfigurationId !== null &&
        kernel.getParameterById(stateElementConfigurationId).path.find((parameter) => parameter.blockType?.id === 40) !== undefined,
    },
    {
      items: <RangeEditor selectedParameter={selectedParameter} />,
      renderCondition:
        renderTableRowRangeEditor &&
        stateElementConfigurationId &&
        stateElementConfigurationId !== null &&
        kernel.getParameterById(stateElementConfigurationId).path.find((parameter) => parameter.blockType?.id === 40) !== undefined,
    },
  ];

  return (
    <>
      {renderVirtualComponents.map((row) => {
        if (row.renderCondition) {
          return row.items;
        } else {
          return <></>;
        }
      })}
    </>
  );
}
