export const createParametersListStruct = (parametersListStruct, parameterStructExtended, hasDeletedParameters) => {
  const properties = parameterStructExtended.properties;
  const children = parameterStructExtended.children;

  if (properties.length > 0) properties.forEach((property) => {
    if (!property.isDeleted) {
      parametersListStruct.push(property.parameterStruct); // добавляем свойство только если он не удален
    } else {
      hasDeletedParameters.status = true;
    };
  });

  if (children.length > 0) children.forEach((children) => {
    if (!children.isDeleted) {
      parametersListStruct.push(children.parameterStruct);
      createParametersListStruct(parametersListStruct, children, hasDeletedParameters); // добавляем параметр только если он не удален
    } else {
      hasDeletedParameters.status = true;
    }
  });
};