import classNames from 'classnames';
import { memo, useCallback } from 'react';

import { Checkbox } from '../../../components.mjs';

import styles from './tableSettingsMenuItem.module.scss';

export default memo(function TableSettingsMenu(props) {
  const { settingsMenuState, setSettingsMenuState, className = null } = props;

  const handleChangeTableSettingsMenuItem = useCallback((event) => {
    const id = parseInt(event.currentTarget.dataset.id);
    const newState = settingsMenuState.map((settingsItem) => {
      if (settingsItem.id === id) {
        return {
          ...settingsItem,
          isVisible: !settingsItem.isVisible,
        };
      }
      return settingsItem;
    });
    localStorage.setItem('settingsTableLogs', JSON.stringify(newState));

    setSettingsMenuState(newState);
  }, [settingsMenuState]);

  return (
    <div className={classNames(styles.settings, className)}>
      {settingsMenuState.map(({ id, columnDescription, isVisible }) => {
        return (
          <Checkbox
            key={id}
            id={id}
            caption={columnDescription}
            isVisible={isVisible}
            handleChange={handleChangeTableSettingsMenuItem}
            className={styles.checkboxMargin}
          />
        );
      })}
    </div>
  );
});
