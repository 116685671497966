export const createOptions = (licensesTypesList) => Object.values(licensesTypesList).map((licensesType) => ({ value: licensesType.id, label: licensesType.name, isDisabled: false }));

export const createServiceInstanceTypeOptions = (licensesTypesList, serviceTypeValue) => licensesTypesList[serviceTypeValue].children.map(({ id, name }) => ({ value: id, label: name, isDisabled: false }));

export const createGroupsOptions = (userGroupsList) => Object.values(userGroupsList).map((userGroup) => ({ value: userGroup.id, label: userGroup.name, isDisabled: false }));

export const createUserOptions = (usersGroups, usersGroupsValue) => usersGroups[usersGroupsValue].children.map(({ id, name }) => ({ value: id, label: name, isDisabled: false }));

export const createCodeOperations = (codeOperations) => Object.values(codeOperations).map(({ id, comment }) => ({ value: id, label: comment, isDisabled: false }));

export const createDbOptions = (configurationList) => {
  const options = configurationList.map(({ id, name }) => ({ value: id, label: `${name.value} (#${id})`, isDisabled: false }));

  return [{ value: -1, label: "Центральная база", isDisabled: false }, ...options];
};