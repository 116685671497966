import { Navigate, useLocation } from 'react-router-dom';

import { useAuth } from '../hooks/hooks.mjs';

export function RequireAuth({ children }) {
  const auth = useAuth();
  const token = localStorage.getItem('token');
  const location = useLocation();
  //будет перенаправлен на страницу авторизации
  if (token === null) {
    return <Navigate to="/" />;
  }
  //будет перенаправлен на страницу проверки пользователя
  if (token != null && !auth) {
    return <Navigate to="/loading" state={{ from: location }} />;
  }
  //перенаправлен на нужную страницу
  return children;
}
