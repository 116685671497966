import { UserStruct } from '../../../Scada/Parser/Users/UserStruct.mjs';
import { StringStruct } from '../../../Scada/Parser/StringStruct.mjs';

class UserStructExtended extends UserStruct {

  constructor(id = 0, groupId = 0, name = new StringStruct(''), password = new StringStruct('')) {
    super(id, groupId, name);
    this.password = password;
  }

  readFrom(dataView, startOffset, isLittleEndian = true) {
    let endOffset = super.readFrom(dataView, startOffset, isLittleEndian);
    endOffset = this.password.readFrom(dataView, endOffset, isLittleEndian);
    return endOffset;
  }

  writeTo(dataView, startOffset, isLittleEndian = true) {
    let endOffset = super.writeTo(dataView, startOffset, isLittleEndian);
    endOffset = this.password.writeTo(dataView, endOffset, isLittleEndian);
    return endOffset;
  }

  getCurrentSize() {
    return super.getCurrentSize()
      + this.password.getCurrentSize(); // password
  }

  clone() {
    let clone = new UserStructExtended(
      this.id,
      this.groupId,
      this.name.clone(),
      this.password.clone()
    );
    clone.setParserOptions(this.getParserOptions());
    return clone;
  }
}

export { UserStructExtended };
