import { RulesListStruct } from '../quantum-lib/Arm/Parser/Configurations/RulesListStruct.mjs';
import { ErrorsApp } from '../utils/errors-app/ErrorsApp.mjs';

import { setWorkingMode } from './services.mjs';

export class RulesService {
  static async getRulesListStruct(configId) {
    const response = await fetch(`${setWorkingMode()}/get-rules-list-struct/${configId}`, {
      method: 'get',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
    if (!response.ok) {
      throw new ErrorsApp("Произошла ошибка при получении списка прав пользователей!", 2);
    }
    const arrayBuffer = await response.arrayBuffer();

    const isLittleEndian = true;
    const offset = 0;
    const dataView = new DataView(arrayBuffer);

    const licensesListStruct = new RulesListStruct();

    licensesListStruct.readFrom(dataView, offset, isLittleEndian);
    return licensesListStruct;
  }

  static async changeRules(data) {
    const response = await fetch(`${setWorkingMode()}/change-rules`, {
      method: 'post',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      throw new ErrorsApp("Произошла ошибка при изменении прав!", 2);
    }
    const arrayBuffer = await response.arrayBuffer();

    const isLittleEndian = true;
    const offset = 0;
    const dataView = new DataView(arrayBuffer);

    const licensesListStruct = new RulesListStruct();

    licensesListStruct.readFrom(dataView, offset, isLittleEndian);
    return licensesListStruct;
  }
}
