import Utils from '../../../utils/Utils.mjs';
import { Base64 } from '../../Scada/Parser/Base64.mjs';




class License {
  constructor(kernel, licenseStruct) {
    this.kernel = kernel;
    this.licenseStruct = licenseStruct;
  }

  get id() {
    return this.licenseStruct.id;
  }

  get licType() {
    return this.licenseStruct.licType;
  }

  get dataBlockType() {
    return this.licenseStruct.dataBlockType;
  }

  get uuid() {
    return this.licenseStruct.uuid.value;
  }

  get name() {
    return this.licenseStruct.name.value;
  }

  get statusState() {
    return this.licenseStruct.statusState.value;
  }

  get publicContent() {
    const publicContentEncrypted = this.licenseStruct.publicContent.value;
    const publicContentEncryptedReverse = Utils.reverseStr(publicContentEncrypted);
    const buffer = Base64.base64StringToArrayBuffer(publicContentEncryptedReverse);
    const result = new TextDecoder().decode(new Uint8Array(buffer).reverse());

    return result;
  }

  get privateContent() {
    const privateContentEncrypted = this.licenseStruct.privateContent.value;
    const privateContentEncryptedReverse = Utils.reverseStr(privateContentEncrypted);
    const buffer = Base64.base64StringToArrayBuffer(privateContentEncryptedReverse);
    const result = new TextDecoder().decode(new Uint8Array(buffer).reverse());

    return result;
  }
}

export { License };