import React from 'react';
import isDev from 'react-is-dev';

import { ErrorsApp } from '../utils/errors-app/ErrorsApp.mjs';

export class ShowAdminPanelService {
  static async showAdminPanel(params) {
    const ifDev = isDev(React);

    const response = await fetch(`${ifDev ? "http://localhost:3001/c": "/c"}/showAdminPanel`, {
      method: 'post',
      cache: 'reload',
      credentials: 'omit',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify(params),
    });

    if (!response.ok) {
      const errorMessage = (await response.json())?.message || "Ошибка!";
      throw new ErrorsApp(errorMessage, 2);
    } else {
      return await response.json();
    }
  }
}
