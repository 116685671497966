import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { HiCheck } from 'react-icons/hi';

import { useKernel } from '../../../../context/ContextKernel.mjs';
import { InputText, LoadingInUserPageForm } from '../../../components.mjs';
import { CreateNewObjectService } from '../../../../services/services.mjs';
import { useActiveConfigurationId } from '../../../../hooks/useActiveConfigurationId.mjs';
import { createValidationSchemeByInputText } from '../../model/validationScheme.mjs';
import { filterConfigurationTree } from '../../../../utils/filter-configuration-tree/filterConfigurationTree.mjs';

import styles from './customObjectForm.module.scss';

export function CustomObjectForm(props) {
  const { setComponentsTypeId, setConfiguration, selectedParameter, searchState } = props;

  const location = useLocation();
  const [sendingData, setSendingData] = useState(false);
  const [dataSentSuccessfully, setDataSentSuccessfully] = useState(false);
  const [showTooltip, setShowTooltip] = useState({
    nameContainerSystemStructure: false,
    nameTag: false,
  });
  const [handleChangeComponentTableRow, setHandleChangeComponentTableRow] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const { activeConfigurationId } = useActiveConfigurationId();
  const { kernel, addNotification, setStateElementConfigurationId } = useKernel();

  const blockTypesListSorted = kernel.getBlockTypes().sort((a, b) => {
    if (a.description > b.description) return 1;

    if (a.description < b.description) return -1;

    return 0;
  });
  const [typeBlock, setTypeBlock] = useState(blockTypesListSorted[0]);

  const {
    register,
    unregister,
    setValue,
    trigger,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm({
    mode: 'onChange',
  });

  const handleFocus = useCallback(
    ({ target }) => {
      const name = target.name;
      setShowTooltip({
        ...showTooltip,
        [name]: true,
      });
    },
    [showTooltip],
  );

  const handleBlur = useCallback(
    ({ target }) => {
      const name = target.name;
      setShowTooltip({
        ...showTooltip,
        [name]: false,
      });
    },
    [showTooltip],
  );

  const handleChangeTypeBlock = ({ target }) => {
    const typeBlockStruct = kernel.getBlockTypeById(target.value);
    setTypeBlock(typeBlockStruct);
    setValue('typeBlockId', target.value);
  };

  const onSubmit = async (data) => {
    setSendingData(true);
    setConfiguration(null);
    try {
      data.parentId = selectedParameter.id.toString();

      await CreateNewObjectService.createNewCustomObject(activeConfigurationId, data);

      kernel.unloadConfiguration();
      await kernel.buildTree(location, activeConfigurationId);

      setSendingData(false);
      setDataSentSuccessfully(true);

      //строим дерево
      const { treeConfiguration } = filterConfigurationTree(searchState, kernel);
      setConfiguration(treeConfiguration);
      addNotification({ messageText: 'Вы успешно вставили узел в дерево конфигурации', statusType: 1 });

      setTimeout(() => {
        setDataSentSuccessfully(false);
        setComponentsTypeId(0);
      }, 1200);
    } catch (error) {
      setSendingData(false);
      const parameterId = kernel.elementConfigurationId;
      await kernel.buildTree(location, activeConfigurationId);
      const { treeConfiguration } = filterConfigurationTree(searchState, kernel);
      setConfiguration(treeConfiguration);
      setStateElementConfigurationId(parameterId);

      addNotification({ messageText: error.message, statusType: 2 });
      console.warn('error: ', error);
    }
  };

  useEffect(() => {
    setValue('nameContainerSystemStructure', typeBlock.description);
    setValue('nameTag', typeBlock.description);

    trigger(['nameContainerSystemStructure', 'nameTag']);

    return () => {};
  }, [typeBlock]);

  useEffect(() => {
    const isWritable = selectedParameter.isWritable; // проверяем есть ли права на запись в выбранный элемент в дереве конфигурации
    if (!isWritable) {
      setButtonDisabled(true);
      addNotification({
        messageText: 'У вас нет прав для вставки произвольного объекта в выбранный узел дерева!',
        statusType: 2,
      });
    } else {
      setButtonDisabled(false);
    }

    return () => {};
  }, [addNotification, selectedParameter]);

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <span className={styles.titleText}>Добавление нового объекта в: </span>
        <span className={classNames(styles.titleText, styles.titleTextAccent)}>{selectedParameter?.displayName}</span>
      </div>
      <div className={styles.formWrap}>
        <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
          <div className={styles.formContainer}>
            <div className={styles.formItem}>
              <span className={styles.formItemText}>Выберите тип блока объекта:</span>
              <select
                value={typeBlock.id}
                onInput={handleChangeTypeBlock}
                className={classNames(styles.formItemSelect, sendingData && styles.sending)}
                disabled={sendingData}
                {...register('typeBlockId')}
              >
                {blockTypesListSorted.map((blockType) => {
                  return (
                    <option value={blockType.id} className={styles.formItemSelectOption}>
                      {blockType.description}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className={styles.formItem}>
              <span className={styles.formItemText}>Наименование контейнера:</span>
              <div className={styles.itemContainer}>
                <InputText
                  value={typeBlock.description}
                  setValue={setValue}
                  placeholder="Наименование контейнера"
                  canEdit={false}
                  isWritable={true}
                  isDeleted={false}
                  register={register}
                  unregister={unregister}
                  name="nameContainerSystemStructure"
                  handleFocus={handleFocus}
                  showTooltip={showTooltip.nameContainerSystemStructure}
                  errors={errors}
                  validationScheme={createValidationSchemeByInputText(handleBlur, 255)}
                  setHandleChangeComponentTableRow={setHandleChangeComponentTableRow}
                  sendingData={sendingData}
                />
              </div>
            </div>
            <div className={styles.formItem}>
              <span className={styles.formItemText}>Наименование объекта:</span>
              <div className={styles.itemContainer}>
                <InputText
                  value={typeBlock.description}
                  setValue={setValue}
                  placeholder="Наименование объекта"
                  canEdit={false}
                  isWritable={true}
                  isDeleted={false}
                  register={register}
                  unregister={unregister}
                  name="nameTag"
                  handleFocus={handleFocus}
                  showTooltip={showTooltip.nameTag}
                  errors={errors}
                  validationScheme={createValidationSchemeByInputText(handleBlur)}
                  setHandleChangeComponentTableRow={setHandleChangeComponentTableRow}
                  sendingData={sendingData}
                />
              </div>
            </div>
          </div>
          <button
            className={
              sendingData || dataSentSuccessfully
                ? styles.formSubmitSending
                : isValid && !buttonDisabled
                  ? classNames(styles.formSubmit, styles.formValid)
                  : classNames(styles.formSubmit, styles.formNoValid)
            }
            disabled={!isValid || buttonDisabled}
          >
            {dataSentSuccessfully ? <HiCheck className={styles.checkControl} /> : sendingData ? <LoadingInUserPageForm /> : 'Создать'}
          </button>
        </form>
      </div>
    </div>
  );
}
