import classNames from 'classnames';
import moment from 'moment';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FaCalendar } from 'react-icons/fa6';

import CustomSelect from '../custom-select/CustomSelect';
import { LoadingInUserPageForm, Tooltip } from '../components.mjs';
import { useKernel } from '../../context/ContextKernel.mjs';
import { ReactComponent as BsArrowRightCircleFill } from '../../assets/icon/BsArrowLeftCircle.svg';
import { useLogs } from '../../context/ContextLogs.mjs';

import {
  createCodeOperations,
  createDbOptions,
  createGroupsOptions,
  createOptions,
  createServiceInstanceTypeOptions,
  createUserOptions,
} from './model/createOptions.mjs';
import styles from './eventLogFilter.module.scss';

const focusInputDateTimeLocal = (event) => {
  const element = event.currentTarget;
  const inputElement = element.previousElementSibling;
  inputElement.showPicker();
};

export default memo(function EventLogFilter(props) {
  const { onSubmit } = props;
  const { kernel } = useKernel();
  const {
    serviceType,
    setServiceType,
    serviceInstanceType,
    setServiceInstanceType,
    groupsId,
    setGroupsId,
    userId,
    setUserId,
    codeOperations,
    setCodeOperations,
    db,
    setDb,
    dateTimeTo,
    setDateTimeTo,
    dateTimeFrom,
    setDateTimeFrom,
    tableContent,
  } = useLogs();

  const [showTooltip, setShowTooltip] = useState({ dateTimeFrom: false, dateTimeTo: false });
  const [rotate, setRotate] = useState(false);

  const {
    register,
    unregister,
    setValue,
    getValues,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm({
    mode: 'onChange',
  });

  const handleFocus = useCallback(
    (event) => {
      const name = event.target.name;

      setShowTooltip({
        ...showTooltip,
        [name]: true,
      });
    },
    [showTooltip],
  );

  const handleBlur = useCallback(
    (event) => {
      const name = event.target.name;

      setShowTooltip({
        ...showTooltip,
        [name]: false,
      });
    },
    [showTooltip],
  );

  useEffect(() => {
    const serviceTypeOptions = createOptions(kernel.licensesTypesList);
    const serviceInstanceTypeOptions = createServiceInstanceTypeOptions(kernel.licensesTypesList, serviceTypeOptions[0]?.value);
    const groupsOptions = createGroupsOptions(kernel.usersGroups);
    const userOptions = createUserOptions(kernel.usersGroups, groupsOptions[1]?.value);
    const codeOperationsOptions = createCodeOperations(kernel.codesOperations);
    const dbOptions = createDbOptions(kernel.configurationList);

    setServiceType({ name: 'serviceType', value: serviceTypeOptions[0]?.value, options: serviceTypeOptions });
    setServiceInstanceType({ name: 'serviceInstanceType', value: serviceInstanceTypeOptions[0]?.value, options: serviceInstanceTypeOptions });
    setGroupsId({ name: 'groupsId', value: groupsOptions[1]?.value, options: groupsOptions });
    setUserId({ name: 'userId', value: userOptions[0]?.value, options: userOptions });
    setCodeOperations({ name: 'codeOperations', value: codeOperationsOptions[2]?.value, options: codeOperationsOptions });
    setDb({ name: 'db', value: dbOptions[0]?.value, options: dbOptions });

    const date = new Date();
    date.setDate(date.getDate() - 7);

    setDateTimeFrom({
      name: 'dateTimeFrom',
      value: moment(date).format('YYYY-MM-DD HH:mm:ss'),
    });
    setDateTimeTo({
      name: 'dateTimeTo',
      value: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
    });

    setValue('dateTimeFrom', moment(date).format('YYYY-MM-DD HH:mm:ss'));
    setValue('dateTimeTo', moment(new Date()).format('YYYY-MM-DD HH:mm:ss'));

    return () => {};
  }, []);

  useEffect(() => {
    if (groupsId.value) {
      const userOptions = createUserOptions(kernel.usersGroups, groupsId.value);
      setUserId({ ...userId, value: userOptions[0]?.value, options: userOptions });
    }

    return () => {};
  }, [groupsId.value]);

  useEffect(() => {
    if (serviceType.value) {
      const serviceInstanceTypeOptions = createServiceInstanceTypeOptions(kernel.licensesTypesList, serviceType.value);
      setServiceInstanceType({ ...serviceInstanceType, value: serviceInstanceTypeOptions[0]?.value, options: serviceInstanceTypeOptions });
    }

    return () => {};
  }, [serviceType.value]);

  return (
    <div className={classNames(styles.eventLogFilterContainer, rotate && styles.eventLogFilterContainerCollapsed)}>
      <div className={styles.eventLogFilter}>
        <div className={classNames(styles.titleContainer, rotate && styles.collapsedTitleContainer)}>
          <span className={classNames(styles.title, rotate && styles.collapsedTitle)}>Фильтры</span>
          <BsArrowRightCircleFill
            className={classNames(styles.filterControl, rotate && styles.filterControlActive)}
            onClick={() => setRotate(!rotate)}
          />
        </div>
        <form className={classNames(styles.controllersContainer, rotate && styles.collapsed)} onSubmit={handleSubmit(onSubmit)}>
          {/* serviceType */}
          <div className={styles.controllersItem}>
            <CustomSelect
              value={serviceType.value}
              setValue={setValue}
              onChange={(name, value) => setServiceType({ ...serviceType, value })}
              inputValueRef={useRef()}
              unregister={unregister}
              name={serviceType.name}
              options={serviceType.options}
              disabled={tableContent.sending}
            />
          </div>

          {/* serviceInstanceType */}
          <div className={styles.controllersItem}>
            <CustomSelect
              value={serviceInstanceType.value}
              setValue={setValue}
              onChange={(name, value) => setServiceInstanceType({ ...serviceInstanceType, value })}
              inputValueRef={useRef()}
              unregister={unregister}
              name={serviceInstanceType.name}
              options={serviceInstanceType.options}
              disabled={tableContent.sending}
            />
          </div>

          {/* groupsId */}
          <div className={styles.controllersItem}>
            <CustomSelect
              value={groupsId.value}
              setValue={setValue}
              onChange={(name, value) => setGroupsId({ ...groupsId, value })}
              inputValueRef={useRef()}
              unregister={unregister}
              name={groupsId.name}
              options={groupsId.options}
              disabled={tableContent.sending}
            />
          </div>

          {/* userId */}
          <div className={styles.controllersItem}>
            <CustomSelect
              value={userId.value}
              setValue={setValue}
              onChange={(name, value) => setUserId({ ...userId, value })}
              inputValueRef={useRef()}
              unregister={unregister}
              name={userId.name}
              options={userId.options}
              disabled={tableContent.sending}
            />
          </div>

          {/* codeOperations */}
          <div className={styles.controllersItem}>
            <CustomSelect
              value={codeOperations.value}
              setValue={setValue}
              onChange={(name, value) => setCodeOperations({ ...codeOperations, value })}
              inputValueRef={useRef()}
              unregister={unregister}
              name={codeOperations.name}
              options={codeOperations.options}
              disabled={tableContent.sending}
            />
          </div>

          {/* db */}
          <div className={styles.controllersItem}>
            <CustomSelect
              value={db.value}
              setValue={setValue}
              onChange={(name, value) => setDb({ ...db, value })}
              inputValueRef={useRef()}
              unregister={unregister}
              name={db.name}
              options={db.options}
              disabled={tableContent.sending}
            />
          </div>

          {/* dateTimeFrom */}
          <div className={styles.controllersItem}>
            <input
              className={classNames(styles.inputTypeDateTime, tableContent.sending && styles.disabled, errors.dateTimeFrom && styles.error)}
              type="datetime-local"
              value={dateTimeFrom.value}
              onInput={({ target }) => setDateTimeFrom({ ...dateTimeFrom, value: target.value })}
              onFocus={handleFocus}
              disabled={tableContent.sending}
              {...register('dateTimeFrom', {
                required: { message: 'Поле обязательно для заполнения!' },
                validate: (valueField) => {
                  if (valueField.length == 0) return 'Неверный формат!';

                  const dateTimeFromValueStr = getValues('dateTimeFrom');
                  const dateTimeToValueStr = getValues('dateTimeTo');

                  const dateTimeToValue = dateTimeToValueStr ? dateTimeToValueStr.split('T').join('T') : '';
                  const dateTimeFromValue = dateTimeFromValueStr ? dateTimeFromValueStr.split('T').join('T') : '';
                  const dateTimeToTimeStamp = new Date(dateTimeToValue);
                  const dateTimeFromTimeStamp = new Date(dateTimeFromValue);

                  if (dateTimeToTimeStamp < dateTimeFromTimeStamp) return "Дата 'c' не должна быть больше даты 'по'!";
                },
              })}
              onBlur={handleBlur}
            />
            <button type="button" disabled={tableContent.sending} onClick={focusInputDateTimeLocal}>
              <FaCalendar className={classNames(styles.iconCalendar, tableContent.sending && styles.disabled)} />
            </button>
            {showTooltip.dateTimeFrom && errors.dateTimeFrom && <Tooltip message={`${errors.dateTimeFrom?.message || 'Ошибка заполнения!'} `} />}
          </div>

          {/* dateTimeTo */}
          <div className={styles.controllersItem}>
            <input
              className={classNames(styles.inputTypeDateTime, tableContent.sending && styles.disabled, errors.dateTimeTo && styles.error)}
              type="datetime-local"
              value={dateTimeTo?.value}
              onInput={({ target }) => setDateTimeTo({ ...dateTimeTo, value: target.value })}
              onFocus={handleFocus}
              disabled={tableContent.sending}
              {...register('dateTimeTo', {
                required: { message: 'Поле обязательно для заполнения!' },
                validate: (valueField) => {
                  if (valueField.length == 0) return 'Неверный формат!';

                  const dateTimeFromValueStr = getValues('dateTimeFrom');
                  const dateTimeToValueStr = getValues('dateTimeTo');

                  const dateTimeToValue = dateTimeToValueStr ? dateTimeToValueStr.split('T').join('T') : '';
                  const dateTimeFromValue = dateTimeFromValueStr ? dateTimeFromValueStr.split('T').join('T') : '';
                  const dateTimeToTimeStamp = new Date(dateTimeToValue);
                  const dateTimeFromTimeStamp = new Date(dateTimeFromValue);

                  if (dateTimeToTimeStamp < dateTimeFromTimeStamp) return "Дата 'по' не должна быть меньше даты 'с'!";
                },
              })}
              onBlur={handleBlur}
            />
            <button type="button" disabled={tableContent.sending} onClick={focusInputDateTimeLocal}>
              <FaCalendar className={classNames(styles.iconCalendar, tableContent.sending && styles.disabled)} />
            </button>
            {showTooltip.dateTimeTo && errors.dateTimeTo && <Tooltip message={`${errors.dateTimeTo?.message || 'Ошибка заполнения!'} `} />}
          </div>

          <button
            type="submit"
            className={
              tableContent.sending
                ? classNames(styles.buttonSubmit, styles.buttonSubmitSending)
                : classNames(styles.buttonSubmit, isValid ? styles.formValid : styles.formNoValid)
            }
            disabled={tableContent.sending || !isValid}
          >
            {tableContent.sending ? <LoadingInUserPageForm /> : 'Показать'}
          </button>
        </form>
      </div>
    </div>
  );
});
