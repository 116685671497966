import { HiOutlinePlusSm } from 'react-icons/hi';

import styles from './addNewConfiguration.module.scss';

export default function AddNewConfiguration(props) {
  const { setIsAddingConfig } = props;

  return (
    <div className={styles.addNewConfiguration}>
      <div className={styles.containerButton} onClick={() => setIsAddingConfig(true)} title="Создать конфигурацию">
        <HiOutlinePlusSm className={styles.button} />
      </div>
    </div>
  );
}
