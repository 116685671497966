import classNames from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { HiCheck } from 'react-icons/hi';

import { CreateNewObjectService } from '../../../../services/services.mjs';
import { useActiveConfigurationId } from '../../../../hooks/hooks.mjs';
import { useKernel } from '../../../../context/ContextKernel.mjs';
import { InputColor, InputText, LoadingInUserPageForm } from '../../../components.mjs';
import { createValidationSchemeByINputColor, createValidationSchemeByInputText } from '../../model/validationScheme.mjs';
import { createSnapshotTypes } from '../../model/createSnapshotTypes.mjs';
import { ColorStruct } from '../../../../quantum-lib/Scada/Parser/ColorStruct.mjs';
import { filterConfigurationTree } from '../../../../utils/filter-configuration-tree/filterConfigurationTree.mjs';

import styles from './creatingCustomParametersForms.module.scss';

const TYPE_LINK1 = 15;
const TYPE_INVALID_TYPE = 16;

export function CreatingCustomParametersForms(props) {
  const { setComponentsTypeId, setConfiguration, selectedParameter, searchState } = props;

  const location = useLocation();
  const { kernel, addNotification, setStateElementConfigurationId } = useKernel();
  const [systemType, setSystemType] = useState(kernel.getSystemTypeById(113));
  const [snapshotType, setSnapshotType] = useState({ value: 0, listOption: [] });
  const [sendingData, setSendingData] = useState(false);
  const [handleChangeComponentTableRow, setHandleChangeComponentTableRow] = useState(false);
  const [dataSentSuccessfully, setDataSentSuccessfully] = useState(false);
  const [showTooltip, setShowTooltip] = useState({ nameTag: false, nameContainerSystemStructure: false });
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const { activeConfigurationId } = useActiveConfigurationId();
  const systemTypesListSorted = kernel.getSystemTypes().sort((a, b) => {
    if (a.description > b.description) return 1;

    if (a.description < b.description) return -1;

    return 0;
  });

  const {
    register,
    unregister,
    formState: { errors, isValid },
    handleSubmit,
    setValue,
    trigger,
  } = useForm({
    mode: 'onChange',
  });

  const handleFocus = useCallback(
    ({ target }) => {
      const name = target.name;
      setShowTooltip({
        ...showTooltip,
        [name]: true,
      });
    },
    [showTooltip],
  );

  const handleBlur = useCallback(
    ({ target }) => {
      const name = target.name;
      setShowTooltip({
        ...showTooltip,
        [name]: false,
      });
    },
    [showTooltip],
  );

  const onSubmit = async (data) => {
    setSendingData(true);
    try {
      setConfiguration(null);
      data.parentId = selectedParameter.id.toString();

      await CreateNewObjectService.createNewCustomParameter(activeConfigurationId, data);

      kernel.unloadConfiguration();
      await kernel.buildTree(location, activeConfigurationId);

      setSendingData(false);
      setDataSentSuccessfully(true);

      //строим дерево
      const { treeConfiguration } = filterConfigurationTree(searchState, kernel);
      setConfiguration(treeConfiguration);
      addNotification({ messageText: 'Вы успешно вставили узел в дерево конфигурации', statusType: 1 });

      setTimeout(() => {
        setDataSentSuccessfully(false);
        setComponentsTypeId(0);
      }, 1200);
    } catch (error) {
      setSendingData(false);
      setConfiguration(null);
      const parameterId = kernel.elementConfigurationId;
      await kernel.buildTree(location, activeConfigurationId);
      const { treeConfiguration } = filterConfigurationTree(searchState, kernel);
      setConfiguration(treeConfiguration);
      setStateElementConfigurationId(parameterId);

      addNotification({ messageText: error.message, statusType: 2 });
      console.warn('error: ', error);
    }
  };

  const handleChangeSystemType = ({ target }) => {
    const systemTypeStruct = kernel.getSystemTypeById(target.value);
    setSystemType(systemTypeStruct);
    setValue('systemTypeId', target.value);
  };

  const handleChangeSnapshotType = ({ target }) => {
    setSnapshotType({
      ...snapshotType,
      value: target.value,
    });
    setValue('snapshotType', target.value);
  };

  useEffect(() => {
    setValue('nameContainerSystemStructure', systemType.description);
    setValue('nameTag', systemType.description);

    trigger(['nameContainerSystemStructure', 'nameTag']);

    const options = createSnapshotTypes(systemType?.type);

    setValue('snapshotType', 0);
    setSnapshotType({
      value: 0,
      listOption: options,
    });
    return () => {};
  }, [systemType]);

  useEffect(() => {
    const isWritable = selectedParameter.isWritable; // проверяем есть ли права на запись в выбранный элемент в дереве конфигурации
    if (!isWritable) {
      setButtonDisabled(true);
      addNotification({
        messageText: 'У вас нет прав для вставки произвольного параметра в выбранный узел дерева!',
        statusType: 2,
      });
    } else {
      setButtonDisabled(false);
    }

    return () => {};
  }, [addNotification, selectedParameter]);

  return (
    <div className={styles.wrap}>
      <div className={styles.title}>
        <span className={styles.titleText}>Добавление нового параметра в:</span>
        <span className={classNames(styles.titleText, styles.titleTextAccent)}>{selectedParameter?.displayName}</span>
      </div>
      <div className={styles.formWrap}>
        <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
          <div className={styles.formItem}>
            <span className={styles.formItemText}>Выберите системный тип контейнера:</span>
            <div className={styles.itemContainer}>
              <select
                value={systemType.id}
                onInput={handleChangeSystemType}
                className={classNames(styles.select, sendingData && styles.sending)}
                disabled={sendingData}
                {...register('systemTypeId')}
              >
                {systemTypesListSorted.map((systemType, index) => {
                  return (
                    systemType.type !== TYPE_LINK1 &&
                    systemType.type !== TYPE_INVALID_TYPE &&
                    systemType.id !== 1 && (
                      <option key={systemType.id} value={systemType.id} className={styles.selectOption}>
                        {systemType.description} ({systemType.id})
                      </option>
                    )
                  );
                })}
              </select>
            </div>
          </div>
          <div className={styles.formItem}>
            <span className={styles.formItemText}>Наименование контейнера:</span>
            <div className={styles.itemContainer}>
              <InputText
                value={systemType.description}
                setValue={setValue}
                placeholder="Наименование контейнера"
                canEdit={false}
                isWritable={true}
                isDeleted={false}
                register={register}
                unregister={unregister}
                name="nameContainerSystemStructure"
                handleFocus={handleFocus}
                showTooltip={showTooltip.nameContainerSystemStructure}
                errors={errors}
                validationScheme={createValidationSchemeByInputText(handleBlur, 255)}
                setHandleChangeComponentTableRow={setHandleChangeComponentTableRow}
                sendingData={sendingData}
              />
            </div>
          </div>
          <div className={styles.formItem}>
            <span className={styles.formItemText}>Наименование параметра:</span>
            <div className={styles.itemContainer}>
              <InputText
                value={systemType.description}
                setValue={setValue}
                placeholder="Наименование параметра"
                canEdit={false}
                isWritable={true}
                isDeleted={false}
                register={register}
                unregister={unregister}
                name="nameTag"
                handleFocus={handleFocus}
                showTooltip={showTooltip.nameTag}
                errors={errors}
                validationScheme={createValidationSchemeByInputText(handleBlur)}
                setHandleChangeComponentTableRow={setHandleChangeComponentTableRow}
                sendingData={sendingData}
              />
            </div>
          </div>

          <div className={styles.formItem}>
            <span className={styles.formItemText}>Выберите тип снапшота:</span>
            <div className={styles.itemContainer}>
              <select
                value={snapshotType.value}
                onInput={handleChangeSnapshotType}
                className={classNames(styles.select, sendingData && styles.sending)}
                disabled={sendingData}
                {...register('snapshotType')}
              >
                {snapshotType?.listOption.map(({ value, label, isDisabled }) => {
                  return (
                    <option key={value} value={value} className={styles.selectOption} disabled={isDisabled}>
                      {label}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>

          <div className={styles.formItem}>
            <span className={styles.formItemText}>Выберите тип цвет:</span>
            <div className={styles.itemContainer}>
              <InputColor
                value={ColorStruct.random().toString(false)}
                setValue={setValue}
                placeholder="Цвет"
                canEdit={false}
                isWritable={true}
                isDeleted={false}
                register={register}
                unregister={unregister}
                name="color"
                handleFocus={handleFocus}
                showTooltip={showTooltip}
                errors={errors}
                validationScheme={createValidationSchemeByINputColor(handleBlur)}
                setHandleChangeComponentTableRow={setHandleChangeComponentTableRow}
                sendingData={sendingData}
              />
            </div>
          </div>

          <button
            className={
              sendingData || dataSentSuccessfully
                ? styles.formSubmitSending
                : isValid && !buttonDisabled
                  ? classNames(styles.formSubmit, styles.formValid)
                  : classNames(styles.formSubmit, styles.formNoValid)
            }
            disabled={!isValid || buttonDisabled}
          >
            {dataSentSuccessfully ? <HiCheck className={styles.checkControl} /> : sendingData ? <LoadingInUserPageForm /> : 'Создать'}
          </button>
        </form>
      </div>
    </div>
  );
}
