import Utils from '../../../../utils/Utils.mjs';
import { InputText } from '../../../components.mjs';
import { STRING_16, STRING_255, STRING_64 } from '../../model/simulatorStrType.mjs';
import { validationScheme } from '../../model/validationScheme.mjs';
import InputNumber from '../input-number/InputNumber';

export const ValueField = (props) => {
  const {
    state,
    name,
    setValue,
    placeholder,
    register,
    unregister,
    handleBlur,
    handleChange,
    handleFocus,
    showTooltip,
    errors,
    sendingData,
    dataSentSuccessfully,
  } = props;

  const components = {
    [STRING_16]: (
      <InputText
        value={state[name]}
        setValue={setValue}
        placeholder={placeholder}
        canEdit={sendingData}
        isWritable={true}
        isDeleted={false}
        register={register}
        unregister={unregister}
        name={name}
        handleFocus={handleFocus}
        showTooltip={showTooltip}
        errors={errors}
        validationScheme={validationScheme(state.simulatorType)}
        setHandleChangeComponentTableRow={() => {}}
        sendingData={sendingData}
      />
    ),
    [STRING_64]: (
      <InputText
        value={state[name]}
        setValue={setValue}
        placeholder={placeholder}
        canEdit={sendingData}
        isWritable={true}
        isDeleted={false}
        register={register}
        unregister={unregister}
        name={name}
        handleFocus={handleFocus}
        showTooltip={showTooltip}
        errors={errors}
        validationScheme={validationScheme(state.simulatorType)}
        setHandleChangeComponentTableRow={() => {}}
        sendingData={sendingData}
      />
    ),
    [STRING_255]: (
      <InputText
        value={state[name]}
        setValue={setValue}
        placeholder={placeholder}
        canEdit={sendingData}
        isWritable={true}
        isDeleted={false}
        register={register}
        unregister={unregister}
        name={name}
        handleFocus={handleFocus}
        showTooltip={showTooltip}
        errors={errors}
        validationScheme={validationScheme(state.simulatorType)}
        setHandleChangeComponentTableRow={() => {}}
        sendingData={sendingData}
      />
    ),
  };

  return components[state.simulatorType] ? (
    components[state.simulatorType]
  ) : (
    <InputNumber
      name={name}
      value={state[name]}
      dataSentSuccessfully={dataSentSuccessfully}
      sendingData={sendingData}
      showTooltip={showTooltip}
      errors={errors}
      onBlur={handleBlur}
      onFocus={handleFocus}
      onInput={handleChange}
      register={{
        ...register(name, {
          validate: (value) => {
            return Utils.testIsNumber(value) ? null : 'Неверный формат. Значение должно быть числом!';
          },
        }),
      }}
    />
  );
};
