const ACCESS_DENIED = 1;
const ACCESS_READ = 2;
const ACCESS_ADD = 4;
const ACCESS_WRITE = 8;
const ACCESS_DELETE = 16;
const ACCESS_FULL = 32;
const ACCESS_ACK = 64;

class Rules {
  constructor(kernel, rulesStruct) {
    this.kernel = kernel;
    this.rulesStruct = rulesStruct;
  }

  get id() {
    return this.rulesStruct.id;
  };

  get groupId() {
    return this.rulesStruct.groupId;
  };

  get group() {
    return this.kernel.usersGroups[this.rulesStruct.groupId];
  }

  get groupName() {
    return this.kernel.usersGroups[this.rulesStruct.groupId].name;
  }

  get configId() {
    return this.rulesStruct.configId;
  };

  get config() {
    return this.kernel.configuration;
  }

  get parameterId() {
    return this.rulesStruct.parameterId;
  };

  get parameter() {
    return this.kernel.parameters[this.rulesStruct.parameterId];
  }

  get type() {
    return this.rulesStruct.type;
  };

  // отказ в доступе
  get accessDenied() {
    return parseInt(this.type) & ACCESS_DENIED
      ? true : false;
  };

  set accessDenied(value) {
    if (value) {
      this.rulesStruct.type |= BigInt(ACCESS_DENIED);
    } else {
      this.rulesStruct.type &= ~BigInt(ACCESS_DENIED);
    }
  };

  // права на чтение
  get isReadable() {
    return parseInt(this.type) & ACCESS_READ && !this.accessDenied
      ? true : false;
  };

  set isReadable(value) {
    if (value) {
      this.rulesStruct.type |= BigInt(ACCESS_READ);
    } else {
      this.rulesStruct.type &= ~BigInt(ACCESS_READ);
    }
  };

  // права на добавление
  get canAdd() {
    return parseInt(this.type) & ACCESS_ADD && !this.accessDenied
      ? true : false;
  };

  set canAdd(value) {
    if (value) {
      this.rulesStruct.type |= BigInt(ACCESS_ADD);
    } else {
      this.rulesStruct.type &= ~BigInt(ACCESS_ADD);
    }
  };

  // права на запись (модификации)
  get isWritable() {
    return parseInt(this.type) & ACCESS_WRITE && !this.accessDenied
      ? true : false;
  }

  set isWritable(value) {
    if (value) {
      this.rulesStruct.type |= BigInt(ACCESS_WRITE);
    } else {
      this.rulesStruct.type &= ~BigInt(ACCESS_WRITE);
    }
  };

  // права на удаление
  get canDelete() {
    return parseInt(this.type) & ACCESS_DELETE && !this.accessDenied
      ? true : false;
  };

  set canDelete(value) {
    if (value) {
      this.rulesStruct.type |= BigInt(ACCESS_DELETE);
    } else {
      this.rulesStruct.type &= ~BigInt(ACCESS_DELETE);
    }
  };

  // root права но не на квитирование
  get canEditAccess() {
    return parseInt(this.type) & ACCESS_FULL && !this.accessDenied
      ? true : false;
  };

  set canEditAccess(value) {
    if (value) {
      this.rulesStruct.type |= BigInt(ACCESS_FULL);
    } else {
      this.rulesStruct.type &= ~BigInt(ACCESS_FULL);
    }
  };

  // права на квитирование
  get isAcknowledgeable() {
    return parseInt(this.type) & ACCESS_ACK && !this.accessDenied
      ? true : false;
  };

  set isAcknowledgeable(value) {
    if (value) {
      this.rulesStruct.type |= BigInt(ACCESS_ACK);
    } else {
      this.rulesStruct.type &= ~BigInt(ACCESS_ACK);
    }
  };

}

export {
  Rules,
};