import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { HiCheck } from 'react-icons/hi';

import Utils from '../../utils/Utils.mjs';
import { useKernel } from '../../context/ContextKernel.mjs';
import { SavePropertiesButtonModuleRs } from '../components.mjs';

import { options } from './model/options.mjs';
import styles from './tableRowWorkingModeModuleAiDi.module.scss';

export default function TableRowWorkingModeModuleAiDi(props) {
  const { selectedParameter } = props;

  const [handleChangeComponentTableRow, setHandleChangeComponentTableRow] = useState(false);
  const [sendingData, setSendingData] = useState(false);
  const [dataSentSuccessfully, setDataSentSuccessfully] = useState(false);
  const [errorParse, setErrorParse] = useState(false);
  const [changesDate, setChangesDate] = useState({ day: '', hour: '' }); // вывод даты и времени последнего изменения свойств (выводится время драйвер адреса)
  const [workingModeValue, setWorkingModeValue] = useState(); // состояние компонента

  const dataUpdateInputModeStatus = useRef(false); // изменилось ли значение компонента состояние компонента или нет
  const { kernel, addNotification, stateElementConfigurationId } = useKernel();

  const saveProperty = async () => {
    setHandleChangeComponentTableRow(false);
    setSendingData(true);
    try {
      const { channelSettingParameterType, channelSettingParameterSubtype, driverAddressParameter } = createParametersWorkingModeObj();

      const newDriverAddressValue = createNewDriverAddress(workingModeValue, driverAddressParameter);
      const channelSettingParameterTypeValue = parseInt(workingModeValue) === 5 ? 0 : 7;
      const channelSettingParameterSubtypeValue = parseInt(workingModeValue) === 5 ? 0 : 3;

      const driverAddressParameterStruct = kernel.getParameterById(driverAddressParameter?.id); // драйвер адрес
      const channelSettingParameterTypeStruct = kernel.getParameterById(channelSettingParameterType?.id); // тип
      const channelSettingParameterSubtypeStruct = kernel.getParameterById(channelSettingParameterSubtype?.id); // подтип

      await kernel.setValues([
        { parameterStruct: driverAddressParameterStruct, newValue: newDriverAddressValue },
        { parameterStruct: channelSettingParameterTypeStruct, newValue: channelSettingParameterTypeValue },
        { parameterStruct: channelSettingParameterSubtypeStruct, newValue: channelSettingParameterSubtypeValue },
      ]);

      setDataSentSuccessfully(true);
      setTimeout(() => {
        setDataSentSuccessfully(false);
        setSendingData(false);
      }, 1500);
    } catch (error) {
      setSendingData(false);
      addNotification({ messageText: error.message, statusType: error.status });
      setHandleChangeComponentTableRow(true);
    }
  };

  const createNewDriverAddress = (workingModeValue, driverAddressParameter) => {
    const propertyValueRoundUp = driverAddressParameter.propertyValueRoundUp;
    const newValue = propertyValueRoundUp.toString().slice(0, -1).concat(workingModeValue);

    return newValue;
  };

  const handleChangeSelectInputMode = (event) => {
    setWorkingModeValue(event.target.value);

    const { driverAddressParameter } = createParametersWorkingModeObj();
    const driverAddressParameterParse = Utils.parseWorkingMode(driverAddressParameter);
    const initialInputMode = driverAddressParameterParse?.inputMode; //начальное значение компонента

    if (event.target.value.toString() === initialInputMode.toString() && !errorParse) {
      dataUpdateInputModeStatus.current = false;
    } else {
      dataUpdateInputModeStatus.current = true;
    }

    if (dataUpdateInputModeStatus.current) {
      setHandleChangeComponentTableRow(true);
    } else {
      setHandleChangeComponentTableRow(false);
    }
  };

  const createParametersWorkingModeObj = () => {
    const parametersWorkingMode = selectedParameter.properties.filter(
      (property) => property.systemTypeId === 64 || property.systemTypeId === 65 || property.systemTypeId === 66,
    );

    const parametersWorkingModeObj = {};
    parametersWorkingMode.forEach((parameterWorkingMode) => {
      parametersWorkingModeObj[parameterWorkingMode.systemTypeId] = parameterWorkingMode;
    });

    const channelSettingParameterType = parametersWorkingModeObj[64];
    const channelSettingParameterSubtype = parametersWorkingModeObj[65];
    const driverAddressParameter = parametersWorkingModeObj[66];

    return { channelSettingParameterType, channelSettingParameterSubtype, driverAddressParameter };
  };

  const validationTypeAndSubtype = (inputMode, channelSettingParameterType, channelSettingParameterSubtype) => {
    if (parseInt(inputMode) === 5) {
      return channelSettingParameterType.propertyValueRoundUp === 0 && channelSettingParameterSubtype.propertyValueRoundUp === 0;
    } else if (parseInt(inputMode) === 3) {
      return channelSettingParameterType.propertyValueRoundUp === 7 && channelSettingParameterSubtype.propertyValueRoundUp === 3;
    } else {
      return false;
    }
  };

  useEffect(() => {
    const { channelSettingParameterType, channelSettingParameterSubtype, driverAddressParameter } = createParametersWorkingModeObj();

    if (driverAddressParameter) {
      const day = Utils.convertTimestamp(driverAddressParameter.propertyValueTimestamp);
      const hour = Utils.convertTimestampGetHour(driverAddressParameter.propertyValueTimestamp);
      setChangesDate({ day: day, hour: hour });
      const { inputMode } = Utils.parseWorkingMode(driverAddressParameter);

      const validationTypeAndSubtypeTest = validationTypeAndSubtype(inputMode, channelSettingParameterType, channelSettingParameterSubtype);
      if (!validationTypeAndSubtypeTest) {
        setErrorParse(true);

        // инициализируем состояние компонента
        setWorkingModeValue(0);
        // сбрасываем состояние изменения компонента
        setHandleChangeComponentTableRow(false);
      } else {
        setErrorParse(false);

        // инициализируем состояние компонента
        setWorkingModeValue(inputMode);
        // сбрасываем состояние изменения компонента
        setHandleChangeComponentTableRow(false);
      }
    }

    return () => {};
  }, [stateElementConfigurationId]);

  return (
    <tr className={styles.tableRow}>
      <td className="tableRowPropertyTd">
        <span className={styles.tableRowText} title={'Режим работы'}>
          Режим работы
        </span>
      </td>
      <td className="tableRowPropertyTd">
        {/* компонент */}
        <div className={styles.workingModeAiDi}>
          <select
            className={classNames(styles.selectInputMode, sendingData && styles.sending)}
            value={workingModeValue}
            onInput={handleChangeSelectInputMode}
            disabled={sendingData}
          >
            {options.map((inputModeOption) => {
              return (
                <option key={inputModeOption.valueOption} value={inputModeOption.valueOption} className={styles.selectInputModeOption}>
                  {inputModeOption.textOption}
                </option>
              );
            })}
            {errorParse && (
              <option key={0} value={0} className={styles.selectInputModeOption}>
                Значение не определено
              </option>
            )}
          </select>
        </div>
        {/* компонент */}
      </td>
      <td className="tableRowPropertyTd">
        <span className={classNames(styles.tableRowText, styles.tableDate)}>{changesDate.day}</span>
        <span className={styles.tableRowText}>{changesDate.hour}</span>
      </td>
      <td className="tableRowPropertyTd">
        {dataSentSuccessfully ? (
          <HiCheck className={styles.checkControl} />
        ) : (
          <SavePropertiesButtonModuleRs
            sendingData={sendingData}
            handleChangeComponentTableRow={handleChangeComponentTableRow}
            saveProperty={saveProperty}
          />
        )}
      </td>
    </tr>
  );
}
