import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';

import { useKernel } from '../../context/ContextKernel.mjs';

import { BuildDotAlignmentSelect } from './ui/build-dot-alignment-select/BuildDotAlignmentSelect';
import { BuildAlignmentEqualHourMarksSelect } from './ui/build-alignment-equal-hour-marks-select/BuildAlignmentEqualHourMarksSelect';
import { BuildDotlessAlignmentSelect } from './ui/build-dotless-alignment-select/BuildDotlessAlignmentSelect.jsx';
import { BuildHourOfDaySelect } from './ui/build-hour-of-day-select/BuildHourOfDaySelect.jsx';
import styles from './reportingHour.module.scss';

export default function ReportingHour(props) {
  const { value, setValue, canEdit, isWritable, isDeleted,  register, unregister, name, setHandleChangeComponentTableRow, sendingData } = props;

  const [componentsShow, setComponentsShow] = useState({
    hourOfDaySelectShow: false,
    dotAlignmentSelectShow: false,
    dotlessAlignmentSelectShow: false,
    alignmentEqualHourMarksSelectShow: false,
  });

  const [mainSelectValue, setMainSelectValue] = useState(); //состояние значений mainSelect
  const [hourOfDaySelectValue, setHourOfDaySelectValue] = useState(); //состояние значений hourOfDaySelect
  const [dotAlignmentSelectValue, setDotAlignmentSelectValue] = useState(); //состояние значений DotAlignmentSelect
  const [dotlessAlignmentSelectValue, setDotlessAlignmentSelectValue] = useState(); //состояние значений DotlessAlignmentSelect
  const [alignmentEqualHourMarksSelectValue, setAlignmentEqualHourMarksSelectValue] = useState(); //состояние значений alignmentEqualHourMarksSelectValue
  const [valueNotRecognized, setValueNotRecognized] = useState(false); //пришли с бд невалидные данные
  const [initialSelectValue, setInitialSelectValue] = useState({ name: '', value: '' });
  const initialValue = useRef(value);

  const { kernel } = useKernel();
  const activeElementConfigurationId = kernel.elementConfigurationId;
  const parameterStruct = kernel.getParameterById(activeElementConfigurationId);

  const optionMainSelect = [
    { value: 24, text: 'Не установлен', display: true },
    { value: 'hourOfDay', text: 'Час суток', display: true },
    { value: 'dotAlignment', text: 'Выравнивание с точкой', display: true },
    { value: 'dotlessAlignment', text: 'Выравнивание без точки', display: true },
    {
      value: `alignmentEqualHourMarks`,
      text: 'Выравнивание по равным часовым меткам',
      display: [23, 24, 25, 40].includes(parameterStruct?.blockType?.id),
    },
  ];

  const setInitialState = () => {
    if (initialValue.current >= 0 && initialValue.current <= 23) {
      //hourOfDaySelect
      setComponentsShow({
        hourOfDaySelectShow: true,
        dotAlignmentSelectShow: false,
        dotlessAlignmentSelectShow: false,
        alignmentEqualHourMarksSelectShow: false,
      });
      setHourOfDaySelectValue(initialValue.current);
      setMainSelectValue('hourOfDay');
      setInitialSelectValue({ name: 'hourOfDay', value: initialValue.current });
    } else if (initialValue.current >= 50 && initialValue.current <= 73) {
      //dotAlignment
      setComponentsShow({
        hourOfDaySelectShow: false,
        dotAlignmentSelectShow: true,
        dotlessAlignmentSelectShow: false,
        alignmentEqualHourMarksSelectShow: false,
      });
      setDotAlignmentSelectValue(initialValue.current);
      setMainSelectValue('dotAlignment');
      setInitialSelectValue({ name: 'dotAlignment', value: initialValue.current });
    } else if (initialValue.current >= 80 && initialValue.current <= 103) {
      //dotlessAlignment
      setComponentsShow({
        hourOfDaySelectShow: false,
        dotAlignmentSelectShow: false,
        dotlessAlignmentSelectShow: true,
        alignmentEqualHourMarksSelectShow: false,
      });
      setDotlessAlignmentSelectValue(initialValue.current);
      setMainSelectValue('dotlessAlignment');
      setInitialSelectValue({ name: 'dotlessAlignment', value: initialValue.current });
    } else if (initialValue.current >= 110 && initialValue.current <= 134) {
      setComponentsShow({
        hourOfDaySelectShow: false,
        dotAlignmentSelectShow: false,
        dotlessAlignmentSelectShow: false,
        alignmentEqualHourMarksSelectShow: true,
      });
      setAlignmentEqualHourMarksSelectValue(initialValue.current);
      setMainSelectValue(`alignmentEqualHourMarks`);
      setInitialSelectValue({ name: `alignmentEqualHourMarks`, value: initialValue.current });
    } else if (
      initialValue.current !== 24 &&
      initialValue.current !== 'hourOfDay' &&
      initialValue.current !== 'dotAlignment' &&
      initialValue.current !== 'dotlessAlignment' &&
      initialValue.current !== `alignmentEqualHourMarks`
    ) {
      setValueNotRecognized(true);
      setMainSelectValue('valueNotRecognized');
    } else {
      setInitialSelectValue({ name: `mainSelectReportingHour`, value: initialValue.current });
      setMainSelectValue(initialValue.current);
    }
  };

  const handleChangeMainSelect = (event) => {
    if (event.target.value === 'hourOfDay') {
      setValueNotRecognized(false);
      setComponentsShow({
        hourOfDaySelectShow: true,
        dotAlignmentSelectShow: false,
        dotlessAlignmentSelectShow: false,
        alignmentEqualHourMarksSelectShow: false,
      });
      setValue(name, 0);
      setHourOfDaySelectValue(0);
    } else if (event.target.value === 'dotAlignment') {
      setComponentsShow({
        hourOfDaySelectShow: false,
        dotAlignmentSelectShow: true,
        dotlessAlignmentSelectShow: false,
        alignmentEqualHourMarksSelectShow: false,
      });
      setValueNotRecognized(false);
      setValue(name, 50);
      setDotAlignmentSelectValue(50);
    } else if (event.target.value === 'dotlessAlignment') {
      setComponentsShow({
        hourOfDaySelectShow: false,
        dotAlignmentSelectShow: false,
        dotlessAlignmentSelectShow: true,
        alignmentEqualHourMarksSelectShow: false,
      });
      setValueNotRecognized(false);
      setValue(name, 80);
      setDotlessAlignmentSelectValue(80);
    } else if (event.target.value === `alignmentEqualHourMarks`) {
      setComponentsShow({
        hourOfDaySelectShow: false,
        dotAlignmentSelectShow: false,
        dotlessAlignmentSelectShow: false,
        alignmentEqualHourMarksSelectShow: true,
      });
      setValueNotRecognized(false);
      setValue(name, 110);
      setAlignmentEqualHourMarksSelectValue(110);
    } else {
      // Не установлен
      setComponentsShow({
        hourOfDaySelectShow: false,
        dotAlignmentSelectShow: false,
        dotlessAlignmentSelectShow: false,
        alignmentEqualHourMarksSelectShow: false,
      });
      setValueNotRecognized(false);
      setValue(name, event.target.value);
    }

    if (event.target.value.toString() === initialSelectValue.name.toString()) {
      setHandleChangeComponentTableRow(false);
    } else {
      setHandleChangeComponentTableRow(true);
    }
    setMainSelectValue(event.target.value);
  };

  const handleChangeHourOfDaySelect = (event) => {
    if (event.target.value.toString() === initialSelectValue.value.toString()) {
      setHandleChangeComponentTableRow(false);
    } else {
      setHandleChangeComponentTableRow(true);
    }
    setHourOfDaySelectValue(event.target.value);
    setValue(name, event.target.value);
  };

  const handleChangeDotAlignmentSelect = (event) => {
    if (event.target.value.toString() === initialSelectValue.value.toString()) {
      setHandleChangeComponentTableRow(false);
    } else {
      setHandleChangeComponentTableRow(true);
    }
    setDotAlignmentSelectValue(event.target.value);
    setValue(name, event.target.value);
  };

  const handleChangeDotlessAlignmentSelect = (event) => {
    if (event.target.value.toString() === initialSelectValue.value.toString()) {
      setHandleChangeComponentTableRow(false);
    } else {
      setHandleChangeComponentTableRow(true);
    }
    setDotlessAlignmentSelectValue(event.target.value);
    setValue(name, event.target.value);
  };

  const handleChangeAlignmentEqualHourMarksSelect = (event) => {
    if (event.target.value.toString() === initialSelectValue.value.toString()) {
      setHandleChangeComponentTableRow(false);
    } else {
      setHandleChangeComponentTableRow(true);
    }
    setAlignmentEqualHourMarksSelectValue(event.target.value);
    setValue(name, event.target.value);
  };

  useEffect(() => {
    setInitialState();
    setValue(name, value);
    return () => {
      unregister(name);
    };
  }, []);

  useEffect(() => {
    initialValue.current = value;
    return () => {};
  }, [value]);

  return (
    <div className={styles.reportingHourContainer}>
      <input type="text" style={{ display: 'none' }} {...register(name)} />
      <div className={styles.reportingHourForm}>
        <select
          className={classNames(
            styles.reportingHourSelect,
            styles.mainSelect,
            sendingData && styles.inputrepRtingHourSending,
            canEdit && styles.inputrepRtingHourSending,
            !isWritable && styles.inputrepRtingHourSending,
            isDeleted && styles.inputrepRtingHourSending,
          )}
          value={mainSelectValue}
          onInput={handleChangeMainSelect}
          disabled={sendingData || canEdit || !isWritable || isDeleted}
        >
          <>
            {valueNotRecognized ? (
              <option
                className={classNames(styles.reportingHourSelectOption, styles.mainSelectOption)}
                key="valueNotRecognized"
                value="valueNotRecognized"
                disabled
              >
                значение не распознано
              </option>
            ) : (
              <></>
            )}
            {optionMainSelect.map((item) => {
              return (
                item.display && (
                  <option className={classNames(styles.reportingHourSelectOption, styles.mainSelectOption)} key={item.value} value={item.value}>
                    {item.text}
                  </option>
                )
              );
            })}
          </>
        </select>

        {componentsShow.hourOfDaySelectShow && (
          <BuildHourOfDaySelect
            sendingData={sendingData}
            isWritable={isWritable}
            isDeleted={isDeleted}
            canEdit={canEdit}
            register={register}
            hourOfDaySelectValue={hourOfDaySelectValue}
            handleChangeHourOfDaySelect={handleChangeHourOfDaySelect}
          />
        )}

        {componentsShow.dotAlignmentSelectShow && (
          <BuildDotAlignmentSelect
            sendingData={sendingData}
            isWritable={isWritable}
            isDeleted={isDeleted}
            canEdit={canEdit}
            register={register}
            dotAlignmentSelectValue={dotAlignmentSelectValue}
            handleChangeDotAlignmentSelect={handleChangeDotAlignmentSelect}
          />
        )}

        {componentsShow.dotlessAlignmentSelectShow && (
          <BuildDotlessAlignmentSelect
            sendingData={sendingData}
            isWritable={isWritable}
            isDeleted={isDeleted}
            canEdit={canEdit}
            register={register}
            dotlessAlignmentSelectValue={dotlessAlignmentSelectValue}
            handleChangeDotlessAlignmentSelect={handleChangeDotlessAlignmentSelect}
          />
        )}
        {componentsShow.alignmentEqualHourMarksSelectShow && (
          <BuildAlignmentEqualHourMarksSelect
            sendingData={sendingData}
            isWritable={isWritable}
            isDeleted={isDeleted}
            canEdit={canEdit}
            register={register}
            alignmentEqualHourMarksSelectValue={alignmentEqualHourMarksSelectValue}
            handleChangeAlignmentEqualHourMarksSelect={handleChangeAlignmentEqualHourMarksSelect}
          />
        )}
      </div>
    </div>
  );
}
