import classNames from 'classnames';
import { useLocation, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';
import { AiOutlinePlus } from 'react-icons/ai';

import { flagsParser } from '../../utils/flags-parser/flagsParser.mjs';
import { ReactComponent as BsArrowRightCircleFill } from '../../assets/icon/BsArrowLeftCircle.svg';
import { ErrorMessagePopup, SetNamePopUp, TaskEditor, TreeTask } from '../../components/components.mjs';
import { ContentApp, HeaderApp, NavApp } from '../../layout/layout.mjs';
import { useKernel } from '../../context/ContextKernel.mjs';
import { useActiveConfigurationId, useUserTokenDecode } from '../../hooks/hooks.mjs';
import { TaskServerService } from '../../services/services.mjs';

import styles from './taskServer.module.scss';

export default function TaskServer() {
  const location = useLocation();
  const navigate = useNavigate();
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('Ошибка!');
  const [configurationName, setConfigurationName] = useState(null);
  const [userName, setUserName] = useState('');
  const [rotate, setRotate] = useState(false);
  const [task, setTask] = useState({ taskList: [], activeTask: {}, activeTaskId: null, isLoaded: false });
  const [typeComponents, setTypeComponents] = useState(1);
  const [activeTypeTask, setActiveTypeTask] = useState(null);
  const [namePopUpVisible, setNamePopUpVisible] = useState(false);
  const [sendingData, setSendingData] = useState(false);
  const [dataSentSuccessfully, setDataSentSuccessfully] = useState(false);

  const { kernel, currentLicense, addNotification, configuration, setConfiguration } = useKernel();
  const { activeConfigurationId } = useActiveConfigurationId();
  const { flags } = useUserTokenDecode();
  const { hasAccessRightsTaskServerModule } = flagsParser(flags);

  const getTasksListStruct = async () => {
    try {
      const tasksListStruct = await TaskServerService.getTasksListStruct(activeConfigurationId);
      kernel.setTasksListForTaskServer(tasksListStruct.items);

      setTask({
        taskList: kernel.getTasksListForTaskServer(),
        activeTask: {},
        activeTaskId: null,
        isLoaded: true,
      });
    } catch (error) {
      setIsError(true);
      setErrorMessage('Ошибка загрузки данных сервера задач!');
    }
  };

  const buildConfigurationTree = async () => {
    try {
      const { tree } = await kernel.buildTree(location, activeConfigurationId);

      setConfiguration(tree);
      setConfigurationName(kernel.configuration.name);
      setUserName(kernel.getUser().name);
    } catch (error) {
      setIsError(true);
      setErrorMessage('Ошибка загрузки конфигурации!');
    }
  };

  const createNewTask = async (data) => {
    try {
      setSendingData(true);
      const nameTask = data.name;
      const typeTask = activeTypeTask;

      // запрос на изменение данных
      const newTask = await TaskServerService.createTask(activeConfigurationId, typeTask, nameTask);
      const taskList = Object.values(kernel.setNewTaskInListTaskForTaskServer(newTask));

      setTask({
        ...task,
        taskList,
      });
      // изменение состояния

      setTimeout(() => {
        setDataSentSuccessfully(true);
        setSendingData(false);
      }, 800);

      setTimeout(() => {
        setDataSentSuccessfully(false);
        setNamePopUpVisible(false);
      }, 1600);
    } catch (error) {
      setNamePopUpVisible(false);
      setSendingData(false);
      addNotification({ messageText: error.message, statusType: error.status });
    }
  };

  useEffect(() => {
    setConfiguration(null);
    buildConfigurationTree();
    getTasksListStruct();

    kernel.timerRefreshToken(); //запуск обновления токена
    kernel.setNavigatePath('/task-server');
    return () => {};
  }, []);

  useEffect(() => {
    const { hasAccessTaskServerModule = undefined } = currentLicense;
    if (hasAccessTaskServerModule !== 'true' || hasAccessTaskServerModule === undefined || hasAccessRightsTaskServerModule === false) {
      navigate('/section-selection');
    }
    return () => {};
  }, [currentLicense]);

  return (
    <div className={styles.taskServer}>
      {isError && (
        <ErrorMessagePopup
          errorMessage={errorMessage}
          actionPerform={() => {
            setIsError(false);
            kernel.setNavigatePath('/task-server');
            navigate('/section-selection');
          }}
        />
      )}
      {namePopUpVisible && (
        <SetNamePopUp
          onSubmit={createNewTask}
          setNamePopUpVisible={setNamePopUpVisible}
          sendingData={sendingData}
          dataSentSuccessfully={dataSentSuccessfully}
          title="Введите название задачи"
          placeholder="Название задачи"
        />
      )}

      <NavApp />
      <ContentApp>
        <div className={styles.headerContainer}>
          <HeaderApp configurationName={configurationName} userName={userName} />
        </div>
        <div className={styles.contentContainer}>
          <div
            className={classNames('treeContainer', rotate && 'collapsedTree')}
            onContextMenu={(event) => {
              event.preventDefault();
            }}
          >
            <div className={classNames(styles.treeWrap, rotate && 'collapsedTreeWrap')}>
              <TreeTask
                task={task}
                setTask={setTask}
                setTypeComponents={setTypeComponents}
                activeTypeTask={activeTypeTask}
                setActiveTypeTask={setActiveTypeTask}
              />
            </div>
            <BsArrowRightCircleFill
              className={classNames(styles.treeControl, rotate && styles.treeControlActive)}
              onClick={() => setRotate(!rotate)}
            />
          </div>

          <div className={styles.codeEditorContainer}>
            {typeComponents === 1 ? (
              activeTypeTask ? (
                <div className={styles.taskAdd} onClick={() => setNamePopUpVisible(true)}>
                  <div className={styles.taskAddContainer}>
                    <AiOutlinePlus className={styles.taskAddIcon} />
                  </div>
                  <p className={styles.taskAddText}>Создать задачу</p>
                </div>
              ) : (
                <p className={styles.taskAddTextContainer}>Выберете тип задачи в дереве!</p>
              )
            ) : (
              <TaskEditor
                task={task}
                setTask={setTask}
                typeComponents={typeComponents}
                sendingData={sendingData}
                dataSentSuccessfully={dataSentSuccessfully}
                setSendingData={setSendingData}
                setDataSentSuccessfully={setDataSentSuccessfully}
                configuration={configuration}
              />
            )}
          </div>
        </div>
      </ContentApp>
    </div>
  );
}
