import { MdTask } from 'react-icons/md';
import classNames from 'classnames';

import { IconFolderConfigurationTree } from '../../../components.mjs';
import { useKernel } from '../../../../context/ContextKernel.mjs';

import styles from './taskTreeItem.module.scss';

export function TaskTreeItem(props) {
  const { taskItem, task, setTask, setTypeComponents, activeTypeTask, setActiveTypeTask } = props;
  const { kernel } = useKernel();

  const handleDoubleClickTypeTask = (event) => {
    const typeTaskId = event.currentTarget.dataset.typeTaskId;
    const newTaskList = kernel.setIsOpenTaskTreeItem(taskItem.typeTask);
    setTask({
      ...task,
      taskList: newTaskList,
      activeTask: {},
      activeTaskId: null,
    });
    setTypeComponents(1);
    setActiveTypeTask(typeTaskId);
  };

  const handleClickTypeTask = (event) => {
    const typeTaskId = event.currentTarget.dataset.typeTaskId;
    setTask({
      ...task,
      activeTask: {},
      activeTaskId: null,
    });
    setTypeComponents(1);
    setActiveTypeTask(typeTaskId);
  };

  const handleClickTask = (id) => {
    const newActiveTask = kernel.getTaskByIdAndTypeTask(id, taskItem.typeTask);
    setTask({
      ...task,
      activeTask: newActiveTask,
      activeTaskId: newActiveTask.id,
    });
    setTypeComponents(2);
    setActiveTypeTask(null);
  };

  return (
    <div className={styles.wrap}>
      <div
        className={classNames(styles.typeTask, parseInt(activeTypeTask) === taskItem.typeTask && styles.taskActive)}
        title={taskItem?.typeName}
        data-type-task-id={taskItem.typeTask}
        onDoubleClick={handleDoubleClickTypeTask}
        onClick={handleClickTypeTask}
      >
        <IconFolderConfigurationTree showChildren={taskItem.isOpen} isDeleted={false} />
        <span className={styles.typeTaskText}>{taskItem?.typeName}</span>
      </div>
      <div className={styles.taskContainer}>
        {taskItem.isOpen &&
          taskItem.children.map((taskItemChildren) => {
            return (
              <div
                key={taskItemChildren.id}
                data-task-id={taskItemChildren.id}
                className={classNames(styles.taskWrapper, taskItem.isOpen && styles.taskWrapperActive)}
                onClick={(event) => handleClickTask(event.currentTarget.dataset.taskId)}
              >
                <div className={classNames(styles.task, task.activeTaskId === taskItemChildren.id && styles.taskActive)}>
                  <MdTask className={styles.taskIcon} />
                  <span className={styles.taskText}>{taskItemChildren.nameTask}</span>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}
