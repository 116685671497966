import { LightenDarkenColor } from './model/lightenDarkenColor.mjs';
import styles from './preload.module.scss';

export default function Preload(props) {
  const { width, height, marginBottom = 0, backgroundColor = '#2f2e41' } = props;

  const color =  LightenDarkenColor(backgroundColor, 20);
  
  return (
    <div
      className={styles.preload}
      style={{
        width: width,
        height: height,
        marginBottom: marginBottom,
        backgroundImage: `linear-gradient(90deg, ${backgroundColor} 0px, ${color} 30px, ${backgroundColor} 55px)`,
      }}
    ></div>
  );
}
