import { Struct } from '../../../Scada/Parser/Struct.mjs';

class RulesStruct extends Struct {
  constructor(
    id = 0n,
    groupId = 0n,
    configId = 0n,
    parameterId = 0n,
    type = 0n,
  ) {
    super();
    this.id = id;
    this.groupId = groupId;
    this.configId = configId;
    this.parameterId = parameterId;
    this.type = type;
  }

  readFrom(dataView, startOffset, isLittleEndian = true) {
    let endOffset = startOffset;
    this.id = dataView.getBigUint64(endOffset, isLittleEndian);
    endOffset += 8;
    this.groupId = dataView.getBigUint64(endOffset, isLittleEndian);
    endOffset += 8;
    this.configId = dataView.getBigUint64(endOffset, isLittleEndian);
    endOffset += 8;
    this.parameterId = dataView.getBigUint64(endOffset, isLittleEndian);
    endOffset += 8;
    this.type = dataView.getBigUint64(endOffset, isLittleEndian);
    endOffset += 8;

    return endOffset;
  }

  writeTo(dataView, startOffset, isLittleEndian = true) {
    let endOffset = startOffset;
    dataView.setBigUint64(endOffset, this.id, isLittleEndian);
    endOffset += 8;
    dataView.setBigUint64(endOffset, this.groupId, isLittleEndian);
    endOffset += 8;
    dataView.setBigUint64(endOffset, this.configId, isLittleEndian);
    endOffset += 8;
    dataView.setBigUint64(endOffset, this.parameterId, isLittleEndian);
    endOffset += 8;
    dataView.setBigUint64(endOffset, this.type, isLittleEndian);
    endOffset += 8;

    return endOffset;
  }

  getCurrentSize() {
    return 0
      + 8 // id
      + 8 // groupId
      + 8 // configId
      + 8 // parameterId
      + 8; // type
  }

  clone() {
    const clone = new RulesStruct(
      this.id,
      this.groupId,
      this.configId,
      this.parameterId,
      this.type,
    );

    clone.setParserOptions(this.getParserOptions());
    return clone;
  }
}

export {

  RulesStruct,
};