class HandbookList {
  constructor(kernel, handbookByParameterIdListStruct) {
    this.kernel = kernel;
    this.handbookByParameterIdListStruct = handbookByParameterIdListStruct;
  }
  
  get id() {
    return this.handbookByParameterIdListStruct.id;
  }
  
  get parameterId() {
    return this.handbookByParameterIdListStruct.parameterId;
  }

  get dictId() {
    return this.handbookByParameterIdListStruct.dictId;
  }
  
  get handbookContentList() {
    return this.kernel.handbookContentListStruct.hasOwnProperty(this.handbookByParameterIdListStruct.id) ? this.kernel.handbookContentListStruct[this.handbookByParameterIdListStruct.id] : [];
  }
}
  
export { HandbookList };