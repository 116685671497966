import classNames from 'classnames';
import { MdSearch } from 'react-icons/md';
import PropTypes from 'prop-types';

import styles from './inputSearch.module.scss';

export default function InputSearch(props) {
  const { searchState, setSearchState, classes = null } = props;

  const handleChangeSearch = ({ target }) => {
    setSearchState(target.value);
  };

  return (
    <div className={classNames(styles.search, classes)}>
      <input className={styles.searchInput} type="text" value={searchState} onInput={handleChangeSearch} />
      <MdSearch className={classNames(styles.searchIcon, searchState?.length > 0 && styles.searchIconActive)} />
    </div>
  );
}

InputSearch.propTypes = {
  searchState: PropTypes.string.isRequired,
  setSearchState: PropTypes.func.isRequired,
  classes: PropTypes.string,
};
