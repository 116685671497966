import { SetPointsListStruct } from '../quantum-lib/Arm/Parser/SetPoints/SetPointsListStruct.mjs';
import { SetPointsStruct } from '../quantum-lib/Arm/Parser/SetPoints/SetPointsStruct.mjs';
import { ErrorsApp } from '../utils/errors-app/ErrorsApp.mjs';

import { setWorkingMode } from './services.mjs';

export class SetpointsService {
  static async getSetpointsListStruct(idConfiguration) {
    const response = await fetch(`${setWorkingMode()}/get-setpoints-list-struct/${idConfiguration}`, {
      method: 'get',
      cache: 'reload',
      credentials: 'omit',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
    if (!response.ok) {
      throw new ErrorsApp("Произошла ошибка при получении уставок!", 2);
    }
    const arrayBuffer = await response.arrayBuffer();

    const isLittleEndian = true;
    const offset = 0;
    const dataView = new DataView(arrayBuffer);

    const setPointsListStruct = new SetPointsListStruct();
    setPointsListStruct.readFrom(dataView, offset, isLittleEndian);

    return setPointsListStruct;
  }

  static async addSetpoint(idConfiguration, params) {
    const response = await fetch(`${setWorkingMode()}/add-setpoint/${idConfiguration}`, {
      method: 'post',
      cache: 'reload',
      credentials: 'omit',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify(params),
    });

    if (!response.ok) {
      throw new ErrorsApp("Произошла ошибка при создании произвольной уставки!", 2);
    }
    const arrayBuffer = await response.arrayBuffer();

    const isLittleEndian = true;
    const offset = 0;
    const dataView = new DataView(arrayBuffer);

    const setPointsStruct = new SetPointsStruct();
    setPointsStruct.readFrom(dataView, offset, isLittleEndian);

    return setPointsStruct;
  }

  static async addTemplatesSetpoint(idConfiguration, params) {
    const response = await fetch(`${setWorkingMode()}/add-templates-setpoint/${idConfiguration}`, {
      method: 'post',
      cache: 'reload',
      credentials: 'omit',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify(params),
    });

    if (!response.ok) {
      throw new ErrorsApp("Произошла ошибка при создании шаблонной уставки!", 2);
    }
    const arrayBuffer = await response.arrayBuffer();

    const isLittleEndian = true;
    const offset = 0;
    const dataView = new DataView(arrayBuffer);

    const setPointsStruct = new SetPointsStruct();
    setPointsStruct.readFrom(dataView, offset, isLittleEndian);

    return setPointsStruct;
  }

  static async changeSetpointParameters(idConfiguration, params) {
    const response = await fetch(`${setWorkingMode()}/change-setpoint-parameters/${idConfiguration}`, {
      method: 'put',
      cache: 'reload',
      credentials: 'omit',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify(params),
    });

    if (!response.ok) {
      throw new ErrorsApp("Произошла ошибка при изменении уставки!", 2);
    }
    const arrayBuffer = await response.arrayBuffer();

    const isLittleEndian = true;
    const offset = 0;
    const dataView = new DataView(arrayBuffer);

    const setPointsStruct = new SetPointsStruct();
    setPointsStruct.readFrom(dataView, offset, isLittleEndian);

    return setPointsStruct;
  }

  static async changeSetpointOptions(idConfiguration, params) {
    const response = await fetch(`${setWorkingMode()}/change-setpoint-options/${idConfiguration}`, {
      method: 'put',
      cache: 'reload',
      credentials: 'omit',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify(params),
    });

    if (!response.ok) {
      throw new ErrorsApp("Произошла ошибка при изменении уставки!", 2);
    }
    const arrayBuffer = await response.arrayBuffer();

    const isLittleEndian = true;
    const offset = 0;
    const dataView = new DataView(arrayBuffer);

    const setPointsStruct = new SetPointsStruct();
    setPointsStruct.readFrom(dataView, offset, isLittleEndian);

    return setPointsStruct;
  }

  static async deleteSetpoint(idConfiguration, setpointId) {
    const response = await fetch(`${setWorkingMode()}/delete-setpoint/${idConfiguration}`, {
      method: 'delete',
      cache: 'reload',
      credentials: 'omit',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify({ setpointId }),
    });

    if (!response.ok) {
      throw new ErrorsApp("Произошла ошибка при удалении уставки!", 2);
    }
  }
}

