export const EnumSourceAlarm = {
  0: { value: 0, text: "Не инициализирован" },
  1: { value: 1, text: "Текущее значение параметра равно заданному" },
  2: { value: 2, text: "Архивное значение параметра" },
  3: { value: 3, text: "Контроль скорости нарастания, снижения" },
  4: { value: 4, text: "Допустимый диапазон. Задаются 2 границы верхняя и нижняя по текущему параметру" },
  5: { value: 5, text: "Вычисляемая функция" },
  6: { value: 6, text: "Допустимый диапазон с зонами предупреждения. Задаются 4 границы верхние и нижние аварийные и предупредительные по текущему параметру" },
  7: { value: 7, text: "Текущее значение параметра больше заданного" },
  8: { value: 8, text: "Текущее значение параметра меньше заданного" },
  9: { value: 9, text: "Текущее значение параметра НЕ равно заданному" },
  10: { value: 10, text: "Текущее значение параметра повысилось более определенного значения на соседнем измерении" },
  11: { value: 11, text: "Текущее значение параметра понизилось более определенного значения на соседнем измерении" },
  12: { value: 12, text: "Текущее значение параметра изменилось" },
  13: { value: 13, text: "Диапазон. Задаются 2 границы верхняя и нижняя по текущему параметру. Событие наступает если значение в диапазоне" },
  14: { value: 14, text: "Вычисляемая функция с учетом архивов" },
};

export const EnumTypeAlarm = {
  0: { value: 0, text: "Не инициализирован" },
  1: { value: 1, text: "Тревога" },
  2: { value: 2, text: "Предупреждение" },
  3: { value: 3, text: "Уведомление" },
};

export const EnumStatusAlarm = {
  0: { value: 0, text: "Не инициализирован" },
  1: { value: 1, text: "Установлена" },
  2: { value: 2, text: "Квитирована" },
};
