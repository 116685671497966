import classNames from 'classnames';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { IoIosArrowRoundForward } from 'react-icons/io';

import { useKernel } from '../../context/ContextKernel.mjs';
import { PARAMETER_CLASS_PROPERTY, PARAMETER_CLASS_VALUE } from '../../global/variables/objectTypeVariables.mjs';
import { useActiveConfigurationId } from '../../hooks/useActiveConfigurationId.mjs';

import { getSnapshotTypes, getTypeByTypeId, getObjectType, getSystemType } from './model/index.mjs';
import styles from './informationParameter.module.scss';

export default function InformationParameter() {
  const {
    kernel,
    showInformationParameter,
    setShowInformationParameter,
    setConfiguration,
    setStateElementConfigurationId,
    setSearchConfigurationState,
  } = useKernel();
  const location = useLocation();
  const { activeConfigurationId } = useActiveConfigurationId();
  const parameterExtended = kernel.getParameterById(showInformationParameter?.id);
  const { id, displayName, systemType, blockType, isLink, snapshotType, description, linkPath } = parameterExtended;
  const classify = parameterExtended.classify();

  function closePopupOnClick(event){
    if(event.target !== undefined && event.target.className === styles.wrapper){
      setShowInformationParameter({ ...showInformationParameter, open: false });
    }
  }

  function closePopupOnEsc(event){
    if(event.key === 'Escape'){
      setShowInformationParameter({ ...showInformationParameter, open: false });
    }
  }

  useEffect(() => {
    document.body.addEventListener('click', closePopupOnClick);
    document.body.addEventListener('keydown', closePopupOnEsc);
    return () => {
      document.body.removeEventListener('click', closePopupOnClick);
      document.body.removeEventListener('keydown', closePopupOnEsc);
    };
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <AiOutlineCloseCircle
          className={styles.buttonClose}
          onClick={() => setShowInformationParameter({ ...showInformationParameter, open: false })}
        />
        <div className={styles.header}>
          <h2 className={styles.title} title={classify === PARAMETER_CLASS_PROPERTY ? description : displayName}>
            {classify === PARAMETER_CLASS_PROPERTY ? description : displayName}
          </h2>
        </div>
        <div className={styles.list}>
          <div className={styles.listItem}>
            <p className={styles.listDescription}>ID параметра</p>
            <span className={styles.listValue} title={id.toString()}>
              {id.toString()}
            </span>
          </div>
          <div className={styles.listItem}>
            <p className={styles.listDescription}>Тип объекта</p>
            <span className={styles.listValue} title={getObjectType(classify)}>
              {getObjectType(classify)}
            </span>
          </div>
          <div className={styles.listItem}>
            <p className={styles.listDescription}>Системный тип</p>
            <span
              className={styles.listValue}
              title={`#${getSystemType(classify, parameterExtended).id} ${getSystemType(classify, parameterExtended).description}`}
            >
              #{getSystemType(classify, parameterExtended).id} {getSystemType(classify, parameterExtended).description}
            </span>
          </div>
          <div className={styles.listItem}>
            <p className={styles.listDescription}>Базовый тип</p>
            <span className={styles.listValue} title={`#${systemType.type} ${getTypeByTypeId(systemType.type)}`}>
              #{systemType.type} {getTypeByTypeId(systemType.type)}
            </span>
          </div>
          {classify === PARAMETER_CLASS_VALUE && (
            <div className={styles.listItem}>
              <p className={styles.listDescription}>Тип блока</p>
              <span className={styles.listValue} title={`#${blockType.id} ${blockType.description}`}>
                #{blockType.id} {blockType.description}
              </span>
            </div>
          )}
          {classify === PARAMETER_CLASS_VALUE && (
            <div className={styles.listItem}>
              <p className={styles.listDescription}>Тип снапшота</p>
              <span className={styles.listValue} title={getSnapshotTypes(snapshotType)}>
                {getSnapshotTypes(snapshotType)}
              </span>
            </div>
          )}
          {isLink && (
            <div className={styles.listItem}>
              <p className={styles.listDescription}>Ссылка</p>
              <div className={styles.linkContainer}>
                <span className={classNames(styles.text, styles.linkText)}>Параметр является ссылочным.</span>
                {linkPath.map((link, index) => {
                  return (
                    <>
                      <span
                        className={styles.link}
                        onClick={async () => {
                          // костыльное решение
                          setShowInformationParameter(false);
                          setConfiguration(null);
                          setSearchConfigurationState('');
                          const { tree } = await kernel.buildTree(location, activeConfigurationId);

                          const parameterId = kernel.getParameterById(link.id).parent.id;
                          const parameterStruct = kernel.getParameterById(parameterId);
                          const path = parameterStruct.path;

                          path.forEach((parameterExtended) => {
                            kernel.openElementTreeTmp.set(parameterExtended.id, true);
                          });
                          kernel.openElementTreeTmp.set(BigInt(parameterId), false);

                          kernel.openElementTree.clear(); // закрываем все
                          const openedElementConfigurationTree = kernel.openElementTreeTmp;
                          Array.from(openedElementConfigurationTree, ([key, value]) => kernel.openElementTree.set(key, value)); // восстанавливаем состояние дерева (открытые узлы)

                          setConfiguration(tree);
                          kernel.setElementConfigurationId(BigInt(link.id));
                          setStateElementConfigurationId(kernel.elementConfigurationId);
                        }}
                      >
                        {link.id.toString()}
                      </span>
                      {linkPath.length !== index + 1 && <IoIosArrowRoundForward className={styles.linkIcon} />}
                    </>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
