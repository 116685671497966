import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';

import { optionMainSelect } from './model/option.mjs';
import { BuildDayOfWeekSelect } from './ui/build-day-of-week-select/BuildDayOfWeekSelect';
import { BuildDayMonthSelect } from './ui/build-day-month-select/BuildDayMonthSelect';
import styles from './reportingDay.module.scss';

export default function ReportingDay(props) {
  const { value, setValue, canEdit, isWritable, isDeleted, register, unregister, name, setHandleChangeComponentTableRow, sendingData } = props;

  const [buildDayOfWeekSelectShow, setBuildDayOfWeekSelectShow] = useState(false);
  const [buildDayMonthSelectShow, setBuildDayMonthSelectShow] = useState(false);
  const [mainSelectValue, setMainSelectValue] = useState();
  const [dayOfWeekSelectValue, setDayOfWeekSelectValue] = useState();
  const [dayMonthSelectValue, setDayMonthSelectValue] = useState();
  const [valueNotRecognized, setValueNotRecognized] = useState(false);

  const [initialSelectValue, setInitialSelectValue] = useState({ name: '', value: '' });

  const initialValue = useRef(value);

  const setInitialState = () => {
    if (initialValue.current >= 1 && initialValue.current <= 28) {
      setBuildDayMonthSelectShow(true);
      setDayMonthSelectValue(initialValue.current);
      setMainSelectValue('dayMonth');
      setInitialSelectValue({ name: 'dayMonth', value: initialValue.current });
    } else if (initialValue.current >= 31 && initialValue.current <= 37) {
      setBuildDayOfWeekSelectShow(true);
      setDayOfWeekSelectValue(initialValue.current);
      setMainSelectValue('dayOfWeek');
      setInitialSelectValue({ name: 'dayOfWeek', value: initialValue.current });
    } else if (
      initialValue.current !== 0 &&
      initialValue.current !== 29 &&
      initialValue.current !== 30 &&
      initialValue.current !== 38 &&
      initialValue.current !== 'dayOfWeek' &&
      value !== 'dayMonth'
    ) {
      setValueNotRecognized(true);
      setMainSelectValue('valueNotRecognized');
    } else {
      setInitialSelectValue({ name: 'mainSelectReportingDay', value: initialValue.current });
      setMainSelectValue(initialValue.current);
    }
  };

  const handleChangeMainSelect = (event) => {
    if (event.target.value === 'dayOfWeek') {
      setBuildDayOfWeekSelectShow(true);
      setBuildDayMonthSelectShow(false);
      setValueNotRecognized(false);
      setDayOfWeekSelectValue(31);
      setValue(name, 31);
    } else if (event.target.value === 'dayMonth') {
      setBuildDayOfWeekSelectShow(false);
      setBuildDayMonthSelectShow(true);
      setValueNotRecognized(false);
      setDayMonthSelectValue(1);
      setValue(name, 1);
    } else {
      setBuildDayOfWeekSelectShow(false);
      setBuildDayMonthSelectShow(false);
      setValueNotRecognized(false);
      setValue(name, event.target.value);
    }

    if (event.target.value.toString() === initialSelectValue.name.toString()) {
      setHandleChangeComponentTableRow(false);
    } else {
      setHandleChangeComponentTableRow(true);
    }

    setMainSelectValue(event.target.value);
  };

  const handleChangeDayOfWeekSelect = (event) => {
    if (event.target.value.toString() === initialSelectValue.value.toString()) {
      setHandleChangeComponentTableRow(false);
    } else {
      setHandleChangeComponentTableRow(true);
    }
    setDayOfWeekSelectValue(event.target.value);
    setValue(name, event.target.value);
  };

  const handleChangeDayMonthSelect = (event) => {
    if (event.target.value.toString() === initialSelectValue.value.toString()) {
      setHandleChangeComponentTableRow(false);
    } else {
      setHandleChangeComponentTableRow(true);
    }
    setDayMonthSelectValue(event.target.value);
    setValue(name, event.target.value);
  };

  useEffect(() => {
    setInitialState();
    setValue(name, value);
    return () => {
      unregister(name);
    };
  }, []);

  useEffect(() => {
    initialValue.current = value;
    return () => {};
  }, [value]);

  return (
    <div className={styles.reportingDayContainer}>
      <input type="text" style={{ display: 'none' }} {...register(name)} />
      <div className={styles.reportingDayForm}>
        <select
          className={classNames(
            styles.mainSelect,
            styles.reportingDaySelect,
            sendingData && styles.reportingDaySending,
            canEdit && styles.reportingDaySending,
            !isWritable && styles.reportingDaySending,
            isDeleted && styles.reportingDaySending,
          )}
          value={mainSelectValue}
          onInput={handleChangeMainSelect}
          disabled={sendingData || canEdit || !isWritable || isDeleted}
        >
          <>
            {valueNotRecognized ? (
              <option
                className={classNames(styles.reportingDaySelectOption, styles.mainSelectOption)}
                key="valueNotRecognized"
                value="valueNotRecognized"
                disabled
              >
                значение не распознано
              </option>
            ) : (
              <></>
            )}
            {optionMainSelect.map((item) => {
              return (
                <option className={classNames(styles.reportingDaySelectOption, styles.mainSelectOption)} key={item.value} value={item.value}>
                  {item.text}
                </option>
              );
            })}
          </>
        </select>

        {buildDayOfWeekSelectShow && (
          <BuildDayOfWeekSelect
            sendingData={sendingData}
            isWritable={isWritable}
            isDeleted={isDeleted}
            dayOfWeekSelectValue={dayOfWeekSelectValue}
            handleChangeDayOfWeekSelect={handleChangeDayOfWeekSelect}
            canEdit={canEdit}
          />
        )}
        {buildDayMonthSelectShow && (
          <BuildDayMonthSelect
            sendingData={sendingData}
            isWritable={isWritable}
            isDeleted={isDeleted}
            canEdit={canEdit}
            dayMonthSelectValue={dayMonthSelectValue}
            handleChangeDayMonthSelect={handleChangeDayMonthSelect}
          />
        )}
      </div>
    </div>
  );
}
