class InvalidSyntax extends Error {

    constructor(source = '', position = 0, message = '') {
        super(message)
        this.source = source
        this.position = position
    }
}

export {

    InvalidSyntax
}
