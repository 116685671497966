import classNames from 'classnames';
import { useState, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { HiCheck } from 'react-icons/hi';
import { MdDelete, MdKeyboardArrowDown, MdKeyboardArrowUp, MdOutlineHistory, MdOutlineMoreVert } from 'react-icons/md';
import { IoArrowUndoCircle } from 'react-icons/io5';
import { IoMdInformationCircleOutline } from 'react-icons/io';

import Utils from '../../utils/Utils.mjs';
import { ConfigurationService } from '../../services/services.mjs';
import { ComponentTableRow } from '../../hoc/hoc.mjs';
import { ConfirmationPopUpWithTimer, SavePropertiesButton } from '../components.mjs';
import { useKernel } from '../../context/ContextKernel.mjs';
import { useActiveConfigurationId } from '../../hooks/useActiveConfigurationId.mjs';
import { PARAMETER_CLASS_FOLDER, PARAMETER_CLASS_OBJECT, PARAMETER_CLASS_VALUE } from '../../global/variables/objectTypeVariables.mjs';

import styles from './tableRow.module.scss';

export default function TableRow(props) {
  const {
    id,
    sortOrderId,
    canEdit,
    isWritable,
    isDeleted,
    description,
    systemType,
    setComponentsTypeId,
    propertyValueTimestamp,
    propertyValueRoundUp,
    setProperty,
    buildConfigurationTree,
    properties,
    setProperties,
  } = props;

  const [handleChangeComponentTableRow, setHandleChangeComponentTableRow] = useState(false);
  const [sendingData, setSendingData] = useState(false);
  const [dataSentSuccessfully, setDataSentSuccessfully] = useState(false);
  const [popupStateDeleteProperty, setPopupStateDeleteProperty] = useState({ isVisible: false, message: '', canSave: false, sending: false });
  const [popupStateRecoveryProperty, setPopupStateRecoveryProperty] = useState({ isVisible: false, message: '', canSave: false, sending: false });
  const { activeConfigurationId } = useActiveConfigurationId();
  const { kernel, currentLicense, addNotification, setContextMenu, setShowInformationParameter } = useKernel();
  const { hasAccessDeleteProperties = undefined, hasAccessRecoveryProperties = undefined } = currentLicense;
  const property = kernel.getParameterById(id);

  const { hasAccessMovingObjects = undefined } = currentLicense;

  const {
    register,
    unregister,
    clearErrors,
    setValue,
    getValues,
    setFocus,
    trigger,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm({ mode: 'onChange' });

  const setPropertyHistoryComponents = () => {
    setProperty(property);
    setComponentsTypeId(1);
  };

  const saveProperty = async (data) => {
    let value = data.value;

    if (value === 'true') value = true;

    if (value === 'false') value = false;

    setHandleChangeComponentTableRow(false);
    setSendingData(true);
    try {
      await kernel.setValues([{ parameterStruct: property, newValue: value }]);

      setDataSentSuccessfully(true);
      setTimeout(() => {
        setDataSentSuccessfully(false);
        setSendingData(false);
      }, 1000);
    } catch (error) {
      setSendingData(false);

      //показать сообщение об ошибке
      addNotification({ messageText: error.message, statusType: error.status });
      setHandleChangeComponentTableRow(true);
    }
  };

  const deleteProperty = async () => {
    try {
      setPopupStateDeleteProperty({ ...popupStateDeleteProperty, sending: true });
      const propertyId = id.toString();

      await ConfigurationService.deletePropertyById(propertyId, activeConfigurationId);
      await buildConfigurationTree();

      setPopupStateDeleteProperty({ ...popupStateDeleteProperty, sending: false, isVisible: false, canSave: false });
    } catch (error) {
      setPopupStateDeleteProperty({ ...popupStateDeleteProperty, sending: false, isVisible: false, canSave: false });
      //показать сообщение об ошибке
      addNotification({ messageText: error.message, statusType: error.status });
      setHandleChangeComponentTableRow(true);
    }
  };

  const recoveryProperty = async () => {
    try {
      setPopupStateRecoveryProperty({ ...popupStateRecoveryProperty, sending: true });
      const propertyId = id.toString();

      await ConfigurationService.recoveryPropertyById(propertyId, activeConfigurationId);
      await buildConfigurationTree();

      setPopupStateRecoveryProperty({ ...popupStateRecoveryProperty, sending: false, isVisible: false, canSave: false });
    } catch (error) {
      setPopupStateRecoveryProperty({ ...popupStateRecoveryProperty, sending: false, isVisible: false, canSave: false });
      //показать сообщение об ошибке
      addNotification({ messageText: error.message, statusType: error.status });
      setHandleChangeComponentTableRow(true);
    }
  };

  const openContextMenu = useCallback(
    (event) => {
      event.stopPropagation();
      const { clientX, clientY, currentTarget } = event;
      const parameter = kernel.getParameterById(id); //получение параметра на котором было вызвано контекстное меню
      const property = kernel.getParameterById(id);

      const contextMenuSettings = [
        {
          id: 0,
          icon: <MdOutlineHistory className={styles.icon} />,
          text: 'История',
          renderElement: true,
          isActive: false,
          onClick: setPropertyHistoryComponents,
        },
        {
          id: 1,
          icon: <IoMdInformationCircleOutline className={styles.icon} />,
          text: 'Справка',
          renderElement: true,
          isActive: false,
          onClick: () => {
            setShowInformationParameter({ open: true, id: id });
          },
        },
        {
          id: 2,
          icon: <IoArrowUndoCircle className={styles.icon} title="Восстановить свойство" />,
          text: 'Восстановить',
          renderElement: !property.parent.isDeleted && property.isDeleted && hasAccessRecoveryProperties === 'true',
          isActive: false,
          onClick: () => {
            const parameterStruct = property;
            const parameterStructParent = property.parent;
            const parentType = parameterStructParent.classify();

            let parentTypeValue;
            if (parentType === PARAMETER_CLASS_FOLDER) parentTypeValue = 'папки';
            if (parentType === PARAMETER_CLASS_OBJECT) parentTypeValue = 'объекта';
            if (parentType === PARAMETER_CLASS_VALUE) parentTypeValue = 'параметра';

            setPopupStateRecoveryProperty({
              ...popupStateRecoveryProperty,
              message: `Вы точно хотите восстановить свойство "${parameterStruct.description}" у ${parentTypeValue} "${parameterStructParent.displayName}". Данное действие может привести к сбою работы устройства или программного обеспечения!`,
              isVisible: true,
            });
          },
        },
        {
          id: 3,
          icon: <MdDelete className={classNames(styles.deleteIcon, styles.icon)} title="Пометить на удаление свойство" />,
          text: 'Удалить',
          renderElement: !property.parent.isDeleted && !property.isDeleted && hasAccessDeleteProperties === 'true',
          isActive: false,
          onClick: () => {
            const parameterStruct = property;
            const parameterStructParent = property.parent;
            const parentType = parameterStructParent.classify();

            let parentTypeValue;
            if (parentType === PARAMETER_CLASS_FOLDER) parentTypeValue = 'папки';
            if (parentType === PARAMETER_CLASS_OBJECT) parentTypeValue = 'объекта';
            if (parentType === PARAMETER_CLASS_VALUE) parentTypeValue = 'параметра';

            setPopupStateDeleteProperty({
              ...popupStateDeleteProperty,
              message: `Вы точно хотите пометить на удаление свойство "${parameterStruct.description}" у ${parentTypeValue} "${parameterStructParent.displayName}". Данное действие может привести к сбою работы устройства или программного обеспечения!`,
              isVisible: true,
            });
          },
          className: styles.delete,
        },
      ];

      setContextMenu(contextMenuSettings, [clientX, clientY], parameter, currentTarget, 'top');
    },
    [setContextMenu],
  );

  const sortOrderInDb = async (newPropertyList) => {
    try {
      const newParameterSortedList = newPropertyList.map((parameterStructExtended, index) => {
        return { parameterId: parameterStructExtended.id.toString(), sortOrderId: index };
      });

      await ConfigurationService.setParameterSortByParameterId(activeConfigurationId, newParameterSortedList);
      kernel.getParameterById(id).parent.properties = newPropertyList;
    } catch (error) {
      const propertiesList = kernel.getParameterById(id).parent.properties;
      addNotification({ messageText: error.message, statusType: error.status });
      setProperties(propertiesList);
    }
  };

  const sortOrderDecrement = (event) => {
    const propertyTmp = [...properties];

    propertyTmp.splice(sortOrderId - 1, 0, propertyTmp.splice(sortOrderId, 1)[0]);
    setProperties(propertyTmp);
    sortOrderInDb(propertyTmp);
  };

  const sortOrderIncrement = (event) => {
    const propertyTmp = [...properties];

    propertyTmp.splice(sortOrderId + 1, 0, propertyTmp.splice(sortOrderId, 1)[0]);
    setProperties(propertyTmp);
    sortOrderInDb(propertyTmp);
  };

  const parameterStruct = property.parent.parent; // сам объект
  const coordObjectParameters = parameterStruct.properties.find((property) => property.systemTypeId === 123);
  const coordObject = coordObjectParameters ? coordObjectParameters.propertyValueRoundUp : '';

  return (
    <tr className={classNames(styles.tableRow)} data-id={id} data-sort-order-id={sortOrderId}>
      {/* подтверждение удаления свойства*/}
      {popupStateDeleteProperty.isVisible && (
        <ConfirmationPopUpWithTimer callBack={deleteProperty} setState={setPopupStateDeleteProperty} state={popupStateDeleteProperty} />
      )}

      {/* подтверждение восстановления свойства*/}
      {popupStateRecoveryProperty.isVisible && (
        <ConfirmationPopUpWithTimer callBack={recoveryProperty} setState={setPopupStateRecoveryProperty} state={popupStateRecoveryProperty} />
      )}

      <td className="tableRowPropertyTd">
        <span className={styles.tableRowText} title={description}>
          {description}
        </span>
      </td>
      <td className="tableRowPropertyTd">
        <form id={id} onSubmit={(event) => handleSubmit(saveProperty)(event)} style={{ width: '100%' }}>
          <ComponentTableRow
            canEdit={canEdit}
            isWritable={isWritable}
            isDeleted={isDeleted}
            systemType={systemType}
            propertyValueRoundUp={propertyValueRoundUp}
            setHandleChangeComponentTableRow={setHandleChangeComponentTableRow}
            register={register}
            unregister={unregister}
            errors={errors}
            clearErrors={clearErrors}
            setValue={setValue}
            getValues={getValues}
            trigger={trigger}
            saveProperty={saveProperty}
            setFocus={setFocus}
            sendingData={sendingData}
            dataSentSuccessfully={dataSentSuccessfully}
            setComponentsTypeId={setComponentsTypeId}
            coordObject={coordObject}
          />
        </form>
      </td>
      <td className="tableRowPropertyTd">
        <span className={classNames(styles.tableRowText, styles.tableDate)}>{Utils.convertTimestamp(propertyValueTimestamp)}</span>
        <span className={styles.tableRowText}>{Utils.convertTimestampGetHour(propertyValueTimestamp)}</span>
      </td>
      <td className="tableRowPropertyTd">
        {dataSentSuccessfully ? (
          <HiCheck className={styles.checkControl} />
        ) : (
          <SavePropertiesButton
            id={id}
            sendingData={sendingData}
            canEdit={canEdit}
            isWritable={isWritable}
            isDeleted={isDeleted}
            isValid={isValid}
            handleChangeComponentTableRow={handleChangeComponentTableRow}
          />
        )}
        {kernel.getUser().settings.isRootUser && hasAccessMovingObjects === 'true' && (
          <div className={styles.moveControlContainer}>
            <button type="button" className={styles.control} onClick={sortOrderDecrement} disabled={!isWritable || canEdit || sortOrderId === 0}>
              <MdKeyboardArrowUp className={classNames(styles.ArrowUp, (!isWritable || canEdit || sortOrderId === 0) && styles.disabled)} />
            </button>
            <button
              type="button"
              className={styles.control}
              onClick={sortOrderIncrement}
              disabled={!isWritable || canEdit || sortOrderId + 1 === kernel.getParameterById(id).parent.properties.length}
            >
              <MdKeyboardArrowDown
                className={classNames(
                  styles.ArrowDown,
                  (!isWritable || canEdit || sortOrderId + 1 === kernel.getParameterById(id).parent.properties.length) && styles.disabled,
                )}
              />
            </button>
          </div>
        )}

        <button type="button" onClick={(event) => openContextMenu(event)} disabled={!isWritable || canEdit}>
          <MdOutlineMoreVert
            className={classNames(styles.moreControl, (!isWritable || canEdit) && styles.disabled)}
            title={Utils.titleFromMoreControl(isWritable, canEdit)}
          />
        </button>
      </td>
    </tr>
  );
}
