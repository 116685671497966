import classNames from 'classnames';
import { forwardRef, useEffect, useRef, useState } from 'react';
import { IMaskInput } from 'react-imask';

import { Tooltip } from '../components.mjs';
import Utils from '../../utils/Utils.mjs';

import styles from './inputPhone.module.scss';

const InputPhone = forwardRef((props) => {
  const {
    value,
    setValue,
    canEdit,
    isWritable,
    isDeleted,
    register,
    unregister,
    errors,
    handleFocus,
    showTooltip,
    validationScheme,
    name,
    setHandleChangeComponentTableRow,
    ref,
    inputRef,
    clearErrors,
    sendingData,
  } = props;

  const [state, setState] = useState(value);
  const [incorrectValue, setIncorrectValue] = useState();
  const [inputPhoneInitialValueValid, setInputPhoneInitialValueValid] = useState(true);
  const [autoFocusState, setAutoFocusState] = useState(false);

  const initialValue = useRef(value);

  const registerState = { ...register(name, validationScheme) };

  const handleAccept = (inputValue, mask) => {
    if (inputValue !== '') {
      setState(inputValue);
      setValue(name, mask.unmaskedValue);
    } else {
      setValue(name, '');
      setValue('');
      clearErrors(name);
      setHandleChangeComponentTableRow(true);
    }

    if (inputValue.toString() === initialValue.current.toString()) {
      setHandleChangeComponentTableRow(false);
    } else {
      setHandleChangeComponentTableRow(true);
    }
  };

  useEffect(() => {
    const testInputPhoneInitialValue = Utils.testInputPhoneInputPhoneInitialValue(value);
    setInputPhoneInitialValueValid(testInputPhoneInitialValue);
    if (testInputPhoneInitialValue) {
      setValue(name, value);
      setState(value);
    } else {
      setIncorrectValue(value);
    }
    return () => {
      unregister([name]);
    };
  }, []);

  useEffect(() => {
    initialValue.current = value;
    return () => {};
  }, [value]);

  return (
    <div className={styles.inputPhoneContainer}>
      <div className={styles.inputPhoneForm}>
        {inputPhoneInitialValueValid ? (
          <>
            <IMaskInput
              type="tel"
              mask="+{7} (000) 000-00-00"
              className={classNames(
                styles.inputPhone,
                errors[name] && styles.inputPhoneError,
                sendingData && styles.inputPhoneSending,
                canEdit && styles.inputPhoneSending,
                !isWritable && styles.inputPhoneIsWritableFalse,
                isDeleted && styles.inputPhoneIsWritableFalse,
              )}
              value={state}
              ref={ref}
              inputRef={inputRef}
              placeholder="+7 (920) 000-00-00"
              onFocus={handleFocus}
              autoFocus={autoFocusState}
              readOnly={sendingData || canEdit || !isWritable || isDeleted}
              onAccept={(value, mask, event) => {
                handleAccept(value, mask);
                if (autoFocusState) {
                  registerState.onChange(event);
                }
              }}
              {...registerState}
            />

            {showTooltip && errors[name] && <Tooltip message={`${errors[name]?.message || 'Телефон введен неверно!'} `} />}
          </>
        ) : (
          <div className={styles.inputTextContainer}>
            <input
              type="text"
              placeholder="Некорректные данные"
              value={incorrectValue}
              className={classNames(styles.inputText, styles.inputTextError)}
              onFocus={() => {
                clearErrors(name);
                setValue(name, '');
                setState('');
                setHandleChangeComponentTableRow(true);
                setInputPhoneInitialValueValid(true);
                setAutoFocusState(true);
              }}
            />
            {<Tooltip message={'Значения, хранящиеся в БД, не соответствуют системному типу!'} />}
          </div>
        )}
      </div>
    </div>
  );
});

export default InputPhone;
