import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import classNames from "classnames";
import { MdDelete } from "react-icons/md";
import { IoIosSave } from "react-icons/io";

import { useKernel } from "../../../context/ContextKernel.mjs";
import { LoadingInTableSave } from "../../components.mjs";

import styles from './handbookContent.module.scss';


export default function HandbookContent(props){
  const { handbook, setHandbook, content, updateContent, deletePopup, addNotification } = props;

  const { kernel } = useKernel();

  const [sendingData, setSendingData] = useState(false);
  const [dataSentSuccessfully, setDataSentSuccessfully] = useState(false);
  const [status, setStatus] = useState({ loading: false, success: false });

  const [state, setState] = useState({key:'', name:''});

  const [hasHover, setHasHover] = useState(false);

  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    setValue,
    trigger,
  } = useForm({
    mode: 'onChange',
  });

  const handleChangeKey = ({ target: { value } }) => {
    setState({
      ...state,
      key: value,
    });
  };

  const handleChangeName = ({ target: { value } }) => {
    setState({
      ...state,
      name: value,
    });
  };

  const updateHandbookContent = () => {
    setSendingData(true);
    try {
      updateContent(content.id, state.key, state.name);

      setDataSentSuccessfully(true);
      setTimeout(() => {
        setDataSentSuccessfully(false);
        setSendingData(false);
      }, 1000);
    } catch (error) {
      setSendingData(false);

      addNotification({ messageText: error.message, statusType: error.status });
    }
  };

  useEffect(() => {
    setState({
      key: content.key,
      name: content.name,
    });

    setValue('key', content.key);
    setValue('name', content.name);

    trigger('key');
    return () => {};
  }, [content.id]);

  return (
    <div className={classNames(styles.handbookContentEditorContainer/*, hasHover && styles.handbookContentEditorContainerDelete*/)}>
      <div className={styles.contentBox}>
        <input
          className={classNames(
            styles.textField,
            errors.key && styles.textFieldError,
            (sendingData || dataSentSuccessfully) && styles.sending,
          )}
          type="text"
          value={state.key}
          onInput={handleChangeKey}
          disabled={sendingData || dataSentSuccessfully}
          {...register('key', {
            required: 'Поле обязательно к заполнению',
            minLength: {
              value: 1,
              message: 'Поле должно содержать минимум 1 символ',
            },
            maxLength: {
              value: 255,
              message: 'Поле может содержать максимум 255 символа',
            },
          })}
        />
        {errors?.key && <p className={styles.errorMessage}>{errors?.key?.message || 'Ошибка заполнения!'}</p>}
      </div>
      <div className={styles.contentBox}>
        <input
          className={classNames(
            styles.textField,
            errors.name && styles.textFieldError,
            (sendingData || dataSentSuccessfully) && styles.sending,
          )}
          type="text"
          value={state.name}
          onInput={handleChangeName}
          disabled={sendingData || dataSentSuccessfully}
          {...register('name', {
            required: 'Поле обязательно к заполнению',
            minLength: {
              value: 1,
              message: 'Поле должно содержать минимум 1 символ',
            },
            maxLength: {
              value: 255,
              message: 'Поле может содержать максимум 255 символа',
            },
          })}
        />
        {errors?.name && <p className={styles.errorMessage}>{errors?.name?.message || 'Ошибка заполнения!'}</p>}
      </div>
      {/* {(kernel.getUser().settings.hasParameterIdInTree ? ' #' + id : '')} */}
      <div className={styles.buttonContainer}>
        {
          sendingData ? (
            <LoadingInTableSave />
          ) :(<IoIosSave className={styles.saveIcon} onClick={updateHandbookContent}/>)
        }
      </div>
      <div className={styles.buttonContainer}>
        <MdDelete
          className={styles.deleteIcon}
          data-id={handbook.activeHandbookId}
          onMouseEnter={() => setHasHover(true)}
          onMouseLeave={() => setHasHover(false)}
          onClick={() => {setHandbook({...handbook, activeContentId:content.id}); deletePopup(true);}}
        />
      </div>
    </div>
  );

}