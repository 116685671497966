export const optionMainSelect = [
  { value: 0, text: 'Не установлен' },
  { value: 29, text: 'Каждый день' },
  { value: 30, text: 'Последний день месяца' },
  { value: 38, text: '1,8,15,22 числа' },
  { value: 'dayOfWeek', text: 'День недели' },
  { value: 'dayMonth', text: 'Число месяца' },
];

export const optionDayOfWeekSelect = [
  { value: 31, text: 'Понедельник' },
  { value: 32, text: 'Вторник' },
  { value: 33, text: 'Среда' },
  { value: 34, text: 'Четверг' },
  { value: 35, text: 'Пятница' },
  { value: 36, text: 'Суббота' },
  { value: 37, text: 'Воскресенье' },
];