import classNames from 'classnames';
import { useCallback, useEffect, useRef, useState } from 'react';
import { LuSettings2 } from 'react-icons/lu';
import { AiFillCode } from 'react-icons/ai';

import { SetpointsService } from '../../services/services.mjs';
import { useKernel } from '../../context/ContextKernel.mjs';
import { ConfirmationPopUp, SetNamePopUp, SetTemplateTaskPopUp, Tabs } from '../components.mjs';
import { useActiveConfigurationId } from '../../hooks/useActiveConfigurationId.mjs';

import { SetpointArguments, SetpointOptions, SetpointsList } from './ui/index.mjs';
import styles from './setpointsEditor.module.scss';

const tabsList = [
  {
    id: 1,
    icon: <LuSettings2 className="tabListIcon" />,
    text: 'Общие',
    renderingConditions: true,
  },
  {
    id: 2,
    icon: <AiFillCode className="tabListIcon" />,
    text: 'Параметры',
    renderingConditions: true,
  },
];

export default function SetpointsEditor(props) {
  const {
    configuration,
    setpointsEditorState,
    setSetpointsEditorState,
    treeTab,
    setTreeTemplateState,
    activeElementTree,
    setActiveElementTree,
  } = props;

  const { activeConfigurationId } = useActiveConfigurationId();
  const [sendingData, setSendingData] = useState(false);
  const [dataSentSuccessfully, setDataSentSuccessfully] = useState(false);
  const [namePopUpVisible, setNamePopUpVisible] = useState(false);
  const [tabVisible, setTabVisible] = useState(1);

  const [setpointOptions, setSetpointOptions] = useState({ name: '', comment: '' });
  const [popUpVisible, setPopUpVisible] = useState(false);
  const [status, setStatus] = useState({ loading: false, success: false });
  const { kernel, addNotification, stateElementConfigurationId } = useKernel();
  const classify = kernel.getParameterById(stateElementConfigurationId)?.classify();
  const type = kernel.getParameterById(stateElementConfigurationId)?.typeNode?.systemType?.type;
  const deleteSetpointId = useRef();

  const getActiveSetpoint = useCallback((setpointsListStruct, currentActiveSetpointId) => {
    return setpointsListStruct.find((taskStruct) => taskStruct.id === currentActiveSetpointId) || setpointsListStruct[0];
  }, []);

  const createSpecialSetpoint = async (data) => {
    try {
      setSendingData(true);

      const parameterId = kernel.getParameterById(stateElementConfigurationId).id.toString();

      const newSetpoint = await SetpointsService.addSetpoint(activeConfigurationId, {
        name: data.name,
        parameterId,
      });

      kernel.setNewSetpoint(newSetpoint);

      const setpointsList = kernel.setpoints[newSetpoint.parameterId];
      const activeSetpoint = kernel.getSetpointsByParameterId(parameterId, newSetpoint.id);

      setTimeout(() => {
        setSendingData(false);
        setDataSentSuccessfully(true);
      }, 350);

      setTimeout(() => {
        setSetpointsEditorState({
          setpointsList,
          activeSetpoint,
          activeSetpointId: activeSetpoint.id,
        });

        setDataSentSuccessfully(false);
        setNamePopUpVisible(false);
      }, 1300);
    } catch (error) {
      setSendingData(false);
      setNamePopUpVisible(false);
      addNotification({ messageText: error.message, statusType: error.status });
    }
  };

  const createTemplatesSetpoint = async (data) => {
    try {
      setSendingData(true);

      const newTemplateSetpoint = await SetpointsService.addTemplatesSetpoint(activeConfigurationId, {
        name: data.name,
        systemTypeId: data.systemTypeId,
      });

      kernel.setNewTemplateSetpoint(newTemplateSetpoint);

      const setpointsList = kernel.templateSetpoints[newTemplateSetpoint.systemTypeId].templateSetpointsList;
      const activeSetpoint = kernel.getTemplateSetpointsByParameterId(newTemplateSetpoint.systemTypeId, newTemplateSetpoint.id);

      setTimeout(() => {
        setSendingData(false);
        setDataSentSuccessfully(true);
      }, 350);

      setTimeout(() => {
        setSetpointsEditorState({
          setpointsList,
          activeSetpoint,
          activeSetpointId: activeSetpoint.id,
        });

        const treeTemplateSetpoint = Object.values(kernel.templateSetpoints);
        setTreeTemplateState(treeTemplateSetpoint);
        setActiveElementTree(newTemplateSetpoint.systemTypeId);
        setDataSentSuccessfully(false);
        setNamePopUpVisible(false);
      }, 1300);
    } catch (error) {
      setSendingData(false);
      setNamePopUpVisible(false);
      addNotification({ messageText: error.message, statusType: error.status });
    }
  };

  const handleChangeSetpointsList = (event) => {
    const setpointId = parseInt(event.currentTarget.dataset.id);
    const setpointListStruct = kernel.getSetpointsListByParameterId(stateElementConfigurationId);

    setSetpointsEditorState({
      ...setpointsEditorState,
      activeSetpoint: setpointListStruct.find((setpoint) => setpoint.id === setpointId),
      activeSetpointId: parseInt(setpointId),
    });
  };

  const saveArguments = async (data) => {
    try {
      setSendingData(true);
      const setpointId = setpointsEditorState.activeSetpointId.toString();

      const changeSetpoint = await SetpointsService.changeSetpointParameters(activeConfigurationId, {
        id: setpointsEditorState.activeSetpointId,
        parameterId: data.parameterId,
        systemTypeId: data.systemTypeId,
        typeAlarm: data.typeAlarm,
        typeSourceAlarm: data.typeSourceAlarm,
        value_1: data.value_1,
        value_2: data.value_2,
        value_3: data.value_3,
        value_4: data.value_4,
        value_5: data.value_5,
        value_6: data.value_6,
      });

      // изменяем
      kernel.setChangeSetpointBySetpointId(stateElementConfigurationId, setpointId, changeSetpoint);

      if (treeTab === 1) {
        const setpointsList = kernel.getSetpointsListByParameterId(stateElementConfigurationId);
        setSetpointsEditorState({
          setpointsList,
          activeSetpoint:
            data.parameterId.toString() === stateElementConfigurationId.toString()
              ? setpointsList.find((setpoint) => setpoint.id === parseInt(setpointId))
              : setpointsList[0],
          activeSetpointId: data.parameterId === stateElementConfigurationId.toString() ? BigInt(setpointId) : BigInt(setpointsList[0].id),
        });
      } else {
        const setpointsList = kernel.templateSetpoints[changeSetpoint.systemTypeId].templateSetpointsList;
        const activeSetpoint = kernel.getTemplateSetpointsByParameterId(changeSetpoint.systemTypeId, changeSetpoint.id);

        const treeTemplateList = Object.values(kernel.templateSetpoints);
        setTreeTemplateState(treeTemplateList);
        setActiveElementTree(changeSetpoint.systemTypeId);

        setSetpointsEditorState({
          setpointsList,
          activeSetpoint,
          activeSetpointId: activeSetpoint.id,
        });
      }

      setTimeout(() => {
        setDataSentSuccessfully(true);
        setSendingData(false);
      }, 600);

      setTimeout(() => {
        setDataSentSuccessfully(false);
      }, 1400);
    } catch (error) {
      setSendingData(false);
      setNamePopUpVisible(false);
      addNotification({ messageText: error.message, statusType: error.status });
    }
  };

  const saveOptions = async (data) => {
    try {
      setSendingData(true);
      const setpointId = setpointsEditorState.activeSetpointId.toString();

      const changeTemplateSetpoint = await SetpointsService.changeSetpointOptions(activeConfigurationId, {
        id: setpointsEditorState.activeSetpointId,
        name: data.name,
        comment: data.comment,
      });

      // изменяем
      kernel.setChangeSetpointBySetpointId(stateElementConfigurationId, setpointId, changeTemplateSetpoint);

      if (treeTab === 1) {
        const setpointsList = kernel.getSetpointsListByParameterId(stateElementConfigurationId);
        setSetpointsEditorState({
          setpointsList,
          activeSetpoint: setpointsList.find((setpoint) => setpoint.id === parseInt(setpointId)),
          activeSetpointId: BigInt(setpointId),
        });
      } else {
        const setpointsList = kernel.templateSetpoints[changeTemplateSetpoint.systemTypeId].templateSetpointsList;
        const activeSetpoint = kernel.getTemplateSetpointsByParameterId(changeTemplateSetpoint.systemTypeId, changeTemplateSetpoint.id);

        const treeTemplateList = Object.values(kernel.templateSetpoints);
        setTreeTemplateState(treeTemplateList);
        setActiveElementTree(changeTemplateSetpoint.systemTypeId);

        setSetpointsEditorState({
          setpointsList,
          activeSetpoint,
          activeSetpointId: activeSetpoint.id,
        });
      }

      setTimeout(() => {
        setDataSentSuccessfully(true);
        setSendingData(false);
      }, 600);

      setTimeout(() => {
        setDataSentSuccessfully(false);
      }, 1400);
    } catch (error) {
      setSendingData(false);
      setNamePopUpVisible(false);
      addNotification({ messageText: error.message, statusType: error.status });
    }
  };

  const handleTabVisible = (event) => {
    const tabId = parseInt(event.currentTarget.dataset.tab);
    setTabVisible(tabId);
  };

  const deleteSetpoint = async () => {
    try {
      setStatus({
        ...status,
        loading: true,
      });
      const setpointId = deleteSetpointId.current;
      const deletedSetpoint = kernel.getSetpointsByParameterId(stateElementConfigurationId, setpointId);

      await SetpointsService.deleteSetpoint(activeConfigurationId, setpointId);

      kernel.removeSetpointsByParameterId(stateElementConfigurationId, setpointId);

      setTimeout(() => {
        setStatus({ loading: false, success: true });
      }, 350);

      setTimeout(() => {
        if (treeTab === 2) {
          const treeTemplateSetpoint = Object.values(kernel.templateSetpoints);

          setTreeTemplateState(treeTemplateSetpoint);
          if (kernel.templateSetpoints[deletedSetpoint.systemTypeId]) {
            const activeTemplateSetpoint = kernel.templateSetpoints[deletedSetpoint.systemTypeId];
            setActiveElementTree(deletedSetpoint.systemTypeId);
            setSetpointsEditorState({
              setpointsList: activeTemplateSetpoint.setpointsList,
              activeSetpoint: activeTemplateSetpoint.setpointsList[0],
              activeSetpointId: activeTemplateSetpoint.setpointsList[0].id,
            });
          } else {
            const activeTemplateSetpoint = Object.values(kernel.templateSetpoints)[0];
            setActiveElementTree(parseInt(activeTemplateSetpoint.systemTypeId));
            setSetpointsEditorState({
              setpointsList: activeTemplateSetpoint.setpointsList,
              activeSetpoint: activeTemplateSetpoint.setpointsList[0],
              activeSetpointId: activeTemplateSetpoint.setpointsList[0].id,
            });
          }
        }

        setStatus({ loading: false, success: false });
        setPopUpVisible(false);
      }, 1300);
    } catch (error) {
      setPopUpVisible(false);
      setStatus({ loading: false, success: false });
      addNotification({ messageText: error.message, statusType: error.status });
    }
  };

  const popUpOpen = (event) => {
    deleteSetpointId.current = event.currentTarget.dataset.id;

    setPopUpVisible(true);
  };

  const setpointsListStruct = kernel.getSetpointsListByParameterId(stateElementConfigurationId);
  useEffect(() => {
    if (setpointsListStruct && setpointsListStruct.length > 0) {
      if (treeTab === 1) {
        const activeSetpoint = getActiveSetpoint(setpointsListStruct, setpointsEditorState?.activeSetpoint?.id);
        const activeSetpointId = activeSetpoint.id;

        setSetpointsEditorState({ setpointsList: setpointsListStruct, activeSetpoint, activeSetpointId });
      }
    } else {
      setSetpointsEditorState({ setpointsList: [], activeSetpoint: {}, activeSetpointId: null });
    }
    return () => {};
  }, [stateElementConfigurationId, setpointsListStruct]);

  return (
    <div className={styles.setpointsEditor}>
      {namePopUpVisible && treeTab === 1 && (
        <SetNamePopUp
          onSubmit={createSpecialSetpoint}
          setNamePopUpVisible={setNamePopUpVisible}
          sendingData={sendingData}
          dataSentSuccessfully={dataSentSuccessfully}
          title="Создание уставки"
          placeholder="Название уставки"
        />
      )}
      {namePopUpVisible && treeTab === 2 && (
        <SetTemplateTaskPopUp
          onSubmit={createTemplatesSetpoint}
          initialSystemType={activeElementTree}
          setNamePopUpVisible={setNamePopUpVisible}
          sendingData={sendingData}
          dataSentSuccessfully={dataSentSuccessfully}
          title="Создание уставки"
          placeholder="Название уставки"
        />
      )}
      {popUpVisible && (
        <ConfirmationPopUp
          actionConfirmation={deleteSetpoint}
          closePopup={() => setPopUpVisible(false)}
          message="Вы точно хотите удалить уставку?"
          loading={status.loading}
          success={status.success}
        />
      )}

      {(classify === 2 || treeTab === 2) && ![0, 11, 12, 13, 14, 15, 16].includes(type) ? (
        <>
          <div className={classNames(styles.setpointsEditorColumn, styles.setpointsEditorColumnControl)}>
            {(stateElementConfigurationId.toString() !== '0' || treeTab === 2) && (
              <>
                <SetpointsList
                  setpointsEditorState={setpointsEditorState}
                  callback={handleChangeSetpointsList}
                  setVisiblePopup={setNamePopUpVisible}
                  treeTab={treeTab}
                  deleteSetpoint={popUpOpen}
                />
              </>
            )}
          </div>

          <div className={classNames(styles.setpointsEditorColumn, styles.setpointsEditorColumnEditor)}>
            {stateElementConfigurationId.toString() !== '0' || treeTab === 2 ? (
              Object.keys(setpointsEditorState.activeSetpoint).length ? (
                <>
                  <Tabs tabsList={tabsList} tabVisible={tabVisible} handleTabVisible={handleTabVisible} />
                  <div className={styles.setpointsEditorWrap}>
                    {tabVisible === 1 && (
                      <SetpointOptions
                        activeSetpoint={setpointsEditorState.activeSetpoint}
                        sendingData={sendingData}
                        dataSentSuccessfully={dataSentSuccessfully}
                        saveSetting={saveOptions}
                        setpointOptions={setpointOptions}
                        setSetpointOptions={setSetpointOptions}
                      />
                    )}
                    {tabVisible === 2 && (
                      <SetpointArguments
                        onSubmit={saveArguments}
                        sendingData={sendingData}
                        dataSentSuccessfully={dataSentSuccessfully}
                        setpointsEditorState={setpointsEditorState}
                        treeTab={treeTab}
                        configuration={configuration}
                      />
                    )}
                  </div>
                </>
              ) : (
                <div className={styles.createTaskTextContainer}>
                  <p className={styles.createTaskText}>{treeTab === 1 ? 'Для данного параметра нет уставок!' : 'Нет шаблонных уставок!'}</p>
                </div>
              )
            ) : null}
          </div>
        </>
      ) : (
        <div className={styles.createTaskTextContainer}>
          <p className={styles.createTaskText}>Уставки можно создавать только для параметров определенного типа!</p>
        </div>
      )}
    </div>
  );
}
