import { PARAMETER_CLASS_VALUE } from "../global/variables/objectTypeVariables.mjs";

class Utils {
  interfaceSpeed = [
    { valueOption: 0, textOption: '300' },
    { valueOption: 1, textOption: '1200' },
    { valueOption: 2, textOption: '2400' },
    { valueOption: 3, textOption: '4800' },
    { valueOption: 4, textOption: '9600' },
    { valueOption: 5, textOption: '19200' },
    { valueOption: 6, textOption: '38400' },
    { valueOption: 7, textOption: '57600' },
    { valueOption: 8, textOption: '115200' },
  ];

  boardAddressOptionValue = [
    { valueOption: '01', textOption: '01' },
    { valueOption: '02', textOption: '02' },
    { valueOption: '03', textOption: '03' },
    { valueOption: '04', textOption: '04' },
    { valueOption: '05', textOption: '05' },
    { valueOption: '06', textOption: '06' },
    { valueOption: '07', textOption: '07' },
    { valueOption: '08', textOption: '08' },
    { valueOption: '09', textOption: '09' },
    { valueOption: '10', textOption: '10' },
    { valueOption: '11', textOption: '11' },
    { valueOption: '12', textOption: '12' },
    { valueOption: '13', textOption: '13' },
    { valueOption: '14', textOption: '14' },
    { valueOption: '15', textOption: '15' },
    { valueOption: '16', textOption: '16' },
  ];

  createdDeviceInModule = [
    { valueOption: 45, textOption: 'ICPCON AI', deviceType: 7 },
    { valueOption: 46, textOption: 'ICPCON DI', deviceType: 7 },
    { valueOption: 47, textOption: 'ICPCON DO', deviceType: 7 },
    { valueOption: 48, textOption: 'ICPCON DAC', deviceType: 7 },
    { valueOption: 32, textOption: 'EK-260', deviceType: 1 },
    { valueOption: 31, textOption: 'EK-270', deviceType: 2 },
    { valueOption: 49, textOption: 'EK-270 старый', deviceType: 3 },
    { valueOption: 33, textOption: 'TC-220', deviceType: 4 },
    { valueOption: 36, textOption: 'Flowgaz', deviceType: 5 },
    { valueOption: 50, textOption: 'IGM', deviceType: 6 },
    { valueOption: 51, textOption: 'Turboflow', deviceType: 8 },
    { valueOption: 34, textOption: 'Irvis', deviceType: 9 },
    { valueOption: 52, textOption: 'VRSGRI2', deviceType: 10 },
  ];

  convertTimestamp(timestamp) {
    const dateOptions = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      timeZone: 'UTC',
    };
    const date = new Date(timestamp * 1000);
    const dateFormat = new Intl.DateTimeFormat('ru', dateOptions);
    return dateFormat.format(date);
  }

  convertTimestampGetHour(timestamp) {
    const dateOptions = {
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      timeZone: 'UTC',
    };
    const date = new Date(timestamp * 1000);
    const dateFormat = new Intl.DateTimeFormat('ru', dateOptions);
    return dateFormat.format(date);
  }

  setHourOfDayValue() {
    const option = [];
    for (let i = 0; i < 24; i++) {
      option.push({ value: i, text: i });
    }
    return option;
  }

  setDotlessAlignmentValue() {
    const option = [];
    for (let i = 0; i < 24; i++) {
      option.push({ value: i + 50, text: i });
    }
    return option;
  }

  setDotAlignmentValue() {
    const option = [];
    for (let i = 0; i < 24; i++) {
      option.push({ value: i + 80, text: i });
    }
    return option;
  }

  setAlignmentEqualHourMarksValue() {
    const option = [];
    for (let i = 0; i < 24; i++) {
      option.push({ value: i + 110, text: i });
    }
    return option;
  }

  setOptionDayMonthSelect() {
    const option = [];
    for (let i = 1; i < 29; i++) {
      option.push({ value: i, text: i });
    }
    return option;
  }

  testInputPhoneInputPhoneInitialValue(value) {
    const testInputPhoneInitialValue = /^(\d{11})?$/.test(value);
    return testInputPhoneInitialValue;
  }

  testInputCoordinatesPointInitialValue(value) {
    const testInputCoordinatesPointInitialValue = /^(-?\d{1,3}(\.\d+)?\s*,\s*-?\d{1,3}(\.\d+)?)?$/.test(value);
    return testInputCoordinatesPointInitialValue;
  }

  testInputCoordinatesLineInitialValue(value) {
    const testInputCoordinatesLineInitialValue = /^(-?\d{1,3}(\.\d+)?\s*,\s*-?\d{1,3}(\.\d+)?(\s*\|\s*-?\d{1,3}(\.\d+)?\s*,\s*-?\d{1,3}(\.\d+)?)*)?$/.test(value);
    return testInputCoordinatesLineInitialValue;
  }

  testIsNumber(value) {
    return /^(-?\d+(\.\d*)?)?$/.test(value);
  }

  testIsInteger(value) {
    return /^-?\d+$/.test(value); // целые числа (как положительные так и отрицательные)
  }

  reverseStr(publicContentEncrypted) {//разворачивание строки 
    const publicContentEncryptedItems = publicContentEncrypted.split('');
    return [...publicContentEncryptedItems].reverse().join('');
  }

  // функция парсинга значений драйвер адреса для модуля Ai Di модульного контроллера
  parseWorkingMode(driverAddressState) {
    const driverAddressParameterPropertyValue = driverAddressState.propertyValueRoundUp.toString();

    const range = driverAddressParameterPropertyValue.slice(0, 1); // значения диапазона
    const boardAddress = driverAddressParameterPropertyValue.slice(1, 3); // значения адреса платы
    const multiplexerChannel = driverAddressParameterPropertyValue.slice(3, 4); // канал мультиплексора 
    const inputMode = driverAddressParameterPropertyValue.slice(4, 5); //может быть либо 5 либо 3

    return {
      range,
      boardAddress,
      multiplexerChannel,
      inputMode,
    };
  };

  //определяем что выводить пользователю в подсказку
  titleFromButtonSaveProperty(canEdit, isWritable, isDeleted, isValid, handleChangeComponentTableRow) {
    if (isDeleted) return "Вы не можете редактировать удаленные свойства!";
    if (canEdit) return "Вы не можете редактировать данное свойство!";
    if (!isWritable) return "У вас нет прав для редактирования данного свойства!";
    if (!isValid || !handleChangeComponentTableRow) return "Для того чтобы сохранить значение, измените его";

    return "Сохранить";
  };

  titleFromMoreControl(isWritable, canEdit){
    if (!isWritable || canEdit) return "У вас нет прав для взаимодействия с данным свойством!";

    return "Дополнительные действия";
  }

  //определяем что выводить пользователю в подсказку
  titleFromButtonSaveVirtualProperty(handleChangeComponentTableRow) {
    if (handleChangeComponentTableRow) return null;

    return 'Для того чтобы сохранить значение, измените его';
  };

  getBoardAddressValueModuleRs(selectedParameterProperties) {
    let propertyBoardaddress = selectedParameterProperties.filter((selectedParameterProperty) => selectedParameterProperty.systemTypeId === 39);
    propertyBoardaddress = propertyBoardaddress[0]?.propertyValueRoundUp;
    if (propertyBoardaddress.length === 1) {
      propertyBoardaddress = `0${propertyBoardaddress}`;
    }
    return propertyBoardaddress;
  };

  getBoardAddressParameterModuleRs(selectedParameterProperties) {
    let propertyBoardaddress = selectedParameterProperties.filter((selectedParameterProperty) => selectedParameterProperty.systemTypeId === 39);
    propertyBoardaddress = propertyBoardaddress[0];

    return propertyBoardaddress;
  };

  dateChangeModuleRs(selectedParameterProperties) {
    const propertyBoardaddress = selectedParameterProperties.filter((selectedParameterProperty) => selectedParameterProperty.systemTypeId === 39);
    const dateChange = propertyBoardaddress[0].propertyValueTimestamp;
    return { day: this.convertTimestamp(dateChange), hour: this.convertTimestampGetHour(dateChange) };
  };

  treeTraversal(parameterStruct, propertiesDraverAddressInModuleRs, systemTypeId) {
    if (parameterStruct.properties.length > 0) {
      parameterStruct.properties.forEach((properties) => {
        if (properties.systemTypeId === systemTypeId) {
          propertiesDraverAddressInModuleRs.push(properties);
        }
      });
    }
    parameterStruct?.children.forEach((parameterStruct) => {
      if (parameterStruct.children.length > 0) {
        this.treeTraversal(parameterStruct, propertiesDraverAddressInModuleRs, systemTypeId);
      }
    });
  };

  getPropertiesDraverAddressInModuleRs(parameter) {
    const propertiesDraverAddressInModuleRs = [];
    this.treeTraversal(parameter, propertiesDraverAddressInModuleRs, 66);
    return propertiesDraverAddressInModuleRs;
  };

  checkDraverAddressInModuleRs(propertiesDraverAddressInModuleRs, boardAddressValue) {
    const childrenBoardAddressValue = propertiesDraverAddressInModuleRs.map((propertyDraverAddress) => {
      return propertyDraverAddress.propertyValueRoundUp.toString().slice(1, 3);
    });
    if (boardAddressValue.toString().length < 2) {
      boardAddressValue = `0${boardAddressValue}`;
    }
    return childrenBoardAddressValue.every((element) => element === boardAddressValue);
  };

  setNewChildrenBoardAddressValueModuleRs(parameter, boardAddressStateValue) {
    const propertiesDraverAddressInModuleRs = this.getPropertiesDraverAddressInModuleRs(parameter);
    const newValue = propertiesDraverAddressInModuleRs.map((propertyDraverAddress) => {
      return {
        parameterStruct: propertyDraverAddress,
        newValue: `${propertyDraverAddress.propertyValueRoundUp.toString().slice(0, 1)}${boardAddressStateValue}${propertyDraverAddress.propertyValueRoundUp
          .toString()
          .slice(3, 5)}`,
      };
    });
    return newValue;
  };

  getPropertiesDefaultValueInModuleRs(parameter) {
    const propertiesDraverAddressInModuleRs = [];
    this.treeTraversal(parameter, propertiesDraverAddressInModuleRs, 75);
    return propertiesDraverAddressInModuleRs;
  };

  // методы для приведения типов
  splitDefaultValue(defaultValue) { // defaultValue - uint32
    const firstByte = defaultValue & 0xff; // первый байт
    defaultValue /= 256;
    const deviceType = defaultValue & 0xff; // тип устройства
    defaultValue /= 256;
    const interfaceSpeed = defaultValue & 0xff;// скорость интерфейса 
    defaultValue /= 256;
    const deviceAddressOnBus = defaultValue & 0xff; //адрес устройства на шине

    const { low, high } = this.splitByteDefaultValue(firstByte);
    const pipelineNumber = low; // номер трубопровода
    const reportingHour = high; // отчетный час

    return {
      deviceAddressOnBus,
      interfaceSpeed,
      deviceType,
      pipelineNumber,
      reportingHour,
    };
  }

  splitByteDefaultValue(value) {
    const low = value & 0xf;
    const high = (value & 0xf0) >> 4;

    return {
      low,
      high,
    };
  }

  compilationDefaultValue(value) {
    const { deviceAddressOnBus, interfaceSpeed, deviceType, pipelineNumber, reportingHour } = { ...value };

    let defaultValue = 0;
    // uint 32
    defaultValue = defaultValue * 256 + Math.round(deviceAddressOnBus) % 256; //адрес устройства на шине
    defaultValue = defaultValue * 256 + Math.round(interfaceSpeed) % 256; // скорость интерфейса
    defaultValue = defaultValue * 256 + Math.round(deviceType) % 256; // тип устройства

    const firstByte = this.compilationByteDefaultValue(pipelineNumber, reportingHour);

    defaultValue = defaultValue * 256 + Math.round(firstByte) % 256; // номер трубопровода и отчетный час

    return defaultValue;
  }

  compilationByteDefaultValue(pipelineNumber, reportingHour) {
    return (reportingHour << 4) | pipelineNumber;
  }

  setNewDefaultValue(selectedParameter, state, editableField) { // editableField - deviceAddressOnBus||deviceType||interfaceSpeed||pipelineNumber||reportingHour
    const defaultValueParameters = this.getPropertiesDefaultValueInModuleRs(selectedParameter);

    // парсинг значение изменить определенную часть
    const defaultValues = defaultValueParameters.map((defaultValueParameter) => {
      const valueUint32 = defaultValueParameter.propertyValueRoundUp;
      const newDefaultValue = this.splitDefaultValue(valueUint32);
      newDefaultValue[editableField] = parseInt(state);

      return newDefaultValue;
    });

    // новый массив значений
    const newDefaultValues = defaultValues.map((newDefaultValue) => {
      return this.compilationDefaultValue(newDefaultValue);
    });

    return defaultValueParameters.map((defaultValueParameter, index) => {
      return {
        parameterStruct: defaultValueParameter,
        newValue: newDefaultValues[index],
      };
    });
  }

  getPropertiesDefaultValueDeadZone(parameter) {
    const properties = parameter?.properties;
    const defaultValue = properties.find((property) => property.systemTypeId === 75);
    return defaultValue;
  }

  splitDefaultValueDeadZone(defaultValueUint32) {
    const deadZone = defaultValueUint32 & 0xff; // первый байт (мертвая зона)
    defaultValueUint32 /= 256;
    const secondByte = defaultValueUint32 & 0xff; // 2 байт
    defaultValueUint32 /= 256;
    const thirdByte = defaultValueUint32 & 0xff;// 3 байт 
    defaultValueUint32 /= 256;
    const fourthByte = defaultValueUint32 & 0xff; //4 байт

    return {
      deadZone,
      secondByte,
      thirdByte,
      fourthByte,
    };
  }

  compilationDefaultValueDeadZone(value) {
    const { deadZone, secondByte, thirdByte, fourthByte } = { ...value };

    let defaultValue = 0;
    // uint 32
    defaultValue = defaultValue * 256 + Math.round(fourthByte) % 256; // 4 байт 
    defaultValue = defaultValue * 256 + Math.round(thirdByte) % 256; // 3 байт 
    defaultValue = defaultValue * 256 + Math.round(secondByte) % 256; // 2 байт 
    defaultValue = defaultValue * 256 + Math.round(deadZone) % 256; // 1 байт мертвая зона

    return defaultValue;
  }

  setNewDefaultValueDeadZone(selectedParameter, state, editableField) { // editableField - deadZone||secondByte||thirdByte||fourthByte
    const defaultValueParameterDeadZone = this.getPropertiesDefaultValueDeadZone(selectedParameter);

    // парсинг значение изменить определенную часть
    const defaultValueDeadZoneUint32 = defaultValueParameterDeadZone.propertyValueRoundUp;
    const defaultValueDeadZoneByteByByte = this.splitDefaultValueDeadZone(defaultValueDeadZoneUint32);
    defaultValueDeadZoneByteByByte[editableField] = parseInt(state);

    const newDefaultValueDeadZoneUint32 = this.compilationDefaultValueDeadZone(defaultValueDeadZoneByteByByte);

    return {
      parameterStruct: defaultValueParameterDeadZone,
      newValue: newDefaultValueDeadZoneUint32,
    };
  }

  getRangeProperties(properties) {
    const rangePropertiesSystemTypeId = [68, 69, 70, 71, 296, 297, 298, 299];
    const rangeProperties = {};

    const rangePropertiesArr = properties?.filter((property) => rangePropertiesSystemTypeId.includes(property.systemTypeId));
    rangePropertiesArr?.map((rangeProperty, index) => {
      rangeProperties[rangeProperty.systemTypeId] = rangeProperty;
      return rangeProperties.length = index + 1;
    });

    return {
      lowerEmergencyLimit: { propertyId: rangeProperties[68]?.id?.toString(), propertyValueRoundUp: rangeProperties[68]?.propertyValueRoundUp?.toString() },
      lowerWarningLimit: { propertyId: rangeProperties[69]?.id?.toString(), propertyValueRoundUp: rangeProperties[69]?.propertyValueRoundUp?.toString() },
      upperWarningLimit: { propertyId: rangeProperties[70]?.id?.toString(), propertyValueRoundUp: rangeProperties[70]?.propertyValueRoundUp?.toString() },
      upperEmergencyLimit: { propertyId: rangeProperties[71]?.id?.toString(), propertyValueRoundUp: rangeProperties[71]?.propertyValueRoundUp?.toString() },
      lowerInputValue: { propertyId: rangeProperties[296]?.id?.toString(), propertyValueRoundUp: rangeProperties[296]?.propertyValueRoundUp?.toString() },
      lowerOutputValue: { propertyId: rangeProperties[298]?.id?.toString(), propertyValueRoundUp: rangeProperties[298]?.propertyValueRoundUp?.toString() },
      upperInputValue: { propertyId: rangeProperties[297]?.id?.toString(), propertyValueRoundUp: rangeProperties[297]?.propertyValueRoundUp?.toString() },
      upperOutputValue: { propertyId: rangeProperties[299]?.id?.toString(), propertyValueRoundUp: rangeProperties[299]?.propertyValueRoundUp?.toString() },
      length: rangeProperties.length,
    };
  }

  valueChangedTest(state, initialValue) {
    return JSON.stringify(state) !== JSON.stringify(initialValue);
  }

  rangePropertiesChangesDate(properties) {
    const rangePropertiesSystemTypeId = [68, 69, 70, 71, 296, 297, 298, 299];
    let timestampMax = 0;

    properties.forEach((properties) => {
      if (rangePropertiesSystemTypeId.includes(properties.systemTypeId) && properties.propertyValueTimestamp > timestampMax) {
        timestampMax = properties.propertyValueTimestamp;
      }
    });

    return { day: this.convertTimestamp(timestampMax), hour: this.convertTimestampGetHour(timestampMax) };
  }

  convertVoltsInArbitraryUnits(properties) {
    const rangeProperties = this.getRangeProperties(properties); // свойства
    const { lowerEmergencyLimit, lowerWarningLimit, upperEmergencyLimit, upperWarningLimit, ...remainingFields } = { ...rangeProperties }; // значение границ в вольтах

    rangeProperties.lowerEmergencyLimit.propertyValueRoundUp = this.convertVoltsInArbitraryUnitsCalc(remainingFields, lowerEmergencyLimit.propertyValueRoundUp); // НА в условных единицах

    rangeProperties.lowerWarningLimit.propertyValueRoundUp = this.convertVoltsInArbitraryUnitsCalc(remainingFields, lowerWarningLimit.propertyValueRoundUp);//ВА в условных единицах

    rangeProperties.upperEmergencyLimit.propertyValueRoundUp = this.convertVoltsInArbitraryUnitsCalc(remainingFields, upperEmergencyLimit.propertyValueRoundUp);// НП в условных единицах

    rangeProperties.upperWarningLimit.propertyValueRoundUp = this.convertVoltsInArbitraryUnitsCalc(remainingFields, upperWarningLimit.propertyValueRoundUp);//ВП в условных единицах

    return rangeProperties;
  }

  convertVoltsInArbitraryUnitsCalc(remainingFields, limitValue) {
    const { lowerInputValue, lowerOutputValue, upperInputValue, upperOutputValue } = { ...remainingFields };

    const lowerInputValueInVolts = lowerInputValue.propertyValueRoundUp; // нижнее входное значение
    const lowerOutputValueInVolts = lowerOutputValue.propertyValueRoundUp; // нижнее выходное значение
    const upperInputValueInVolts = upperInputValue.propertyValueRoundUp; // верхнее входное значение
    const upperOutputValueInVolts = upperOutputValue.propertyValueRoundUp; // верхнее выходное 

    return (((limitValue - lowerOutputValueInVolts) / (upperOutputValueInVolts - lowerOutputValueInVolts) * (upperInputValueInVolts - lowerInputValueInVolts)) + parseFloat(lowerInputValueInVolts)).toFixed(2);
  }

  convertArbitraryUnitsInVolts(state) {
    const rangeProperties = JSON.parse(JSON.stringify(state));
    delete rangeProperties.length;

    const { lowerEmergencyLimit, lowerWarningLimit, upperEmergencyLimit, upperWarningLimit, ...remainingFields } = { ...rangeProperties }; // значение границ в условных единицах

    rangeProperties.lowerEmergencyLimit.propertyValueRoundUp = this.convertArbitraryUnitsInVoltsCalc(remainingFields, lowerEmergencyLimit.propertyValueRoundUp); // НА в вольтах
    rangeProperties.lowerWarningLimit.propertyValueRoundUp = this.convertArbitraryUnitsInVoltsCalc(remainingFields, lowerWarningLimit.propertyValueRoundUp);// НП в вольтах
    rangeProperties.upperEmergencyLimit.propertyValueRoundUp = this.convertArbitraryUnitsInVoltsCalc(remainingFields, upperEmergencyLimit.propertyValueRoundUp);//ВА в вольтах
    rangeProperties.upperWarningLimit.propertyValueRoundUp = this.convertArbitraryUnitsInVoltsCalc(remainingFields, upperWarningLimit.propertyValueRoundUp);//ВП в вольтах

    return rangeProperties;
  }

  convertArbitraryUnitsInVoltsCalc(remainingFields, limitValue) {
    const { lowerInputValue, lowerOutputValue, upperInputValue, upperOutputValue } = { ...remainingFields };

    const lowerInputValueInVolts = lowerInputValue.propertyValueRoundUp; // нижнее входное значение
    const lowerOutputValueInVolts = lowerOutputValue.propertyValueRoundUp; // нижнее выходное значение
    const upperInputValueInVolts = upperInputValue.propertyValueRoundUp; // верхнее входное значение
    const upperOutputValueInVolts = upperOutputValue.propertyValueRoundUp; // верхнее выходное значение

    return (((limitValue - lowerInputValueInVolts) / (upperInputValueInVolts - lowerInputValueInVolts) * (upperOutputValueInVolts - lowerOutputValueInVolts)) + parseFloat(lowerOutputValueInVolts)).toFixed(2);
  }

  dragZoneCheck(treeNodeDrag, treeNodeDragOver, kernel, event) {
    const ctrlKey = event.ctrlKey;
    const treeNodeDragOverClassify = treeNodeDragOver?.classify();
    const treeNodeDragOverParameterStruct = kernel.parameters[treeNodeDragOver.id];

    const pathWithoutTreeNodeDragOver = treeNodeDragOverParameterStruct.path;
    pathWithoutTreeNodeDragOver.splice(pathWithoutTreeNodeDragOver.length - 1);

    const treeNodeDragOverIsChildren = pathWithoutTreeNodeDragOver.filter((treNode) => treNode.id === treeNodeDrag.id);

    if (ctrlKey) {
      return { canDrop: true, movementCheck: false, ctrlKey };
    } else if (treeNodeDragOverClassify === PARAMETER_CLASS_VALUE) {
      return { canDrop: true, movementCheck: false, ctrlKey };
    } else if (treeNodeDrag.id === treeNodeDragOver.id) {
      return { canDrop: false, movementCheck: false, ctrlKey };
    } else if (treeNodeDragOverIsChildren.length > 0) {
      return { canDrop: false, movementCheck: false, ctrlKey };
    }

    return { canDrop: true, movementCheck: true, ctrlKey };
  }

  sortOrderCheck(treeNodeDrag, treeNodeDragOver, event) {
    const { pageY, target, ctrlKey } = event;
    const { y, height } = target.getBoundingClientRect();

    if (!ctrlKey) return { top: false, bottom: false };
    if (treeNodeDragOver?.parent?.id !== treeNodeDrag?.parent?.id) return { top: false, bottom: false };
    if (treeNodeDrag?.id === treeNodeDragOver?.id) return { top: false, bottom: false };
    if (treeNodeDrag.classify() !== treeNodeDragOver.classify()) return { top: false, bottom: false };

    if (pageY < y + height / 2) {
      return { top: true, bottom: false };
    } else {
      return { top: false, bottom: true };
    }
  }

  sortOrderPropertyCheck(treeNodeDrag, treeNodeDragOver, event) {
    const { pageY, target, ctrlKey } = event;
    const { y, height } = target.getBoundingClientRect();

    if (!ctrlKey) return { top: false, bottom: false };
    if (!treeNodeDrag?.isProperty || !treeNodeDragOver?.isProperty) return { top: false, bottom: false };
    if (treeNodeDragOver?.parent?.id !== treeNodeDrag?.parent?.id) return { top: false, bottom: false };
    if (treeNodeDrag?.id === treeNodeDragOver?.id) return { top: false, bottom: false };

    if (pageY < y + height / 2) {
      return { top: true, bottom: false };
    } else {
      return { top: false, bottom: true };
    }
  }

  moveNodeCheck(treeNodeDrag, treeNodeDragOver, kernel) {
    const treeNodeDragOverClassify = treeNodeDragOver?.classify();
    const treeNodeDragOverParameterStruct = kernel.parameters[treeNodeDragOver.id];

    const pathWithoutTreeNodeDragOver = treeNodeDragOverParameterStruct.path;
    pathWithoutTreeNodeDragOver.splice(pathWithoutTreeNodeDragOver.length - 1);

    const treeNodeDragOverIsChildren = pathWithoutTreeNodeDragOver.filter((treNode) => treNode.id === treeNodeDrag.id);

    let resultTest = { status: true, message: "" };

    if (treeNodeDragOverClassify === PARAMETER_CLASS_VALUE) resultTest = { status: false, message: "Вставка внутрь параметров запрещена!" };
    if (treeNodeDragOverIsChildren.length) resultTest = { status: false, message: "Родительский объект нельзя перемещать в дочерний!" };

    return resultTest;
  }

  sortParameterChildren(children) {
    return children.sort((a, b) => {
      const d1 = a.classify() - b.classify();
      const d2 = a.id - b.id;
      return d1 < 0 ? -1 : d1 > 0 ? 1 : d2 < 0 ? -1 : d2 > 0 ? 1 : 0;
    }); // сортировка
  }
}

export default new Utils();
