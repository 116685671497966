class HandbookContent {
  constructor(kernel, handbookContentStruct) {
    this.kernel = kernel;
    this.handbookContentStruct = handbookContentStruct;
  }

  get id() {
    return this.handbookContentStruct.id;
  }

  get dictId() {
    return this.handbookContentStruct.dictId;
  }

  get key() {
    return this.handbookContentStruct.key.value;
  }

  get name(){
    return this.handbookContentStruct.name.value;
  }
}

export { HandbookContent };