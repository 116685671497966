

class TemplateTaskSrvCalc {
  constructor(kernel, templateTasksList, systemTypeId) {
    this.kernel = kernel;
    this.templateTasksList = templateTasksList;
    this.systemTypeId = systemTypeId;
  };

  get tasksList() {
    return this.templateTasksList;
  }

  get systemType() {
    return this.kernel.systemTypes[this.systemTypeId];
  }

  get systemTypeDescription() {
    return this.kernel.systemTypes[this.systemTypeId].description;
  }

}

export { TemplateTaskSrvCalc };