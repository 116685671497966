import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { PiFunctionLight } from 'react-icons/pi';
import { ImInsertTemplate } from 'react-icons/im';
import { BsPlusSquareDotted } from 'react-icons/bs';
import { MdDelete, MdDriveFileRenameOutline } from 'react-icons/md';
import { FaLink, FaUnlink } from 'react-icons/fa';

import { Checkbox, ConfirmationPopUp, ErrorMessagePopup, InputSearch, SetFullnamePopUp, SetNamePopUp, TreeConfiguration, TreeHandbook } from '../../components/components.mjs';
import { ContentApp, HeaderApp, NavApp } from '../../layout/layout.mjs';
import { useKernel } from '../../context/ContextKernel.mjs';
import { useActiveConfigurationId } from '../../hooks/useActiveConfigurationId.mjs';
import { ReactComponent as BsArrowRightCircleFill } from '../../assets/icon/BsArrowLeftCircle.svg';
import { filterConfigurationTree } from '../../utils/filter-configuration-tree/filterConfigurationTree.mjs';
import { HandbookService } from '../../services/services.mjs';
import HandbookContent from '../../components/handbook-content-list/handbook-content/HandbookContent';

import styles from './handBook.module.scss';

export default function ReferenceBook() {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    kernel,
    addNotification,
    stateElementConfigurationId,
    setStateElementConfigurationId,
    configuration,
    setConfiguration,
    searchConfigurationState,
    setSearchConfigurationState,
  } = useKernel();

  const [configurationName, setConfigurationName] = useState(null);
  const [userName, setUserName] = useState('');
  const [isError, setIsError] = useState(false);
  const [isErrorLoadingConfiguration, setIsErrorLoadingConfiguration] = useState(false);
  const [errorMessage, setErrorMessage] = useState('Ошибка!');
  const [rotate, setRotate] = useState(false);
  const { activeConfigurationId } = useActiveConfigurationId();
  const [treeTab, setTreeTab] = useState(1);
  const [checked, setChecked] = useState(false);
  const [handbook, setHandbook] = useState({ activeHandbookId: null,  activeContentId: null, isLoaded: false });
  const [popUpVisible, setPopUpVisible] = useState(false);
  const [popUpDeleteVisible, setPopUpDeleteVisible] = useState(false);
  const [sendingData, setSendingData] = useState(false);
  const [dataSentSuccessfully, setDataSentSuccessfully] = useState(false);
  const [status, setStatus] = useState({ loading: false, success: false });
  const [popUpContentUpdateVisible, setPopUpUpdateVisible] = useState(false);

  const [popUpContentCreateVisible, setPopUpContentCreateVisible] = useState(false);
  const [popUpContentDeleteVisible, setPopUpContentDeleteVisible] = useState(false);

  const [popUpHandbookByParameterId, setPopUpHandbookByParameterId] = useState(false);
  const [popUpUpdateHandbookByParameterId, setPopUpUpdateHandbookByParameterId] = useState(false);
  const [popUpDeleteHandbookByParameterId, setPopUpDeleteHandbookByParameterId] = useState(false);

  const [hasHover, setHasHover] = useState(false);

  const [showTempleHandbookList, setShowTempleHandbookList] = useState(false);

  const buildConfigurationTree = async () => {
    try {
      const { tree } = await kernel.buildTree(location, activeConfigurationId);
      await kernel.buildTemplatesTree();

      setConfiguration(tree);
      setConfigurationName(kernel.configuration.name);

      setUserName(kernel.getUser().name);
    } catch (error) {
      console.error('error: ', error);
      setIsErrorLoadingConfiguration(true);
      setErrorMessage('Ошибка загрузки конфигурации!');
    }
  };

  const buildTree = async () => {
    try {
      const { licensesListStruct, licensesTypesListStruct } = await kernel.getLicensesData();
      kernel.buildLicensesTree(licensesListStruct, licensesTypesListStruct);
    } catch (error) {
      setIsError(true);
      setErrorMessage('Ошибка загрузки лицензии!');
    }
  };

  const handleChangeTreeTabItem = ({ currentTarget: { dataset } }) => {
    setTreeTab(parseInt(dataset.treeTabId));
  };

  const downloadingHandBook = async () => {
    try {
      const [handbooks, handbookContentList, handbookList] = await Promise.all([
        HandbookService.getHandbookList(activeConfigurationId),
        HandbookService.getHandbookContentList(activeConfigurationId),
        HandbookService.getHandbookListByParameterId(activeConfigurationId),
      ]);    

      kernel.setHandbookListStruct(handbooks.items);
      kernel.setHandbookContentListStruct(handbookContentList.items);
      kernel.setHandbookList(handbookList.items);      

      setHandbook({
        ...handbook,
        activeHandbookId: null,
        activeContentId: null,
        isLoaded: true,
      });
    } catch (error) {
      setIsError(true);
      setErrorMessage('Ошибка загрузки справочников!');
    }
  };

  const createHandbookByParameterId = async (parameterId, handbookId) => {
    const isCreated = await HandbookService.createHandbookByParameterId(activeConfigurationId, parameterId, handbookId);
    if(isCreated){
      await downloadingHandBook();
      buildConfigurationTree();
      setPopUpHandbookByParameterId(false);
      setShowTempleHandbookList(false);
    }
  };

  const updateHandbookByParameterId = async (parameterId, handbookId) => {
    const isUpdated = await HandbookService.updateHandbookByParameterId(activeConfigurationId, parameterId, handbookId);
    if(isUpdated){
      await downloadingHandBook();
      buildConfigurationTree();
      setPopUpUpdateHandbookByParameterId(false);
    }
  };

  const deleteHandbookByParameterId = async (parameterId) => {
    const isDeleted = await HandbookService.deleteHandbookByParameterId(activeConfigurationId, parameterId);
    if(isDeleted){
      await downloadingHandBook();
      buildConfigurationTree();
      setPopUpDeleteHandbookByParameterId(false);
    }
  };

  const createHandbook = async (props) => {
    const isCreated = await HandbookService.createHandbook(activeConfigurationId, props.name);
    if(isCreated){

      setHandbook({
        ...handbook,
        isLoaded: false,
      });

      setPopUpVisible(false);
      await downloadingHandBook();
    }
  };
  //дописать клиентское обновление списка справочников при обновлении и удалении оных
  const updateHandbook = async (props) => {
    const isUpdated = await HandbookService.updateHandbook(activeConfigurationId, handbook.activeHandbookId, props.name);
    const ahb = handbook.activeHandbookId;

    if(isUpdated){

      setHandbook({
        ...handbook,
        isLoaded: false,
      });

      setPopUpUpdateVisible(false);
      await downloadingHandBook();

      setHandbook({
        ...handbook,
        activeHandbookId: ahb,
      });
    }
  };

  const deleteHandbook = async (props) => {
    const isDeleted = await HandbookService.deleteHandbook(activeConfigurationId, handbook.activeHandbookId);
    if(isDeleted){
      setHandbook({
        ...handbook,
        isLoaded: false,
      });

      delete kernel.getHandbookListStruct()[handbook.activeHandbookId];
      setPopUpDeleteVisible(false);

      setHandbook({
        ...handbook,
        activeHandbookId: null,
        activeContentId: null,
        isLoaded: true,
      });
    }
  };

  const createHandbookContent = async (props) => {
    const isCreated = await HandbookService.createHandbookContent(activeConfigurationId, handbook.activeHandbookId, props.name, props.name2);
    const ahb = handbook.activeHandbookId;

    if(isCreated){
      setHandbook({
        ...handbook,
        isLoaded: false,
      });

      setPopUpContentCreateVisible(false);
      await downloadingHandBook();

      setHandbook({
        ...handbook,
        activeHandbookId:ahb,
      });
    }
  };

  const updateHandbookContent = async (id, key, name) => {
    const isUpdated = await HandbookService.updateHandbookContent(activeConfigurationId, handbook.activeHandbookId, id, key, name);
    const ahb = handbook.activeHandbookId;
    const acb = handbook.activeContentId;
    if(isUpdated){
      setHandbook({
        ...handbook,
        isLoaded: false,
      });

      setPopUpUpdateVisible(false);
      await downloadingHandBook();

      setHandbook({
        ...handbook,
        activeHandbookId: ahb,
        activeContentId: acb,
      });
    }
  };

  const deleteHandbookContent = async () => {
    const isDeleted = await HandbookService.deleteHandbookContent(activeConfigurationId, handbook.activeHandbookId, handbook.activeContentId);
    const ahb = handbook.activeHandbookId;

    if(isDeleted){
      setHandbook({
        ...handbook,
        isLoaded: false,
      });

      setPopUpContentDeleteVisible(false);
      await downloadingHandBook();

      setHandbook({
        ...handbook,
        activeHandbookId:ahb,
      });
    }
  };

  useEffect(() => {
    setShowTempleHandbookList(false);
  }, [stateElementConfigurationId]);

  useEffect(() => {
    setConfiguration(null);
    buildConfigurationTree();
    buildTree();
    downloadingHandBook();
    kernel.timerRefreshToken(); //запуск обновления токена
    kernel.setNavigatePath('/reference-book');

    return () => {
      setStateElementConfigurationId(0n);
      kernel.openElementTemplateTree.clear();
      kernel.openElementTemplateTree.set(0n, true);
    };
  }, []);

  useEffect(() => {
    setConfiguration(null);

    if (searchConfigurationState !== '') {
      setChecked(false);
      setStateElementConfigurationId(0n);
    }

    const debounce = setTimeout(() => {
      if (!checked) {
        const { treeConfiguration } = filterConfigurationTree(searchConfigurationState, kernel);
        setConfiguration(treeConfiguration);
      } else {
        const { treeConfiguration } = filterConfigurationTree('#0', kernel, 5);
        setConfiguration(treeConfiguration);
      }
    }, 300);

    return () => {
      clearTimeout(debounce);
    };
  }, [searchConfigurationState, checked]);

  return (
    <div className={styles.handBook}>
      {isError && (
        <ErrorMessagePopup
          errorMessage={errorMessage}
          actionPerform={() => {
            setIsError(false);
            kernel.setNavigatePath('/reference-book');
            navigate('/section-selection');
          }}
        />
      )}
      {isErrorLoadingConfiguration && (
        <ErrorMessagePopup
          errorMessage={errorMessage}
          actionPerform={() => {
            setIsErrorLoadingConfiguration(false);
            kernel.setNavigatePath('/reference-book');
            navigate('/section-selection');
          }}
        />
      )}
      <NavApp />
      <ContentApp>
        <div className={styles.headerContainer}>
          <HeaderApp configurationName={configurationName} userName={userName} />
        </div>
        <div className={styles.contentContainer}>
          <div
            className={classNames(styles.treeContainer, rotate && 'collapsedTree')}
            id="treeConfigurationContainer"
            onContextMenu={(event) => {
              event.preventDefault();
            }}
          >
            <div className={classNames(styles.treeTab, rotate && 'collapsedTreeTab')}>
              <div
                data-tree-tab-id={1}
                className={classNames(styles.treeTabItem, treeTab === 1 && styles.treeTabItemActive)}
                onClick={handleChangeTreeTabItem}
              >
                <PiFunctionLight className={styles.treeTabIcon} />
                <span className={styles.treeTabItemText}>Параметры</span>
              </div>
              <div
                data-tree-tab-id={2}
                className={classNames(styles.treeTabItem, treeTab === 2 && styles.treeTabItemActive)}
                onClick={handleChangeTreeTabItem}
              >
                <ImInsertTemplate className={styles.treeTabIcon} />
                <span className={styles.treeTabItemText}>Справочники</span>
              </div>
            </div>
            {treeTab === 1 && (
              <InputSearch
                searchState={searchConfigurationState}
                setSearchState={setSearchConfigurationState}
                classes={rotate && styles.collapsedSearch}
              />
            )}
            {treeTab === 1 && (
              <Checkbox
                className={classNames(styles.checkbox, rotate && 'collapsedTreeWrap')}
                isVisible={checked}
                handleChange={() => setChecked(!checked)}
                caption={'Отображать только параметры с справочниками'}
                disabled={configuration === null ? true : searchConfigurationState !== '' ? true : false}
              />   
            )}
            <div className={classNames(styles.treeWrap, rotate && 'collapsedTreeWrap')}>
              {treeTab === 1 ? (
                <TreeConfiguration setComponentsTypeId={() => {}} configuration={configuration} setConfiguration={setConfiguration} sortOrderId={0} />
              ) : (
                <div>
                  {
                    <TreeHandbook
                      handbook={handbook}
                      setHandbook={setHandbook}
                      hasParameterIdInTree={kernel.getUser().settings.hasParameterIdInTree ? true : false}
                    />
                  }
                </div>
              )
              }
            </div>
            {
              treeTab === 2 ?
                <div className={classNames(styles.createHandbookButton, rotate && 'collapsedTreeWrap')} onClick={() => {setPopUpVisible(true);}}>
                  <BsPlusSquareDotted/>
                  <span>Добавить справочник</span>
                </div>
                : <div></div>
            }
            <BsArrowRightCircleFill
              className={classNames(styles.treeControl, rotate && styles.treeControlActive)}
              onClick={() => setRotate(!rotate)}
            />
          </div>
          <div className={styles.handbookEditor}>
            {popUpHandbookByParameterId && (
              <ConfirmationPopUp
                actionConfirmation={() => createHandbookByParameterId(stateElementConfigurationId, handbook.activeHandbookId)}
                closePopup={() => setPopUpHandbookByParameterId(false)}
                message="Вы точно хотите прикрепить справочник к выбранному параметру?"
                loading={status.loading}
                success={status.success}
              />
            )}
            {popUpUpdateHandbookByParameterId && (
              <ConfirmationPopUp
                actionConfirmation={() => updateHandbookByParameterId(stateElementConfigurationId, handbook.activeHandbookId)}
                closePopup={() => setPopUpUpdateHandbookByParameterId(false)}
                message="Вы точно хотите изменить справочник для выбранного параметра?"
                loading={status.loading}
                success={status.success}
              />
            )}
            {popUpDeleteHandbookByParameterId && (
              <ConfirmationPopUp
                actionConfirmation={() => deleteHandbookByParameterId(stateElementConfigurationId)}
                closePopup={() => setPopUpDeleteHandbookByParameterId(false)}
                message="Вы точно хотите открепить справочник от выбранного параметра?"
                loading={status.loading}
                success={status.success}
              />
            )}

            {popUpVisible && (
              <SetNamePopUp
                setNamePopUpVisible={setPopUpVisible}
                onSubmit={createHandbook}
                sendingData={sendingData}
                dataSentSuccessfully={dataSentSuccessfully}
                title="Создание справочника"
                placeholder="Название справочника"
                textButton="Добавить"
              />
            )}
            {popUpContentUpdateVisible && (
              <SetNamePopUp
                setNamePopUpVisible={setPopUpUpdateVisible}
                onSubmit={updateHandbook}
                sendingData={sendingData}
                dataSentSuccessfully={dataSentSuccessfully}
                title="Переименовать справочник"
                placeholder="Название справочника"
                textButton="Изменить"
              />
            )}
            {popUpDeleteVisible && (
              <ConfirmationPopUp
                actionConfirmation={deleteHandbook}
                closePopup={() => setPopUpDeleteVisible(false)}
                message="Вы точно хотите удалить справочник?"
                loading={status.loading}
                success={status.success}
              />
            )}
            {popUpContentCreateVisible && (
              <SetFullnamePopUp
                setNamePopUpVisible={setPopUpContentCreateVisible}
                onSubmit={createHandbookContent}
                sendingData={sendingData}
                dataSentSuccessfully={dataSentSuccessfully}
                title="Создание содержимого справочника"
                placeholder="Значние параметра"
                placeholder2="Отображаемое значение"
                textButton="Добавить"
              />
            )}
            {popUpContentDeleteVisible && (
              <ConfirmationPopUp
                actionConfirmation={deleteHandbookContent}
                closePopup={() => setPopUpContentDeleteVisible(false)}
                message="Вы точно хотите удалить содержимое справочника?"
                loading={status.loading}
                success={status.success}
              />
            )}
            {
              treeTab === 1 ? (
                <div className={styles.containerBinder}>
                  {
                    showTempleHandbookList &&
                    <span className={styles.centeredText}>Выберите справочник для прикрепления к параметру</span>
                  }
                  { showTempleHandbookList &&
                    <div className={styles.contentBox}>
                      {(Object.values(kernel.getHandbookListStruct())).map(book => {return (<div className={classNames(styles.content, kernel.getHandbookByParameterId(stateElementConfigurationId)?.dictId === book.id && styles.contentActive)} onClick={() => { setHandbook({...handbook, activeHandbookId:book.id}); setPopUpHandbookByParameterId(true); }}><span>{book.name}</span></div>);})}
                    </div>
                  }
                  {
                    !showTempleHandbookList && kernel.getHandbookList()[stateElementConfigurationId] !== undefined && <span className={styles.centeredText}>Список справочников доступных для прикрепления</span>
                  }
                  { !showTempleHandbookList && kernel.getHandbookList()[stateElementConfigurationId] !== undefined ?
                    (<div className={styles.contentBox}>
                      {(Object.values(kernel.getHandbookListStruct())).map(book => {return (<div className={classNames(styles.content, kernel.getHandbookByParameterId(stateElementConfigurationId)?.dictId === book.id && styles.contentActive)} onClick={() => { setHandbook({...handbook, activeHandbookId:book.id}); setPopUpUpdateHandbookByParameterId(true); }}><span>{book.name}</span></div>);})}
                    </div>)
                    : !showTempleHandbookList && <div className={styles.emptyBinder} >{
                      stateElementConfigurationId !== null && kernel.getParameterById(stateElementConfigurationId) !== undefined && kernel.getParameterById(stateElementConfigurationId).isValue ? (Object.values(kernel.getHandbookListStruct()).length > 0 ? (<FaLink className={styles.bindHandbookButton} onClick={() => {
                        setShowTempleHandbookList(true);
                      }}/>) : (<div>Создайте справочник!</div>)) : (<div className={styles.createHandbookText}>Добавлять справочники можно только для параметров!</div>)
                    }</div>
                  }
                  { !showTempleHandbookList && kernel.getHandbookList()[stateElementConfigurationId] !== undefined &&
                    <div style={{ 'position':'relative', 'top':'15px' }} className={styles.buttonContainer} onClick={() => setPopUpDeleteHandbookByParameterId(true)}>
                      <FaUnlink
                        className={styles.deleteHandbookIcon}
                        data-id={handbook.activeHandbookId}
                        onMouseEnter={() => setHasHover(true)}
                        onMouseLeave={() => setHasHover(false)}
                      />
                      <span>Открепить</span>
                    </div>
                  }
                </div>
              ) : (<div></div>)
            }

            {
              treeTab === 2 && kernel.getHandbookContentListStruct()[handbook.activeHandbookId] !== undefined ? 
                <div className={styles.table}>
                  <span>Значение параметра</span>
                  <span>Отображаемое значение</span>
                  <div></div>
                </div>
                : <div></div>
            }

            <div className={styles.editorContainer}>             
              {
                // handbook.activeContentId !== null && 
                treeTab === 2 && kernel.getHandbookContentListStruct()[handbook.activeHandbookId] !== undefined ? 
                  <div className={styles.contentEditorContainer}>
                    {
                      kernel.getHandbookContentListStruct()[handbook.activeHandbookId].map(content => {
                        return (
                          <HandbookContent
                            handbook={handbook}
                            setHandbook={setHandbook}
                            content={content}
                            updateContent={updateHandbookContent}
                            deletePopup={() => {setPopUpContentDeleteVisible(true);}}
                            addNotification={addNotification}
                          />
                        );
                      })
                    }
                  </div>: (<div></div>)
              }
              { treeTab === 2 && handbook.activeHandbookId !== null ? 
                <div className={styles.cudButtons}>
                  <div className={styles.createHandbookButton} onClick={() => {setPopUpContentCreateVisible(true);}}>
                    <BsPlusSquareDotted className={styles.createHandbookButtonIcon}/>
                    <span>Добавить содержимое справочника</span>
                  </div>
                  <div className={styles.buttonContainer} onClick={() => {setPopUpUpdateVisible(true);}}>
                    <MdDriveFileRenameOutline 
                      className={styles.updateIcon}
                    />
                    <span>Переименовать</span>
                  </div>
                  <div className={styles.buttonContainer} onClick={() => {setPopUpDeleteVisible(true);}}>
                    <MdDelete
                      className={styles.deleteIcon}
                    />
                    <span>Удалить</span>
                  </div>
                </div>
                : treeTab === 2 && 
                <div className={styles.emptyBinder}>
                  <BsPlusSquareDotted className={styles.bindHandbookButton} onClick={() => {
                    setPopUpVisible(true);
                  }}/>
                </div>
              }
            </div>
          </div>
        </div>
      </ContentApp>
    </div>
  );
}
