import { useKernel } from '../../context/ContextKernel.mjs';
import { useLogs } from '../../context/ContextLogs.mjs';
import { LoggingService } from '../../services/LoggingService.mjs';
import { EventLogFilter, EventLogsList } from '../components.mjs';

export default function EventLogContainer() {
  const { kernel, addNotification } = useKernel();
  const {
    serviceType,
    serviceInstanceType,
    groupsId,
    userId,
    codeOperations,
    db,
    dateTimeTo,
    dateTimeFrom,
    offset,
    limit,
    tableContent,
    setTableContent,
    formFieldValue,
  } = useLogs();

  const onSubmit = async () => {
    try {
      setTableContent({ ...tableContent, sending: true, hasData: true });

      offset.current = 0;
      limit.current = 100;

      const data = {
        serviceType: serviceType.value,
        serviceInstanceType: serviceInstanceType.value,
        groupsId: groupsId.value,
        userId: userId.value,
        codeOperations: codeOperations.value,
        db: db.value,
        dateTimeTo: dateTimeTo ? dateTimeTo.value.split('T').join(' ') : '',
        dateTimeFrom: dateTimeFrom ? dateTimeFrom.value.split('T').join(' ') : '',
        offset: offset.current,
        limit: limit.current,
      };

      // записать в глобальную память данных о поиске (используется при пагинации страниц лога)
      formFieldValue.current = data;

      const logs = await LoggingService.getLogsByQueryParameters(data);
      const Logs = kernel.buildLogs(logs.items);

      if (logs.count > 0) {
        setTableContent({ ...tableContent, sending: false, rows: Logs, stepPageLogs: 1, logsCount: logs.count, hasData: true });
      } else {
        setTableContent({ ...tableContent, sending: false, rows: Logs, stepPageLogs: 0, logsCount: logs.count, hasData: false });
      }
    } catch (error) {
      addNotification({ messageText: error.message, statusType: error.status });
      setTableContent({ sending: false, rows: [], stepPageLogs: 0, logsCount: 0, hasData: true });
    }
  };

  return (
    <>
      <EventLogFilter onSubmit={onSubmit} />
      <EventLogsList />
    </>
  );
}
