import { StringStruct } from '../../../Scada/Parser/StringStruct.mjs';
import { UsersGroupStruct } from '../../../Scada/Parser/Users/UsersGroupStruct.mjs';

class UsersGroupStructExtended extends UsersGroupStruct {

  constructor(id = 0, name = new StringStruct(''), flags = 0n) {
    super(id, name);
    this.flags = flags;
  }

  readFrom(dataView, startOffset, isLittleEndian = true) {
    let endOffset = super.readFrom(dataView, startOffset, isLittleEndian);
    this.flags = dataView.getBigUint64(endOffset, isLittleEndian);
    endOffset += 8;

    return endOffset;
  }

  writeTo(dataView, startOffset, isLittleEndian = true) {
    let endOffset = super.writeTo(dataView, startOffset, isLittleEndian);
    dataView.setBigUint64(endOffset, this.flags, isLittleEndian);
    endOffset += 8;

    return endOffset;
  }

  getCurrentSize() {
    return super.getCurrentSize() +
      + 8; // flags
  }

  clone() {
    const clone = new UsersGroupStructExtended(
      this.id,
      this.name.clone(),
      this.flags
    );
    clone.setParserOptions(this.getParserOptions());
    return clone;
  }
}

export { UsersGroupStructExtended };
