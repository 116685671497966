import styles from './loadingInTableSave.module.scss';

export default function LoadingInTableSave() {
  return (
    <div className={styles.loadingInTableSave}>
      <div className={styles.loadingInTableSaveContainer}>
        <span className={styles.loader}></span>
      </div>
    </div>
  );
}
