import { useEffect, useRef } from 'react';
import classNames from 'classnames';

import { Tooltip } from '../components.mjs';

import styles from './inputIp.module.scss';

export default function InputIp(props) {
  const {
    value,
    setValue,
    canEdit,
    isWritable,
    isDeleted,
    placeholder,
    register,
    unregister,
    handleFocus,
    showTooltip,
    errors,
    validationScheme,
    name,
    setHandleChangeComponentTableRow,
    sendingData,
  } = props;

  const initialValue = useRef(value);

  const handleChange = ({target}) => {
    if (target.value.toString() === initialValue.toString()) {
      setHandleChangeComponentTableRow(false);
    } else {
      setHandleChangeComponentTableRow(true);
    }
  };

  useEffect(() => {
    setValue(name, value);
    return () => {
      unregister(name);
    };
  }, []);

  useEffect(() => {
    initialValue.current = value;
    return () => {};
  }, [value]);

  return (
    <div className={styles.inputIpContainer}>
      <div className={styles.inputIpForm}>
        <input
          type="text"
          placeholder={placeholder}
          className={classNames(
            styles.inputIp,
            errors[name] && styles.inputIpError,
            sendingData && styles.inputIpSending,
            canEdit && styles.inputIpSending,
            !isWritable && styles.inputIpIsWritableFalse,
            isDeleted && styles.inputIpIsWritableFalse,
          )}
          onInput={handleChange}
          onFocus={handleFocus}
          readOnly={sendingData || canEdit || !isWritable || isDeleted}
          {...register(name, validationScheme)}
        />
        {showTooltip && errors[name] && <Tooltip message={`${errors[name]?.message || 'IP адрес введен неверно!'} `} />}
      </div>
    </div>
  );
}
