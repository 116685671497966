import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { HiCheck } from 'react-icons/hi';

import { useKernel } from '../../context/ContextKernel.mjs';
import { SavePropertiesButtonModuleRs } from '../components.mjs';
import Utils from '../../utils/Utils.mjs';

import { pipeNumber } from './model/options.mjs';
import styles from './tableRowPipelineNumberModuleRs.module.scss';

export default function TableRowPipelineNumberModuleRs(props) {
  const { selectedParameter } = props;

  const [handleChangeComponentTableRow, setHandleChangeComponentTableRow] = useState(false);
  const [sendingData, setSendingData] = useState(false);
  const [dataSentSuccessfully, setDataSentSuccessfully] = useState(false);
  const [errorParse, setErrorParse] = useState(false);
  const [changesDate, setChangesDate] = useState({ day: '', hour: '' }); // вывод даты и времени последнего изменения свойства (выводится время драйвер адреса)
  const [state, setState] = useState(); // состояние компонента

  const initialValue = useRef();
  const dataUpdateInputModeStatus = useRef(false); // изменилось ли значение компонента состояние компонента или нет
  const { kernel, addNotification, stateElementConfigurationId } = useKernel();

  const saveProperty = async () => {
    setHandleChangeComponentTableRow(false);
    setSendingData(true);

    try {
      const newChildrenDefaultValues = Utils.setNewDefaultValue(selectedParameter, state, 'pipelineNumber');

      await kernel.setValues([...newChildrenDefaultValues]);
      initialValue.current = state;

      setDataSentSuccessfully(true);
      setTimeout(() => {
        setDataSentSuccessfully(false);
        setSendingData(false);
      }, 1500);
    } catch (error) {
      setSendingData(false);
      addNotification({ messageText: error.message, statusType: error.status });
      setHandleChangeComponentTableRow(true);
    }
  };

  const handleChange = (event) => {
    setState(event.target.value);

    if (parseInt(event.target.value) === initialValue.current && !errorParse) {
      dataUpdateInputModeStatus.current = false;
    } else {
      dataUpdateInputModeStatus.current = true;
    }

    if (dataUpdateInputModeStatus.current) {
      setHandleChangeComponentTableRow(true);
      setErrorParse(false);
    } else {
      setHandleChangeComponentTableRow(false);
    }
  };

  useEffect(() => {
    // получаем значения дочерних свойств (default value)
    const defaultValueParameters = Utils.getPropertiesDefaultValueInModuleRs(selectedParameter);

    const day = Utils.convertTimestamp(defaultValueParameters[0].propertyValueTimestamp);
    const hour = Utils.convertTimestampGetHour(defaultValueParameters[0].propertyValueTimestamp);
    setChangesDate({ day: day, hour: hour });

    const defaultValues = defaultValueParameters.map((defaultValueParameter) => {
      const valueUint32 = defaultValueParameter.propertyValueRoundUp;
      return Utils.splitDefaultValue(valueUint32);
    });

    const pipelineNumber = defaultValues[0].pipelineNumber;
    const pipelineNumberCheck = defaultValues.every((defaultValue) => defaultValues[0].pipelineNumber === defaultValue.pipelineNumber);

    if (pipelineNumberCheck) {
      setState(pipelineNumber);
      initialValue.current = pipelineNumber;
    } else {
      setErrorParse(true);
      setState(4);
      initialValue.current = 4;
    }

    return () => {};
  }, [stateElementConfigurationId]);

  return (
    <tr className={styles.tableRow}>
      <td className="tableRowPropertyTd">
        <span className={styles.tableRowText} title={'Номер трубопровода'}>
          Номер трубопровода
        </span>
      </td>
      <td className="tableRowPropertyTd">
        <div className={styles.componentContainer}>
          <select
            className={classNames(styles.selectInputMode, sendingData && styles.sending)}
            value={state}
            onInput={handleChange}
            disabled={sendingData}
          >
            {pipeNumber.map((inputModeOption) => {
              return (
                <option key={inputModeOption.valueOption} value={inputModeOption.valueOption} className={styles.selectInputModeOption}>
                  {inputModeOption.textOption}
                </option>
              );
            })}
            {errorParse && (
              <option key={4} value={4} className={styles.selectInputModeOption}>
                Значение не определено
              </option>
            )}
          </select>
        </div>
      </td>
      <td className="tableRowPropertyTd">
        <span className={classNames(styles.tableRowText, styles.tableDate)}>{changesDate.day}</span>
        <span className={styles.tableRowText}>{changesDate.hour}</span>
      </td>
      <td className="tableRowPropertyTd">
        {dataSentSuccessfully ? (
          <HiCheck className={styles.checkControl} />
        ) : (
          <SavePropertiesButtonModuleRs
            sendingData={sendingData}
            handleChangeComponentTableRow={handleChangeComponentTableRow}
            saveProperty={saveProperty}
          />
        )}
      </td>
    </tr>
  );
}
