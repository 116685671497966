import classNames from 'classnames';
import PropTypes from 'prop-types';

import { Tooltip } from '../../../components.mjs';

import styles from './inputNumber.module.scss';

export default function InputNumber(props) {
  const { name, sendingData, dataSentSuccessfully, value, onInput, onFocus, onBlur, showTooltip, errors, register, children, ...otherProps } = props;

  return (
    <div className={styles.inputWrap}>
      <input
        className={classNames(styles.textField, errors[name] && styles.textFieldError, (sendingData || dataSentSuccessfully) && styles.sending)}
        {...otherProps}
        type="text"
        value={value}
        onInput={onInput}
        onFocus={onFocus}
        disabled={sendingData || dataSentSuccessfully}
        {...register}
        onBlur={onBlur}
      />
      {children}
      {showTooltip[name] && errors[name] && <Tooltip message={errors[name]?.message} />}
    </div>
  );
}

InputNumber.propTypes = {
  name: PropTypes.string.isRequired,
  sendingData: PropTypes.bool.isRequired,
  dataSentSuccessfully: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
  onInput: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  showTooltip: PropTypes.bool.isRequired,
  errors: PropTypes.object.isRequired,
  register: PropTypes.object.isRequired,
  otherProps: PropTypes.any,
};
