export const valueParse = (value) => {
  value = value.toString();
  const parseValue = value.split(';');
  const selectValue = parseValue.shift();
  const inputValue = parseValue.join(';');

  return { selectValue, inputValue };
};

export const dataFromState = (kernel) => {
  const licensesTypeList = kernel.getLicensesTypesList();
  const dataFromStateArr = [];
  dataFromStateArr.push({ value: '', text: 'не выбрано' });

  licensesTypeList.forEach((licenseType) => {
    const licenseTypeName = licenseType.name;
    licenseType.children.forEach((license) => {
      dataFromStateArr.push({ value: license.id, text: `${licenseTypeName} / ${license.name}` });
    });
  });
  return dataFromStateArr;
};