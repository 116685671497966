import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { TreeTemplateConfiguration } from '../../../components.mjs';
import { useKernel } from '../../../../context/ContextKernel.mjs';
import { useActiveConfigurationId } from '../../../../hooks/useActiveConfigurationId.mjs';
import { copyTreeNode, pasteTreeNode } from '../../../tree-configuration/model/helpers.mjs';
import { ConfigurationService } from '../../../../services/ConfigurationServices.mjs';
import { filterConfigurationTree } from '../../../../utils/filter-configuration-tree/filterConfigurationTree.mjs';

import styles from './creatingElementsUsingTemplatesForms.module.scss';

export function CreatingElementsUsingTemplatesForms(props) {
  const { setComponentsTypeId, setConfiguration, selectedParameter, searchState } = props;

  const [configurationTemplate, setConfigurationTemplate] = useState(null);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const { activeConfigurationId } = useActiveConfigurationId();
  const { kernel, addNotification, stateElementTemplateConfigurationId, setStateElementConfigurationId } = useKernel();
  const location = useLocation();

  const createNewElementConfiguration = async () => {
    try {
      const parameterStructExtended = kernel.getTemplateParameterById(stateElementTemplateConfigurationId);
      const parameterId = kernel.elementConfigurationId.toString();

      const { hasDeletedParameters } = await copyTreeNode(parameterStructExtended);
      if (hasDeletedParameters.status)
        addNotification({
          messageText: 'В вставляемом вами элементе имеются удаленные элементы. Удаленные элементы не вставляются!',
          statusType: 3,
        });

      const { arrayBuffer } = await pasteTreeNode();

      setConfiguration(null);
      setComponentsTypeId(0);
      setStateElementConfigurationId(null);
      await ConfigurationService.pasteTreeNode(parameterId, arrayBuffer, activeConfigurationId);

      await kernel.buildTree(location, activeConfigurationId);

      const { treeConfiguration } = filterConfigurationTree(searchState, kernel);

      setConfiguration(treeConfiguration);
      setStateElementConfigurationId(parameterId);

      addNotification({ messageText: 'Вы успешно вставили узел в дерево конфигурации', statusType: 1 });
    } catch (error) {
      setConfiguration(null);
      const parameterId = kernel.elementConfigurationId;
      await kernel.buildTree(location, activeConfigurationId);
      const { treeConfiguration } = filterConfigurationTree(searchState, kernel);
      setConfiguration(treeConfiguration);
      setStateElementConfigurationId(parameterId);

      addNotification({ messageText: error.message, statusType: 2 });
    }
  };

  useEffect(() => {
    const treeTemplate = kernel.getRootTemplateParameter();
    kernel.elementTemplateConfigurationId = 0n;
    setConfigurationTemplate(treeTemplate);
    return () => {};
  }, []);

  useEffect(() => {
    const isWritable = selectedParameter.isWritable; // проверяем есть ли права на запись в выбранный элемент в дереве конфигурации
    if (!isWritable) {
      setButtonDisabled(true);
      addNotification({
        messageText: 'У вас нет прав для вставки элемента в выбранный узел дерева!',
        statusType: 2,
      });
    } else {
      setButtonDisabled(false);
    }
    return () => {};
  }, [addNotification, selectedParameter]);

  useEffect(() => {
    if (stateElementTemplateConfigurationId === 0n) {
      setButtonDisabled(true);
    } else {
      setButtonDisabled(false);
    }
    return () => {};
  }, [stateElementTemplateConfigurationId]);

  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <div className={styles.title}>
          <span className={styles.titleText}>Добавление нового элемента в: </span>
          <span className={classNames(styles.titleText, styles.titleTextAccent)}>{selectedParameter?.displayName}</span>
        </div>
        <div className={styles.wrap}>
          <div
            className={styles.treeContainer}
            onContextMenu={(event) => {
              event.preventDefault();
            }}
          >
            <TreeTemplateConfiguration configurationTemplate={configurationTemplate} />
          </div>
        </div>
      </div>

      <button
        type="button"
        disabled={buttonDisabled}
        className={classNames(styles.submit, buttonDisabled ? styles.disabled : styles.undisabled)}
        onClick={createNewElementConfiguration}
      >
        Создать
      </button>
    </div>
  );
}
