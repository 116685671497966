// This file was generated by lezer-generator. You probably shouldn't edit it.
import {LRParser} from "@lezer/lr"
import {highlighting} from "./func.highlight.js"
export const parser = LRParser.deserialize({
  version: 14,
  states: "!pOVQPOOOhQPO'#CcOOQO'#C^'#C^OmQPOOOrQPO,58}QOQPOOOyQPO'#CfO!RQPO1G.iOVQPO'#ChO!WQPO,59QOOQO7+$T7+$TOOQO,59S,59SOOQO-E6f-E6f",
  stateData: "!`~O_OS~ORQOSQOTQOUQOWPO~OXSO~OaTO~OZYP~PVO`WOZYX~OZYO~O`WOZYa~O",
  goto: "u]PP^PPPPgPPlPoQROQUSRZWVQOSWRVSQXUR[X",
  nodeNames: "⚠ Program Expression ParameterId SystemTypeId BlockTypeId Number FunctionCall FunctionName ParenStart FunctionArguments ParenEnd",
  maxTerm: 17,
  nodeProps: [
    ["closedBy", 9,"ParenEnd"],
    ["openedBy", 11,"ParenStart"]
  ],
  propSources: [highlighting],
  skippedNodes: [0],
  repeatNodeCount: 1,
  tokenData: "$o~R_XY!QYZ!Q]^!Qpq!Qst!ctu!qxy#Pyz#U!Q![#Z!]!^#t!b!c#y!c!}$X#R#S$X#T#o$X~~$j~!VS_~XY!QYZ!Q]^!Qpq!Q~!fP!Q![!i~!nPT~!Q![!i~!tP!Q![!w~!|PR~!Q![!w~#UOX~~#ZOZ~~#`QU~!O!P#f!Q![#Z~#iP!Q![#l~#qPU~!Q![#l~#yO`~~#|P!Q![$P~$UPS~!Q![$P~$^SW~!Q![$X!c!}$X#R#S$X#T#o$X~$oOa~",
  tokenizers: [0],
  topRules: {"Program":[0,1]},
  tokenPrec: 0
})
