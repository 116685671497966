export const setPositionContextMenu = (targetElement, position, contextMenuElement, preferredPosition) => {
  const {
    width: elementWidth,
    height: elementHeight,
    x: elementPositionX,
    y: elementPositionY,
    top,
    right,
    left,
    bottom,
  } = targetElement.getBoundingClientRect();
  const margin = 15;
  const marginFromElement = 10;
  
  const [ x, y ] = position;
  const { clientWidth: contextMenuWidth, clientHeight: contextMenuHeight } = contextMenuElement.current; // размеры контекстного меню
  const windowHeight = window.innerHeight;
  const windowWidth = window.innerWidth;
  let leftPosition;
  let topPosition;

  switch (preferredPosition) {
  // eslint-disable-next-line no-lone-blocks
  case 'left': {
    if (elementPositionX > contextMenuWidth + elementWidth) {
      leftPosition = `${left - contextMenuWidth - marginFromElement}px`;

      if (windowHeight - elementPositionY > contextMenuHeight/2 + elementHeight/2) {
        topPosition = `${top + elementHeight / 2 - contextMenuHeight / 2}px`;
      }else{
        topPosition = `${top - elementHeight / 2 - contextMenuHeight }px`;
      }
    } else {
      const { leftPositionValue, topPositionValue } = createPosition(targetElement, contextMenuElement, position);
      leftPosition = leftPositionValue;
      topPosition = topPositionValue;
    }
    break;
  }

  case 'right': {
    // расположить справа от элемента
    if (windowWidth - elementPositionX > contextMenuWidth + elementWidth) {
      leftPosition = `${right + marginFromElement}px`;

      if (windowHeight - elementPositionY > contextMenuHeight/2 + elementHeight/2) {
        topPosition = `${top + elementHeight / 2 - contextMenuHeight / 2}px`;
      }else{
        topPosition = `${top + elementHeight / 2 - contextMenuHeight }px`;
      }
    } else {
      const { leftPositionValue, topPositionValue } = createPosition(targetElement, contextMenuElement, position);
      leftPosition = leftPositionValue;
      topPosition = topPositionValue;
    }
    break;
  }

  case 'top': {
    // расположить сверху от элемента
    if (elementPositionY > contextMenuHeight + elementHeight) {
      leftPosition = `${elementPositionX + elementWidth / 2 - contextMenuWidth / 2}px`;
      topPosition = `${top - contextMenuHeight - marginFromElement}px`;
    } else {
      const { leftPositionValue, topPositionValue } = createPosition(targetElement, contextMenuElement, position);
      leftPosition = leftPositionValue;
      topPosition = topPositionValue;
    }
    break;
  }

  case 'bottom': {
    // расположить снизу от элемента
    if (windowHeight - elementPositionY > contextMenuHeight + elementHeight) {
      leftPosition = `${elementPositionX + elementWidth / 2 - contextMenuWidth / 2}px`;
      topPosition = `${bottom + marginFromElement}px`;
    } else {
      const { leftPositionValue, topPositionValue } = createPosition(targetElement, contextMenuElement, position);
      leftPosition = leftPositionValue;
      topPosition = topPositionValue;
    }
    break;
  }

  case 'mouseLeft': {
    // расположить слева от мыши
    if (x > contextMenuWidth + margin) {
      leftPosition = `${x - contextMenuWidth - margin}px`;

      if (windowHeight - y > contextMenuHeight / 2 ) {
        topPosition = `${y + elementHeight / 2 - contextMenuHeight / 2}px`;
      }else{
        topPosition = `${y - elementHeight / 2 - contextMenuHeight }px`;
      }
    } else {
      if (windowHeight - x > contextMenuWidth + margin) {
        leftPosition = `${x + margin}px`;
        if (windowHeight - y > contextMenuHeight / 2 ) {
          topPosition = `${y - contextMenuHeight / 2}px`;
        }else{
          topPosition = `${y - contextMenuHeight }px`;
        }
      }else{
        const { leftPositionValue, topPositionValue } = createPosition(targetElement, contextMenuElement, position);
        leftPosition = leftPositionValue;
        topPosition = topPositionValue;
      }
    }
    break;
  }

  case 'mouseRight': {
    // расположить справа от мыши
    if (windowWidth - x > contextMenuWidth + margin) {
      leftPosition = `${x + margin}px`;

      if (windowHeight - y > contextMenuHeight / 2 ) {
        topPosition = `${y - contextMenuHeight / 2}px`;
      }else{
        topPosition = `${y - contextMenuHeight }px`;
      }
    } else {
      if ( x > contextMenuWidth + margin) {
        leftPosition = `${x - contextMenuWidth - margin}px`;

        if (windowHeight - y > contextMenuHeight / 2 ) {
          topPosition = `${y - contextMenuHeight / 2}px`;
        }else{
          topPosition = `${y - contextMenuHeight }px`;
        }
      }else{
        const { leftPositionValue, topPositionValue } = createPosition(targetElement, contextMenuElement, position);
        leftPosition = leftPositionValue;
        topPosition = topPositionValue;
      }
    }
    break;
  }

  case 'mouseTop': {
    // расположить сверху от курсора
    if (y > contextMenuHeight + margin) {
      leftPosition = `${x - contextMenuWidth / 2}px`;
      topPosition = `${y - contextMenuHeight - margin}px`;
    } else {
      if (windowWidth - x > contextMenuWidth / 2) {
        leftPosition = `${x - contextMenuWidth / 2}px`;
        topPosition = `${y + margin}px`;
      }else{
        const { leftPositionValue, topPositionValue } = createPosition(targetElement, contextMenuElement, position);
        leftPosition = leftPositionValue;
        topPosition = topPositionValue;
      }
    }
    break;
  }

  case 'mouseBottom': {
    // расположить снизу от курсора
    if (windowHeight - y > contextMenuHeight + margin) {
      leftPosition = `${x - contextMenuWidth / 2}px`;
      topPosition = `${y + margin}px`;
    } else {
      if (windowWidth - x > contextMenuWidth / 2) {
        leftPosition = `${x - contextMenuWidth / 2}px`;
        topPosition = `${y - contextMenuHeight - margin}px`;
      }else{
        const { leftPositionValue, topPositionValue } = createPosition(targetElement, contextMenuElement, position);
        leftPosition = leftPositionValue;
        topPosition = topPositionValue;
      }
    }
    break;
  }

  default: {
    const { leftPositionValue, topPositionValue } = createPosition(targetElement, contextMenuElement, position);
    leftPosition = leftPositionValue;
    topPosition = topPositionValue;
    break;
  }
  }

  return { leftPosition, topPosition };
};


const createPosition = (targetElement, contextMenuElement, position) => {
  const {
    width: elementWidth,
    height: elementHeight,
    x: elementPositionX,
    y: elementPositionY,
    top,
    right,
    left,
    bottom,
  } = targetElement.getBoundingClientRect();
  const marginFromElement = 10;
  const [x, y] = position;
  const { clientWidth: contextMenuWidth, clientHeight: contextMenuHeight } = contextMenuElement.current; // размеры контекстного меню
  const windowHeight = window.innerHeight;
  const windowWidth = window.innerWidth;

  // расположить слева от элемента
  if (elementPositionX > contextMenuWidth + elementWidth)
    return { leftPositionValue: `${left - contextMenuWidth - marginFromElement}px`, topPositionValue: `${top + elementHeight / 2 - contextMenuHeight / 2}px` };

  // расположить справа от элемента
  else if (windowWidth - elementPositionX > contextMenuWidth + elementWidth)
    return { leftPositionValue: `${right + marginFromElement}px`, topPositionValue: `${top + elementHeight / 2 - contextMenuHeight / 2}px` };

  // расположить сверху от элемента
  else if (elementPositionY > contextMenuHeight + elementHeight)
    return { leftPositionValue: `${elementPositionX + elementWidth / 2 - contextMenuWidth / 2}px`, topPositionValue: `${top - contextMenuHeight - marginFromElement}px` };

  // расположить снизу от элемента
  else if (windowHeight - elementPositionY > contextMenuHeight + elementHeight)
    return { leftPositionValue: `${elementPositionX + elementWidth / 2 - contextMenuWidth / 2}px`, topPositionValue: `${bottom + marginFromElement}px` };

  return { leftPositionValue: `${x + marginFromElement}px`, topPositionValue: `${y - marginFromElement}px` };
};