import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';

import { channelSettingTypeOption } from './model/option.mjs';
import styles from './channelSettingType.module.scss';

export default function ChannelSettingType(props) {
  const { value, setValue, canEdit, isWritable, isDeleted, register, unregister, name, setHandleChangeComponentTableRow, sendingData } = props;

  const [valueNotRecognized, setValueNotRecognized] = useState(false);
  const initialValue = useRef(value);

  const setInitialState = () => {
    if (initialValue.current >= 0 && initialValue.current <= 9) {
      setValue(name, initialValue.current);
    } else {
      setValueNotRecognized(true);
      setValue(name, 'valueNotRecognized');
    }
  };

  const handleChangeChannelSettingType = (event) => {
    setValue(name, event.target.value);
    setValueNotRecognized(false);

    if (event.target.value.toString() === initialValue.current.toString()) {
      setHandleChangeComponentTableRow(false);
    } else {
      setHandleChangeComponentTableRow(true);
    }
  };

  useEffect(() => {
    setInitialState();
    return () => {
      unregister(name);
    };
  }, []);

  useEffect(() => {
    initialValue.current = value;
    return () => {};
  }, [value]);

  return (
    <div className={styles.channelSettingTypeContainer}>
      <div className={styles.channelSettingTypeForm}>
        <select
          className={classNames(
            styles.channelSettingType,
            sendingData && styles.channelSettingTypeSending,
            canEdit && styles.channelSettingTypeSending,
            !isWritable && styles.channelSettingTypeSending,
            isDeleted && styles.channelSettingTypeSending,
          )}
          onInput={handleChangeChannelSettingType}
          disabled={sendingData || canEdit || !isWritable || isDeleted}
          {...register(name)}
        >
          {valueNotRecognized ? (
            <option className={styles.channelSettingTypeOption} key="valueNotRecognized" value="valueNotRecognized" disabled>
              значение не распознано
            </option>
          ) : (
            <></>
          )}
          {channelSettingTypeOption.map((item) => {
            return (
              <option className={styles.channelSettingTypeOption} key={item.value} value={item.value}>
                {item.text}
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
}
