import { BsArrowLeftCircle } from 'react-icons/bs';
import classNames from 'classnames';

import { useKernel } from '../../../../context/ContextKernel.mjs';

import styles from './systemTypeSelectionPopUp.module.scss';

export function SystemTypeSelectionPopUp(props) {
  const { visible, setVisible, systemTypeId, setSystemTypeId, callback } = props;
  const { kernel } = useKernel();

  const buttonBackClick = () => {
    setVisible({
      ...visible,
      systemTypePopUp: false,
    });
  };

  const handleChangeSelectValue = ({ target: { value } }) => {
    setSystemTypeId(value);
  };

  return (
    <div className={styles.popUpWrap}>
      <div className={styles.container}>
        <div className={styles.header}>
          <h2 className={styles.title}>Выберете системный тип</h2>
          <BsArrowLeftCircle className={styles.buttonBack} onClick={buttonBackClick} />
        </div>
        <div className={styles.containerForm}>
          <div className={styles.wrap}>
            <select className={styles.select} value={systemTypeId} onInput={handleChangeSelectValue}>
              {kernel.getSystemTypes().map((blockType) => {
                return (
                  <option className={styles.option} key={blockType.id} value={blockType.id}>
                    {blockType.description}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <button className={classNames(styles.button, styles.formValid)} onClick={callback}>
          Выбрать
        </button>
      </div>
    </div>
  );
}
