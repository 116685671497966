import { Struct } from '../../../Scada/Parser/Struct.mjs';

import { StringStruct } from '../../../Scada/Parser/StringStruct.mjs';

class HandbookContentStruct extends Struct {
  constructor(
    id = 0,
    dictId = 0,
    key = new StringStruct(''),
    name = new StringStruct(''),
  ) {
    super();
    this.id = id;
    this.dictId = dictId;
    this.key = key;
    this.name = name;
  }


  readFrom(dataView, startOffset, isLittleEndian = true) {
    let endOffset = startOffset;
    this.id = dataView.getUint32(endOffset, isLittleEndian);
    endOffset += 4;
    this.dictId = dataView.getUint32(endOffset, isLittleEndian);
    endOffset += 4;
    endOffset = this.key.readFrom(dataView, endOffset, isLittleEndian);
    endOffset = this.name.readFrom(dataView, endOffset, isLittleEndian);

    return endOffset;
  }

  writeTo(dataView, startOffset, isLittleEndian = true) {
    let endOffset = startOffset;
    dataView.setUint32(endOffset, this.id, isLittleEndian);
    endOffset += 4;
    dataView.setUint32(endOffset, this.dictId, isLittleEndian);
    endOffset += 4;
    endOffset = this.key.writeTo(dataView, endOffset, isLittleEndian);
    endOffset = this.name.writeTo(dataView, endOffset, isLittleEndian);
    return endOffset;
  }

  getCurrentSize() {
    return 0
      + 4 // id
      + 4 // dictId
      + this.key.getCurrentSize() // key
      + this.name.getCurrentSize(); // name
  }

  clone() {
    let clone = new HandbookContentStruct(
      this.id,
      this.dictId,
      this.key.clone(),
      this.name.clone(),
    )
    clone.setParserOptions(this.getParserOptions());
    return clone;
  }
}

export {

  HandbookContentStruct,
};