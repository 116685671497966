import { Struct } from '../../../Scada/Parser/Struct.mjs';
import { StringStruct } from '../../../Scada/Parser/StringStruct.mjs';

class SettlementServerSetupStruct extends Struct {
  constructor(
    id = 0,
    parameterId = 0,
    launchParameters = new StringStruct(''),
    workParameters = new StringStruct(''),
    nameTask = new StringStruct(''),
    workingStatus = 0,
    blockType = 0,
    blockTypeParent = 0,
    systemType = 0,
  ) {
    super();
    this.id = id;
    this.parameterId = parameterId;
    this.launchParameters = launchParameters;
    this.workParameters = workParameters;
    this.nameTask = nameTask;
    this.workingStatus = workingStatus;
    this.blockType = blockType;
    this.blockTypeParent = blockTypeParent;
    this.systemType = systemType;
  }


  readFrom(dataView, startOffset, isLittleEndian = true) {
    let endOffset = startOffset;
    this.id = dataView.getBigUint64(endOffset, isLittleEndian);
    endOffset += 8;
    this.parameterId = dataView.getBigUint64(endOffset, isLittleEndian);
    endOffset += 8;
    endOffset = this.launchParameters.readFrom(dataView, endOffset, isLittleEndian);
    endOffset = this.workParameters.readFrom(dataView, endOffset, isLittleEndian);
    endOffset = this.nameTask.readFrom(dataView, endOffset, isLittleEndian);
    this.workingStatus = dataView.getUint8(endOffset, isLittleEndian);
    endOffset += 1;
    this.blockType = dataView.getUint32(endOffset, isLittleEndian);
    endOffset += 4;
    this.blockTypeParent = dataView.getUint32(endOffset, isLittleEndian);
    endOffset += 4;
    this.systemType = dataView.getUint32(endOffset, isLittleEndian);
    endOffset += 4;

    return endOffset;
  }

  writeTo(dataView, startOffset, isLittleEndian = true) {
    let endOffset = startOffset;
    dataView.setBigUint64(endOffset, this.id, isLittleEndian);
    endOffset += 8;
    dataView.setBigUint64(endOffset, this.parameterId, isLittleEndian);
    endOffset += 8;
    endOffset = this.launchParameters.writeTo(dataView, endOffset, isLittleEndian);
    endOffset = this.workParameters.writeTo(dataView, endOffset, isLittleEndian);
    endOffset = this.nameTask.writeTo(dataView, endOffset, isLittleEndian);
    dataView.setUint8(endOffset, this.workingStatus, isLittleEndian);
    endOffset += 1;
    dataView.setUint32(endOffset, this.blockType, isLittleEndian);
    endOffset += 4;
    dataView.setUint32(endOffset, this.blockTypeParent, isLittleEndian);
    endOffset += 4;
    dataView.setUint32(endOffset, this.systemType, isLittleEndian);
    endOffset += 4;

    return endOffset;
  }

  getCurrentSize() {
    return 0
      + 8 // id
      + 8 //parameterId
      + this.launchParameters.getCurrentSize() // launchParameters
      + this.workParameters.getCurrentSize() // workParameters
      + this.nameTask.getCurrentSize() // nameTask
      + 1 //workingStatus
      + 4 // blockType
      + 4 // blockTypeParent
      + 4; // systemType
  }

  clone() {
    const clone = new SettlementServerSetupStruct(
      this.id,
      this.parameterId,
      this.launchParameters.clone(),
      this.workParameters.clone(),
      this.nameTask.clone(),
      this.workingStatus,
      this.blockType,
      this.blockTypeParent,
      this.systemType,
    );
    clone.setParserOptions(this.getParserOptions());
    return clone;
  }
}

export {

  SettlementServerSetupStruct,
};