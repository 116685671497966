import { AddNewLicenses, EditingLicenses, SelectActionReplaceLicense, SetLicensesName } from '../components/components.mjs';

const ADD_NEW_LICENSES = 0;
const SET_LICENSES_NAME = 1;
const EDITING_LICENSES = 3;
const SELECT_ACTION_REPLACE_LICENSE = 4;

export function ComponentLicenses(props) {
  const { setComponentsTypeId, componentsTypeId, drag, setDrag, licensesTree, setLicensesTree, idTreeElement } = props;

  const componentsByLicenses = {
    [ADD_NEW_LICENSES]: (
      <AddNewLicenses
        setComponentsTypeId={setComponentsTypeId}
        drag={drag}
        setDrag={setDrag}
      />
    ),
    [SET_LICENSES_NAME]: <SetLicensesName setComponentsTypeId={setComponentsTypeId} licensesTree={licensesTree} setLicensesTree={setLicensesTree} />,
    [EDITING_LICENSES]: (
      <EditingLicenses
        licensesTree={licensesTree}
        setLicensesTree={setLicensesTree}
        setComponentsTypeId={setComponentsTypeId}
        idTreeElement={idTreeElement}
      />
    ),
    [SELECT_ACTION_REPLACE_LICENSE]: (
      <SelectActionReplaceLicense
        setComponentsTypeId={setComponentsTypeId}
        licensesTree={licensesTree}
        setLicensesTree={setLicensesTree}
      />
    ),
  };

  return (
    <>
      {componentsByLicenses[componentsTypeId] ? (
        componentsByLicenses[componentsTypeId]
      ) : (
        <p>Произошла ошибка при выборе действия! Обновите страницу!</p>
      )}
    </>
  );
}
