export const channelSettingTypeOption = [
  { value: 0, text: 'bool' },
  { value: 1, text: 'uint8' },
  { value: 2, text: 'int8' },
  { value: 3, text: 'uint16' },
  { value: 4, text: 'int16' },
  { value: 5, text: 'uint32' },
  { value: 6, text: 'int32' },
  { value: 7, text: 'float' },
  { value: 8, text: 'uint64' },
  { value: 9, text: 'double' },
];