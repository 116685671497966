export const preloadList = () => {
  const clientWidth = window.screen.width;
  if (clientWidth < 1600) {
    return [
      { id: 1, width: 350 },
      { id: 2, width: 310 },
      { id: 3, width: 205 },
      { id: 4, width: 205 },
      { id: 5, width: 235 },
      { id: 6, width: 310 },
      { id: 7, width: 110 },
      { id: 8, width: 165 },
      { id: 9, width: 350 },
      { id: 10, width: 235 },
      { id: 12, width: 235 },
      { id: 13, width: 310 },
      { id: 14, width: 235 },
      { id: 15, width: 310 },
      { id: 16, width: 110 },
      { id: 17, width: 165 },
      { id: 18, width: 350 },
      { id: 19, width: 350 },
      { id: 20, width: 350 },
      { id: 21, width: 205 },
      { id: 22, width: 310 },
    ];
  } else {
    return [
      { id: 1, width: 360 },
      { id: 2, width: 320 },
      { id: 3, width: 215 },
      { id: 4, width: 215 },
      { id: 5, width: 245 },
      { id: 6, width: 320 },
      { id: 7, width: 120 },
      { id: 8, width: 175 },
      { id: 9, width: 360 },
      { id: 10, width: 245 },
      { id: 12, width: 245 },
      { id: 13, width: 320 },
      { id: 14, width: 245 },
      { id: 15, width: 320 },
      { id: 16, width: 120 },
      { id: 17, width: 175 },
      { id: 18, width: 360 },
      { id: 19, width: 360 },
      { id: 20, width: 360 },
      { id: 21, width: 215 },
      { id: 22, width: 320 },
    ];
  }
};
