import { ImFolder, ImFolderOpen } from 'react-icons/im';
import classNames from 'classnames';

import styles from './iconFolderConfigurationTree.module.scss';

export default function IconFolderConfigurationTree(props) {
  const { showChildren, isDeleted, isLink } = props;

  return (
    <>
      {!showChildren ? (
        <ImFolder className={classNames(styles.iconFolder, isLink && styles.link, isDeleted && styles.isDeleted)} />
      ) : (
        <ImFolderOpen className={classNames(styles.iconFolder, isLink && styles.link, isDeleted && styles.isDeleted)} />
      )}
    </>
  );
}
