import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { HiCheck } from 'react-icons/hi';
import { useForm } from 'react-hook-form';

import { LoadingInUserPageForm } from '../../../components.mjs';
import { useKernel } from '../../../../context/ContextKernel.mjs';
import { TaskServerService } from '../../../../services/TaskServerService.mjs';
import { StringStruct } from '../../../../quantum-lib/Scada/Parser/StringStruct.mjs';
import { useActiveConfigurationId } from '../../../../hooks/hooks.mjs';

import styles from './taskGeneralSettings.module.scss';

export function TaskGeneralSettings(props) {
  const { sendingData, dataSentSuccessfully, setSendingData, setDataSentSuccessfully, setTask, task } = props;

  const [state, setState] = useState({
    nameTask: '',
    workingStatus: 0,
  });
  const { kernel, addNotification } = useKernel();
  const { activeConfigurationId } = useActiveConfigurationId();

  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    setValue,
    trigger,
  } = useForm({
    mode: 'onChange',
  });

  const handleChangeNameTask = ({ target: { value } }) => {
    setState({
      ...state,
      nameTask: value,
    });
  };

  const handleChangeWorkingStatus = ({ target: { value } }) => {
    setState({
      ...state,
      workingStatus: value,
    });
  };

  const onSubmit = async (data) => {
    try {
      setSendingData(true);

      // запрос на изменение данных
      data.taskId = task.activeTaskId;
      const nameTask = data.nameTask;
      await TaskServerService.changeTaskSettings(activeConfigurationId, data);

      data.typeTask = task.activeTask.typeTask.value;
      data.nameTask = new StringStruct(nameTask);

      kernel.changedTask(data, task.activeTask);

      setTask({
        ...task,
        taskList: Object.values(kernel.getTasksListForTaskServer()),
        activeTask: kernel.getTaskByIdAndTypeTask(data.taskId, data.typeTask),
      });

      setTimeout(() => {
        setDataSentSuccessfully(true);
        setSendingData(false);
      }, 800);

      setTimeout(() => {
        setDataSentSuccessfully(false);
      }, 1600);
    } catch (error) {
      setSendingData(false);
      addNotification({ messageText: error.message, statusType: error.status });
    }
  };

  useEffect(() => {
    setState({
      nameTask: task.activeTask.nameTask,
      workingStatus: task.activeTask.workingStatus,
    });

    setValue('nameTask', task.activeTask.nameTask);
    setValue('workingStatus', task.activeTask.workingStatus);
    trigger('nameTask');
    return () => {};
  }, [task.activeTaskId]);

  return (
    <div className={styles.taskEditorContainer}>
      <div className={styles.serverSettingsContainer}>
        <div className={styles.editorWrap}>
          <form className={styles.form} onSubmit={handleSubmit(onSubmit)} id="taskEditorGeneralSettingsForm">
            <div className={styles.formItem}>
              <span className={styles.formItemText} title="Название задачи">
                Название задачи
              </span>
              <div className={styles.inputWrap}>
                <input
                  className={classNames(
                    styles.textField,
                    errors.nameTask && styles.textFieldError,
                    (sendingData || dataSentSuccessfully) && styles.sending,
                  )}
                  type="text"
                  value={state.nameTask}
                  onInput={handleChangeNameTask}
                  disabled={sendingData || dataSentSuccessfully}
                  {...register('nameTask', {
                    required: 'Поле обязательно к заполнению',
                    minLength: {
                      value: 3,
                      message: 'Поле должно содержать минимум 3 символа',
                    },
                    maxLength: {
                      value: 255,
                      message: 'Поле может содержать максимум 255 символа',
                    },
                  })}
                />
                {errors?.nameTask && <p className={styles.errorMessage}>{errors?.nameTask?.message || 'Ошибка заполнения!'}</p>}
              </div>
            </div>
            <div className={styles.formItem}>
              <span className={styles.formItemText} title="Статус">
                Статус
              </span>
              <select
                className={classNames(styles.formItemSelect, (sendingData || dataSentSuccessfully) && styles.sending)}
                value={state.status}
                onInput={handleChangeWorkingStatus}
                disabled={sendingData || dataSentSuccessfully}
                {...register('workingStatus')}
              >
                <option className={styles.formItemOption} value="0">
                  Выключен
                </option>
                <option className={styles.formItemOption} value="1">
                  Включен
                </option>
              </select>
            </div>
          </form>
        </div>
      </div>
      <div className={styles.controlContainer}>
        <button
          type="submit"
          form="taskEditorGeneralSettingsForm"
          className={
            sendingData || dataSentSuccessfully
              ? styles.creatingNewModuleFormSubmitSending
              : classNames(styles.creatingNewModuleFormSubmit, !isValid ? styles.formNoValid : styles.formValid)
          }
          disabled={sendingData || dataSentSuccessfully || !isValid}
        >
          {dataSentSuccessfully ? <HiCheck className={styles.checkControl} /> : sendingData ? <LoadingInUserPageForm /> : 'Сохранить'}
        </button>
      </div>
    </div>
  );
}
