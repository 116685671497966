import { ParametersExtended } from "../../quantum-lib/Arm/Parameters/ParametersExtended.mjs";
import { ParameterStruct } from "../../quantum-lib/Scada/Parser/Configurations/ParameterStruct.mjs";

// алгоритм очень неоптимизированный TODO

export const filterConfigurationTree = (searchText, kernel, flag = 0) => {

  const filteredConfiguration = {};
  const copyParameterStructExtendedList = {};
  const parameterStructExtendedList = kernel.getParametersListStructExtendedWithoutProperty();
  searchText = searchText.toLowerCase();

  // в случае если поисковая строка пуста
  if (searchText.length === 0) {
    kernel.openElementTree.clear(); // закрываем все
    const openedElementConfigurationTree = kernel.openElementTreeTmp;
    Array.from(openedElementConfigurationTree, ([key, value]) => kernel.openElementTree.set(key, value)); // восстанавливаем состояние дерева (открытые узлы)

    return { treeConfiguration: kernel.getRootParameter() };
  };

  // если displayName параметра соответствует поисковой строке и parameterStructExtended не является свойством
  const filteredParameterStructExtendedList = [];
  parameterStructExtendedList.forEach(parameterStructExtended => {
    const copyParameterStructExtended = Object.assign(new ParametersExtended(), parameterStructExtended); // создаем новый объект элементов конфигурации
    copyParameterStructExtended.children = []; // убираем потомков (потом перепривязываем)
    copyParameterStructExtendedList[copyParameterStructExtended.id] = copyParameterStructExtended;

    switch (flag) {
    default:
      if (parameterStructExtended !== undefined && parameterStructExtended.id === 0 && !parameterStructExtended.isTypeNode && !parameterStructExtended.isProperty) {
        filteredParameterStructExtendedList.push(parameterStructExtended);
      }
      break;
    case 0:
      if (parameterStructExtended !== undefined && (parameterStructExtended.displayName.toLowerCase().includes(searchText) || (kernel.getUser().settings.hasParameterIdInTree && ((searchText[0] === '#' && parameterStructExtended.id.toString() === searchText.replace('#', '')) || parameterStructExtended.id.toString().toLowerCase().includes(searchText)) && !parameterStructExtended.isTypeNode)) && !parameterStructExtended.isProperty) {
        filteredParameterStructExtendedList.push(parameterStructExtended); // проверить
      }
      break;

    case 1:
      if (parameterStructExtended !== undefined && kernel.getSettlementServerSetupByParameterId(parameterStructExtended.id) !== undefined && !parameterStructExtended.isTypeNode && !parameterStructExtended.isProperty) {
        filteredParameterStructExtendedList.push(parameterStructExtended);
      }
      break;

    case 2:
      if (parameterStructExtended !== undefined && kernel.getSetpointsListByParameterId(parameterStructExtended.id) !== undefined && !parameterStructExtended.isTypeNode && !parameterStructExtended.isProperty) {
        filteredParameterStructExtendedList.push(parameterStructExtended);
      }
      break;
    case 3:
      if (parameterStructExtended !== undefined && kernel.getAlarmListByParameterId(parameterStructExtended.id) !== undefined && !parameterStructExtended.isTypeNode && !parameterStructExtended.isProperty) {
        filteredParameterStructExtendedList.push(parameterStructExtended);
      }
      break;
    case 4:
      if (parameterStructExtended !== undefined && kernel.getSimulatorTasksByParameterId(parameterStructExtended.id).toString() !== '' && !parameterStructExtended.isTypeNode && !parameterStructExtended.isProperty) {
        filteredParameterStructExtendedList.push(parameterStructExtended);
      }
      break;
    case 5:
      if (parameterStructExtended !== undefined && kernel.getHandbookByParameterId(parameterStructExtended.id).toString() !== '' && !parameterStructExtended.isTypeNode && !parameterStructExtended.isProperty) {
        filteredParameterStructExtendedList.push(parameterStructExtended);
      }
      break;
    }
  });


  filteredParameterStructExtendedList.forEach((parameterStructExtended) => {

    filteredConfiguration[parameterStructExtended.id] = parameterStructExtended;
    const path = parameterStructExtended.path;

    path.forEach((parent) => {
      if (!filteredConfiguration[parent.id]) {
        filteredConfiguration[parent.id] = copyParameterStructExtendedList[parent.id];
      }
    });
  });


  delete filteredConfiguration[0]; // удаляем старый корень
  const filteredConfigurationList = Object.values(filteredConfiguration); // список параметров без корня

  const configuration = kernel.getActiveConfiguration();
  const rootParameterStruct = new ParameterStruct(
    0n, // id
    0n, // parentId
    1, // systemType
    0, // snapshotType
    0, // countValue
    configuration.accessBits, // accessBits
  );

  const root = new ParametersExtended(
    kernel,
    rootParameterStruct,
    kernel.disableTransforms
  );

  Object.defineProperty(root, 'ObjectNameOtobrazhenie', {
    configurable: false,
    enumerable: true,
    get() {
      return configuration.name;
    },
  });

  filteredConfiguration[root.id] = root;

  kernel.openElementTree.clear(); // закрываем все

  filteredConfigurationList.forEach((parameterStructExtended) => {
    const child = filteredConfiguration[parameterStructExtended.id];
    const parent = filteredConfiguration[parameterStructExtended.parentId];

    kernel.openElementTree.set(parent.id, true);

    const test = parent.children.find(children => children.id === child.id);
    if (!test) parent.children.push(child);
  });

  // фильтруем новое дерево
  filteredConfigurationList.unshift(root);
  filteredConfigurationList.forEach(parameterStruct => {
    const parameter = filteredConfiguration[parameterStruct.id];
    if (parameter.children) {
      parameter.children.sort((a, b) => {
        const d1 = a.classify() - b.classify();
        const d2 = a.sortOrder - b.sortOrder;
        const d3 = a.id - b.id;
        return d1 < 0 ? -1 : (d1 > 0 ? 1 : (d2 < 0 ? -1 : (d2 > 0 ? 1 : (d3 < 0 ? -1 : (d3 > 0 ? 1 : 0)))));
      });
    }
    if (parameter.properties) {
      parameter.properties.sort((a, b) => {
        const d1 = a.sortOrder - b.sortOrder;
        const d2 = a.id - b.id;
        return d1 < 0 ? -1 : (d1 > 0 ? 1 : (d2 < 0 ? -1 : (d2 > 0 ? 1 : 0)));
      });
    }
  });

  return { treeConfiguration: filteredConfiguration[0] };
};