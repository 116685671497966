import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useUserTokenDecode } from '../../hooks/useUserTokenDecode.mjs';
import { ReactComponent as BsArrowRightCircleFill } from '../../assets/icon/BsArrowLeftCircle.svg';
import { AlarmEditor, Checkbox, ErrorMessagePopup, InputSearch, TreeConfiguration } from '../../components/components.mjs';
import { ContentApp, HeaderApp, NavApp } from '../../layout/layout.mjs';
import { useKernel } from '../../context/ContextKernel.mjs';
import { flagsParser } from '../../utils/flags-parser/flagsParser.mjs';
import { useActiveConfigurationId } from '../../hooks/useActiveConfigurationId.mjs';
import { filterConfigurationTree } from '../../utils/filter-configuration-tree/filterConfigurationTree.mjs';

import styles from './alarm.module.scss';

export default function Alarm() {
  const location = useLocation();
  const navigate = useNavigate();
  const { activeConfigurationId } = useActiveConfigurationId();
  const [configurationName, setConfigurationName] = useState(null);
  const [userName, setUserName] = useState('');
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('Ошибка!');
  const [alarmEditorState, setAlarmEditorState] = useState({ alarmList: [], activeAlarm: {}, activeAlarmId: null });
  const [componentsTypeId, setComponentsTypeId] = useState(0);
  const [selectedParameter, setSelectedParameter] = useState({});
  const [checked, setChecked] = useState(false);
  const [rotate, setRotate] = useState(false);
  const {
    kernel,
    currentLicense,
    configuration,
    setStateElementConfigurationId,
    searchConfigurationState,
    setSearchConfigurationState,
    setConfiguration,
  } = useKernel();
  const { flags } = useUserTokenDecode();
  const { hasAccessRightsAlarmServerModule } = flagsParser(flags);

  const buildConfigurationTree = async () => {
    try {
      const { tree } = await kernel.buildTree(location, activeConfigurationId);

      setConfiguration(tree);
      setConfigurationName(kernel.configuration.name);
      setUserName(kernel.getUser().name);
    } catch (error) {
      setIsError(true);
      setErrorMessage('Ошибка загрузки конфигурации!');
    }
  };

  useEffect(() => {
    setConfiguration(null);

    if (searchConfigurationState !== '') {
      setChecked(false);
      setStateElementConfigurationId(0n);
    }

    const debounce = setTimeout(() => {
      if (!checked) {
        const { treeConfiguration } = filterConfigurationTree(searchConfigurationState, kernel);
        setConfiguration(treeConfiguration);
      } else {
        const { treeConfiguration } = filterConfigurationTree('#0', kernel, 3);
        setConfiguration(treeConfiguration);
      }
    }, 300);

    return () => {
      clearTimeout(debounce);
    };
  }, [searchConfigurationState, checked]);

  useEffect(() => {
    setConfiguration(null);
    buildConfigurationTree();
    kernel.downloadingAlarm();
    kernel.timerRefreshToken(); //запуск обновления токена
    kernel.setNavigatePath('/alarm');
    return () => {
      setSearchConfigurationState('');
    };
  }, []);

  useEffect(() => {
    const { hasAccessAlarmServerModule = undefined } = currentLicense;
    if (hasAccessAlarmServerModule !== 'true' || hasAccessAlarmServerModule === undefined || hasAccessRightsAlarmServerModule === false) {
      navigate('/section-selection');
    }
    return () => {};
  }, [currentLicense]);

  return (
    <div className={styles.page}>
      {isError && (
        <ErrorMessagePopup
          errorMessage={errorMessage}
          actionPerform={() => {
            setIsError(false);
            kernel.setNavigatePath('/alarm');
            navigate('/section-selection');
          }}
        />
      )}
      <NavApp />
      <ContentApp>
        <div className={styles.headerContainer}>
          <HeaderApp configurationName={configurationName} userName={userName} />
        </div>
        <div className={styles.contentContainer}>
          <div
            className={classNames('treeContainer', rotate && 'collapsedTree')}
            onContextMenu={(event) => {
              event.preventDefault();
            }}
          >
            <InputSearch
              searchState={searchConfigurationState}
              setSearchState={setSearchConfigurationState}
              classes={rotate && styles.collapsedSearch}
            />
            <Checkbox
              className={classNames(styles.checkbox, rotate && 'collapsedTreeWrap')}
              isVisible={checked}
              handleChange={() => setChecked(!checked)}
              caption={'Отображать только параметры с тревогами'}
              disabled={configuration === null ? true : searchConfigurationState !== '' ? true : false}
            />
            <div className={classNames(styles.treeWrap, rotate && 'collapsedTreeWrap')}>
              <TreeConfiguration
                configuration={configuration}
                setConfiguration={setConfiguration}
                setComponentsTypeId={setComponentsTypeId}
                setSelectedParameter={setSelectedParameter}
              />
            </div>
            <BsArrowRightCircleFill
              className={classNames(styles.treeControl, rotate && styles.treeControlActive)}
              onClick={() => setRotate(!rotate)}
            />
          </div>
          <div className={styles.codeEditorContainer}>
            <AlarmEditor alarmEditorState={alarmEditorState} setAlarmEditorState={setAlarmEditorState} configuration={configuration} />
          </div>
        </div>
      </ContentApp>
    </div>
  );
}
