const uint32ToFloat32 = value => {
  const arrayBuffer = new ArrayBuffer(4);
  const dataView = new DataView(arrayBuffer);
  dataView.setUint32(0, value, true);
  return dataView.getFloat32(0, true);
};

const float32ToUint32 = value => {
  const arrayBuffer = new ArrayBuffer(4);
  const dataView = new DataView(arrayBuffer);
  dataView.setFloat32(0, value, true);
  return dataView.getUint32(0, true);
};

const float32ToUint64 = value => {
  const arrayBuffer = new ArrayBuffer(8);
  const dataView = new DataView(arrayBuffer);
  dataView.setFloat32(0, value, true);
  return dataView.getBigUint64(0, true);
};

const float32ToInt64 = value => {
  const arrayBuffer = new ArrayBuffer(8);
  const dataView = new DataView(arrayBuffer);
  dataView.setFloat32(0, value, true);
  return dataView.getBigInt64(0, true);
};

const uint32ToInt64 = value => {
  const arrayBuffer = new ArrayBuffer(8);
  const dataView = new DataView(arrayBuffer);
  dataView.setUint32(0, value, true);
  return dataView.getBigInt64(0, true);
};

const uint64ToFloat32 = value => {
  const arrayBuffer = new ArrayBuffer(8);
  const dataView = new DataView(arrayBuffer);
  dataView.setBigUint64(0, value, true);
  return dataView.getFloat32(0, true);
};


export {
  uint32ToFloat32,
  float32ToUint32,
  float32ToInt64,
  uint32ToInt64,
  float32ToUint64,
  uint64ToFloat32,
};