import classNames from 'classnames/bind';

import styles from './tooltip.module.scss';

const WARNING = 0;
const ERROR = 1;

function Tooltip(props) {
  const { message, children, status = ERROR } = props;
  const classes = classNames.bind(styles);

  const tooltipWrapClassNames = classes({
    tooltipWrap: true,
    error: status === ERROR,
    warning: status === WARNING,
  });

  const tooltipContainerClassNames = classes({
    tooltipContainer: true,
    backgroundError: status === ERROR,
    backgroundWarning: status === WARNING,
  });

  return (
    <div className={tooltipWrapClassNames}>
      <div className={tooltipContainerClassNames}>
        <div className={styles.tooltipArrow}></div>
        {children ? (
          children
        ) : (
          <span title={message} className={styles.errorMessage}>
            {message}
          </span>
        )}
      </div>
    </div>
  );
}

export default Tooltip;
