import classNames from 'classnames';
import { useEffect } from 'react';

import { PreloadList } from '../components.mjs';
import { useKernel } from '../../context/ContextKernel.mjs';

import { preloadList } from './model/preloadList.mjs';
import styles from './treeTemplateSetpoints.module.scss';


export default function TreeTemplateSetpoints(props) {
  const { configuration, setSetpointsEditorState, activeElementTree, setActiveElementTree, treeTemplateState, setTreeTemplateState } = props;

  const { kernel } = useKernel();

  const handleTreeTemplateSetpoint = ({ currentTarget: { dataset } }) => {
    const templateSetpoints = kernel.templateSetpoints;

    setActiveElementTree(parseInt(dataset.id));
    setSetpointsEditorState({
      setpointsList: templateSetpoints[dataset.id]?.setpointsList,
      activeSetpoint: templateSetpoints[dataset.id]?.setpointsList[0],
      activeSetpointId: templateSetpoints[dataset.id]?.setpointsList[0]?.id,
    });
  };

  useEffect(() => {
    const templateSetpointsList = kernel.getTemplateSetpointsList();
    if (templateSetpointsList.length !== 0) {
      setTreeTemplateState(templateSetpointsList);
      setSetpointsEditorState({
        setpointsList: templateSetpointsList[0]?.setpointsList,
        activeSetpoint: templateSetpointsList[0]?.setpointsList[0],
        activeSetpointId: templateSetpointsList[0]?.setpointsList[0]?.id,
      });

      setActiveElementTree(templateSetpointsList[0]?.setpointsList[0]?.systemTypeId);
    } else {
      setSetpointsEditorState({ setpointsList: [], activeSetpoint: {}, activeSetpointId: null });
    }

    return () => {};
  }, []);

  return (
    <div className={styles.tree}>
      {configuration ? (
        treeTemplateState?.map((templateSetpoint) => {
          return (
            <div
              key={templateSetpoint.systemTypeId}
              className={classNames(styles.treeItem, activeElementTree === parseInt(templateSetpoint.systemTypeId) && styles.treeItemActive)}
              data-id={templateSetpoint.systemTypeId}
              onClick={handleTreeTemplateSetpoint}
            >
              <span className={styles.circle}></span>
              <span className={styles.treeItemText}>{templateSetpoint.systemTypeDescription}</span>
            </div>
          );
        })
      ) : (
        <PreloadList preloadSizeElement={preloadList()} />
      )}
    </div>
  );
}
