class User {

  constructor(kernel, userStruct) {
    this.kernel = kernel;
    this.userStruct = userStruct;
  }

  get id() {
    return this.userStruct.id;
  }

  get groupId() {
    return this.userStruct.groupId;
  }

  get name() {
    return this.userStruct.name.value;
  }

  get password() {
    return this.userStruct.password.value;
  }

  set newName(newName) {
    return this.userStruct.name.value = newName;
  }

  set newPassword(newPassword) {
    return this.userStruct.password.value = newPassword;
  }

}



export { User };
