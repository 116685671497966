import { SystemTypesListStruct } from '../quantum-lib/Scada/Parser/Configurations/SystemTypesListStruct.mjs';
import { BlockTypesListStruct } from '../quantum-lib/Scada/Parser/Configurations/BlockTypesListStruct.mjs';
import { EditorSettingsListStruct } from '../quantum-lib/Arm/Parser/Configurations/EditorSettingsListStruct.mjs';
import { ErrorsApp } from '../utils/errors-app/ErrorsApp.mjs';

import { setWorkingMode } from './services.mjs';

export class ReferenceBooksService {
  static async systemTypesList() {
    const response = await fetch(`${setWorkingMode()}/system-types-list`, {
      method: 'get',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
    if (!response.ok) {
      throw new ErrorsApp("Произошла ошибка при получении списка системных типов!", 2);
    }
    const arrayBuffer = await response.arrayBuffer();

    const isLittleEndian = true;
    const offset = 0;
    const dataView = new DataView(arrayBuffer);

    const systemTypesListStruct = new SystemTypesListStruct();
    systemTypesListStruct.readFrom(dataView, offset, isLittleEndian);

    return systemTypesListStruct;
  }

  static async blockTypesList() {
    const response = await fetch(`${setWorkingMode()}/block-types-list`, {
      method: 'get',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });

    if (!response.ok) {
      throw new ErrorsApp("Произошла ошибка при получении списка типов блоков!", 2);
    }
    const arrayBuffer = await response.arrayBuffer();

    const isLittleEndian = true;
    const offset = 0;
    const dataView = new DataView(arrayBuffer);

    const blockTypesListStruct = new BlockTypesListStruct();
    blockTypesListStruct.readFrom(dataView, offset, isLittleEndian);

    return blockTypesListStruct;
  }

  static async editorSettingsList() {
    const response = await fetch(`${setWorkingMode()}/get-editor-settings-list`, {
      method: 'get',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });

    if (!response.ok) {
      throw new ErrorsApp("Произошла ошибка при получении списка настроек отображения свойств!", 2);
    }
    const arrayBuffer = await response.arrayBuffer();

    const isLittleEndian = true;
    const offset = 0;
    const dataView = new DataView(arrayBuffer);

    const editorSettingsListStruct = new EditorSettingsListStruct();
    editorSettingsListStruct.readFrom(dataView, offset, isLittleEndian);

    return editorSettingsListStruct;
  }
}
