import classNames from 'classnames';
import { MdDelete, MdDriveFileRenameOutline } from 'react-icons/md';
import { useState } from 'react';

import styles from './treeHandbookItem.module.scss';

export function TreeHandbookItem(props) {
  const { handbookItem, handbook, setHandbook, hasParameterIdInTree } = props;

  const [hasHoverEdit, setHasHoverEdit] = useState(false);
  const [hasHoverDelete, setHasHoverDelete] = useState(false);

  const handleDoubleClickTypeTask = (event) => {
    setHandbook({
      ...handbook,
      activeHandbook: {},
      activeHandbookId: null,
    });
  };

  const handleClickHandbook = (event) => {
    const activeHandbookId = event.currentTarget.dataset.handbookId;
    
    setHandbook({
      ...handbook,
      activeHandbook: {},
      activeHandbookId: activeHandbookId,
    });   
  };

  return (
    <div>
      <div className={styles.wrap}>
        <div
          className={classNames(styles.typeHandbook, parseInt(handbook.activeHandbookId) === handbookItem.id && styles.handbookActive,)}
          title={handbookItem?.name}
          data-handbook-id={handbookItem.id}
          onDoubleClick={handleDoubleClickTypeTask}
          onClick={handleClickHandbook}
        >
          <span className={styles.typeHandbookText}>{handbookItem?.name + (hasParameterIdInTree ? ' #' + handbookItem?.id : '')}</span>
        </div>
      </div>
    </div>
  );
}