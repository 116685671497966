


export const createSnapshotTypes = (type) => {
  type = parseInt(type);
  const typeIsStringType = type === 12 || type === 13 || type === 14;

  return [
    { value: 0, label: 'Не назначен', isDisabled: false },
    { value: 10, label: 'Быстрый', isDisabled: typeIsStringType },
    { value: 11, label: 'Средний', isDisabled: typeIsStringType },
    { value: 12, label: 'Медленный', isDisabled: typeIsStringType },
    { value: 13, label: 'Строковый', isDisabled: !typeIsStringType },
  ];
};