export const dateTimeUTCList = [
  { value: -43200, text: 'UTC-12 Линия перемены дат' },
  { value: -39600, text: 'UTC-11 Острова Мидуэй' },
  { value: -36000, text: 'UTC-10 Гавайи' },
  { value: -34200, text: 'UTC-9:30 Маркизские острова' },
  { value: -32400, text: 'UTC-9 Аляска' },
  { value: -28800, text: 'UTC-8 Нижняя Калифорния' },
  { value: -25200, text: 'UTC-7 Аризона' },
  { value: -21600, text: 'UTC-6 Чикаго, Мехико' },
  { value: -18000, text: 'UTC-5 Нью-Йорк, Гавана' },
  { value: -14400, text: 'UTC-4 Санто-Доминго, Каракас' },
  { value: -12600, text: 'UTC-3:30 Ньюфаундленд' },
  { value: -10800, text: 'UTC-3 Рио-Де-Жанейро, Сальвадор' },
  { value: -7200, text: 'UTC-2 Гренландия' },
  { value: -3600, text: 'UTC-1 Азорские острова' },
  { value: 0, text: 'UTC+0 Лондон, Дублин' },
  { value: 3600, text: 'UTC+1 Берлин, Рим, Стокгольм, Париж, Алжир' },
  { value: 7200, text: 'UTC+2 Калининград, Афины, Бухарест' },
  { value: 10800, text: 'UTC+3 Москва, Санкт-Петербург' },
  { value: 12600, text: 'UTC+3:30 Тегеран' },
  { value: 14400, text: 'UTC+4 Самара, Астрахань, Ульяновск, Ижевск' },
  { value: 16200, text: 'UTC+4:30 Кабул' },
  { value: 18000, text: 'UTC+5 Екатеринбург' },
  { value: 19800, text: 'UTC+5:30 Колката, Мумбаи, Нью-Дели, Ченнай' },
  { value: 20700, text: 'UTC+5:45 Катманду' },
  { value: 21600, text: 'UTC+6 Омск, Астана' },
  { value: 23400, text: 'UTC+6:30 Ягон' },
  { value: 25200, text: 'UTC+7 Красноярск, Новосибирск, Барнаул, Томск' },
  { value: 28800, text: 'UTC+8 Иркутск, Гонконг, Пекин' },
  { value: 30600, text: 'UTC+8:30 Пхеньян' },
  { value: 32400, text: 'UTC+9 Чита, Якутск, Осака, Токио' },
  { value: 36000, text: 'UTC+10 Владивосток, Канберра' },
  { value: 39600, text: 'UTC+11 Магадан, Сахалин' },
  { value: 43200, text: 'UTC+12 Анадырь, Петропавловск-Камчатский, Веллингтон, Окленд' },
  { value: 46800, text: 'UTC+13 Самоа' },
];