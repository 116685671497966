class EditorSettings {

  constructor( EditorSettingsStruct) {
    this.EditorSettingsStruct = EditorSettingsStruct;
  }

  get id() {
    return this.EditorSettingsStruct.id;
  }

  get systemTypeId() {
    return this.EditorSettingsStruct.systemType;
  }

  get systemTypeParent() {
    return this.EditorSettingsStruct.systemTypeParent;
  }

  get mask() {
    return this.EditorSettingsStruct.mask.value;
  }

  get canView() {
    return this.EditorSettingsStruct.canView;
  }

  get canEdit() {
    return this.EditorSettingsStruct.canEdit;
  }

  get typeBlockParent() {
    return this.EditorSettingsStruct.typeBlockParent;
  }

}

export { EditorSettings };
