import { Base64 } from './Base64.mjs'

class Struct {

    constructor() {
        this.setParserOptions()
    }

    setParserOptions(options = { }) {
        this.parserOptions = options
    }

    getParserOptions() {
        return this.parserOptions
    }

    readFrom(dataView, startOffset, isLittleEndian = true) {
        // nothing to read
        return startOffset // endOffset
    }

    writeTo(dataView, startOffset, isLittleEndian = true) {
        // nothing to write
        return startOffset // endOffset
    }

    getCurrentSize() {
        return 0
    }

    clone() {
        let clone = new Struct()
        clone.setParserOptions(this.getParserOptions())
        return clone
    }

    toArrayBuffer(isLittleEndian = true) {
        const arrayBuffer = new ArrayBuffer(this.getCurrentSize())
        const dataView = new DataView(arrayBuffer)
        this.writeTo(dataView, 0, isLittleEndian)
        return arrayBuffer
    }

    toBase64() {
        return Base64.arrayBufferToBase64String(this.toArrayBuffer())
    }
}

export {
    
    Struct
}
