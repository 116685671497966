import { ParameterStruct } from '../Parser/Configurations/ParameterStruct.mjs'
import { ResponseParameterValueDataStruct } from '../Parser/Responses/ResponseParameterValueDataStruct.mjs'
import { RequestSetParameterValueDataStruct } from '../Parser/Requests/RequestSetParameterValueDataStruct.mjs'
import { ColorStruct } from '../Parser/ColorStruct.mjs'
import { ParameterValue } from './ParameterValue.mjs'
import { BlockType } from './BlockType.mjs'
import { SystemType } from './SystemType.mjs'
import { PRNG } from '../PRNG.mjs'

// timings in milliseconds
const TIMING_FAST = 250 // 1000
const TIMING_MEDIUM = 1500 // 5000
const TIMING_SLOW = 5000 // 30000
const TIMING_DEFAULT = TIMING_FAST

const TTL_FAST = 1000
const TTL_MEDIUM = 5000
const TTL_SLOW = 30000
const TTL_DEFAULT = TTL_FAST

const STATUS_NOT_INITIALIZED = 0
const STATUS_UNKNOWN = 1
const STATUS_NORMAL = 2
const STATUS_NOT_LINKED = 3
const STATUS_WARNING = 4
const STATUS_DANGER = 5

const PARAMETER_CLASS_FOLDER = 0
const PARAMETER_CLASS_OBJECT = 1
const PARAMETER_CLASS_VALUE = 2
const PARAMETER_CLASS_PROPERTY = 3

const timingBySnapshotType = {}

timingBySnapshotType[ParameterStruct.SNAPSHOT_FAST] = TIMING_FAST
timingBySnapshotType[ParameterStruct.SNAPSHOT_MEDIUM] = TIMING_MEDIUM
timingBySnapshotType[ParameterStruct.SNAPSHOT_SLOW] = TIMING_SLOW

const ttlBySnapshotType = {}

ttlBySnapshotType[ParameterStruct.SNAPSHOT_FAST] = TTL_FAST
ttlBySnapshotType[ParameterStruct.SNAPSHOT_MEDIUM] = TTL_MEDIUM
ttlBySnapshotType[ParameterStruct.SNAPSHOT_SLOW] = TTL_SLOW

class Parameter {

    constructor(kernel, parameterStruct, disableTransforms = false) {
        this.kernel = kernel
        this.parameterStruct = parameterStruct
        this.disableTransforms = disableTransforms
        this.children = []
        this.properties = []
        this.staticColorStruct = null
    }

    get id() {
        return this.parameterStruct.id
    }

    get parentId() {
        return this.parameterStruct.parentId
    }

    get systemTypeId() {
        return this.parameterStruct.systemType
    }

    get systemType() {
        return this.kernel.getSystemTypeById(this.systemTypeId)
    }

    get blockType() {
        return this.kernel.getBlockTypeById(this.ObjectType)
    }

    get countValue() {
        return this.parameterStruct.countValue
    }

    classify() {
        return this.isProperty
            ? PARAMETER_CLASS_PROPERTY
            : (this.hasValue
                ? PARAMETER_CLASS_VALUE
                : PARAMETER_CLASS_OBJECT);
    }

    get isFolder() {
        return this.classify() === PARAMETER_CLASS_FOLDER
    }

    get isObject() {
        return this.classify() === PARAMETER_CLASS_OBJECT
    }

    get isValue() {
        return this.classify() === PARAMETER_CLASS_VALUE
    }

    get isProperty() {
        return this.countValue === 0 && this.systemTypeId !== 1
    }

    get isTypeNode() {
        return (!this.children || this.children.length <= 0)
            && this.systemTypeId !== 1
    }

    get parent() {
        let id = this.id
        let parentId = this.parentId
        if (id === 0n && id === parentId) {
            return null
        }
        return this.kernel.getParameterById(parentId)
    }

    get parentObject() {
        let p = this.parent
        while (p) {
            if (p.isObject) {
                return p
            }
            p = p.parent
        }
        return null
    }

    get object() {
        let p = this
        while (p) {
            if (p.isObject) {
                return p
            }
            p = p.parent
        }
        return null
    }

    get name() {
        return this.systemType.name
    }

    get displayPathName() {
        return this.path.slice(0, -1).map(parameter => parameter.displayName).join(' / ')
    }

    get displayFullPathName() {
        return this.path.map(parameter => parameter.displayName).join(' / ')
    }

    get displayPathNameWithoutRoot() {
        return this.path.slice(1, -1).map(parameter => parameter.displayName).join(' / ')
    }

    get displayFullPathNameWithoutRoot() {
        return this.path.slice(1).map(parameter => parameter.displayName).join(' / ')
    }

    get displayName() {
        let name = ''
        if ('ObjectNameOtobrazhenie' in this) {
            name = this.ObjectNameOtobrazhenie
        } else if (this.isProperty) {
            name = this.description
        }
        if (name === '') {
            name = this.name
        }
        return name
    }

    get displayNameWithObjectName() {
        if (this.isObject) {
            return this.displayName
        }
        const p = this.parentObject
        return p
            ? p.displayName + ': ' + this.displayName
            : this.displayName
    }

    get displayNameWithUnitName() {
        return this.displayName + ', ' + this.unitName
    }

    get unitName() {
        let unitName = ''
        if ('NameEdIzm' in this) {
            unitName = this.NameEdIzm
        }
        if (unitName === '') {
            unitName = this.systemType.unitName
        }
        return unitName
    }

    get enabled() {
        if ('ObjectOn_OFF' in this) {
            return this.ObjectOn_OFF
        }
        const parent = this.parent
        return parent ? parent.enabled : true
    }

    get lowDangerLevel() {
        if ('ChanelParametrLA' in this) {
            return this.ChanelParametrLA
        }
        return 0
    }

    get highDangerLevel() {
        if ('ChanelParametrHA' in this) {
            return this.ChanelParametrHA
        }
        return 0
    }

    get lowWarningLevel() {
        if ('ChanelParametrLP' in this) {
            return this.ChanelParametrLP
        }
        return 0
    }

    get highWarningLevel() {
        if ('ChanelParametrHP' in this) {
            return this.ChanelParametrHP
        }
        return 0
    }

    get lowEngineeringLevel() {
        if ('EngineeringLimitLow' in this) {
            return this.EngineeringLimitLow
        }
        return 0
    }

    get highEngineeringLevel() {
        if ('EngineeringLimitHigh' in this) {
            return this.EngineeringLimitHigh
        }
        return 0
    }

    get hasEngineeringLevels() {
        return 'EngineeringLimitLow' in this
            || 'EngineeringLimitHigh' in this
    }

    get description() {
        return this.systemType.description
    }

    get propertyValue() {
        return this.systemType.parseValue(
            this.parameterStruct.rawValue,
            this.parameterStruct.strValue.value
        )
    }

    get propertyValueTimestamp() {
        return this.parameterStruct.timestamp
    }

    get propertyValueDate() {
        return new Date(this.transformTimestamp(this.propertyValueTimestamp) * 1000)
    }

    get isStatusNode() {
        return this.typeNode.systemTypeId === SystemType.SYSTEM_TYPE_STATUS
    }

    get typeNode() {
        if (this.isTypeNode) {
            return this
        }
        let typeNode = this.children.find(node => node.isTypeNode)
        if (typeNode) {
            return typeNode
        }
        return this
    }

    get statusNode() {
        if (this.isStatusNode) {
            return this
        }
        return this.children.find(p => p.isStatusNode)
    }

    get activeStatusNode() {
        if (this.enabled) {
            let statusNode = this.statusNode
            if (statusNode && statusNode.enabled && this.id !== statusNode.id) {
                return statusNode
            }
        }
        return null
    }

    get displayTimeZone() {
        let parameter = this
        while (!('TimeZoneClient' in parameter) && parameter.parent) {
            parameter = parameter.parent
        }
        return 'TimeZoneClient' in parameter ? parameter.TimeZoneClient : 0
    }

    get snapshotType() {
        return this.parameterStruct.snapshotType
    }

    get timing() {
        const snapshotType = this.snapshotType
        return snapshotType in timingBySnapshotType
            ? timingBySnapshotType[snapshotType]
            : TIMING_DEFAULT
    }

    get ttl() {
        const snapshotType = this.snapshotType
        return snapshotType in ttlBySnapshotType
            ? ttlBySnapshotType[snapshotType]
            : TTL_DEFAULT
    }

    get path() {
        if (this.id === 0n) {
            return [this]
        }
        const path = this.parent.path
        path.push(this)
        return path
    }

    get valueNodes() {
        if (this.hasValue) {
            return [this]
        }
        return [].concat(...this.children.map(node => node.valueNodes))
    }

    get hasValue() {
        return this.systemTypeId !== 1 || this.typeNode.id !== this.id
    }

    get isReadable() {
        return this.parameterStruct.isReadable
    }

    get isWritable() {
        return this.parameterStruct.isWritable
    }

    get isAcknowledgeable() {
        return this.parameterStruct.isAcknowledgeable
    }

    get isDeleted() {
        return this.parameterStruct.isDeleted
    }

    get hints() {
        let blockType = this.blockType
        let hintsMap = {}
        if (blockType) {
            hintsMap['Тип объекта'] = this.blockType.description
        }
        if (this.ObjectType === BlockType.TYPE_PARAMETER) {
            hintsMap['Системный тип параметра'] = this.typeNode.systemType.description
        }
        return hintsMap
    }

    isDescedantOf(parentId) {
        let parent = this.parent
        while (parent) {
            if (parent.id === parentId) {
                return true
            }
            parent = parent.parent
        }
        return false
    }

    parseValue(rawValue, strValue = '') {
        let systemType = this.typeNode.systemType
        let value = systemType.parseValue(
            rawValue,
            strValue,
            this.KoefPerevoda,
            this.Smeschenie,
            this.displayTimeZone
        )
        return value
    }

    transformTimestamp(timestamp) {
        return timestamp
            + (this.disableTransforms ? 0 : this.displayTimeZone)
    }

    async getValue(useLocalDataForProperties = true) {
        if (useLocalDataForProperties && this.isProperty) {
            return new ParameterValue(
                this,
                this.propertyValue,
                this.propertyValueDate
            )
        }
        const remoteServersPool = this.kernel.getRemoteServersPool()
        const data = await remoteServersPool.getParameterValue(this.id)
        if (!data.isOk) {
            if (data.resultCode === ResponseParameterValueDataStruct.RESULT_PAUSE) {
                throw `Parameter ${this.id} is temporarily unavailable.`
            } else if (data.resultCode === ResponseParameterValueDataStruct.RESULT_NOT_FOUND) {
                throw `Parameter ${this.id} is not found.`
            } else if (data.resultCode === ResponseParameterValueDataStruct.RESULT_PERMISSION_DENIED) {
                throw `Reading of parameter ${this.id} is not permitted.`
            }
            throw `Unexpected resultCode ${data.resultCode} was returned while reading parameter ${this.id}.`
        }
        const value = this.parseValue(
            data.rawValue,
            data.strValue.value
        )
        return new ParameterValue(
            this,
            value,
            new Date(this.transformTimestamp(data.timestamp) * 1000)
        )
    }

    async getArchiveV2(periodBegin, periodEnd, flags = 0, offset = 0, limit = null) {
        const remoteServersPool = this.kernel.getRemoteServersPool()
        const archive = await remoteServersPool.getParameterArchiveV2(
            this.id,
            periodBegin,
            periodEnd,
            flags,
            offset,
            limit
        )
        const systemType = this.typeNode.systemType
        if (systemType.disableTransforms) {
            archive.t = row => row
        } else {
            const scale = this.KoefPerevoda
            const translate = this.Smeschenie
            const timeZoneOffsetInSeconds = this.displayTimeZone
            archive.t = row => {
                let { value, timestamp, sourceId } = row
                value = systemType.transformValue(value, scale, translate, timeZoneOffsetInSeconds)
                timestamp += timeZoneOffsetInSeconds
                return { value, timestamp, sourceId }
            }
        }
        archive.f = archive.t
        return archive
    }

    async getStatus() {
        let statusNode = this.statusNode
        if (statusNode) {
            return await statusNode.getValue()
        }
        return new ParameterValue(this, STATUS_UNKNOWN)
    }

    async setValue(value, timestamp) {
        const remoteServersPool = this.kernel.getRemoteServersPool()
        const result = await remoteServersPool.setParameterValue(this.id, value, timestamp)
        if (result.responseSetParameterValueDataStruct.isOk) {
            if (this.isProperty) {
                const requestSetParameterValueDataStruct = result.requestSetParameterValueDataStruct
                this.parameterStruct.timestamp = requestSetParameterValueDataStruct.timestamp
                this.parameterStruct.rawValue = requestSetParameterValueDataStruct.rawValue
                this.parameterStruct.strValue.value = requestSetParameterValueDataStruct.strValue.value
            }
        } else {
            throw `Unexpected resultCode ${result.responseSetParameterValueDataStruct.resultCode} was returned while writing value ${value} to parameter ${this.id}.`
        }
    }

    encodeValue(value, timestamp = new Date()) {
        const encoded = this.typeNode.systemType.encodeValue(
            value,
            this.KoefPerevodaInput, // SINCE 2022-07-12 KoefPerevodaInput used instead of KoefPerevoda, see SystemType.reverseTransformValue() for more details
            this.SmeschenieInput, // SINCE 2022-07-12 SmeschenieInput used instead of Smeschenie, see SystemType.reverseTransformValue() for more details
            this.displayTimeZone
        )
        return new RequestSetParameterValueDataStruct(
            BigInt(this.id),
            parseInt(Math.round(timestamp.getTime() / 1000)),
            encoded.rawValue,
            encoded.strValue
        )
    }

    get colorStruct() {
        if (this.staticColorStruct) {
            return this.staticColorStruct
        }
        if ('Color' in this) {
            return ColorStruct.fromString(this.Color)
        }
        return PRNG.fromBigInt(this.id).randomColor()
    }

    get isLink() {
        return 'IDParametrLink' in this
    }

    get targetNode() {
        const kernel = this.kernel
        const visitedNodes = {}
        let node = this
        while (node.isLink) {
            visitedNodes[node.id] = true
            node = kernel.getParameterById(node.IDParametrLink)
            if (!node || node.id in visitedNodes)
                return null
        }
        return node
    }

    get sortOrder() {
        return this.kernel.getParameterSortOrder(this.id)
    }
}

Parameter.TIMING_FAST = TIMING_FAST
Parameter.TIMING_MEDIUM = TIMING_MEDIUM
Parameter.TIMING_SLOW = TIMING_SLOW

Parameter.STATUS_NOT_INITIALIZED = STATUS_NOT_INITIALIZED
Parameter.STATUS_UNKNOWN = STATUS_UNKNOWN
Parameter.STATUS_NORMAL = STATUS_NORMAL
Parameter.STATUS_NOT_LINKED = STATUS_NOT_LINKED
Parameter.STATUS_WARNING = STATUS_WARNING
Parameter.STATUS_DANGER = STATUS_DANGER

Parameter.PARAMETER_CLASS_FOLDER = PARAMETER_CLASS_FOLDER
Parameter.PARAMETER_CLASS_OBJECT = PARAMETER_CLASS_OBJECT
Parameter.PARAMETER_CLASS_VALUE = PARAMETER_CLASS_VALUE
Parameter.PARAMETER_CLASS_PROPERTY = PARAMETER_CLASS_PROPERTY

export {

    Parameter
}
