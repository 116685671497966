import styles from './loadingInUserPageForm.module.scss';

export default function LoadingInUserPageForm() {
  return (
    <div className={styles.loadingInUserPageForm}>
      <div className={styles.loadingInUserPageFormContainer}>
        <span className={styles.loader}></span>
      </div>
    </div>
  );
};
