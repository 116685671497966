const TYPE_PARAMETER = 12

class BlockType {

    constructor(blockTypeStruct) {
        this.blockTypeStruct = blockTypeStruct
    }

    get id() {
        return this.blockTypeStruct.id
    }

    get name() {
        return this.blockTypeStruct.name.value
    }

    get description() {
        return this.blockTypeStruct.description.value
    }
}

BlockType.TYPE_PARAMETER = TYPE_PARAMETER

export {

    BlockType
}
