class PropertyHistory {
  constructor(kernel, propertyHistoryStruct, parametrId) {
    this.kernel = kernel;
    this.propertyHistoryStruct = propertyHistoryStruct;
    this.parametrId = parametrId;
  }

  get id() {
    return this.propertyHistoryStruct.id;
  }

  get sourceId() {
    return this.propertyHistoryStruct.sourceId;
  }

  get user() {
    const usersList = this.kernel.users;
    return usersList[this.propertyHistoryStruct.sourceId];
  }

  get userName() {
    const usersList = this.kernel.users;
    return usersList[this.propertyHistoryStruct.sourceId].name;
  }

  get valuePropertyHistory() {
    return this.kernel.getParameterById(this.parametrId).systemType.parseValue(this.propertyHistoryStruct.rawValue, this.propertyHistoryStruct.strValue.value);
  }

  get dateChangesProperty() {
    return this.propertyHistoryStruct.timestamp;
  }

}

export { PropertyHistory };