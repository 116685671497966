export const deadZoneOptions = [
  { valueOption: 0, textOption: 0 },
  { valueOption: 1, textOption: 1 },
  { valueOption: 2, textOption: 2 },
  { valueOption: 3, textOption: 3 },
  { valueOption: 4, textOption: 4 },
  { valueOption: 5, textOption: 5 },
  { valueOption: 6, textOption: 6 },
  { valueOption: 7, textOption: 7 },
  { valueOption: 8, textOption: 8 },
  { valueOption: 9, textOption: 9 },
  { valueOption: 10, textOption: 10 },
];