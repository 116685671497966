class Configuration {

    constructor(kernel, configurationStruct) {
        this.kernel = kernel
        this.configurationStruct = configurationStruct
    }

    get id() {
        return this.configurationStruct.id
    }

    get name() {
        return this.configurationStruct.name.value
    }

    get accessBits() {
        return this.configurationStruct.accessBits
    }

    get isReadable() {
        return this.configurationStruct.isReadable
    }

    get isWritable() {
        return this.configurationStruct.isWritable
    }
}

export {

    Configuration
}
