import classNames from 'classnames';

import Utils from '../../../../utils/Utils.mjs';

import styles from './buildDayMonthSelect.module.scss';

export const BuildDayMonthSelect = (props) => {
  const { sendingData, isWritable, isDeleted, canEdit, dayMonthSelectValue, handleChangeDayMonthSelect } = props;

  const optionDayMonthSelect = Utils.setOptionDayMonthSelect();

  return (
    <select
      className={classNames(
        styles.dayMonthSelect,
        styles.reportingDaySelect,
        sendingData && styles.reportingDaySending,
        canEdit && styles.reportingDaySending,
        !isWritable && styles.reportingDaySending,
        isDeleted && styles.reportingDaySending,
      )}
      value={dayMonthSelectValue}
      onInput={handleChangeDayMonthSelect}
      disabled={sendingData || canEdit || !isWritable || isDeleted}
    >
      {optionDayMonthSelect.map((item) => {
        return (
          <option className={classNames(styles.reportingDaySelectOption, styles.dayMonthSelectOption)} key={item.value} value={item.value}>
            {item.text}
          </option>
        );
      })}
    </select>
  );
};
