import { LicensesListStruct } from '../quantum-lib/Arm/Parser/Licenses/LicensesListStruct.mjs';
import { LicensesTypesListStruct } from '../quantum-lib/Arm/Parser/Licenses/LicensesTypesListStruct.mjs';
import { ErrorsApp } from '../utils/errors-app/ErrorsApp.mjs';

import { setWorkingMode } from './services.mjs';

export class LicensesService {

  static async licensesListStruct() {
    const response = await fetch(`${setWorkingMode()}/licenses-list`, {
      method: 'get',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });

    if (!response.ok) {
      throw new ErrorsApp("Произошла ошибка при получении списка лицензий!", 2);
    }
    const arrayBuffer = await response.arrayBuffer();
    
    const isLittleEndian = true;
    const offset = 0;
    const dataView = new DataView(arrayBuffer);

    const licensesListStruct = new LicensesListStruct();

    licensesListStruct.readFrom(dataView, offset, isLittleEndian);
    return licensesListStruct;
  }

  static async licensesTypesListStruct() {
    const response = await fetch(`${setWorkingMode()}/licenses-types-list`, {
      method: 'get',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });

    if (!response.ok) {
      throw new ErrorsApp("Произошла ошибка при получении списка типов лицензий!", 2);
    }
    const arrayBuffer = await response.arrayBuffer();

    const isLittleEndian = true;
    const offset = 0;
    const dataView = new DataView(arrayBuffer);

    const licensesTypesListStruct = new LicensesTypesListStruct();

    licensesTypesListStruct.readFrom(dataView, offset, isLittleEndian);
    return licensesTypesListStruct;
  }

  static async getCurrentLicense() {
    const response = await fetch(`${setWorkingMode()}/get-current-license`, {
      method: 'get',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });

    if (!response.ok) {
      throw new ErrorsApp("Ошибка загрузки лицензии приложения!", 2);
    } else {
      return await response.json();
    }
  }

  static async checkLicensesName(name, uuid) {
    const response = await fetch(`${setWorkingMode()}/check-licenses-name`, {
      method: 'post',
      cache: 'reload',
      credentials: 'omit',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify({ name, uuid }),
    });

    if (!response.ok) {
      const errorMessage = (await response.json())?.message || "Ошибка!";
      throw new ErrorsApp(errorMessage, 2);
    } else {
      return await response.json();
    }
  }

  static async checkLicensesUuid(uuid) {
    const response = await fetch(`${setWorkingMode()}/check-licenses-uuid`, {
      method: 'post',
      cache: 'reload',
      credentials: 'omit',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify({ uuid }),
    });

    if (!response.ok) {
      const errorMessage = (await response.json())?.message || "Ошибка!";
      throw new ErrorsApp(errorMessage, 2);
    } else {
      return await response.json();
    }
  }

  static async addNewLicenses(licenses) {
    const response = await fetch(`${setWorkingMode()}/add-new-licenses`, {
      method: 'post',
      cache: 'reload',
      credentials: 'omit',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify({ ...licenses }),
    });

    if (!response.ok) {
      const errorMessage = (await response.json())?.message || "Ошибка!";
      throw new ErrorsApp(errorMessage, 2);
    } else {
      return await response.json();
    }
  }

  static async updatingExistingLicense(licenses) {
    const response = await fetch(`${setWorkingMode()}/updating-existing-license`, {
      method: 'put',
      cache: 'reload',
      credentials: 'omit',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify(licenses),
    });
    
    if (!response.ok) {
      const errorMessage = (await response.json())?.message || "Ошибка!";
      throw new ErrorsApp(errorMessage, 2);
    } else {
      return await response.json();
    }
  }
}