export const testIncludesPropertyName = (systemTypeId, kernel, selectedParameter) => {
  const systemTypeStruct = kernel.getSystemTypeById(systemTypeId);
  const properties = selectedParameter.properties;
  const includes = properties.find((property) => {
    return property.name === systemTypeStruct.name;
  });
  return includes ? true : false;
};

export const testIncludeInEditorSettings = (editorSettingsListBySystemTypeId, systemTypeParentId, typeBlockParent) => {
  if (editorSettingsListBySystemTypeId ) {
    //проверяем есть ли вообще правила для этого системного типа
    let canView = true;
    let canEdit = false; //если false то можно редактировать

    editorSettingsListBySystemTypeId.forEach((editorSettings) => {
      //проверяем есть ли вообще правила для этого системного типа
      if (editorSettings.systemTypeParent === 0 || editorSettings.systemTypeParent === systemTypeParentId) {
        //проверяем system type parent

        if (editorSettings.typeBlockParent === 0 || editorSettings.typeBlockParent === typeBlockParent) {
          //проверяем type block parent

          if (editorSettings.canView === 0) {
            canView = false;
          }

          if (editorSettings.canEdit === 0) {
            canEdit = true;
          }
        }
      }
    });

    if (!canView) {
      return {
        status: true,
        message:
          'У данного свойства есть определенный набор правил отображения и редактирования. При создании свойства у этого параметра, данное свойство будет скрыто и не доступно к редактированию! Для того чтобы была возможность изменения, включите режим "суперпользователя".',
      };
    } // просматривать нельзя

    if (canEdit) {
      return {
        status: true,
        message:
          'У данного свойства есть определенный набор правил отображения и редактирования. При создании свойства у этого параметра, Вы не сможете его редактировать! Для того чтобы была возможность изменения, включите режим "суперпользователя".',
      }; //редактировать нельзя
    }
  }

  return { status: false, message: '' };
};