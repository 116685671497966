import { HandbookListStruct } from '../quantum-lib/Arm/Parser/Handbook/HandbookListStruct.mjs';
import { HandbookContentListStruct } from '../quantum-lib/Arm/Parser/Handbook/HandbookContentListStruct.mjs';
import { HandbookByParameterIdListStruct } from '../quantum-lib/Arm/Parser/Handbook/HandbookByParameterIdListStruct.mjs';
import { ErrorsApp } from '../utils/errors-app/ErrorsApp.mjs';

import { setWorkingMode } from './services.mjs';

export class HandbookService {

  static async createHandbook(idConfiguration, handbookName){
    const response = await fetch(`${setWorkingMode()}/create-handbook/${idConfiguration}`, {
      method: 'post',
      cache: 'reload',
      credentials: 'omit',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify({handbookName:handbookName}),
    });

    if (!response.ok) {
      throw new ErrorsApp("Произошла ошибка при создании справочника!", 2);
    }

    return true;
    // return response.json();
  }

  static async getHandbookList(idConfiguration) {
    const response = await fetch(`${setWorkingMode()}/get-handbook-list/${idConfiguration}`, {
      method: 'get',
      cache: 'reload',
      credentials: 'omit',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
    if (!response.ok) {
      throw new ErrorsApp("Произошла ошибка при получении списка справочников!", 2);
    }
    const arrayBuffer = await response.arrayBuffer();

    const isLittleEndian = true;
    const offset = 0;
    const dataView = new DataView(arrayBuffer);

    const handbookListStruct = new HandbookListStruct();
    handbookListStruct.readFrom(dataView, offset, isLittleEndian);

    return handbookListStruct;
  }

  static async updateHandbook(idConfiguration, handbookId, handbookName){
    const response = await fetch(`${setWorkingMode()}/update-handbook/${idConfiguration}/${handbookId}`, {
      method: 'put',
      cache: 'reload',
      credentials: 'omit',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify({handbookName:handbookName}),
    });

    if (!response.ok) {
      throw new ErrorsApp("Произошла ошибка при обновлении справочника!", 2);
    }

    return true;

    // return response.json();
  }

  static async deleteHandbook(idConfiguration, handbookId){
    const response = await fetch(`${setWorkingMode()}/delete-handbook/${idConfiguration}/${handbookId}`, {
      method: 'delete',
      cache: 'reload',
      credentials: 'omit',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json;charset=utf-8',
      },
    });

    if (!response.ok) {
      throw new ErrorsApp("Произошла ошибка при обновлении справочника!", 2);
    }

    return true;

    // return response.json();
  }

  static async createHandbookContent(idConfiguration, handbookId, key, value){
    const response = await fetch(`${setWorkingMode()}/create-handbook-content/${idConfiguration}/${handbookId}`, {
      method: 'post',
      cache: 'reload',
      credentials: 'omit',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify({key:key, value:value}),
    });

    if (!response.ok) {
      throw new ErrorsApp("Произошла ошибка при создании содержимого справочника!", 2);
    }

    return true;
    // return response.json();
  }

  static async getHandbookContentList(idConfiguration) {
    const response = await fetch(`${setWorkingMode()}/get-handbook-content-list/${idConfiguration}`, {
      method: 'get',
      cache: 'reload',
      credentials: 'omit',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
    if (!response.ok) {
      throw new ErrorsApp("Произошла ошибка при получении справочника!", 2);
    }
    const arrayBuffer = await response.arrayBuffer();

    const isLittleEndian = true;
    const offset = 0;
    const dataView = new DataView(arrayBuffer);

    const handbookContentListStruct = new HandbookContentListStruct();
    handbookContentListStruct.readFrom(dataView, offset, isLittleEndian);

    return handbookContentListStruct;
  }

  static async updateHandbookContent(idConfiguration, handbookId, contentId, key, value){
    const response = await fetch(`${setWorkingMode()}/update-handbook-content/${idConfiguration}/${handbookId}/${contentId}`, {
      method: 'put',
      cache: 'reload',
      credentials: 'omit',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify({key:key, value:value}),
    });

    if (!response.ok) {
      throw new ErrorsApp("Произошла ошибка при обновлении содержимого справочника!", 2);
    }

    return true;

    // return response.json();
  }

  static async deleteHandbookContent(idConfiguration, handbookId, contentId){
    const response = await fetch(`${setWorkingMode()}/delete-handbook-content/${idConfiguration}/${handbookId}/${contentId}`, {
      method: 'delete',
      cache: 'reload',
      credentials: 'omit',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json;charset=utf-8',
      },
    });

    if (!response.ok) {
      throw new ErrorsApp("Произошла ошибка при удалении содержимого справочника!", 2);
    }

    return true;

    // return response.json();
  }

  static async createHandbookByParameterId(idConfiguration, parameterId, handbookId){
    const response = await fetch(`${setWorkingMode()}/create-handbook-by-parameter-id/${idConfiguration}/${parameterId}/${handbookId}`, {
      method: 'post',
      cache: 'reload',
      credentials: 'omit',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json;charset=utf-8',
      },
    });

    if (!response.ok) {
      throw new ErrorsApp("Произошла ошибка при создании содержимого справочника!", 2);
    }

    return true;
    // return response.json();
  }

  static async getHandbookListByParameterId(idConfiguration) {
    const response = await fetch(`${setWorkingMode()}/get-handbook-list-by-parameter-id/${idConfiguration}`, {
      method: 'get',
      cache: 'reload',
      credentials: 'omit',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
    if (!response.ok) {
      throw new ErrorsApp("Произошла ошибка при получении справочника по параметру!", 2);
    }

    const arrayBuffer = await response.arrayBuffer();

    const isLittleEndian = true;
    const offset = 0;
    const dataView = new DataView(arrayBuffer);

    const handbookByParameterIdListStruct = new HandbookByParameterIdListStruct();
    handbookByParameterIdListStruct.readFrom(dataView, offset, isLittleEndian);

    return handbookByParameterIdListStruct;
  }

  static async updateHandbookByParameterId(idConfiguration, parameterId, handbookId){
    const response = await fetch(`${setWorkingMode()}/update-handbook-by-parameter-id/${idConfiguration}/${parameterId}/${handbookId}`, {
      method: 'put',
      cache: 'reload',
      credentials: 'omit',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json;charset=utf-8',
      },
    });

    if (!response.ok) {
      throw new ErrorsApp("Произошла ошибка при обновлении содержимого справочника!", 2);
    }

    return true;

    // return response.json();
  }

  static async deleteHandbookByParameterId(idConfiguration, parameterId){
    const response = await fetch(`${setWorkingMode()}/delete-handbook-by-parameter-id/${idConfiguration}/${parameterId}`, {
      method: 'delete',
      cache: 'reload',
      credentials: 'omit',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json;charset=utf-8',
      },
    });

    if (!response.ok) {
      throw new ErrorsApp("Произошла ошибка при удалении содержимого справочника!", 2);
    }

    return true;

    // return response.json();
  }
}