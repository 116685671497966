import { GroupForm, GroupFormChangeCurrentGroup, SelectActionsOnUsersPage, UserForm, UserFormChangeCurrentUser } from '../components/components.mjs';
import { useKernel } from '../context/ContextKernel.mjs';

const SELECT_ACTIONS_USERS = 0;
const CREATE_NEW_USER = 1;
const CHANGE_CURRENT_USER = 2;
const CREATE_NEW_GROUP = 3;
const CHANGE_CURRENT_GROUP = 4;

export function ComponentUser(props) {
  const {
    componentsTypeId,
    setComponentsTypeId, //если 2 то это id пользователя если 4 то id группы
    setIdTreeElement,
    idTreeElement,
    usersTree,
    initialStateUserTree,
    setUsersTree,
    setInitialStateUserTree,
  } = props;

  const { kernel } = useKernel();
  const users = kernel.users;
  const group = kernel.usersGroups;
  const dataForm = componentsTypeId === 2 ? users[idTreeElement] : group[idTreeElement];

  const componentsByUsers = {
    [SELECT_ACTIONS_USERS]: <SelectActionsOnUsersPage setIdTreeElement={setIdTreeElement} setComponentsTypeId={setComponentsTypeId} />,
    [CREATE_NEW_USER]: (
      <UserForm
        idTreeElement={idTreeElement}
        usersTree={usersTree}
        initialStateUserTree={initialStateUserTree}
        setUsersTree={setUsersTree}
        setInitialStateUserTree={setInitialStateUserTree}
        setComponentsTypeId={setComponentsTypeId}
      />
    ),
    [CHANGE_CURRENT_USER]: (
      <UserFormChangeCurrentUser
        idTreeElement={idTreeElement}
        setComponentsTypeId={setComponentsTypeId}
        dataForm={dataForm}
        usersTree={usersTree}
        initialStateUserTree={initialStateUserTree}
        setUsersTree={setUsersTree}
        setInitialStateUserTree={setInitialStateUserTree}
      />
    ),
    [CREATE_NEW_GROUP]: (
      <GroupForm
        setComponentsTypeId={setComponentsTypeId}
        usersTree={usersTree}
        initialStateUserTree={initialStateUserTree}
        setUsersTree={setUsersTree}
        setInitialStateUserTree={setInitialStateUserTree}
      />
    ),
    [CHANGE_CURRENT_GROUP]: (
      <GroupFormChangeCurrentGroup
        setComponentsTypeId={setComponentsTypeId}
        dataForm={dataForm}
        usersTree={usersTree}
        initialStateUserTree={initialStateUserTree}
        setUsersTree={setUsersTree}
        setInitialStateUserTree={setInitialStateUserTree}
      />
    ),
  };

  return <>{componentsByUsers[componentsTypeId] ? componentsByUsers[componentsTypeId] : <p>Произошла ошибка при выборе действия!</p>}</>;
}
