import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { HiCheck } from 'react-icons/hi';

import Utils from '../../utils/Utils.mjs';
import { useKernel } from '../../context/ContextKernel.mjs';
import { SavePropertiesButtonModuleRs } from '../components.mjs';

import styles from './tableRowConnectionTypeModuleTcp.module.scss';

const connectionTypeOptionValue = [
  { valueOption: '6', textOption: 'Канал TCP 2-х проводной' },
  { valueOption: '7', textOption: 'Канал TCP 3-х проводной' },
  { valueOption: '8', textOption: 'Канал TCP 4-х проводной' },
];

export default function TableRowConnectionTypeModuleTcp(props) {
  const { selectedParameter } = props;

  const [handleChangeComponentTableRow, setHandleChangeComponentTableRow] = useState(false);
  const [sendingData, setSendingData] = useState(false);
  const [dataSentSuccessfully, setDataSentSuccessfully] = useState(false);
  const [errorParse, setErrorParse] = useState(false);
  const [changesDate, setChangesDate] = useState({ day: '', hour: '' }); // вывод даты и времени последнего изменения свойства (выводится время драйвер адреса)
  const [connectionTypeValue, setConnectionTypeValue] = useState(); // состояние компонента

  const dataUpdateInputModeStatus = useRef(false); // изменилось ли значение компонента состояние компонента или нет
  const { kernel, addNotification, stateElementConfigurationId } = useKernel();

  const saveProperty = async () => {
    setHandleChangeComponentTableRow(false);
    setSendingData(true);
    try {
      const { driverAddressParameter } = createParametersConnectionTypeObj();
      const newDriverAddressValue = createNewDriverAddress(connectionTypeValue, driverAddressParameter);

      const driverAddressParameterStruct = kernel.getParameterById(driverAddressParameter?.id); // драйвер адрес

      await kernel.setValues([{ parameterStruct: driverAddressParameterStruct, newValue: newDriverAddressValue }]);

      setDataSentSuccessfully(true);
      setTimeout(() => {
        setDataSentSuccessfully(false);
        setSendingData(false);
      }, 1500);
    } catch (error) {
      setSendingData(false);
      addNotification({ messageText: error.message, statusType: error.status });
      setHandleChangeComponentTableRow(true);
    }
  };

  const createNewDriverAddress = (connectionTypeValue, driverAddressParameter) => {
    const propertyValueRoundUp = driverAddressParameter.propertyValueRoundUp;
    let newValue = propertyValueRoundUp.toString().split('');
    newValue[4] = connectionTypeValue;
    newValue = newValue.join('');

    return newValue;
  };

  const handleChangeSelectInputMode = (event) => {
    setConnectionTypeValue(event.target.value);

    const { driverAddressParameter } = createParametersConnectionTypeObj();
    const driverAddressParameterParse = Utils.parseWorkingMode(driverAddressParameter);
    const initialInputMode = driverAddressParameterParse?.inputMode; //начальное значение компонента

    if (event.target.value.toString() === initialInputMode.toString() && !errorParse) {
      dataUpdateInputModeStatus.current = false;
    } else {
      dataUpdateInputModeStatus.current = true;
    }

    if (dataUpdateInputModeStatus.current) {
      setHandleChangeComponentTableRow(true);
    } else {
      setHandleChangeComponentTableRow(false);
    }
  };

  const createParametersConnectionTypeObj = () => {
    const parametersConnectionType = selectedParameter.properties.filter((property) => property.systemTypeId === 66);

    const parametersConnectionTypeObj = {};
    parametersConnectionType.forEach((parameterConnectionType) => {
      parametersConnectionTypeObj[parameterConnectionType.systemTypeId] = parameterConnectionType;
    });

    const driverAddressParameter = parametersConnectionTypeObj[66];

    return { driverAddressParameter };
  };

  useEffect(() => {
    const { driverAddressParameter } = createParametersConnectionTypeObj();

    if (driverAddressParameter) {
      const day = Utils.convertTimestamp(driverAddressParameter.propertyValueTimestamp);
      const hour = Utils.convertTimestampGetHour(driverAddressParameter.propertyValueTimestamp);
      setChangesDate({ day: day, hour: hour });
      const { inputMode } = Utils.parseWorkingMode(driverAddressParameter);

      if ([6, 7, 8].includes(parseInt(inputMode))) {
        setErrorParse(false);
        setConnectionTypeValue(inputMode);
        setHandleChangeComponentTableRow(false);
      } else {
        setErrorParse(true);
        setConnectionTypeValue(0);
        setHandleChangeComponentTableRow(false);
      }
    }

    return () => {};
  }, [stateElementConfigurationId]);

  return (
    <tr className={styles.tableRow}>
      <td className="tableRowPropertyTd">
        <span className={styles.tableRowText} title={'Тип подключения'}>
          Тип подключения
        </span>
      </td>
      <td className="tableRowPropertyTd">
        {/* компонент */}
        <div className={styles.connectionType}>
          <select
            className={classNames(styles.selectInputMode, sendingData && styles.sending)}
            value={connectionTypeValue}
            onInput={handleChangeSelectInputMode}
            disabled={sendingData}
          >
            {connectionTypeOptionValue.map((inputModeOption) => {
              return (
                <option key={inputModeOption.valueOption} value={inputModeOption.valueOption} className={styles.selectInputModeOption}>
                  {inputModeOption.textOption}
                </option>
              );
            })}
            {errorParse && (
              <option key={0} value={0} className={styles.selectInputModeOption}>
                Значение не определено
              </option>
            )}
          </select>
        </div>
        {/* компонент */}
      </td>
      <td className="tableRowPropertyTd">
        <span className={classNames(styles.tableRowText, styles.tableDate)}>{changesDate.day}</span>
        <span className={styles.tableRowText}>{changesDate.hour}</span>
      </td>
      <td className="tableRowPropertyTd">
        {dataSentSuccessfully ? (
          <HiCheck className={styles.checkControl} />
        ) : (
          <SavePropertiesButtonModuleRs
            sendingData={sendingData}
            handleChangeComponentTableRow={handleChangeComponentTableRow}
            saveProperty={saveProperty}
          />
        )}
      </td>
    </tr>
  );
}
