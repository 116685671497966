import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { IoIosArrowDown } from 'react-icons/io';
import { RxCross2 } from 'react-icons/rx';

import { CustomSelectOptionList, ValueItemsList } from './ui/index.mjs';
import { searchOption } from './model/searchOption.mjs';
import styles from './customSelect.module.scss';

export default function CustomSelect(props) {
  const {
    value,
    setValue,
    onChange = () => {},
    inputValueRef,
    unregister,
    name,
    disabled = false,
    options = [],
    classList = '',
    isMulti = false,
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [itemsList, setItemsList] = useState([]);
  const [optionsList, setOptionsList] = useState(options);
  const [inputState, setInputState] = useState({ value: '', size: '16px' });
  const initialValue = useRef(value);
  const inputRef = useRef();
  const optionsListRef = useRef();
  const elementRef = useRef();
  const containerRef = useRef();

  const handleBlur = () => {
    if (!isOpen) return;

    const input = inputRef.current;
    input.blur();
  };

  const handleFocus = () => {
    if (isOpen) return;

    const input = inputRef.current;
    input.focus();
  };

  const clearItemsList = () => {
    if (disabled) return;

    setOptionsList(options);
    setItemsList([]);
    setInputState({ value: '', size: '16px' });
  };

  const handleChange = ({ target: { value } }) => {
    const clientWidth = window.innerWidth;

    const size = clientWidth > 1400 ? `${(value.length + 1) * 10}px` : `${(value.length + 1) * 9}px`;
    setInputState({
      value,
      size,
    });
    if (isMulti) {
      // доработать
    } else {
      const newOptionsList = searchOption(options, value);
      setOptionsList(newOptionsList);
    }
  };

  const toggleOpen = () => {
    if (isOpen) {
      handleBlur();
      setIsOpen(false);
    } else {
      handleFocus();
      setIsOpen(true);
    }
  };

  // const currentOption = useRef();

  // const arrowDown = useCallback(() => {
  //   let currentOptionIndex;
  //   optionsList.forEach((option, index) => {
  //     if (option.value === currentOption.current.value) {
  //       currentOptionIndex = index;
  //     }
  //   });

  //   currentOption.current = optionsList[currentOptionIndex + 1];
  // }, []);

  const checkClickInContainerSelect = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', checkClickInContainerSelect);
    optionsListRef.current = options;

    if (isMulti) {
      // убирать из optionList уже выбранные options
      setItemsList(value);
    } else {
      const actionOptions = options.find((option) => option.value == value);
      setOptionsList(options);
      setItemsList([actionOptions]);
      setValue(name, value);
    }

    const element = inputRef.current;
    element.addEventListener('keydown', (event) => {
      const key = event.key;

      if (key === 'ArrowDown') {
        // arrowDown();
      }
    });

    return () => {
      unregister([name]);
      // removeEventListener
      document.removeEventListener('mousedown', checkClickInContainerSelect);
    };
  }, [options]);

  useEffect(() => {
    initialValue.current = value;
    if (isMulti) {
      setValue(name, itemsList);
    } else {
      setValue(name, value);
      onChange(name, value);
    }
    return () => {};
  }, [value]);

  useEffect(() => {
    if (isMulti) {
      setValue(name, itemsList);
    } else {
      setValue(name, itemsList[0]?.value);
      onChange(name, itemsList[0]?.value);
    }

    return () => {};
  }, [itemsList, name, setValue]);

  return (
    <div ref={containerRef} className={classNames(styles.container, disabled && styles.disabled, classList)}>
      <div className={styles.valueContainer} onClick={() => !disabled && toggleOpen()}>
        <ValueItemsList
          optionsList={optionsList}
          itemsList={itemsList}
          isOpen={isOpen}
          isMulti={isMulti}
          setItemsList={setItemsList}
          setOptionsList={setOptionsList}
          inputState={inputState}
          optionsListRef={optionsListRef}
        />

        <input
          type="text"
          disabled={disabled}
          autoCapitalize="none"
          autoComplete="off"
          autoCorrect="off"
          className={styles.textField}
          style={{ width: inputState.size }}
          ref={inputRef}
          onChange={handleChange}
          value={inputState.value}
        />
      </div>
      <div className={styles.controls}>
        {isMulti && (
          <button type="button" className={styles.clear} onClick={clearItemsList} disabled={disabled}>
            <RxCross2 className={classNames(styles.icon)} />
          </button>
        )}

        <button type="button" className={styles.dropDown} onClick={toggleOpen} disabled={disabled}>
          <IoIosArrowDown className={classNames(styles.icon)} disabled={disabled} />
        </button>
      </div>
      <CustomSelectOptionList
        optionsList={optionsList}
        itemsList={itemsList}
        isOpen={isOpen}
        isMulti={isMulti}
        setItemsList={setItemsList}
        setOptionsList={setOptionsList}
        setIsOpen={setIsOpen}
        optionsListRef={optionsListRef}
        setInputState={setInputState}
        elementRef={elementRef}
        // currentOption={currentOption}
      />
      <input type="hidden" ref={inputValueRef} value={JSON.stringify(itemsList)} />
    </div>
  );
};

// CustomSelect.propTypes = {};
