import { memo } from 'react';

import { PreloadList } from '../components.mjs';
import { useKernel } from '../../context/ContextKernel.mjs';

import { preloadList } from './model/preloadList.mjs';
import { TreeHandbookItem } from './ui/index.mjs';

import styles from './treeHandbook.module.scss';

export default memo(function TreeHandbook(props) {
  const { handbook, setHandbook, hasParameterIdInTree } = props;

  const { kernel } = useKernel();

  return (
    <div className={styles.treeHandbook}>
      { handbook.isLoaded ? (
        (Object.values(kernel.getHandbookListStruct())).map(handbookItem => {
          return (
            <TreeHandbookItem
              key={handbookItem.id}
              handbookItem={handbookItem}
              handbook={handbook}
              setHandbook={setHandbook}
              hasParameterIdInTree={hasParameterIdInTree}
            />
          );
        })
      ) : (
        <PreloadList preloadSizeElement={preloadList()} />
      )}      
    </div>
  );
});