import { useState } from 'react';
import classNames from 'classnames';
import { MdDelete } from 'react-icons/md';
import { BsPlusSquareDotted } from 'react-icons/bs';

import styles from './alarmList.module.scss';

const AlarmListItem = (props) => {
  const { alarmStruct, state, handleChangeAlarmList, deleteAlarm, canDeleteAlarm } = props;
  const [hasHover, setHasHover] = useState(false);

  return (
    <div
      key={alarmStruct.id}
      data-id={alarmStruct.id}
      title={`ID тревоги ${alarmStruct.id}`}
      className={classNames(
        styles.alarmListItem,
        state.activeAlarmId === alarmStruct.id && styles.alarmListItemActive,
        hasHover && styles.alarmListItemDelete,
      )}
      onClick={handleChangeAlarmList}
    >
      <span className={styles.alarmListItemText}>ID тревоги {alarmStruct.id}</span>
      {canDeleteAlarm && (
        <MdDelete
          className={styles.deleteIcon}
          data-id={alarmStruct.id}
          onMouseEnter={() => setHasHover(true)}
          onMouseLeave={() => setHasHover(false)}
          onClick={deleteAlarm}
        />
      )}
    </div>
  );
};

export function AlarmList(props) {
  const { state, handleChangeAlarmList, setPopUpVisible, deleteAlarm, canDeleteAlarm = true, canAddAlarm = true } = props;

  return (
    <div className={styles.alarmList}>
      <div className={styles.alarmListContainer}>
        <div className={styles.alarmListWrap}>
          {state.alarmList.length > 0 ? (
            state.alarmList.map((alarmStruct) => {
              return (
                <AlarmListItem
                  alarmStruct={alarmStruct}
                  state={state}
                  handleChangeAlarmList={handleChangeAlarmList}
                  deleteAlarm={deleteAlarm}
                  canDeleteAlarm={canDeleteAlarm}
                />
              );
            })
          ) : (
            <span className={styles.alarmListEmptyText}>Для данного параметра еще нет тревог, создайте их!</span>
          )}
        </div>
      </div>
      {canAddAlarm && (
        <div className={styles.alarmListButtonContainer} onClick={() => setPopUpVisible(true)}>
          <BsPlusSquareDotted className={styles.alarmListButtonIcon} />
          <span className={styles.alarmListButtonText}>Добавить тревогу</span>
        </div>
      )}
    </div>
  );
}
