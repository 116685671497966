import classNames from 'classnames';
import { HiCheck } from 'react-icons/hi';
import { GrTree } from 'react-icons/gr';
import { useForm } from 'react-hook-form';
import { useCallback, useEffect, useRef, useState } from 'react';

import InputNumber from '../input-number/InputNumber.jsx';
import Utils from '../../../../utils/Utils.mjs';
import { useKernel } from '../../../../context/ContextKernel.mjs';
import { LoadingInUserPageForm, SettlementServerPopUp } from '../../../components.mjs';
import { createValidationScheme } from '../../model/validationScheme.mjs';
import { EnumSourceAlarm, EnumTypeAlarm } from '../../../../quantum-lib/Enum/AlarmEnum.mjs';
import { PARAMETER_CLASS_VALUE } from '../../../../global/variables/objectTypeVariables.mjs';

import styles from './setpointArguments.module.scss';

export function SetpointArguments(props) {
  const { onSubmit, sendingData, dataSentSuccessfully, setpointsEditorState, treeTab, configuration } = props;

  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    setValue,
    trigger,
  } = useForm({
    mode: 'onChange',
  });

  const { kernel } = useKernel();
  const [showTooltip, setShowTooltip] = useState({
    parameterId: false,
    systemTypeId: false,
    typeAlarm: false,
    typeSourceAlarm: false,
    value_1: false,
    value_2: false,
    value_3: false,
    value_4: false,
    value_5: false,
    value_6: false,
  });
  const [state, setState] = useState({});
  const [visible, setVisible] = useState(false);
  const [activeElementTree, setActiveElementTree] = useState(0);

  // в зависимости от типа задачи берем type из разных мест
  const type = useRef(
    setpointsEditorState.activeSetpoint.parameterId !== 0n
      ? kernel.getParameterById(setpointsEditorState.activeSetpoint.parameterId).typeNode.systemType.type
      : kernel.getSystemTypeById(setpointsEditorState.activeSetpoint.systemTypeId).type,
  );

  const parameterStruct = kernel.getParameterById(setpointsEditorState.activeSetpoint.parameterId);

  const handleFocus = useCallback(
    (event) => {
      const name = event.target.name;

      setShowTooltip({
        ...showTooltip,
        [name]: true,
      });
    },
    [showTooltip],
  );

  const handleBlur = useCallback(
    (event) => {
      const name = event.target.name;

      setShowTooltip({
        ...showTooltip,
        [name]: false,
      });
    },
    [showTooltip],
  );

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    setState({
      ...state,
      [name]: value,
    });
    setValue(name, value);

    // шаблонные уставки
    if (name === 'systemTypeId') {
      const newType = kernel.getSystemTypeById(value).type;

      if (newType != type.current) type.current = newType;
    }

    // уставки для параметра
    if (name === 'parameterId') {
      const testParameterIdValue = validateParameterId(value);

      if (testParameterIdValue !== true) return;
      const newType = kernel.getParameterById(value).typeNode.systemType.type;

      if (newType != type.current) type.current = newType;
    }
  };

  const setParameterId = () => {
    const parameterId = activeElementTree.toString();

    setValue('parameterId', parameterId);

    setVisible(false);
    setActiveElementTree(0);
  };

  const closePopUp = () => {
    setVisible(false);
    setActiveElementTree(0);
  };

  const validateParameterId = (value) => {
    const isInteger = Utils.testIsInteger(value);
    if (!isInteger) return 'Допустимый формат ввода - целые числа!';

    const parameterStruct = kernel.getParameterById(value);
    if (!parameterStruct) return 'Такого параметра не существует!';

    const parameterIsDeleted = parameterStruct.isDeleted;
    if (parameterIsDeleted) return 'Для удаленных объектов нельзя создавать уставки!';

    const classify = parameterStruct.classify();
    if (classify !== PARAMETER_CLASS_VALUE) return 'Уставки можно создавать только для параметров!';

    const type = parameterStruct.typeNode.systemType.type;
    if ([0, 11, 12, 13, 14, 15, 16].includes(type)) return 'Для параметра с данным типом невозможно создать уставку!';

    return true;
  };

  useEffect(() => {
    setState({
      parameterId: setpointsEditorState.activeSetpoint.parameterId.toString(),
      systemTypeId: setpointsEditorState.activeSetpoint.systemTypeId,
      typeAlarm: setpointsEditorState.activeSetpoint.typeAlarm.value,
      typeSourceAlarm: setpointsEditorState.activeSetpoint.typeSourceAlarm.value,
      value_1: setpointsEditorState.activeSetpoint.value_1,
      value_2: setpointsEditorState.activeSetpoint.value_2,
      value_3: setpointsEditorState.activeSetpoint.value_3,
      value_4: setpointsEditorState.activeSetpoint.value_4,
      value_5: setpointsEditorState.activeSetpoint.value_5,
      value_6: setpointsEditorState.activeSetpoint.value_6,
    });

    setValue('parameterId', setpointsEditorState.activeSetpoint.parameterId.toString());
    setValue('systemTypeId', setpointsEditorState.activeSetpoint.systemTypeId);
    setValue('typeAlarm', setpointsEditorState.activeSetpoint.typeAlarm.value);
    setValue('typeSourceAlarm', setpointsEditorState.activeSetpoint.typeSourceAlarm.value);
    setValue('value_1', setpointsEditorState.activeSetpoint.value_1);
    setValue('value_2', setpointsEditorState.activeSetpoint.value_2);
    setValue('value_3', setpointsEditorState.activeSetpoint.value_3);
    setValue('value_4', setpointsEditorState.activeSetpoint.value_4);
    setValue('value_5', setpointsEditorState.activeSetpoint.value_5);
    setValue('value_6', setpointsEditorState.activeSetpoint.value_6);

    const newType =
      setpointsEditorState.activeSetpoint.parameterId !== 0n
        ? kernel.getParameterById(setpointsEditorState.activeSetpoint.parameterId).typeNode.systemType.type
        : kernel.getSystemTypeById(setpointsEditorState.activeSetpoint.systemTypeId).type;

    type.current = newType;

    trigger(['value_1', 'value_2', 'value_3', 'value_4', 'value_5', 'value_6']);

    return () => {};
  }, [setpointsEditorState.activeSetpointId]);

  useEffect(() => {
    trigger(['value_1', 'value_2', 'value_3', 'value_4', 'value_5', 'value_6']);
    return () => {};
  }, [isValid, type.current]);

  return (
    <div className={styles.container}>
      {visible && (
        <SettlementServerPopUp
          configuration={configuration}
          activeElementTree={activeElementTree}
          setActiveElementTree={setActiveElementTree}
          closePopUp={closePopUp}
          callback={setParameterId}
          parameterDisabled={true}
        />
      )}
      <div className={styles.wrap}>
        <div className={styles.editorWrap}>
          <form className={styles.form} onSubmit={handleSubmit(onSubmit)} id="setpointsArgumentsForm">
            {treeTab === 1 ? (
              <div className={styles.formItem}>
                <span className={styles.formItemText} title="Id объекта">
                  Id объекта
                </span>
                <InputNumber
                  name="parameterId"
                  value={state.parameterId}
                  dataSentSuccessfully={dataSentSuccessfully}
                  sendingData={sendingData}
                  showTooltip={showTooltip}
                  errors={errors}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                  onInput={handleChange}
                  readOnly={parameterStruct.isDeleted}
                  style={parameterStruct.isDeleted ? { opacity: '0.5' } : { opacity: '1' }}
                  register={{
                    ...register('parameterId', {
                      validate: (value) => validateParameterId(value),
                    }),
                  }}
                >
                  <button
                    className={classNames(
                      styles.showParameterTree,
                      (sendingData || dataSentSuccessfully || parameterStruct.isDeleted) && styles.sending,
                    )}
                    disabled={parameterStruct.isDeleted}
                    onClick={() => {
                      !sendingData && !dataSentSuccessfully && setVisible(true);
                    }}
                  >
                    <GrTree className={styles.showParameterTreeIcon} />
                  </button>
                </InputNumber>
              </div>
            ) : (
              <div className={styles.formItem}>
                <span className={styles.formItemText} title="Системный тип">
                  Системный тип
                </span>
                <select
                  className={classNames(styles.formItemSelect, (sendingData || dataSentSuccessfully) && styles.sending)}
                  value={state.systemTypeId}
                  onInput={handleChange}
                  disabled={sendingData || dataSentSuccessfully || parameterStruct.isDeleted}
                  style={parameterStruct.isDeleted ? { opacity: '0.5' } : { opacity: '1' }}
                  {...register('systemTypeId')}
                >
                  {kernel.getSystemTypes().map((systemType) => {
                    return (
                      <>
                        {![0, 11, 12, 13, 14, 15, 16].includes(systemType.type) && (
                          <option key={systemType.id} className={styles.formItemOption} value={systemType.id}>
                            {systemType.description}
                          </option>
                        )}
                      </>
                    );
                  })}
                </select>
              </div>
            )}
            <div className={styles.formItem}>
              <span className={styles.formItemText} title="Тип тревоги">
                Тип тревоги
              </span>
              <select
                className={classNames(styles.formItemSelect, (sendingData || dataSentSuccessfully) && styles.sending)}
                value={state.typeAlarm}
                onInput={handleChange}
                disabled={sendingData || dataSentSuccessfully || parameterStruct.isDeleted}
                style={parameterStruct.isDeleted ? { opacity: '0.5' } : { opacity: '1' }}
                {...register('typeAlarm')}
              >
                {Object.values(EnumTypeAlarm).map((typeAlarm) => {
                  return (
                    <option key={typeAlarm.value} disabled={typeAlarm.value === 0} className={styles.formItemOption} value={typeAlarm.value}>
                      {typeAlarm.text}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className={styles.formItem}>
              <span className={styles.formItemText} title="Тип проверки">
                Тип проверки
              </span>
              <select
                className={classNames(styles.formItemSelect, (sendingData || dataSentSuccessfully) && styles.sending)}
                value={state.typeSourceAlarm}
                onInput={handleChange}
                disabled={sendingData || dataSentSuccessfully || parameterStruct.isDeleted}
                style={parameterStruct.isDeleted ? { opacity: '0.5' } : { opacity: '1' }}
                {...register('typeSourceAlarm')}
              >
                {Object.values(EnumSourceAlarm).map((typeSourceAlarm) => {
                  return (
                    <option
                      key={typeSourceAlarm.value}
                      disabled={typeSourceAlarm.value === 0}
                      className={styles.formItemOption}
                      value={typeSourceAlarm.value}
                    >
                      {typeSourceAlarm.text}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className={styles.formItem}>
              <span className={styles.formItemText} title="Значение 1">
                Значение 1
              </span>
              <InputNumber
                name="value_1"
                value={state.value_1}
                dataSentSuccessfully={dataSentSuccessfully}
                sendingData={sendingData}
                showTooltip={showTooltip}
                errors={errors}
                onBlur={handleBlur}
                onFocus={handleFocus}
                onInput={handleChange}
                readOnly={parameterStruct.isDeleted}
                style={parameterStruct.isDeleted ? { opacity: '0.5' } : { opacity: '1' }}
                register={{
                  ...register('value_1', createValidationScheme(type.current)),
                }}
              />
            </div>
            <div className={styles.formItem}>
              <span className={styles.formItemText} title="Значение 2">
                Значение 2
              </span>
              <InputNumber
                name="value_2"
                value={state.value_2}
                dataSentSuccessfully={dataSentSuccessfully}
                sendingData={sendingData}
                showTooltip={showTooltip}
                errors={errors}
                onBlur={handleBlur}
                onFocus={handleFocus}
                onInput={handleChange}
                readOnly={parameterStruct.isDeleted}
                style={parameterStruct.isDeleted ? { opacity: '0.5' } : { opacity: '1' }}
                register={{
                  ...register('value_2', createValidationScheme(type.current)),
                }}
              />
            </div>
            <div className={styles.formItem}>
              <span className={styles.formItemText} title="Значение 3">
                Значение 3
              </span>
              <InputNumber
                name="value_3"
                value={state.value_3}
                dataSentSuccessfully={dataSentSuccessfully}
                sendingData={sendingData}
                showTooltip={showTooltip}
                errors={errors}
                onBlur={handleBlur}
                onFocus={handleFocus}
                onInput={handleChange}
                readOnly={parameterStruct.isDeleted}
                style={parameterStruct.isDeleted ? { opacity: '0.5' } : { opacity: '1' }}
                register={{
                  ...register('value_3', createValidationScheme(type.current)),
                }}
              />
            </div>
            <div className={styles.formItem}>
              <span className={styles.formItemText} title="Значение 4">
                Значение 4
              </span>
              <InputNumber
                name="value_4"
                value={state.value_4}
                dataSentSuccessfully={dataSentSuccessfully}
                sendingData={sendingData}
                showTooltip={showTooltip}
                errors={errors}
                onBlur={handleBlur}
                onFocus={handleFocus}
                onInput={handleChange}
                readOnly={parameterStruct.isDeleted}
                style={parameterStruct.isDeleted ? { opacity: '0.5' } : { opacity: '1' }}
                register={{
                  ...register('value_4', createValidationScheme(type.current)),
                }}
              />
            </div>
            <div className={styles.formItem}>
              <span className={styles.formItemText} title="Значение 5">
                Значение 5
              </span>
              <InputNumber
                name="value_5"
                value={state.value_5}
                dataSentSuccessfully={dataSentSuccessfully}
                sendingData={sendingData}
                showTooltip={showTooltip}
                errors={errors}
                onBlur={handleBlur}
                onFocus={handleFocus}
                onInput={handleChange}
                readOnly={parameterStruct.isDeleted}
                style={parameterStruct.isDeleted ? { opacity: '0.5' } : { opacity: '1' }}
                register={{
                  ...register('value_5', createValidationScheme(type.current)),
                }}
              />
            </div>
            <div className={styles.formItem}>
              <span className={styles.formItemText} title="Значение 6">
                Значение 6
              </span>
              <InputNumber
                name="value_6"
                value={state.value_6}
                dataSentSuccessfully={dataSentSuccessfully}
                sendingData={sendingData}
                showTooltip={showTooltip}
                errors={errors}
                onBlur={handleBlur}
                onFocus={handleFocus}
                onInput={handleChange}
                readOnly={parameterStruct.isDeleted}
                style={parameterStruct.isDeleted ? { opacity: '0.5' } : { opacity: '1' }}
                register={{
                  ...register('value_6', createValidationScheme(type.current)),
                }}
              />
            </div>
          </form>
        </div>
      </div>
      <div className={styles.controlContainer}>
        <button
          type="submit"
          form="setpointsArgumentsForm"
          className={
            sendingData || dataSentSuccessfully
              ? styles.creatingNewModuleFormSubmitSending
              : classNames(styles.creatingNewModuleFormSubmit, !isValid ? styles.formNoValid : styles.formValid)
          }
          disabled={sendingData || dataSentSuccessfully || !isValid || parameterStruct.isDeleted}
        >
          {dataSentSuccessfully ? <HiCheck className={styles.checkControl} /> : sendingData ? <LoadingInUserPageForm /> : 'Сохранить'}
        </button>
      </div>
    </div>
  );
}
