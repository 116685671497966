import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import { ConfigurationService } from '../../services/ConfigurationServices.mjs';
import { useActiveConfigurationId } from '../../hooks/useActiveConfigurationId.mjs';
import { useKernel } from '../../context/ContextKernel.mjs';
import { Preload } from '../components.mjs';

import { createSnapshotTypes } from './model/createSnapshotTypes.mjs';
import styles from './snapshotType.module.scss';

export default function SnapshotType(props) {
  const { selectedParameter, classes = null } = props;

  const [state, setState] = useState({ value: 0, loading: false });
  const { activeConfigurationId } = useActiveConfigurationId();
  const { kernel, addNotification } = useKernel();
  const type = selectedParameter?.typeNode?.systemType?.type;
  const canEdit = selectedParameter?.canEdit;
  const isWritable = !selectedParameter?.isWritable;
  const isDeleted = selectedParameter?.isDeleted;
  const initialValue = useRef();

  const handleChangeSnapshotType = async ({ target: { value } }) => {
    setState({ ...state, loading: true });

    try {
      const parameterId = selectedParameter.id.toString();
      const newSnapshotType = parseInt(value);

      await ConfigurationService.changeSnapshotType(activeConfigurationId, { parameterId, newSnapshotType });

      kernel.getParameterById(parameterId).parameterStruct.snapshotType = newSnapshotType; // изменение типа снапшота в kernel

      const messageText = `Вы успешно изменили тип снапшота у параметра #${parameterId} ${selectedParameter.displayName}`;
      addNotification({ messageText, statusType: 1 });

      initialValue.current = newSnapshotType; // обновляем начальное состояние

      setState({
        value: newSnapshotType,
        loading: false,
      });
    } catch (error) {
      addNotification({ messageText: error.message, statusType: error.status });
      setState({
        value: initialValue.current,
        loading: false,
      });
    }
  };

  useEffect(() => {
    if (selectedParameter) {
      const snapshotTypeValue = selectedParameter.snapshotType;
      setState({
        value: snapshotTypeValue,
        loading: false,
      });
      initialValue.current = snapshotTypeValue;
    }
    return () => {};
  }, [selectedParameter]);

  return (
    <div className={classes}>
      {state.loading ? (
        <Preload width="400px" height="30px" backgroundColor="#494765" />
      ) : (
        <>
          <div className={styles.container}>
            <span className={styles.text}>Срез значений</span>
            <select
              className={classNames(styles.select, (canEdit || isWritable || isDeleted) && styles.disabled)}
              value={state.value}
              disabled={canEdit || isWritable || isDeleted}
              onChange={handleChangeSnapshotType}
            >
              {createSnapshotTypes(type).map(({ value, label, isDisabled }) => {
                return (
                  <option className={styles.option} value={value} disabled={isDisabled}>
                    {label}
                  </option>
                );
              })}
            </select>
          </div>
        </>
      )}
    </div>
  );
}
