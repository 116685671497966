import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { YMaps } from '@pbe/react-yandex-maps';


import App from './App';
import reportWebVitals from './reportWebVitals';
import { KernelProvider } from './context/ContextKernel.mjs';
import { LicensesService } from './services/services.mjs';
import './reset.css';
import './assets/global-style/global.scss';

const root = ReactDOM.createRoot(document.getElementById('root'));

const renderApp = (yandexApiKey = "") => {
  root.render(
    <React.StrictMode>
      <BrowserRouter>
        <KernelProvider>
          <YMaps query={{ enterprise: true, apikey: yandexApiKey, lang: "ru_RU" }} >
            <App />
          </YMaps>
        </KernelProvider>
      </BrowserRouter>
    </React.StrictMode>,
  );
  reportWebVitals();
};


const getLicenses = async () => {
  const yandexApiKey = (await LicensesService.getCurrentLicense()).MAPS_API_TOKEN_YANDEX;
  renderApp(yandexApiKey);
};

getLicenses();


