import { Struct } from '../Struct.mjs'
import { StringStruct } from '../StringStruct.mjs'

class BlockTypeStruct extends Struct {

    constructor(id = 0, name = new StringStruct(''), description = new StringStruct('')) {
        super()
        this.id = id
        this.name = name
        this.description = description
    }

    readFrom(dataView, startOffset, isLittleEndian = true) {
        let endOffset = startOffset
        this.id = dataView.getUint32(endOffset, isLittleEndian)
        endOffset += 4
        endOffset = this.name.readFrom(dataView, endOffset, isLittleEndian)
        endOffset = this.description.readFrom(dataView, endOffset, isLittleEndian)
        return endOffset
    }

    writeTo(dataView, startOffset, isLittleEndian = true) {
        let endOffset = startOffset
        dataView.setUint32(endOffset, this.id, isLittleEndian)
        endOffset += 4
        endOffset = this.name.writeTo(dataView, endOffset, isLittleEndian)
        endOffset = this.description.writeTo(dataView, endOffset, isLittleEndian)
        return endOffset
    }

    getCurrentSize() {
        return 0
            + 4 // id
            + this.name.getCurrentSize() // name
            + this.description.getCurrentSize() // description
    }

    clone() {
        let clone = new BlockTypeStruct(
            this.id,
            this.name.clone(),
            this.description.clone()
        )
        clone.setParserOptions(this.getParserOptions())
        return clone
    }
}

export {
    
    BlockTypeStruct
}
