import {styleTags, tags} from "@lezer/highlight"

export const highlighting = styleTags({
  FunctionName: tags.name,
  Number: tags.number,
  ParameterId: tags.labelName,
  SystemTypeId: tags.string,
  BlockTypeId: tags.null,
  "( )": tags.paren
})
