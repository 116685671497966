import classNames from 'classnames';

import styles from './terminal.module.scss';

export function Terminal(props) {
  const { errors } = props;

  return (
    <div className={styles.container}>
      <textarea readOnly={true} className={classNames(styles.terminal, errors?.status && styles.terminalActive)} value={errors?.errorMessage} />
    </div>
  );
}
