import classNames from 'classnames';
import { memo, useEffect, useState } from 'react';

import SnapshotType from '../snapshot-type/SnapshotType';
import { useKernel } from '../../context/ContextKernel.mjs';
import { TableRow } from '../components.mjs';
import { VirtualTableRow } from '../../hoc/hoc.mjs';

import styles from './property.module.scss';

export default memo(function Property(props) {
  const { setComponentsTypeId, setProperty, buildConfigurationTree } = props;

  const { kernel, stateElementConfigurationId } = useKernel();
  const selectedParameter = kernel.getParameterById(stateElementConfigurationId);
  const [text, setText] = useState('');
  const [properties, setProperties] = useState([]);

  useEffect(() => {
    if (selectedParameter) {
      const propertiesList = selectedParameter?.properties ? selectedParameter?.properties : [];
      const typeNode = selectedParameter?.typeNode;
      const blockType = typeNode?.blockType;
      const isValue = typeNode?.isValue;
      const str = isValue ? `Системный тип параметра: ${typeNode?.description || `Папка`}` : `Тип объекта: ${blockType?.description || `Папка`}`;
      setText(str);
      setProperties(propertiesList);
    }
    return () => {};
  }, [selectedParameter]);

  return (
    <div className={styles.container}>
      <div className={styles.typeNodeContainer}>
        <span className={classNames(styles.text, styles.textAccent)} title={text}>
          {text}
        </span>
        {selectedParameter?.isValue && <SnapshotType selectedParameter={selectedParameter} />}
      </div>
      <div className={styles.property}>
        <div className={styles.propertyWrapTable}>
          <table className={styles.table}>
            <thead className={styles.tableThead}>
              <tr className={styles.tableTr}>
                <th className={styles.tableTh}>Свойства</th>
                <th className={styles.tableTh}>Значения</th>
                <th className={styles.tableTh}>Дата</th>
                <th className={styles.tableTh}></th>
              </tr>
            </thead>
            <tbody className={styles.tableTbody}>
              {properties.map((item, index) => {
                return (
                  item.canViewParameter && (
                    <TableRow
                      key={item.id}
                      id={item.id}
                      sortOrderId={index}
                      canEdit={item.canEdit}
                      isWritable={item.isWritable}
                      isDeleted={item.isDeleted}
                      description={item.description}
                      systemType={item.systemType}
                      propertyValueTimestamp={item.transformTimestamp(item.propertyValueTimestamp)}
                      setComponentsTypeId={setComponentsTypeId}
                      propertyValueRoundUp={item.propertyValueRoundUp}
                      setProperty={setProperty}
                      buildConfigurationTree={buildConfigurationTree}
                      properties={properties}
                      setProperties={setProperties}
                    />
                  )
                );
              })}

              <VirtualTableRow selectedParameter={selectedParameter} />
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
});
