import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { HiCheck } from 'react-icons/hi';
import { BsArrowLeftCircle } from 'react-icons/bs';

import { LoadingInUserPageForm } from '../components.mjs';

import styles from './setFullnamePopUp.module.scss';

export default function SetFullnamePopUp(props) {
  const {
    name = 'name',
    name2 = 'name2',
    setNamePopUpVisible,
    onSubmit,
    sendingData,
    dataSentSuccessfully,
    title,
    placeholder,
    placeholder2,
    textButton = 'Добавить',
  } = props;

  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    setValue,
    trigger,
  } = useForm({
    mode: 'onChange',
  });

  const [state, setState] = useState({name:'', name2:''});

  const buttonBackClick = () => {
    setNamePopUpVisible(false);
  };

  const handleChangeName = ({ target: { value } }) => {
    setState({
      ...state,
      name: value,
    });
  };

  const handleChangeName2 = ({ target: { value } }) => {
    setState({
      ...state,
      name2: value,
    });
  };

  function closePopupOnClick(event){
    if(event.target !== undefined && event.target.className === styles.setTaskNamePopUpWrap){
      buttonBackClick();
    }
  }

  function closePopupOnEsc(event){
    if(event.key === 'Escape'){
      buttonBackClick();
    }
  }

  useEffect(() => {
    document.body.addEventListener('click', closePopupOnClick);
    document.body.addEventListener('keydown', closePopupOnEsc);
    return () => {
      document.body.removeEventListener('click', closePopupOnClick);
      document.body.removeEventListener('keydown', closePopupOnEsc);
    };
  }, []);

  return (
    <div className={styles.setTaskNamePopUpWrap}>
      <div className={styles.container}>
        <div className={styles.header}>
          <h2 className={styles.title}>{title}</h2>
          <BsArrowLeftCircle className={styles.buttonBack} onClick={buttonBackClick} />
        </div>
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.containerForm}>
            <div className={styles.inputWrap}>
              <input
                className={classNames(styles.input, errors[name] && styles.inputError)}
                type="text"
                placeholder={placeholder}
                onInput={handleChangeName}
                {...register(name, {
                  required: 'Поле обязательно к заполнению',
                  minLength: {
                    value: 1,
                    message: 'Поле должно содержать минимум 1 символ',
                  },
                  maxLength: {
                    value: 255,
                    message: 'Поле может содержать максимум 255 символа',
                  },
                })}
              />
              {errors[name] && <p className={styles.errorMessage}>{errors[name].message || 'Ошибка заполнения!'}</p>}
            </div>
            <div className={styles.inputWrap}>
              <input
                className={classNames(styles.input, errors[name2] && styles.inputError)}
                type="text"
                placeholder={placeholder2}
                onInput={handleChangeName2}
                {...register(name2, {
                  required: 'Поле обязательно к заполнению',
                  minLength: {
                    value: 1,
                    message: 'Поле должно содержать минимум 1 символ',
                  },
                  maxLength: {
                    value: 255,
                    message: 'Поле может содержать максимум 255 символа',
                  },
                })}
              />
              {errors[name2] && <p className={styles.errorMessage}>{errors[name2].message || 'Ошибка заполнения!'}</p>}
            </div>
          </div>
          <button
            className={
              sendingData || dataSentSuccessfully
                ? styles.buttonSending
                : isValid
                  ? classNames(styles.button, styles.formValid)
                  : classNames(styles.button, styles.formNoValid)
            }
            type="submit"
            disabled={!isValid}
          >
            {dataSentSuccessfully ? <HiCheck className={styles.checkControl} /> : sendingData ? <LoadingInUserPageForm /> : textButton}
          </button>
        </form>
      </div>
    </div>
  );
}
