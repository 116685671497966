import classNames from 'classnames';

import utils from '../../utils/Utils.mjs';

import styles from './propertyHistoryTableRow.module.scss';

export default function PropertyHistoryTableRow(props) {
  const { propertyHistory } = props;

  return (
    <tr className={styles.tableRow}>
      <td className={styles.tableRowTd}>
        <span className={styles.tableRowText}>{propertyHistory.userName}</span>
      </td>
      <td className={styles.tableRowTd}>
        <span className={styles.tableRowText}>{propertyHistory.valuePropertyHistory.toString()}</span>
      </td>
      <td className={styles.tableRowTd}>
        <span className={classNames(styles.tableRowText, styles.tableDate)}>{utils.convertTimestamp(propertyHistory.dateChangesProperty)}</span>
        <span className={styles.tableRowText}>{utils.convertTimestampGetHour(propertyHistory.dateChangesProperty)}</span>
      </td>
    </tr>
  );
}
