import classNames from 'classnames';
import { BsPlusSquareDotted } from 'react-icons/bs';

import styles from './taskListSettlementServer.module.scss';

export function TaskListSettlementServer(props) {
  const { codeEditorState, handleChangeTaskList, setNamePopUpVisible, treeTab } = props;

  return (
    <div className={styles.taskList}>
      <div className={styles.taskListContainer}>
        <div className={styles.taskListWrap}>
          {codeEditorState.taskList.length ? (
            codeEditorState.taskList.map((settlementServerSetupStruct) => {
              return (
                <div
                  key={settlementServerSetupStruct.id}
                  data-id={settlementServerSetupStruct.id}
                  title={settlementServerSetupStruct.nameTask}
                  className={classNames(
                    styles.taskListItem,
                    codeEditorState.activeTaskId === settlementServerSetupStruct.id && styles.taskListItemActive,
                  )}
                  onClick={handleChangeTaskList}
                >
                  <span className={styles.taskListItemText}>{settlementServerSetupStruct.nameTask}</span>
                </div>
              );
            })
          ) : (
            <span className={styles.taskListEmptyText}>
              {treeTab === 1 ? 'Для данного параметра еще нет задач, создайте их!' : 'Нет шаблонных задач, создайте их!'}
            </span>
          )}
        </div>
      </div>
      <div className={styles.taskListButtonContainer} onClick={() => setNamePopUpVisible(true)}>
        <BsPlusSquareDotted className={styles.taskListButtonIcon} />
        <span className={styles.taskListButtonText}>Добавить задачу</span>
      </div>
    </div>
  );
}
