import { FormatEncodedStruct } from '../Parser/FormatEncodedStruct.mjs'

//const locale = 'ru'
const locale = 'ru-RU'

const formatters = { }

formatters[FormatEncodedStruct.TYPE_DEFAULT] = (format, value, systemType, extOptions) => {
    if (systemType) {
        return systemType.formatValue(value)
    }
    return value
}

formatters[FormatEncodedStruct.TYPE_NUMBER] = (format, value, systemType, extOptions) => {
    let formatter = new Intl.NumberFormat(locale, {
        style: 'decimal',
        useGrouping: format.formatEncoded.groupDigits,
        minimumFractionDigits: format.formatEncoded.fractionDigits,
        maximumFractionDigits: format.formatEncoded.fractionDigits,
    })
    return formatter.format(value)
}

formatters[FormatEncodedStruct.TYPE_LOGICAL] = (format, value, systemType, extOptions) => {
    if (typeof value === 'bigint') {
        return value !== 0n
            ? format.trueValue.value
            : format.falseValue.value
    }
    return Math.abs(value) > 0.000001
            ? format.trueValue.value
            : format.falseValue.value
}

/*
// v1 - slow
formatters[FormatEncodedStruct.TYPE_DATETIME] = (format, value, systemType, extOptions) => {
    let parts = { }
    let options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
    }
    if (extOptions.timeZone !== 'local') { // means use local timeZone
        options.timeZone = extOptions.timeZone
            ? extOptions.timeZone
            : 'UTC'
    }
    new Intl.DateTimeFormat(locale, options)
        .formatToParts(value)
        .filter(part => part.type !== 'literal')
        .forEach(part => {
            parts[part.type] = part.value
        })
    let result = []
    if (format.formatEncoded.displayDate) {
        result.push(`${parts.day}.${parts.month}.${parts.year}`)
    }
    if (format.formatEncoded.displayTime) {
        result.push(`${parts.hour}:${parts.minute}:${parts.second}`)
    }
    return result.join(' ')
}
*/

formatters[FormatEncodedStruct.TYPE_DATETIME] = (format, value, systemType, extOptions) => {
    if (value === null) {
        return null
    }
    const timeZone = extOptions.timeZone ? extOptions.timeZone : 'UTC'
    const isISOFormat = extOptions.isISOFormat
    const withoutSeconds = extOptions.withoutSeconds
    const withMilliseconds = extOptions.withMilliseconds
    let result = ''
    if (timeZone === 'UTC') {
        if (format.formatEncoded.displayDate) {
            let day = value.getUTCDate()
            day = (day < 10 ? '0' : '') + day
            let month = value.getUTCMonth() + 1
            month = (month < 10 ? '0' : '') + month
            let year = value.getUTCFullYear()
            result += isISOFormat
                ? year + '-' + month + '-' + day
                : day + '.' + month + '.' + year
        }
        if (format.formatEncoded.displayTime) {
            let hours = value.getUTCHours()
            hours = (hours < 10 ? '0' : '') + hours
            let minutes = value.getUTCMinutes()
            minutes = (minutes < 10 ? '0' : '') + minutes
            let seconds = value.getUTCSeconds()
            seconds = (seconds < 10 ? '0' : '') + seconds
            let milliseconds = value.getUTCMilliseconds()
            milliseconds = (milliseconds < 10 ? '00' : (milliseconds < 100 ? '0' : '')) + milliseconds
            if (format.formatEncoded.displayDate) {
                result += ' '
            }
            result += hours + ':' + minutes
                + (withoutSeconds ? '' : ':' + seconds)
                + (withMilliseconds ? '.' + milliseconds : '')
        }
    } else if (timeZone === 'local') {
        if (format.formatEncoded.displayDate) {
            let day = value.getDate()
            day = (day < 10 ? '0' : '') + day
            let month = value.getMonth() + 1
            month = (month < 10 ? '0' : '') + month
            let year = value.getFullYear()
            result += isISOFormat
                ? year + '-' + month + '-' + day
                : day + '.' + month + '.' + year
        }
        if (format.formatEncoded.displayTime) {
            let hours = value.getHours()
            hours = (hours < 10 ? '0' : '') + hours
            let minutes = value.getMinutes()
            minutes = (minutes < 10 ? '0' : '') + minutes
            let seconds = value.getSeconds()
            seconds = (seconds < 10 ? '0' : '') + seconds
            let milliseconds = value.getUTCMilliseconds()
            milliseconds = (milliseconds < 10 ? '00' : (milliseconds < 100 ? '0' : '')) + milliseconds
            if (format.formatEncoded.displayDate) {
                result += ' '
            }
            result += hours + ':' + minutes
                + (withoutSeconds ? '' : ':' + seconds)
                + (withMilliseconds ? '.' + milliseconds : '')
        }
    }
    return result
}

const textTransforms = { }

const ucfirst = value => value.length > 0
    ? value[0].toUpperCase() + value.slice(1).toLowerCase()
    : value

textTransforms[FormatEncodedStruct.TEXT_TRANSFORM_UPPERCASE] = value => value.toUpperCase()
textTransforms[FormatEncodedStruct.TEXT_TRANSFORM_LOWERCASE] = value => value.toLowerCase()
textTransforms[FormatEncodedStruct.TEXT_TRANSFORM_UCFIRST] = ucfirst
textTransforms[FormatEncodedStruct.TEXT_TRANSFORM_UCWORDS] = value => value.split(' ').map(ucfirst).join(' ')

formatters[FormatEncodedStruct.TYPE_STRING] = (format, value) => {
    let transform = format.formatEncoded.textTransform
    if (transform in textTransforms) {
        value = textTransforms[transform](value)
    }
    return value
}

formatters[FormatEncodedStruct.TYPE_BIT] = (format, value, systemType, extOptions) => {
    const index = format.formatEncoded.fractionDigits
    return BigInt(value) & (1n << BigInt(index))
        ? format.trueValue.value
        : format.falseValue.value
}

class Formatter {

    constructor(format, extOptions = { }) {
        this.format = format
        this.extOptions = extOptions
    }

    formatValue(value, unitName = '', systemType = null) {
        const type = this.format.formatEncoded.type
        if (type in formatters) {
            value = formatters[type](this.format, value, systemType, this.extOptions)
        }
        if (this.format.formatEncoded.displayUnitName) {
            value = `${value} ${unitName}`
        }
        return value
    }
}

export {

    Formatter
}
