import { Struct } from '../../../Scada/Parser/Struct.mjs';
import { StringStruct } from '../../../Scada/Parser/StringStruct.mjs';

class TaskServerStruct extends Struct {
  constructor(
    id = 0,
    workingStatus = 0,
    typeTask = 0,
    objectId = 0n,
    parameter_1 = 0,
    parameter_2 = 0,
    parameter_3 = 0,
    parameter_4 = 0,
    parameter_5 = 0,
    typeImplementation = 0,
    firstSettingParameter = 0,
    secondSettingParameter = 0,
    typeTable = 0,
    nameTask = new StringStruct(''),
    script = new StringStruct(''),
  ) {
    super();
    this.id = id;
    this.workingStatus = workingStatus;
    this.typeTask = typeTask;
    this.objectId = objectId;
    this.parameter_1 = parameter_1;
    this.parameter_2 = parameter_2;
    this.parameter_3 = parameter_3;
    this.parameter_4 = parameter_4;
    this.parameter_5 = parameter_5;
    this.typeImplementation = typeImplementation;
    this.firstSettingParameter = firstSettingParameter;
    this.secondSettingParameter = secondSettingParameter;
    this.typeTable = typeTable;
    this.nameTask = nameTask;
    this.script = script;
  }


  readFrom(dataView, startOffset, isLittleEndian = true) {
    let endOffset = startOffset;
    this.id = dataView.getUint32(endOffset, isLittleEndian);
    endOffset += 4;
    this.workingStatus = dataView.getUint8(endOffset, isLittleEndian);
    endOffset += 1;
    this.typeTask = dataView.getUint32(endOffset, isLittleEndian);
    endOffset += 4;
    this.objectId = dataView.getBigUint64(endOffset, isLittleEndian);
    endOffset += 8;
    this.parameter_1 = dataView.getUint32(endOffset, isLittleEndian);
    endOffset += 4;
    this.parameter_2 = dataView.getUint32(endOffset, isLittleEndian);
    endOffset += 4;
    this.parameter_3 = dataView.getUint32(endOffset, isLittleEndian);
    endOffset += 4;
    this.parameter_4 = dataView.getUint32(endOffset, isLittleEndian);
    endOffset += 4;
    this.parameter_5 = dataView.getUint32(endOffset, isLittleEndian);
    endOffset += 4;
    this.typeImplementation = dataView.getUint32(endOffset, isLittleEndian);
    endOffset += 4;
    this.firstSettingParameter = dataView.getUint32(endOffset, isLittleEndian);
    endOffset += 4;
    this.secondSettingParameter = dataView.getUint32(endOffset, isLittleEndian);
    endOffset += 4;
    this.typeTable = dataView.getUint32(endOffset, isLittleEndian);
    endOffset += 4;
    endOffset = this.nameTask.readFrom(dataView, endOffset, isLittleEndian);
    endOffset = this.script.readFrom(dataView, endOffset, isLittleEndian);

    return endOffset;
  }

  writeTo(dataView, startOffset, isLittleEndian = true) {
    let endOffset = startOffset;
    dataView.setUint32(endOffset, this.id, isLittleEndian);
    endOffset += 4;
    dataView.setUint8(endOffset, this.workingStatus, isLittleEndian);
    endOffset += 1;
    dataView.setUint32(endOffset, this.typeTask, isLittleEndian);
    endOffset += 4;
    dataView.setBigUint64(endOffset, this.objectId, isLittleEndian);
    endOffset += 8;
    dataView.setUint32(endOffset, this.parameter_1, isLittleEndian);
    endOffset += 4;
    dataView.setUint32(endOffset, this.parameter_2, isLittleEndian);
    endOffset += 4;
    dataView.setUint32(endOffset, this.parameter_3, isLittleEndian);
    endOffset += 4;
    dataView.setUint32(endOffset, this.parameter_4, isLittleEndian);
    endOffset += 4;
    dataView.setUint32(endOffset, this.parameter_5, isLittleEndian);
    endOffset += 4;
    dataView.setUint32(endOffset, this.typeImplementation, isLittleEndian);
    endOffset += 4;
    dataView.setUint32(endOffset, this.firstSettingParameter, isLittleEndian);
    endOffset += 4;
    dataView.setUint32(endOffset, this.secondSettingParameter, isLittleEndian);
    endOffset += 4;
    dataView.setUint32(endOffset, this.typeTable, isLittleEndian);
    endOffset += 4;
    endOffset = this.nameTask.writeTo(dataView, endOffset, isLittleEndian);
    endOffset = this.script.writeTo(dataView, endOffset, isLittleEndian);

    return endOffset;
  }

  getCurrentSize() {
    return 0
      + 4 // id
      + 1 // workingStatus
      + 4 // typeTask
      + 8 // objectId
      + 4 // parameter_1
      + 4 // parameter_2
      + 4 // parameter_3
      + 4 // parameter_4
      + 4 // parameter_5
      + 4 // typeImplementation
      + 4 // firstSettingParameter
      + 4 // secondSettingParameter
      + 4 // typeTable
      + this.nameTask.getCurrentSize() // nameTask
      + this.script.getCurrentSize(); // script
  }

  clone() {
    const clone = new TaskServerStruct(
      this.id,
      this.workingStatus,
      this.typeTask,
      this.objectId,
      this.parameter_1,
      this.parameter_2,
      this.parameter_3,
      this.parameter_4,
      this.parameter_5,
      this.typeImplementation,
      this.firstSettingParameter,
      this.secondSettingParameter,
      this.typeTable,
      this.nameTask.clone(),
      this.script.clone(),
    );
    clone.setParserOptions(this.getParserOptions());
    return clone;
  }
}

export {

  TaskServerStruct,
};