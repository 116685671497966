import { ColorStruct } from './Parser/ColorStruct.mjs'

class PRNG {

    constructor() {
        this.w = 123456789
        this.z = 987654321
        this.mask = 0xffffffff
    }

    // Takes any integer
    seed(i) {
        this.w = (123456789 + i) & this.mask
        this.z = (987654321 - i) & this.mask
    }

    // Returns number between 0 (inclusive) and 1.0 (exclusive), just like Math.random().
    random() {
        this.z = (36969 * (this.z & 65535) + (this.z >> 16)) & this.mask
        this.w = (18000 * (this.w & 65535) + (this.w >> 16)) & this.mask
        let result = ((this.z << 16) + (this.w & 65535)) >>> 0
        result /= 4294967296
        return result
    }

    randomColor(alpha = 255) {
        return new ColorStruct(
            Math.floor(256 * this.random()),
            Math.floor(256 * this.random()),
            Math.floor(256 * this.random()),
            alpha
        )
    }

    static fromBigInt(n) {
        const result = new PRNG()
        result.seed(parseInt(
            (n & 0xffffffffn) ^ ((n >> 32n) & 0xffffffffn)
        ))
        return result
    }
}

export {

    PRNG
}
