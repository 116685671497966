import { TaskServerListStruct } from '../quantum-lib/Arm/Parser/TaskServer/TaskServerListStruct.mjs';
import { TaskServerStruct } from '../quantum-lib/Arm/Parser/TaskServer/TaskServerStruct.mjs';
import { ErrorsApp } from '../utils/errors-app/ErrorsApp.mjs';

import { setWorkingMode } from './services.mjs';


export class TaskServerService {

  static async getTasksListStruct(idConfiguration) {
    const response = await fetch(`${setWorkingMode()}/get-task-list/${idConfiguration}`, {
      method: 'get',
      cache: 'reload',
      credentials: 'omit',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
    if (!response.ok) {
      throw new ErrorsApp("Произошла ошибка при загрузке задач!", 2);
    }
    const arrayBuffer = await response.arrayBuffer();

    const isLittleEndian = true;
    const offset = 0;
    const dataView = new DataView(arrayBuffer);

    const taskServerList = new TaskServerListStruct();
    taskServerList.readFrom(dataView, offset, isLittleEndian);

    return taskServerList;
  }

  static async createTask(idConfiguration, typeTask, nameTask) {
    const response = await fetch(`${setWorkingMode()}/create-task/${idConfiguration}`, {
      method: 'post',
      cache: 'reload',
      credentials: 'omit',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify({ typeTask, nameTask }),
    });

    if (!response.ok) {
      throw new ErrorsApp("Произошла ошибка при создании задачи!", 2);
    }
    const arrayBuffer = await response.arrayBuffer();

    const isLittleEndian = true;
    const offset = 0;
    const dataView = new DataView(arrayBuffer);

    const taskServerStruct = new TaskServerStruct();
    taskServerStruct.readFrom(dataView, offset, isLittleEndian);

    return taskServerStruct;
  }

  static async changeTaskArguments(configurationId, params) {
    const response = await fetch(`${setWorkingMode()}/change-task-arguments/${configurationId}`, {
      method: 'put',
      cache: 'reload',
      credentials: 'omit',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify({ ...params }),
    });

    if (!response.ok) {
      const errorMessage = (await response.json())?.message || "Ошибка!";
      throw new ErrorsApp(errorMessage, 2);
    }
  }

  static async changeTaskScript(configurationId, taskId, script) {
    const response = await fetch(`${setWorkingMode()}/change-task-script/${configurationId}`, {
      method: 'put',
      cache: 'reload',
      credentials: 'omit',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify({ taskId, script }),
    });

    if (!response.ok) {
      const errorMessage = (await response.json())?.message || "Ошибка!";
      throw new ErrorsApp(errorMessage, 2);
    }
  }

  static async changeTaskSettings(configurationId, params) {
    const response = await fetch(`${setWorkingMode()}/change-task-settings/${configurationId}`, {
      method: 'put',
      cache: 'reload',
      credentials: 'omit',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify({ ...params }),
    });

    if (!response.ok) {
      const errorMessage = (await response.json())?.message || "Ошибка!";
      throw new ErrorsApp(errorMessage, 2);
    }
  }
}