import { PropertyHistoryListStruct } from '../quantum-lib/Arm/Parser/Configurations/PropertyHistoryListStruct.mjs';
import { ErrorsApp } from '../utils/errors-app/ErrorsApp.mjs';

import { setWorkingMode } from './services.mjs';

export class PropertyHistoryService {

  static async propertyHistoryList(parameterId, systemTypeId, systemTypeType, idConfiguration) {
    const response = await fetch(`${setWorkingMode()}/property-history-list/${idConfiguration}`, {
      method: 'post',
      cache: 'reload',
      credentials: 'omit',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify({ parametrId: parameterId, systemTypeId, systemTypeType }),
    });

    if (!response.ok) {
      throw new ErrorsApp("Произошла ошибка при получении истории изменения свойства!", 2);
    }
    const arrayBuffer = await response.arrayBuffer();

    const isLittleEndian = true;
    const offset = 0;
    const dataView = new DataView(arrayBuffer);

    const propertyHistoryListStruct = new PropertyHistoryListStruct();
    propertyHistoryListStruct.readFrom(dataView, offset, isLittleEndian);

    return propertyHistoryListStruct;
  }
}