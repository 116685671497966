import { Property, PropertyHistory, CreateNewConfigurationElementForm, SettingsPermissionObject } from '../components/components.mjs';

const TABLE_PROPERTY = 0;
const TABLE_HISTORY = 1;
const CREATE_NEW_CONFIGURATION_ELEMENT = 2;
const SETTINGS_PERMISSION_OBJECT = 3;

export function ComponentPropertyEditor(props) {
  const {
    componentsTypeId,
    setComponentsTypeId,
    selectedParameter,
    setConfiguration,
    property,
    setProperty,
    buildConfigurationTree,
    searchState,
  } = props;

  const componentsByPropertyEditor = {
    [TABLE_PROPERTY]: (
      <Property
        setComponentsTypeId={setComponentsTypeId}
        setProperty={setProperty}
        buildConfigurationTree={buildConfigurationTree}
      />
    ),
    [TABLE_HISTORY]: (
      <PropertyHistory property={property} setComponentsTypeId={setComponentsTypeId} />
    ),
    [CREATE_NEW_CONFIGURATION_ELEMENT]: (
      <CreateNewConfigurationElementForm
        selectedParameter={selectedParameter}
        setComponentsTypeId={setComponentsTypeId}
        setConfiguration={setConfiguration}
        searchState={searchState}
      />
    ),
    [SETTINGS_PERMISSION_OBJECT]: (
      <SettingsPermissionObject/>
    ),
  };

  return (
    <>
      {componentsByPropertyEditor[componentsTypeId] ? (
        componentsByPropertyEditor[componentsTypeId]
      ) : (
        <div style={{ display: 'flex', flex: '1', justifyContent: 'center', alignItems: 'center' }}>
          <p style={{ color: '#d61111' }}>Произошла ошибка при выборе действия!</p>
        </div>
      )}
    </>
  );
}
