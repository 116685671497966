const BITMASK_IS_ADMIN = 0b0001n; // 1
const BITMASK_HAS_CREATE_CONFIGURATION = 0b0010n; // 2
const BITMASK_HAS_MODULE_USERS = 0b00100n; // 4
const BITMASK_HAS_MODULE_LICS = 0b01000n; // 8
const BITMASK_HAS_MODULE_CALC = 0b10000n; // 16
const BITMASK_HAS_MODULE_TASK = 0b100000n; // 32
const BITMASK_HAS_MODULE_SETPOINTS = 0b1000000n; // 64
const BITMASK_HAS_MODULE_ALARM = 0b10000000n; // 128
const BITMASK_HAS_MODULE_LOGS = 0b100000000n; // 256
const BITMASK_HAS_MODULE_SIMULATOR = 0b1000000000n; // 512
const BITMASK_HAS_MODULE_REFERENCE_BOOK = 0b10000000000n; // 1024
// 2047 root права


export const flagsParser = (flags) => {
  flags = BigInt(flags);

  return {
    isAdmin: (flags & BITMASK_IS_ADMIN) !== 0n ? true : false,
    hasAccessRightsCreatingConfigurations: (flags & BITMASK_HAS_CREATE_CONFIGURATION) !== 0n ? true : false,
    hasAccessRightsEventLogsModule: (flags & BITMASK_HAS_MODULE_LOGS) !== 0n ? true : false,
    hasAccessRightsUsersModule: (flags & BITMASK_HAS_MODULE_USERS) !== 0n ? true : false,
    hasAccessRightsLicensesModule: (flags & BITMASK_HAS_MODULE_LICS) !== 0n ? true : false,
    hasAccessRightsCalculationServerModule: (flags & BITMASK_HAS_MODULE_CALC) !== 0n ? true : false,
    hasAccessRightsTaskServerModule: (flags & BITMASK_HAS_MODULE_TASK) !== 0n ? true : false,
    hasAccessRightsSetpointsModule: (flags & BITMASK_HAS_MODULE_SETPOINTS) !== 0n ? true : false,
    hasAccessRightsAlarmServerModule: (flags & BITMASK_HAS_MODULE_ALARM) !== 0n ? true : false,
    hasAccessRightsSimulatorServerModule: (flags & BITMASK_HAS_MODULE_SIMULATOR) !== 0n ? true : false,
    hasAccessRightsHandbookModule: (flags & BITMASK_HAS_MODULE_REFERENCE_BOOK) !== 0n ? true : false,
  };
};
