import { useEffect, useRef } from 'react';
import classNames from 'classnames';

import { Tooltip } from '../components.mjs';

import styles from './inputText.module.scss';

export default function InputText(props) {
  const {
    value,
    setValue,
    placeholder,
    canEdit,
    isWritable,
    isDeleted,
    register,
    unregister,
    name,
    handleFocus,
    showTooltip,
    errors,
    validationScheme,
    setHandleChangeComponentTableRow,
    sendingData,
  } = props;
  const initialValue = useRef(value);

  const handleChange = ({ target }) => {
    if (target.value.toString() === initialValue.current.toString()) {
      setHandleChangeComponentTableRow(false);
    } else {
      setHandleChangeComponentTableRow(true);
    }
    setValue(name, target.value);
  };

  useEffect(() => {
    setValue(name, value);
    return () => {
      unregister([name]);
    };
  }, []);

  useEffect(() => {
    initialValue.current = value;
    return () => {};
  }, [value]);

  return (
    <div className={styles.inputTextContainer}>
      <div className={styles.inputTextForm}>
        <input
          type="text"
          placeholder={placeholder}
          className={classNames(
            styles.inputText,
            errors[name] && styles.inputTextError,
            sendingData && styles.inputTextSending,
            !isWritable && styles.inputTextSending,
            isDeleted && styles.inputTextSending,
            canEdit && styles.inputTextSending,
          )}
          onFocus={handleFocus}
          onInput={handleChange}
          readOnly={sendingData || canEdit || !isWritable || isDeleted}
          {...register(name, validationScheme)}
        />
        {showTooltip && errors[name] && <Tooltip message={`${errors[name]?.message || 'Ошибка заполнения!'} `} />}
      </div>
    </div>
  );
}
