export const EnumTypeSimulator = {
  0: { value: 0, text: "Постоянное простое число", mode: "number" }, // целое число
  1: { value: 1, text: "Меандр", mode: "number" },
  2: { value: 2, text: "Синусоида", mode: "number" },
  3: { value: 3, text: "Треугольная симметричная", mode: "number" },
  4: { value: 4, text: "Пила", mode: "number" },
  5: { value: 5, text: "Пила обратная", mode: "number" },
  6: { value: 6, text: "Сообщение текстом до 16 символов", mode: "string" }, // строковое 16
  7: { value: 7, text: "Сообщение текстом до 64 символов", mode: "string" }, // строковое 64
  8: { value: 8, text: "Сообщение текстом до 256 символов", mode: "string" }, // строковое 256
  9: { value: 9, text: "Меандр, но длительности не одинаковы 2/3 - 1/3", mode: "number" },
  10: { value: 10, text: "Случайное значение из диапазона", mode: "number" },
  11: { value: 11, text: "Косинусоида", mode: "number" },
};