import { Struct } from '../../../Scada/Parser/Struct.mjs';
import { LogsExtStruct } from './LogsExtStruct.mjs';



class LogsExtListStruct extends Struct {
  constructor(settlementServerSetup = [], count) {
    super();
    this.items = settlementServerSetup;
    this.count = count;
  }

  readFrom(dataView, startOffset, isLittleEndian = true) {
    let endOffset = startOffset;
    let numItems = dataView.getUint32(endOffset, isLittleEndian);
    endOffset += 4;
    this.items = [];
    for (let i = 0; i < numItems; i++) {
      let itemStruct = new LogsExtStruct();
      endOffset = itemStruct.readFrom(dataView, endOffset, isLittleEndian);
      this.items.push(itemStruct);
    }

    this.count = dataView.getUint32(endOffset, isLittleEndian);
    endOffset += 4;

    return endOffset;
  }

  writeTo(dataView, startOffset, isLittleEndian = true) {
    let endOffset = startOffset;
    dataView.setUint32(endOffset, this.items.length, isLittleEndian);
    endOffset += 4;
    for (let i = 0; i < this.items.length; i++) {
      endOffset = this.items[i].writeTo(dataView, endOffset, isLittleEndian);
    }

    dataView.setUint32(endOffset, this.count, isLittleEndian);
    endOffset += 4;

    return endOffset;
  }

  get numItems() {
    return this.items.length;
  }

  getCurrentSize() {
    return 0
      + 4 // numItems
      + this.items.reduce((size, itemStruct) => size + itemStruct.getCurrentSize(), 0) // items
      + 4; // count
  }

  clone() {
    let clone = new LogsExtListStruct(
      this.items.map(itemStruct => itemStruct.clone()),
      this.count
    );
    clone.setParserOptions(this.getParserOptions());
    return clone;
  }
}


export {

  LogsExtListStruct,
}