import { Struct } from '../Struct.mjs'
import { StringStruct } from '../StringStruct.mjs'
import { ParameterStruct } from './ParameterStruct.mjs'

class ConfigurationStruct extends Struct {

    constructor(id = 0, name = new StringStruct(''), accessBits = 0) {
        super()
        this.id = id
        this.name = name
        this.accessBits = accessBits
    }

    readFrom(dataView, startOffset, isLittleEndian = true) {
        let endOffset = startOffset
        this.id = dataView.getUint32(endOffset, isLittleEndian)
        endOffset += 4
        endOffset = this.name.readFrom(dataView, endOffset, isLittleEndian)
        this.accessBits = dataView.getUint8(endOffset)
        endOffset ++
        return endOffset
    }

    writeTo(dataView, startOffset, isLittleEndian = true) {
        let endOffset = startOffset
        dataView.setUint32(endOffset, this.id, isLittleEndian)
        endOffset += 4
        endOffset = this.name.writeTo(dataView, endOffset, isLittleEndian)
        dataView.setUint8(endOffset, this.accessBits)
        endOffset ++
        return endOffset
    }

    getCurrentSize() {
        return 0
            + 4 // id
            + this.name.getCurrentSize() // name
            + 1 // accessBits
    }

    clone() {
        let clone = new ConfigurationStruct(
            this.id,
            this.name.clone(),
            this.accessBits
        )
        clone.setParserOptions(this.getParserOptions())
        return clone
    }

    get isReadable() {
        return this.accessBits & ParameterStruct.ACCESS_READ
            ? true : false
    }

    get isWritable() {
        return this.accessBits & ParameterStruct.ACCESS_WRITE
            ? true : false
    }
}

export {
    
    ConfigurationStruct
}
