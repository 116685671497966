import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from './checkBox.module.scss';

export default function Checkbox(props) {
  const { id = 0, caption = '', isVisible = false, handleChange = () => {}, disabled = false, className = null, ...otherProps } = props;

  return (
    <label className={classNames(styles.container, disabled && styles.disabled, className)} data-id={id} >
      <input className={styles.input} type="checkbox" checked={isVisible} disabled={disabled} onChange={handleChange} {...otherProps}/>
      <span className={styles.checkIcon}></span>
      <span className={styles.text}>{caption}</span>
    </label>
  );
}

Checkbox.propTypes = {
  id:PropTypes.number,
  className:PropTypes.string,
  caption: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
};