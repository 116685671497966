import jwt_decode from 'jwt-decode';

import { useUserToken } from './hooks.mjs';

export function useUserTokenDecode() {
  const userToken = useUserToken();
  const userTokenDecode = jwt_decode(userToken);
  
  return userTokenDecode;
}
