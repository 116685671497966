import { useState, useContext, createContext, useRef } from 'react';

const LogsContext = createContext({
  serviceType: {},
  serviceInstanceType: {},
  groupsId: {},
  userId: {},
  codeOperations: {},
  db: {},
  dateTimeTo: {},
  dateTimeFrom: {},
  tableContent: {},
  formFieldValue: {},
  stepPageLogs: 0,
  setTableContent: () => { },
  setServiceType: () => { },
  setServiceInstanceType: () => { },
  setGroupsId: () => { },
  setUserId: () => { },
  setCodeOperations: () => { },
  setDb: () => { },
  setDateTimeTo: () => { },
  setDateTimeFrom: () => { },
});

export const LogsProvider = ({ children }) => {
  const [serviceType, setServiceType] = useState({});
  const [serviceInstanceType, setServiceInstanceType] = useState({});
  const [groupsId, setGroupsId] = useState({});
  const [userId, setUserId] = useState({});
  const [codeOperations, setCodeOperations] = useState({});
  const [db, setDb] = useState({});
  const [dateTimeTo, setDateTimeTo] = useState({});
  const [dateTimeFrom, setDateTimeFrom] = useState({});
  const offset = useRef(0);
  const limit = useRef(100);
  const [tableContent, setTableContent] = useState({ sending: false, rows: [], stepPageLogs: 0, logsCount: 0, hasData: true });
  const formFieldValue = useRef({});

  return (
    <LogsContext.Provider value={{
      serviceType, setServiceType, serviceInstanceType, setServiceInstanceType, groupsId, setGroupsId, userId, setUserId, codeOperations, setCodeOperations, db, setDb, dateTimeTo, setDateTimeTo, dateTimeFrom, setDateTimeFrom, limit, tableContent, setTableContent, formFieldValue, offset,
    }}>
      {children}
    </LogsContext.Provider>
  );
};


export const useLogs = () => useContext(LogsContext);