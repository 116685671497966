import { useEffect } from 'react';
import classNames from 'classnames';

import { useKernel } from '../../context/ContextKernel.mjs';
import { PreloadList } from '../components.mjs';

import { preloadList } from './model/preloadList.mjs';
import styles from './treeTemplateTask.module.scss';


export default function TreeTemplateTask(props) {
  const { configuration, setCodeEditorState, activeElementTree, setActiveElementTree, treeTemplateState, setTreeTemplateState } = props;
  const { kernel } = useKernel();

  const handleTreeTemplateTask = ({ currentTarget: { dataset } }) => {
    const templateTasks = kernel.templateTasks;

    setActiveElementTree(parseInt(dataset.id));
    setCodeEditorState({
      taskList: templateTasks[dataset.id].templateTasksList,
      activeTask: templateTasks[dataset.id].templateTasksList[0],
      activeTaskId: templateTasks[dataset.id].templateTasksList[0].id,
    });
  };

  useEffect(() => {
    const templateTasksList = Object.values(kernel.templateTasks);
    if (templateTasksList.length !== 0) {
      setTreeTemplateState(templateTasksList);
      setCodeEditorState({
        taskList: templateTasksList[0]?.templateTasksList,
        activeTask: templateTasksList[0]?.templateTasksList[0],
        activeTaskId: templateTasksList[0]?.templateTasksList[0]?.id,
      });
      setActiveElementTree(templateTasksList[0]?.templateTasksList[0]?.systemTypeId);
    } else {
      setCodeEditorState({
        taskList: [],
        activeTask: {},
        activeTaskId: null,
      });
    }

    return () => {};
  }, []);

  return (
    <div className={styles.tree}>
      {configuration ? (
        treeTemplateState.map((templateTask) => {
          return (
            <div
              key={templateTask.systemTypeId}
              className={classNames(styles.treeItem, activeElementTree === parseInt(templateTask.systemTypeId) && styles.treeItemActive)}
              data-id={templateTask.systemTypeId}
              onClick={handleTreeTemplateTask}
            >
              <span className={styles.circle}></span>
              <span className={styles.treeItemText}>{templateTask.systemTypeDescription}</span>
            </div>
          );
        })
      ) : (
        <PreloadList preloadSizeElement={preloadList()} />
      )}
    </div>
  );
}
