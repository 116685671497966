import { errorUnexpectedToken, completeSource } from './Helpers.mjs'

const TT_NAME = 1 // 'name'
const TT_INTERVAL = 2 // 'interval'
const TT_CONSTANT = 3 // 'const'
const TT_SEMICOLON = 4 // ';'

function *lexer(source) {
    const rx = /([A-Z]|\;|(?:\d\.)?\d{1,2}\:\d{1,2}\:\d{1,2}|\d+|.)/g
    let position = 0
    for (let m; m = rx.exec(source);) {
        const value = m[1]
        const c = value.charAt(0)
        const cc = c.charCodeAt(0)
        const token = { value, position, source }
        position += value.length
        if ('A'.charCodeAt(0) <= cc && cc <= 'Z'.charCodeAt(0)) {
            token.type = TT_NAME
        } else if ('0'.charCodeAt(0) <= cc && cc <= '9'.charCodeAt(0)) {
            token.type = value.indexOf(':') >= 0 ? TT_INTERVAL : TT_CONSTANT
        } else if (c === ';') {
            token.type = TT_SEMICOLON
        }
        yield token
    }
}

function tokenizer(source) {
    const lex = lexer(source)
    let current = lex.next()?.value
    const top = () => current
    const next = () => {
        const prev = current
        current = lex.next()?.value
        return prev
    }
    return { source, top, next }
}

const NT_SNAPSHOT = 1 // 'S'
const NT_RECALCULATE = 2 // 'R'
const NT_PERIOD = 3 // 'P'
const NT_MOMENT = 4 // 'M'

function astNodeSnapshot(name, shift, bitmask) {
    return {
        type: NT_SNAPSHOT,
        name: name.value,
        shift: shift ? shift.value : undefined,
        bitmask: Number(bitmask.value),
        from: name.position,
        to: name.position + name.value.length,
        source: name.source,
    }
}

function astNodeRecalculate(name, shift, bitmask) {
    return {
        type: NT_RECALCULATE,
        name: name.value,
        shift: shift ? shift.value : undefined,
        bitmask: Number(bitmask.value),
        from: name.position,
        to: name.position + name.value.length,
        source: name.source,
    }
}

function astNodePeriod(name, dept, shift, bitmask, period) {
    return {
        type: NT_PERIOD,
        name: name.value,
        dept: dept.value,
        shift: shift.value,
        bitmask: Number(bitmask.value),
        period: period.value,
        from: name.position,
        to: name.position + name.value.length,
        source: name.source,
    }
}

function astNodeMoment(name, dept, shift, bitmask, moments) {
    return {
        type: NT_MOMENT,
        name: name.value,
        dept: dept.value,
        shift: shift.value,
        bitmask: Number(bitmask.value),
        moments: moments.map(token => token.value),
        from: name.position,
        to: name.position + name.value.length,
        source: name.source,
    }
}

function nextBySimicolon(tokenizer) {
    const token = tokenizer.next()
    if (token?.type !== TT_SEMICOLON) {
        errorUnexpectedToken(tokenizer, token, ';')
    }
    return tokenizer.next()
}

function parseRunningTypeSnapshot(tokenizer) {
    let token
    let name
    let shift
    let bitmask
    token = tokenizer.next()
    if (!token || token.type !== TT_NAME || token.value !== 'S') {
        errorUnexpectedToken(tokenizer, token, `name 'S`)
    }
    name = token
    token = nextBySimicolon(tokenizer)
    if (!token) {
        errorUnexpectedToken(tokenizer, token, 'bitmask')
    }
    if (token.type === TT_INTERVAL) {
        shift = token
        token = nextBySimicolon(tokenizer)
    }
    if (token?.type !== TT_CONSTANT) {
        errorUnexpectedToken(tokenizer, token, 'bitmask')
    }
    bitmask = token
    return astNodeSnapshot(name, shift, bitmask)
}

function parseRunningTypeRecalculate(tokenizer) {
    let token
    let name
    let shift
    let bitmask
    token = tokenizer.next()
    if (!token || token.type !== TT_NAME || token.value !== 'R') {
        errorUnexpectedToken(tokenizer, token, `name 'R`)
    }
    name = token
    token = nextBySimicolon(tokenizer)
    if (!token) {
        errorUnexpectedToken(tokenizer, token, 'bitmask')
    }
    if (token.type === TT_INTERVAL) {
        shift = token
        token = nextBySimicolon(tokenizer)
    }
    if (token?.type !== TT_CONSTANT) {
        errorUnexpectedToken(tokenizer, token, 'bitmask')
    }
    bitmask = token
    return astNodeRecalculate(name, shift, bitmask)
}

function parseRunningTypePeriod(tokenizer) {
    let token
    let name
    let dept
    let shift
    let bitmask
    let period
    token = tokenizer.next()
    if (!token || token.type !== TT_NAME || token.value !== 'P') {
        errorUnexpectedToken(tokenizer, token, `name 'P`)
    }
    name = token
    token = nextBySimicolon(tokenizer)
    if (!token || token.type !== TT_INTERVAL) {
        errorUnexpectedToken(tokenizer, token, 'dept')
    }
    dept = token
    token = nextBySimicolon(tokenizer)
    if (!token || token.type !== TT_INTERVAL) {
        errorUnexpectedToken(tokenizer, token, 'shift')
    }
    shift = token
    token = nextBySimicolon(tokenizer)
    if (!token || token.type !== TT_CONSTANT) {
        errorUnexpectedToken(tokenizer, token, 'bitmask')
    }
    bitmask = token
    token = nextBySimicolon(tokenizer)
    if (!token || token.type !== TT_INTERVAL) {
        errorUnexpectedToken(tokenizer, token, 'period')
    }
    period = token
    return astNodePeriod(name, dept, shift, bitmask, period)
}

function parseRunningTypeMoment(tokenizer) {
    let token
    let name
    let dept
    let shift
    let bitmask
    let moments = []
    token = tokenizer.next()
    if (!token || token.type !== TT_NAME || token.value !== 'M') {
        errorUnexpectedToken(tokenizer, token, `name 'M'`)
    }
    name = token
    token = nextBySimicolon(tokenizer)
    if (!token || token.type !== TT_INTERVAL) {
        errorUnexpectedToken(tokenizer, token, 'dept')
    }
    dept = token
    token = nextBySimicolon(tokenizer)
    if (!token || token.type !== TT_INTERVAL) {
        errorUnexpectedToken(tokenizer, token, 'shift')
    }
    shift = token
    token = nextBySimicolon(tokenizer)
    if (!token || token.type !== TT_CONSTANT) {
        errorUnexpectedToken(tokenizer, token, 'bitmask')
    }
    bitmask = token
    while (tokenizer.top()?.type === TT_SEMICOLON) {
        token = nextBySimicolon(tokenizer)
        if (!token || token.type !== TT_INTERVAL) {
            errorUnexpectedToken(tokenizer, token, 'moment')
        }
        moments.push(token)
    }
    if (moments.length <= 0) {
        errorUnexpectedToken(tokenizer, token, 'moment')
    }
    return astNodeMoment(name, dept, shift, bitmask, moments)
}

function parseRunningType(tokenizer) {
    const token = tokenizer.top()
    const tt = token?.type
    if (tt !== TT_NAME) {
        errorUnexpectedToken(tokenizer, token, 'name')
    }
    const name = token.value
    if (name === 'S') {
        return parseRunningTypeSnapshot(tokenizer)
    } else if (name === 'R') {
        return parseRunningTypeRecalculate(tokenizer)
    } else if (name === 'P') {
        return parseRunningTypePeriod(tokenizer)
    } else if (name === 'M') {
        return parseRunningTypeMoment(tokenizer)
    }
    errorUnexpectedToken(tokenizer, token, `name ('S', 'R', 'P', 'M')`)
}

const parse = source => completeSource(tokenizer(source), parseRunningType)

export {

    NT_SNAPSHOT,
    NT_RECALCULATE,
    NT_PERIOD,
    NT_MOMENT,

    parse
}
