import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { HiCheck } from 'react-icons/hi';

import { LoadingInUserPageForm } from '../components.mjs';

import styles from './confirmationPopUp.module.scss';

export default function ConfirmationPopUp(props) {
  const { actionConfirmation, closePopup, message, loading = false, success = false } = props;

  function closePopupOnClick(event){
    if(event.target !== undefined && event.target.className === styles.wrap){
      closePopup();
    }
  }

  function closePopupOnEsc(event){
    if(event.key === 'Escape'){
      closePopup();
    }
  }

  useEffect(() => {
    document.body.addEventListener('click', closePopupOnClick);
    document.body.addEventListener('keydown', closePopupOnEsc);
    return () => {
      document.body.removeEventListener('click', closePopupOnClick);
      document.body.removeEventListener('keydown', closePopupOnEsc);
    };
  }, []);

  return (
    <div className={styles.wrap}>
      <div className={styles.container}>
        <AiOutlineCloseCircle className={styles.messageClose} onClick={closePopup} />
        <span className={styles.messageText} title={message}>
          {message}
        </span>
        <div className={styles.controlBlock}>
          <button className={classNames(styles.buttonYes, (success || loading) && styles.buttonSending)} onClick={actionConfirmation}>
            {success ? <HiCheck className={styles.checkControl} /> : loading ? <LoadingInUserPageForm /> : 'Да'}
          </button>
          <button className={styles.buttonNo} onClick={closePopup}>
            Нет
          </button>
        </div>
      </div>
    </div>
  );
}

ConfirmationPopUp.propTypes = {
  actionConfirmation: PropTypes.func.isRequired,
  closePopup: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  success: PropTypes.bool,
};
