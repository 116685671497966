import { memo } from 'react';

import { LicensesTreeItem, PreloadList } from '../components.mjs';

import { preloadList } from './model/preloadList.mjs';
import styles from './treeLicenses.module.scss';


export default memo(function TreeLicenses(props) {
  const { licensesTree, setLicensesTree, activeElementTree, setActiveElementTree, setComponentsTypeId, setIdTreeElement } = props;

  return (
    <div className={styles.treeLicenses}>
      {licensesTree.length !== 0 ? (
        licensesTree.map((licensesTreeItem) => (
          <LicensesTreeItem
            key={licensesTreeItem.id}
            licensesTreeItem={licensesTreeItem}
            licensesTree={licensesTree}
            setLicensesTree={setLicensesTree}
            activeElementTree={activeElementTree}
            setActiveElementTree={setActiveElementTree}
            setComponentsTypeId={setComponentsTypeId}
            setIdTreeElement={setIdTreeElement}
          />
        ))
      ) : (
        <PreloadList preloadSizeElement={preloadList()} />
      )}
    </div>
  );
});
