import { IconFolderConfigurationTree, IconObjectConfigurationTree, IconValueConfigurationTree } from '../components/components.mjs';
import {
  PARAMETER_CLASS_FOLDER,
  PARAMETER_CLASS_OBJECT,
  PARAMETER_CLASS_PROPERTY,
  PARAMETER_CLASS_VALUE,
} from '../global/variables/objectTypeVariables.mjs';

export function IconTreeConfiguration(props) {
  const { showChildren, typeElementTree, isDeleted, isLink } = props;

  const iconParameterClassComponent = {
    [PARAMETER_CLASS_FOLDER]: <IconFolderConfigurationTree showChildren={showChildren} isDeleted={isDeleted} isLink={isLink} />,
    [PARAMETER_CLASS_OBJECT]: <IconFolderConfigurationTree showChildren={showChildren} isDeleted={isDeleted} isLink={isLink} />,
    [PARAMETER_CLASS_VALUE]: <IconObjectConfigurationTree isDeleted={isDeleted} isLink={isLink} />,
    [PARAMETER_CLASS_PROPERTY]: <IconValueConfigurationTree isDeleted={isDeleted} />,
  };

  return (
    <>{iconParameterClassComponent[typeElementTree] ? iconParameterClassComponent[typeElementTree] : <p>Произошла ошибка при выборе действия!</p>}</>
  );
}
