import { uint64ToFloat32 } from "../Type.mjs";

class SimulatorTask {
  constructor(kernel, simulatorTaskStruct) {
    this.kernel = kernel;
    this.simulatorTaskStruct = simulatorTaskStruct;
  }

  get id() {
    return this.simulatorTaskStruct.id;
  }

  get workingStatus() {
    return this.simulatorTaskStruct.workingStatus;
  }

  get nameTask() {
    return this.simulatorTaskStruct.nameTask.value;
  }

  get parameterId() {
    return this.simulatorTaskStruct.parameterId;
  }

  get timestampCreate() {
    return this.simulatorTaskStruct.timestampCreate;
  }

  get timestampTo() {
    return this.simulatorTaskStruct.timestampTo;
  }

  get timestampFrom() {
    return this.simulatorTaskStruct.timestampFrom;
  }

  get simulatorType() {
    return this.simulatorTaskStruct.simulatorType;
  }

  get frequency() {
    return this.simulatorTaskStruct.frequency;
  }

  get period() {
    return this.simulatorTaskStruct.period;
  }

  get rawValue() {
    return this.simulatorTaskStruct.rawValue;
  }

  get strValue() {
    return this.simulatorTaskStruct.strValue.value;
  }

  get rawValue_1() {
    return this.simulatorTaskStruct.rawValue_1;
  }

  get strValue_1() {
    return this.simulatorTaskStruct.strValue_1.value;
  }

  get rawValue_2() {
    return this.simulatorTaskStruct.rawValue_2;
  }

  get strValue_2() {
    return this.simulatorTaskStruct.strValue_2.value;
  }

  get value_1() {
    return (this.simulatorType === 6 || this.simulatorType === 7 || this.simulatorType === 8) ? this.strValue : uint64ToFloat32(this.rawValue);
  }

  get value_2() {
    return (this.simulatorType === 6 || this.simulatorType === 7 || this.simulatorType === 8) ? this.strValue_1 : uint64ToFloat32(this.rawValue_1);
  }

  get value_3() {
    return (this.simulatorType === 6 || this.simulatorType === 7 || this.simulatorType === 8) ? this.strValue_2 : uint64ToFloat32(this.rawValue_2);
  }
}

export { SimulatorTask };