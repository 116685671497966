import { NavLink } from 'react-router-dom';
import { MdOutlineSettings } from 'react-icons/md';
import classNames from 'classnames';

import styles from './navSettings.module.scss';

export default function NavSettings(props) {
  const { sidebar } = props;
  const setStileAdaptiveMenu = `${
    sidebar ? classNames(styles.navWrapperListElement, styles.navWrapperListElementSmall) : styles.navWrapperListElement
  }`;

  const setStileActiveMenuLink = classNames(setStileAdaptiveMenu, styles.activeLinkNavMenu);

  const setStileMenuLink = ({ isActive }) => (isActive ? setStileActiveMenuLink : setStileAdaptiveMenu);

  return (
    <NavLink to="/settings" className={setStileMenuLink}>
      <MdOutlineSettings className={styles.settingsIcon} />
      <span className={`${sidebar ? (styles.settingsText, styles.settingsTextSmall) : styles.settingsText}`}>Настройки</span>
    </NavLink>
  );
}
