import classNames from 'classnames';

import { Preload } from '../components.mjs';

import styles from './propertyHistoryTableLoader.module.scss';

export default function PropertyHistoryTableLoader() {
  return (
    <tr className={styles.tableRow}>
      <td className={styles.tableRowTd}>
        <span className={styles.tableRowText}>
          <Preload width={"90%"} height={"25px"} backgroundColor="#494765" />
        </span>
      </td>
      <td className={styles.tableRowTd}>
        <span className={styles.tableRowText}>
          <Preload width={"95%"} height={"25px"} backgroundColor="#494765" />
        </span>
      </td>
      <td className={styles.tableRowTd}>
        <span className={classNames(styles.tableRowText, styles.tableDate)}>
          <Preload width={"95%"} height={"25px"} backgroundColor="#494765" />
        </span>
      </td>
    </tr>
  );
};
