
export const removeValueItem = (optionsList, itemsList, valueId, isMulti) => {
  valueId = parseFloat(valueId);


  if (isMulti) {
    const valueItems = itemsList.filter(({ value }) => value !== valueId);
    const removedElement = itemsList.find(({ value }) => value === valueId);
    const optionsListRemoveValueItem = [...optionsList, removedElement];

    const newOptionsList = optionsListRemoveValueItem;

    return { newOptionsList, valueItems };
  } else {
    const newItemList = itemsList.filter(({ value }) => value !== valueId);

    return { newOptionsList: optionsList, valueItems: newItemList };
  }
};