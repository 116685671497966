import classNames from 'classnames';

import Utils from '../../../../utils/Utils.mjs';

import styles from './buildDotlessAlignmentSelect.module.scss';

export const BuildDotlessAlignmentSelect = (props) => {
  const { sendingData, isWritable, isDeleted, canEdit, dotlessAlignmentSelectValue, handleChangeDotlessAlignmentSelect } = props;
  const dotlessAlignment = Utils.setDotAlignmentValue();

  return (
    <select
      className={classNames(
        styles.reportingHourSelect,
        styles.dotlessAlignmentSelect,
        sendingData && styles.inputrepRtingHourSending,
        canEdit && styles.inputrepRtingHourSending,
        !isWritable && styles.inputrepRtingHourSending,
        isDeleted && styles.inputrepRtingHourSending,
      )}
      value={dotlessAlignmentSelectValue}
      onInput={handleChangeDotlessAlignmentSelect}
      disabled={sendingData || canEdit || !isWritable || isDeleted}
    >
      {dotlessAlignment.map((item) => {
        return (
          <option className={classNames(styles.reportingHourSelectOption, styles.dotlessAlignmentSelectOption)} key={item.value} value={item.value}>
            {item.text}
          </option>
        );
      })}
    </select>
  );
};
