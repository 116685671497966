import { Struct } from './Struct.mjs'
import { StringStruct } from './StringStruct.mjs'
import { FormatEncodedStruct } from './FormatEncodedStruct.mjs'

class FormatStruct extends Struct {

    constructor(
        formatEncoded = new FormatEncodedStruct(),
        trueValue = new StringStruct('Да'),
        falseValue = new StringStruct('Нет')
    ) {
        super()
        this.formatEncoded = formatEncoded
        this.trueValue = trueValue
        this.falseValue = falseValue
    }

    readFrom(dataView, startOffset, isLittleEndian = true) {
        let endOffset = startOffset
        endOffset = this.formatEncoded.readFrom(dataView, endOffset, isLittleEndian)
        endOffset = this.trueValue.readFrom(dataView, endOffset, isLittleEndian)
        endOffset = this.falseValue.readFrom(dataView, endOffset, isLittleEndian)
        return endOffset
    }

    writeTo(dataView, startOffset, isLittleEndian = true) {
        let endOffset = startOffset
        endOffset = this.formatEncoded.writeTo(dataView, endOffset, isLittleEndian)
        endOffset = this.trueValue.writeTo(dataView, endOffset, isLittleEndian)
        endOffset = this.falseValue.writeTo(dataView, endOffset, isLittleEndian)
        return endOffset
    }

    getCurrentSize() {
        return 0
            + this.formatEncoded.getCurrentSize() // formatEncoded
            + this.trueValue.getCurrentSize() // trueValue
            + this.falseValue.getCurrentSize() // falseValue
    }

    clone() {
        let clone = new FormatStruct(
            this.formatEncoded.clone(),
            this.trueValue.clone(),
            this.falseValue.clone()
        )
        clone.setParserOptions(this.getParserOptions())
        return clone
    }
}

export {

    FormatStruct
}
