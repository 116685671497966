class Observer {

    notificationFromObservable(observable, data = null) {
        // abstract
    }
}

export {

    Observer
}
