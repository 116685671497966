import { Struct } from '../Struct.mjs'

const RESULT_PAUSE = 0
const RESULT_OK = 1
const RESULT_NOT_FOUND = 2
const RESULT_PERMISSION_DENIED = 3

class ResponseSetParameterValueDataStruct extends Struct {

    constructor(resultCode = RESULT_PAUSE, parameterId = 0n) {
        super()
        this.resultCode = resultCode
        this.parameterId = parameterId
    }

    readFrom(dataView, startOffset, isLittleEndian = true) {
        let endOffset = startOffset
        this.resultCode = dataView.getUint8(endOffset)
        endOffset += 1
        this.parameterId = dataView.getBigUint64(endOffset, isLittleEndian)
        endOffset += 8
        return endOffset
    }

    writeTo(dataView, startOffset, isLittleEndian = true) {
        let endOffset = startOffset
        dataView.setUint8(endOffset, this.resultCode)
        endOffset += 1
        dataView.setBigUint64(endOffset, this.parameterId, isLittleEndian)
        endOffset += 8
        return endOffset
    }

    getCurrentSize() {
        return 1 // resultCode
            + 8 // parameterId
    }

    clone() {
        const clone = new ResponseSetParameterValueDataStruct(
            this.resultCode,
            this.parameterId
        )
        clone.setParserOptions(this.getParserOptions())
        return clone
    }

    get isOk() {
        return this.resultCode === RESULT_OK
    }
}

ResponseSetParameterValueDataStruct.RESULT_PAUSE = RESULT_PAUSE
ResponseSetParameterValueDataStruct.RESULT_OK = RESULT_OK
ResponseSetParameterValueDataStruct.RESULT_NOT_FOUND = RESULT_NOT_FOUND
ResponseSetParameterValueDataStruct.RESULT_PERMISSION_DENIED = RESULT_PERMISSION_DENIED

export {
    
    ResponseSetParameterValueDataStruct
}
