import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import Utils from '../../utils/Utils.mjs';
import { ReactComponent as BsArrowRightCircleFill } from '../../assets/icon/BsArrowLeftCircle.svg';
import { ContentApp, HeaderApp, NavApp } from '../../layout/layout.mjs';
import {
  TreeConfiguration,
  ErrorMessagePopup,
  ConfirmationPopUp,
  ConfirmationPopUpWithTimer,
  SetNamePopUp,
  InputSearch,
  InformationParameter,
} from '../../components/components.mjs';
import { ConfigurationService } from '../../services/services.mjs';
import { useKernel } from '../../context/ContextKernel.mjs';
import { ComponentPropertyEditor } from '../../hoc/hoc.mjs';
import { useActiveConfigurationId } from '../../hooks/useActiveConfigurationId.mjs';
import { filterConfigurationTree } from '../../utils/filter-configuration-tree/filterConfigurationTree.mjs';

import styles from './propertyEditor.module.scss';

export default function PropertyEditor() {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    kernel,
    addNotification,
    stateElementConfigurationId,
    setStateElementConfigurationId,
    configuration,
    setConfiguration,
    searchConfigurationState,
    setSearchConfigurationState,
    showInformationParameter,
  } = useKernel();
  const [configurationName, setConfigurationName] = useState(null);
  const [userName, setUserName] = useState('');
  const [isError, setIsError] = useState(false);
  const [isErrorLoadingConfiguration, setIsErrorLoadingConfiguration] = useState(false);
  const [errorMessage, setErrorMessage] = useState('Ошибка!');
  const [componentsTypeId, setComponentsTypeId] = useState(0);
  const [selectedParameter, setSelectedParameter] = useState({});
  const [property, setProperty] = useState({});
  const [namePopUpVisible, setNamePopUpVisible] = useState(false);
  const { activeConfigurationId } = useActiveConfigurationId();

  const [removeObject, setRemoveObject] = useState({ objectId: null, isVisible: false, message: '', canSave: false, sending: false });
  const [restoreObject, setRestoreObject] = useState({ objectId: null, isVisible: false, message: '', canSave: false, sending: false });
  const [rotate, setRotate] = useState(false);
  const [confirmationPopUpVisible, setConfirmationPopUpVisible] = useState(false);

  const buildConfigurationTree = async () => {
    try {
      const { tree } = await kernel.buildTree(location, activeConfigurationId);
      await kernel.buildTemplatesTree();

      setConfiguration(tree);
      setConfigurationName(kernel.configuration.name);

      setUserName(kernel.getUser().name);
    } catch (error) {
      console.error('error: ', error);
      setIsErrorLoadingConfiguration(true);
      setErrorMessage('Ошибка загрузки конфигурации!');
    }
  };

  const deleteObject = async () => {
    try {
      setRemoveObject({
        ...removeObject,
        sending: true,
      });

      const configId = kernel.getActiveConfiguration().id;
      await ConfigurationService.deleteObject(removeObject.objectId.toString(), configId);

      await buildConfigurationTree();

      setTimeout(() => {
        setRemoveObject({ objectId: null, isVisible: false, message: '', canSave: false, sending: false });
      }, 500);
    } catch (error) {
      setRemoveObject({ objectId: null, isVisible: false, message: '', canSave: false, sending: false });
      addNotification({ messageText: error.message, statusType: error.status });
    }
  };

  const recoveryObject = async () => {
    try {
      setRestoreObject({
        ...restoreObject,
        sending: true,
      });

      const configId = kernel.getActiveConfiguration().id;
      await ConfigurationService.recoveryObject(restoreObject.objectId.toString(), configId);

      await buildConfigurationTree();

      setTimeout(() => {
        setRestoreObject({ objectId: null, isVisible: false, message: '', canSave: false, sending: false });
      }, 500);
    } catch (error) {
      setRestoreObject({ objectId: null, isVisible: false, message: '', canSave: false, sending: false });
      addNotification({ messageText: error.message, statusType: error.status });
    }
  };

  const moveNodeInConfigurationTree = (treeNodeDrag, treeNodeDragOver) => {
    kernel.setDragOverNodeId(treeNodeDragOver.id.toString());
    setConfirmationPopUpVisible(true);
  };

  const updateConfiguration = () => {
    setConfiguration(null);
    setStateElementConfigurationId(0);
    buildConfigurationTree();
  };

  const confirmationMoveNodeInConfigurationTree = async () => {
    try {
      setConfiguration(null);
      setConfirmationPopUpVisible(false);

      const treeNodeDrag = kernel.getParameterById(kernel.getDragStartNodeId());
      const treeNodeDragOver = kernel.getParameterById(kernel.getDragOverNodeId());

      const configId = kernel.getActiveConfiguration().id;
      const parameterId = treeNodeDrag.id.toString();
      const parameterParentId = treeNodeDragOver.id.toString();

      treeNodeDrag.parent.children = treeNodeDrag.parent.children.filter((parameter) => parameter.id.toString() !== parameterId); // удаление объекта
      treeNodeDragOver.children.push(treeNodeDrag); // добавление объекта
      treeNodeDragOver.children = Utils.sortParameterChildren(treeNodeDragOver.children); // сортировка потомков у параметра
      treeNodeDrag.parameterStruct.parentId = BigInt(parameterParentId); // изменение parentId

      kernel.setDragOverNodeId(null); // сброс id элементов над которыми возникало событие onDropEnter
      kernel.setDragStartNodeId(null); // сброс id элементов над которыми возникало событие onDropStart

      setConfiguration(null); // обновление состояния дерева

      await ConfigurationService.changeParentIdOfParameter(parameterId, parameterParentId, configId);
      await buildConfigurationTree();
    } catch (error) {
      await buildConfigurationTree();
      kernel.setDragOverNodeId(null);
      kernel.setDragStartNodeId(null);
      addNotification({ messageText: error.message, statusType: error.status });
    }
  };

  const closePopupConfirmationPopUp = () => {
    setConfirmationPopUpVisible(false);
  };

  const buildTree = async () => {
    try {
      const { licensesListStruct, licensesTypesListStruct } = await kernel.getLicensesData();
      kernel.buildLicensesTree(licensesListStruct, licensesTypesListStruct);
    } catch (error) {
      setIsError(true);
      setErrorMessage('Ошибка загрузки лицензии!');
    }
  };

  const renameConfiguration = async (data) => {
    setNamePopUpVisible(false);
    try {
      const params = {
        parameterId: stateElementConfigurationId.toString(),
        name: data.name,
      };

      await ConfigurationService.renameConfiguration(activeConfigurationId, params);
      updateConfiguration();
      addNotification({ messageText: 'Имя конфигурации успешно изменено!', statusType: 1 });
    } catch (error) {
      addNotification({ messageText: error.message, statusType: error.status });
    }
  };

  const sortedElement = async (event, sortedZoneActive) => {
    try {
      const { currentTarget } = event;
      const dragOverNodeId = currentTarget.dataset.id;
      const dragOverSortOrderId = currentTarget.dataset.sortOrderId;
      const dragStartNodeId = kernel.getDragStartNodeId();
      const treeNodeDrag = kernel.getParameterById(dragStartNodeId); // элемент который перетаскивают
      const treeNodeDragOver = kernel.getParameterById(dragOverNodeId);
      const { nodeId, dragStartSortOrderId } = kernel.dragStartSortOrderId;
      const sortOrderCheck = Utils.sortOrderCheck(treeNodeDrag, treeNodeDragOver, event);

      if (sortOrderCheck.top || sortOrderCheck.bottom) {
        setConfiguration(null);
        const newParameterSortedList = kernel.setSortOrder(treeNodeDragOver, dragStartSortOrderId, dragOverSortOrderId, sortedZoneActive);
        kernel.setDragOverNodeId(null); // сброс id элементов над которыми возникало событие onDropEnter
        kernel.setDragStartNodeId(null); // сброс id элементов над которыми возникало событие onDropStart
        await ConfigurationService.setParameterSortByParameterId(activeConfigurationId, newParameterSortedList);
        await buildConfigurationTree();
        setStateElementConfigurationId(nodeId);
      }
    } catch (error) {
      kernel.setDragOverNodeId(null); // сброс id элементов над которыми возникало событие onDropEnter
      kernel.setDragStartNodeId(null); // сброс id элементов над которыми возникало событие onDropStart
      addNotification({ messageText: error.message, statusType: error.status });
      await buildConfigurationTree();
    }
  };

  useEffect(() => {
    setConfiguration(null);
    buildConfigurationTree();
    buildTree();
    kernel.timerRefreshToken(); //запуск обновления токена
    kernel.setNavigatePath('/property-editor');

    return () => {
      setStateElementConfigurationId(0n);
      kernel.openElementTemplateTree.clear();
      kernel.openElementTemplateTree.set(0n, true);
      setSearchConfigurationState('');
    };
  }, []);

  useEffect(() => {
    setConfiguration(null);

    if (searchConfigurationState !== '') setStateElementConfigurationId(0n);

    const debounce = setTimeout(() => {
      const { treeConfiguration } = filterConfigurationTree(searchConfigurationState, kernel);
      setConfiguration(treeConfiguration);
    }, 300);

    return () => {
      clearTimeout(debounce);
    };
  }, [searchConfigurationState]);

  return (
    <div className={styles.propertyEditor}>
      {isError && (
        <ErrorMessagePopup
          errorMessage={errorMessage}
          actionPerform={() => {
            setIsError(false);
            kernel.setNavigatePath('/property-editor');
            navigate('/section-selection');
          }}
        />
      )}
      {isErrorLoadingConfiguration && (
        <ErrorMessagePopup
          errorMessage={errorMessage}
          actionPerform={() => {
            setIsErrorLoadingConfiguration(false);
            kernel.setNavigatePath('/property-editor');
            navigate('/section-selection');
          }}
        />
      )}
      {removeObject.isVisible && <ConfirmationPopUpWithTimer callBack={deleteObject} state={removeObject} setState={setRemoveObject} />}
      {restoreObject.isVisible && <ConfirmationPopUpWithTimer callBack={recoveryObject} state={restoreObject} setState={setRestoreObject} />}
      {confirmationPopUpVisible && (
        <ConfirmationPopUp
          actionConfirmation={confirmationMoveNodeInConfigurationTree}
          closePopup={closePopupConfirmationPopUp}
          message="Вы точно хотите переместить объект?"
        />
      )}
      {namePopUpVisible && (
        <SetNamePopUp
          value={kernel.getActiveConfiguration().name}
          setNamePopUpVisible={setNamePopUpVisible}
          onSubmit={renameConfiguration}
          sendingData={false}
          dataSentSuccessfully={false}
          title="Введите новое имя"
          placeholder="Введите новое имя"
          textButton="Изменить"
        />
      )}
      {showInformationParameter.open && <InformationParameter />}
      <NavApp />
      <ContentApp>
        <div className={styles.headerContainer}>
          <HeaderApp configurationName={configurationName} userName={userName} />
        </div>
        <div className={styles.contentContainer}>
          <div
            className={classNames('treeContainer', rotate && 'collapsedTree')}
            id="treeConfigurationContainer"
            onContextMenu={(event) => {
              event.preventDefault();
            }}
          >
            <InputSearch
              searchState={searchConfigurationState}
              setSearchState={setSearchConfigurationState}
              classes={rotate && styles.collapsedSearch}
            />
            <div className={classNames(styles.treeWrap, rotate && 'collapsedTreeWrap')}>
              <TreeConfiguration
                configuration={configuration}
                setConfiguration={setConfiguration}
                componentsTypeId={componentsTypeId}
                setComponentsTypeId={setComponentsTypeId}
                setSelectedParameter={setSelectedParameter}
                removeObject={removeObject}
                setRemoveObject={setRemoveObject}
                restoreObject={restoreObject}
                setRestoreObject={setRestoreObject}
                moveNodeInConfigurationTree={moveNodeInConfigurationTree}
                updateConfiguration={updateConfiguration}
                setNamePopUpVisible={setNamePopUpVisible}
                sortedElement={sortedElement}
                sortOrderId={0}
              />
            </div>
            <BsArrowRightCircleFill
              className={classNames(styles.treeControl, rotate && styles.treeControlActive)}
              onClick={() => setRotate(!rotate)}
            />
          </div>
          <ComponentPropertyEditor
            componentsTypeId={componentsTypeId}
            setComponentsTypeId={setComponentsTypeId}
            selectedParameter={selectedParameter}
            setConfiguration={setConfiguration}
            property={property}
            setProperty={setProperty}
            buildConfigurationTree={buildConfigurationTree}
            searchState={searchConfigurationState}
          />
        </div>
      </ContentApp>
    </div>
  );
}
