import classNames from 'classnames';
import { useState } from 'react';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { BsArrowLeftCircle } from 'react-icons/bs';
import { HiCheck } from 'react-icons/hi';

import { useKernel } from '../../context/ContextKernel.mjs';
import { LicensesService } from '../../services/services.mjs';
import { LoadingInUserPageForm } from '../components.mjs';

import styles from './setLicensesName.module.scss';

export default function SetLicensesName(props) {
  const { setComponentsTypeId, licensesTree, setLicensesTree } = props;

  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    trigger,
    setValue,
    getValues,
  } = useForm({
    mode: 'onChange',
  });

  const [sendingData, setSendingData] = useState(false);
  const [dataSentSuccessfully, setDataSentSuccessfully] = useState(false);
  const [nameInstanceApplicationValue, setNameInstanceApplicationValue] = useState();

  const { kernel, addNotification } = useKernel();

  const handleChangeInputValue = (event) => {
    setNameInstanceApplicationValue(event.target.value);
  };

  const buttonBackClick = () => {
    setComponentsTypeId(0);
  };

  const onSubmit = async (data) => {
    try {
      const newLicensesFileData = () => {
        const license = kernel.licensesFile;
        return {
          ...license,
          name: data.nameInstanceApplication,
        };
      };
      kernel.setLicensesFile(newLicensesFileData());
      setSendingData(true);

      const testUuid = await LicensesService.checkLicensesUuid(kernel.licensesFile?.uuid);

      if (!testUuid) {
        setComponentsTypeId(4);
      } else {
        //отправляем данные
        const response = await LicensesService.addNewLicenses(kernel.licensesFile);
        const newLicenses = kernel.addNewLicensesInLicensesTree(response); //изменяем состояние в kernel

        setTimeout(() => {
          setSendingData(false);
          setDataSentSuccessfully(true);
          addNotification({ messageText: 'Лицензия успешно добавлена!', statusType: 1 });
        }, 350);

        setTimeout(() => {
          setLicensesTree(
            licensesTree.map((license) => {
              if (license.id === newLicenses.licType) {
                license.children = [...license.children];
              }
              return license;
            }),
          );
          setComponentsTypeId(0);
        }, 1300);
      }
    } catch (error) {
      setSendingData(false);
      addNotification({ messageText: error.message, statusType: error.status });
    }
  };

  useEffect(() => {
    const licenseName = kernel.licensesFile?.name;
    setNameInstanceApplicationValue(licenseName);
    setValue('nameInstanceApplication', licenseName);
    trigger('nameInstanceApplication');
    return () => {};
  }, []);

  return (
    <div className={styles.setLicensesName}>
      <div className={styles.header}>
        <h2 className={styles.title}>Добавление лицензии</h2>
        <BsArrowLeftCircle className={styles.buttonBack} onClick={buttonBackClick} />
      </div>
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.container}>
          <div className={styles.inputWrap}>
            <input
              className={classNames(styles.input, errors.nameInstanceApplication && styles.inputError)}
              type="text"
              placeholder="Имя экземпляра приложения"
              onInput={handleChangeInputValue}
              value={nameInstanceApplicationValue}
              {...register('nameInstanceApplication', {
                required: 'Поле обязательно к заполнению',
                minLength: {
                  value: 3,
                  message: 'Поле должно содержать минимум 3 символа',
                },
                maxLength: {
                  value: 64,
                  message: 'Поле может содержать максимум 64 символа',
                },
                validate: {
                  checkLicensesName: async () => {
                    try {
                      const name = getValues('nameInstanceApplication');
                      const uuid = kernel.licensesFile.uuid;
                      const checkLicensesName = await LicensesService.checkLicensesName(name, uuid);

                      if (checkLicensesName) {
                        return true;
                      } else {
                        return 'Лицензия с таким именем уже существует!';
                      }
                    } catch (error) {
                      return error.message;
                    }
                  },
                },
              })}
            />
            {errors?.nameInstanceApplication && (
              <p className={styles.errorMessage}>{errors?.nameInstanceApplication?.message || 'Ошибка заполнения!'}</p>
            )}
          </div>
        </div>
        <button
          className={
            sendingData || dataSentSuccessfully
              ? styles.buttonSending
              : isValid
                ? classNames(styles.button, styles.formValid)
                : classNames(styles.button, styles.formNoValid)
          }
          type="submit"
          disabled={!isValid}
        >
          {dataSentSuccessfully ? <HiCheck className={styles.checkControl} /> : sendingData ? <LoadingInUserPageForm /> : 'Добавить'}
        </button>
      </form>
    </div>
  );
}
