import { FormatStruct } from '../Parser/FormatStruct.mjs'
import { FormatEncodedStruct } from '../Parser/FormatEncodedStruct.mjs'
import { Formatter } from './Formatter.mjs'
import { BigNumber } from '../BigNumber.mjs'
import { StringStruct } from '../Parser/StringStruct.mjs'
import { ColorStruct } from '../Parser/ColorStruct.mjs'

const TYPE_BOOLEAN = 0
const TYPE_BYTE = 1
const TYPE_CHAR = 2
const TYPE_INT16 = 3
const TYPE_UINT16 = 4
const TYPE_INT32 = 5
const TYPE_UINT32 = 6
const TYPE_INT64 = 7
const TYPE_UINT64 = 8
const TYPE_FLOAT = 9
const TYPE_DOUBLE = 10
const TYPE_TIMESTAMP = 11
const TYPE_STRING_16 = 12
const TYPE_STRING_64 = 13
const TYPE_STRING_255 = 14
const TYPE_LINK1 = 15
const TYPE_INVALID_TYPE = 16

const SYSTEM_TYPE_OBJECT_TYPE = 3
const SYSTEM_TYPE_PHONE = 37
const SYSTEM_TYPE_REPORTING_HOUR = 40
const SYSTEM_TYPE_TIME_ZONE_CLIENT = 58
const SYSTEM_TYPE_REPORTING_DAY = 59
const SYSTEM_TYPE_IPV4 = 60
const SYSTEM_TYPE_CHANNEL_SETTING_TYPE = 64
const SYSTEM_TYPE_CHANNEL_SETTING_SUBTYPE = 65
const SYSTEM_TYPE_PARAMETER_TABLE_FOOTER = 77
const SYSTEM_TYPE_TIME_WAIT_CALL = 109
const SYSTEM_TYPE_TIME_BETWEEN_CALL = 110
const SYSTEM_TYPE_GPS_COORDINATES_POINT = 123
const SYSTEM_TYPE_GPS_COORDINATES_LINE = 124
const SYSTEM_TYPE_STATUS = 126
const SYSTEM_TYPE_OPCUA_EXTERNAL_TAG = 279
const SYSTEM_TYPE_COLOR = 315
const SYSTEM_TYPE_CONTROLLER_ID = 44

const parsers = { }
const specialParsers = { }

const isLittleEndian = true

const toDataView = (rawValue) => {
    let arrayBuffer = new ArrayBuffer(8) // 8 bytes
    let dataView = new DataView(arrayBuffer)
    dataView.setBigInt64(0, rawValue, isLittleEndian)
    return dataView
}

/*
WARNING Be careful with bug of storage boolean in "8byte"-container:
    select * from snapshot_slow_25 where parametr_id = 9528 order by id desc limit 1 -- bool 72057594037927936 (1 in highest byte)
    select * from property_config_25 where parametr_id = 9194 order by id desc limit 1 -- bool 1 (1 in lowest byte)
*/
parsers[TYPE_BOOLEAN] = (rawValue, strValue) => rawValue ? true : false
parsers[TYPE_BYTE] = (rawValue, strValue) => toDataView(rawValue).getInt8(0)
parsers[TYPE_CHAR] = (rawValue, strValue) => String.fromCharCode(toDataView(rawValue).getUint8(0))
parsers[TYPE_INT16] = (rawValue, strValue) => toDataView(rawValue).getInt16(0, isLittleEndian)
parsers[TYPE_UINT16] = (rawValue, strValue) => toDataView(rawValue).getUint16(0, isLittleEndian)
parsers[TYPE_INT32] = (rawValue, strValue) => toDataView(rawValue).getInt32(0, isLittleEndian)
parsers[TYPE_UINT32] = (rawValue, strValue) => toDataView(rawValue).getUint32(0, isLittleEndian)
parsers[TYPE_INT64] = (rawValue, strValue) => toDataView(rawValue).getBigInt64(0, isLittleEndian)
parsers[TYPE_UINT64] = (rawValue, strValue) => toDataView(rawValue).getBigUint64(0, isLittleEndian)
parsers[TYPE_FLOAT] = (rawValue, strValue) => toDataView(rawValue).getFloat32(0, isLittleEndian)
parsers[TYPE_DOUBLE] = (rawValue, strValue) => toDataView(rawValue).getFloat64(0, isLittleEndian)
parsers[TYPE_TIMESTAMP] = (rawValue, strValue) => new Date(toDataView(rawValue).getUint32(0, isLittleEndian) * 1000)
parsers[TYPE_STRING_16] = (rawValue, strValue) => strValue
parsers[TYPE_STRING_64] = (rawValue, strValue) => strValue
parsers[TYPE_STRING_255] = (rawValue, strValue) => strValue
parsers[TYPE_LINK1] = (rawValue, strValue) => toDataView(rawValue).getUint32(0, isLittleEndian)

specialParsers[SYSTEM_TYPE_IPV4] = (rawValue, strValue) => {
    const dataView = toDataView(rawValue)
    return `${dataView.getUint8(3)}.${dataView.getUint8(2)}.${dataView.getUint8(1)}.${dataView.getUint8(0)}`
}

specialParsers[SYSTEM_TYPE_COLOR] = (rawValue, strValue) => {
    const dataView = toDataView(rawValue)
    return `${ColorStruct.fromInt(dataView.getUint32(0, isLittleEndian)).toString(false)}`
}

const transformers = { }
const specialTransformers = { }

const transformBigInt = (value, scale, translate, timeZoneOffsetInSeconds) => {
    if (Number.isInteger(scale) && Number.isInteger(translate)) {
        return value * BigInt(scale) + BigInt(translate)
    }
    // return BigInt(Math.round(Number(a) * scale + translate)) // WARNING be careful with precission (see parameter 1104n from configuration 22)
    return BigInt(
        (new BigNumber(value.toString()))
            .multiply(new BigNumber(scale.toString()))
            .add(new BigNumber(translate.toString()))
            .intPart()
            .toString()
    )
}

transformers[TYPE_BOOLEAN] = (value, scale, translate, timeZoneOffsetInSeconds) => value
transformers[TYPE_BYTE] = (value, scale, translate, timeZoneOffsetInSeconds) => value * scale + translate
transformers[TYPE_CHAR] = (value, scale, translate, timeZoneOffsetInSeconds) => value
transformers[TYPE_INT16] = (value, scale, translate, timeZoneOffsetInSeconds) => value * scale + translate
transformers[TYPE_UINT16] = (value, scale, translate, timeZoneOffsetInSeconds) => value * scale + translate
transformers[TYPE_INT32] = (value, scale, translate, timeZoneOffsetInSeconds) => value * scale + translate
transformers[TYPE_UINT32] = (value, scale, translate, timeZoneOffsetInSeconds) => value * scale + translate
transformers[TYPE_INT64] = transformBigInt
transformers[TYPE_UINT64] = transformBigInt
transformers[TYPE_FLOAT] = (value, scale, translate, timeZoneOffsetInSeconds) => value * scale + translate
transformers[TYPE_DOUBLE] = (value, scale, translate, timeZoneOffsetInSeconds) => value * scale + translate
transformers[TYPE_TIMESTAMP] = (value, scale, translate, timeZoneOffsetInSeconds) => new Date(((value.getTime() / 1000) * scale + translate + timeZoneOffsetInSeconds) * 1000)
transformers[TYPE_STRING_16] = (value, scale, translate, timeZoneOffsetInSeconds) => value
transformers[TYPE_STRING_64] = (value, scale, translate, timeZoneOffsetInSeconds) => value
transformers[TYPE_STRING_255] = (value, scale, translate, timeZoneOffsetInSeconds) => value
transformers[TYPE_LINK1] = (value, scale, translate, timeZoneOffsetInSeconds) => value * scale + translate

specialTransformers[SYSTEM_TYPE_IPV4] = (value, scale, translate, timeZoneOffsetInSeconds) => value
specialTransformers[SYSTEM_TYPE_COLOR] = (value, scale, translate, timeZoneOffsetInSeconds) => value

const reverseTransformers = { }
const specialReverseTransformers = { }

const reverseTransformBigInt = (value, scale, translate, timeZoneOffsetInSeconds) => {
    if (Number.isInteger(scale) && Number.isInteger(translate)) {
        return (value - BigInt(translate)) / BigInt(scale)
    }
    // return BigInt(Math.round((Number(a) - translate) / scale)) // WARNING be careful with precission
    return BigInt(
        (new BigNumber(value.toString()))
            .subtract(new BigNumber(translate.toString()))
            .divide(new BigNumber(scale.toString()))
            .intPart()
            .toString()
    )
}

reverseTransformers[TYPE_BOOLEAN] = (value, scale, translate, timeZoneOffsetInSeconds) => value
reverseTransformers[TYPE_BYTE] = (value, scale, translate, timeZoneOffsetInSeconds) => (value - translate) / scale
reverseTransformers[TYPE_CHAR] = (value, scale, translate, timeZoneOffsetInSeconds) => value
reverseTransformers[TYPE_INT16] = (value, scale, translate, timeZoneOffsetInSeconds) => (value - translate) / scale
reverseTransformers[TYPE_UINT16] = (value, scale, translate, timeZoneOffsetInSeconds) => (value - translate) / scale
reverseTransformers[TYPE_INT32] = (value, scale, translate, timeZoneOffsetInSeconds) => (value - translate) / scale
reverseTransformers[TYPE_UINT32] = (value, scale, translate, timeZoneOffsetInSeconds) => (value - translate) / scale
reverseTransformers[TYPE_INT64] = reverseTransformBigInt
reverseTransformers[TYPE_UINT64] = reverseTransformBigInt
reverseTransformers[TYPE_FLOAT] = (value, scale, translate, timeZoneOffsetInSeconds) => (value - translate) / scale
reverseTransformers[TYPE_DOUBLE] = (value, scale, translate, timeZoneOffsetInSeconds) => (value - translate) / scale
reverseTransformers[TYPE_TIMESTAMP] = (value, scale, translate, timeZoneOffsetInSeconds) => new Date((((value.getTime() / 1000) - translate) / scale - timeZoneOffsetInSeconds) * 1000)
reverseTransformers[TYPE_STRING_16] = (value, scale, translate, timeZoneOffsetInSeconds) => value
reverseTransformers[TYPE_STRING_64] = (value, scale, translate, timeZoneOffsetInSeconds) => value
reverseTransformers[TYPE_STRING_255] = (value, scale, translate, timeZoneOffsetInSeconds) => value
reverseTransformers[TYPE_LINK1] = (value, scale, translate, timeZoneOffsetInSeconds) => (value - translate) / scale

specialReverseTransformers[SYSTEM_TYPE_IPV4] = (value, scale, translate, timeZoneOffsetInSeconds) => value
specialReverseTransformers[SYSTEM_TYPE_COLOR] = (value, scale, translate, timeZoneOffsetInSeconds) => value

const encoders = { }
const specialEncoders = { }

const encode8b = methodName => value => {
    let arrayBuffer = new ArrayBuffer(8) // 8 bytes
    let dataView = new DataView(arrayBuffer)
    dataView[methodName](0, value, isLittleEndian)
    return dataView.getBigInt64(0, isLittleEndian)
}

const encode8bUint8 = encode8b('setUint8')
const encode8bInt8 = encode8b('setInt8')
const encode8bUint16 = encode8b('setUint16')
const encode8bInt16 = encode8b('setInt16')
const encode8bUint32 = encode8b('setUint32')
const encode8bInt32 = encode8b('setInt32')
const encode8bUint64 = encode8b('setBigUint64')
const encode8bInt64 = encode8b('setBigInt64')
const encode8bFloat32 = encode8b('setFloat32')
const encode8bFloat64 = encode8b('setFloat64')

const clipString = (value, maxLength) => value.length > maxLength ? value.substring(0, maxLength) : value

const returnRawValue = value => {
    return {
        rawValue: BigInt(value),
        strValue: new StringStruct()
    }
}

const returnStrValue = (value, maxLength) => {
    return {
        rawValue: 0n,
        strValue: new StringStruct(clipString(`${value}`, maxLength))
    }
}

const nonNaN = (x, defVal = 0) => isNaN(x) ? defVal : x 
const getUnixTimestamp = dt => dt.getTime() / 1000 - dt.getTimezoneOffset() * 60

encoders[TYPE_BOOLEAN] = value => returnRawValue(encode8bInt8(value === false || value === 0 || value === '0' ? 0 : 1))
encoders[TYPE_BYTE] = value => returnRawValue(encode8bInt8(parseInt(value)))
encoders[TYPE_CHAR] = value => returnRawValue(encode8bUint8(nonNaN(`${value}`.charCodeAt(0))))
encoders[TYPE_INT16] = value => returnRawValue(encode8bInt16(parseInt(value)))
encoders[TYPE_UINT16] = value => returnRawValue(encode8bUint16(parseInt(value)))
encoders[TYPE_INT32] = value => returnRawValue(encode8bInt32(parseInt(value)))
encoders[TYPE_UINT32] = value => returnRawValue(encode8bUint32(parseInt(value)))
encoders[TYPE_INT64] = value => returnRawValue(encode8bInt64(BigInt(value)))
encoders[TYPE_UINT64] = value => returnRawValue(encode8bUint64(BigInt(value)))
encoders[TYPE_FLOAT] = value => returnRawValue(encode8bFloat32(Number(value)))
encoders[TYPE_DOUBLE] = value => returnRawValue(encode8bFloat64(Number(value)))
// encoders[TYPE_TIMESTAMP] = value => returnRawValue(encode8bUint32(Math.round((new Date(value)).getTime() / 1000)))
encoders[TYPE_TIMESTAMP] = value => returnRawValue(encode8bUint32(getUnixTimestamp(value)))
encoders[TYPE_STRING_16] = value => returnStrValue(value, 16)
encoders[TYPE_STRING_64] = value => returnStrValue(value, 64)
encoders[TYPE_STRING_255] = value => returnStrValue(value, 255)
encoders[TYPE_LINK1] = value => returnRawValue(encode8bUint32(Number(value)))

specialEncoders[SYSTEM_TYPE_IPV4] = value => {
    const nums = `${value}`.split('.')
    if (nums.length !== 4) {
        throw new Error(`Invalid argument: IP address expected.`)
    }
    let arrayBuffer = new ArrayBuffer(8) // 8 bytes
    let dataView = new DataView(arrayBuffer)
    dataView.setUint8(0, parseInt(nums[3]))
    dataView.setUint8(1, parseInt(nums[2]))
    dataView.setUint8(2, parseInt(nums[1]))
    dataView.setUint8(3, parseInt(nums[0]))
    return returnRawValue(dataView.getBigInt64(0, isLittleEndian))
}

specialEncoders[SYSTEM_TYPE_COLOR] = value => returnRawValue(ColorStruct.fromString(value).toInt())

const sizeInBytes = { }

sizeInBytes[TYPE_BOOLEAN] = 1
sizeInBytes[TYPE_BYTE] = 1
sizeInBytes[TYPE_CHAR] = 1
sizeInBytes[TYPE_INT16] = 2
sizeInBytes[TYPE_UINT16] = 2
sizeInBytes[TYPE_INT32] = 4
sizeInBytes[TYPE_UINT32] = 4
sizeInBytes[TYPE_INT64] = 8
sizeInBytes[TYPE_UINT64] = 8
sizeInBytes[TYPE_FLOAT] = 4
sizeInBytes[TYPE_DOUBLE] = 8
sizeInBytes[TYPE_TIMESTAMP] = 4
sizeInBytes[TYPE_STRING_16] = 17
sizeInBytes[TYPE_STRING_64] = 65
sizeInBytes[TYPE_STRING_255] = 256
sizeInBytes[TYPE_LINK1] = 4

const defaultBooleanFormatter = new Formatter(new FormatStruct(new FormatEncodedStruct(
    FormatEncodedStruct.TYPE_LOGICAL
)))

const defaultIntegerFormatter = new Formatter(new FormatStruct(new FormatEncodedStruct(
    FormatEncodedStruct.TYPE_NUMBER,
    0,
    FormatEncodedStruct.TEXT_TRANSFORM_NONE,
    FormatEncodedStruct.BITS_MASK_DISPLAY_UNIT_NAME | FormatEncodedStruct.BITS_MASK_GROUP_DIGITS
)))

const defaultFloatFormatter = new Formatter(new FormatStruct(new FormatEncodedStruct(
    FormatEncodedStruct.TYPE_NUMBER,
    2,
    FormatEncodedStruct.TEXT_TRANSFORM_NONE,
    FormatEncodedStruct.BITS_MASK_DISPLAY_UNIT_NAME | FormatEncodedStruct.BITS_MASK_GROUP_DIGITS
)))

const defaultTimestampFormatter = new Formatter(new FormatStruct(new FormatEncodedStruct(
    FormatEncodedStruct.TYPE_DATETIME,
    0,
    FormatEncodedStruct.TEXT_TRANSFORM_NONE,
    FormatEncodedStruct.BITS_MASK_DISPLAY_DATE | FormatEncodedStruct.BITS_MASK_DISPLAY_TIME
)))

const defaultStringFormatter = new Formatter(new FormatStruct(new FormatEncodedStruct(
    FormatEncodedStruct.TYPE_STRING,
    2,
    FormatEncodedStruct.TEXT_TRANSFORM_NONE
)))

const defaultFormatters = { }

defaultFormatters[TYPE_BOOLEAN] = defaultBooleanFormatter
defaultFormatters[TYPE_BYTE] = defaultIntegerFormatter
defaultFormatters[TYPE_CHAR] = defaultStringFormatter
defaultFormatters[TYPE_INT16] = defaultIntegerFormatter
defaultFormatters[TYPE_UINT16] = defaultIntegerFormatter
defaultFormatters[TYPE_INT32] = defaultIntegerFormatter
defaultFormatters[TYPE_UINT32] = defaultIntegerFormatter
defaultFormatters[TYPE_INT64] = defaultIntegerFormatter
defaultFormatters[TYPE_UINT64] = defaultIntegerFormatter
defaultFormatters[TYPE_FLOAT] = defaultFloatFormatter
defaultFormatters[TYPE_DOUBLE] = defaultFloatFormatter
defaultFormatters[TYPE_TIMESTAMP] = defaultTimestampFormatter
defaultFormatters[TYPE_STRING_16] = defaultStringFormatter
defaultFormatters[TYPE_STRING_64] = defaultStringFormatter
defaultFormatters[TYPE_STRING_255] = defaultStringFormatter

class SystemType {

    constructor(systemTypeStruct, disableTransforms = false) {
        this.systemTypeStruct = systemTypeStruct
        this.disableTransforms = disableTransforms
    }

    get id() {
        return this.systemTypeStruct.id
    }

    get type() {
        return this.systemTypeStruct.type
    }

    get name() {
        return this.systemTypeStruct.name.value
    }

    get description() {
        return this.systemTypeStruct.description.value
    }

    get unitName() {
        return this.systemTypeStruct.unitName.value
    }

    get sizeInBytes() {
        let type = this.type
        return type in sizeInBytes ? sizeInBytes[type] : 0
    }

    parseValue(rawValue, strValue, scale = 1, translate = 0, timeZoneOffsetInSeconds = 0) {
        let value = null
        if (specialParsers[this.id]) {
            value = specialParsers[this.id](rawValue, strValue)
        } else if (parsers[this.type]) {
            value = parsers[this.type](rawValue, strValue)
        }
        if (value !== null && !this.disableTransforms) {
            value = this.transformValue(value, scale, translate, timeZoneOffsetInSeconds)
        }
        return value
    }

    transformValue(value, scale, translate, timeZoneOffsetInSeconds) {
        if (this.disableTransforms) {
            return value
        }
        if (specialTransformers[this.id]) {
            return specialTransformers[this.id](value, scale, translate, timeZoneOffsetInSeconds)
        } else if (transformers[this.type]) {            
            return transformers[this.type](value, scale, translate, timeZoneOffsetInSeconds)
        }
        return value
    }

    reverseTransformValue(value, scale, translate, timeZoneOffsetInSeconds) {
        
        // SINCE 2022-12-07 "KoefPerevodaInput" and "SmeschenieInput" used inside reverseTransform()
        // in form k * x + b, where k - "KoefPerevodaInput", b - "SmeschenieInput".
        // So code of reverseTransform() becomes equalents with code of transformValue().
        return this.transformValue(value, scale, translate, -timeZoneOffsetInSeconds)

        // BEFORE 2022-12-07 "KoefPerevoda" and "Smeschenie" used inside reverseTransform()
        // in form (x - b) / k as a reverse form of k * x + b, where k - "KoefPerevoda", b - "Smeschenie".
        // if (this.disableTransforms) {
        //     return value
        // }
        // if (specialReverseTransformers[this.id]) {
        //     return specialReverseTransformers[this.id](value, scale, translate, timeZoneOffsetInSeconds)
        // } else if (reverseTransformers[this.type]) {            
        //     return reverseTransformers[this.type](value, scale, translate, timeZoneOffsetInSeconds)
        // }
        // return value
    }

    transformArchive(rows, scale = 1, translate = 0, timeZoneOffsetInSeconds = 0) {
        if (this.disableTransforms) {
            return rows
        }
        for (let i = 0; i < rows.length; i ++) {
            rows[i].value = this.transformValue(rows[i].value, scale, translate, timeZoneOffsetInSeconds)
            rows[i].timestamp += timeZoneOffsetInSeconds
        }
        return rows
    }

    formatValue(value, unitName = '') {
        let type = this.type
// console.log('format', 'type', type, 'value', value, 'formatter', defaultFormatters[type])
        return type in defaultFormatters
            ? defaultFormatters[type].formatValue(value, unitName)
            : value
    }

    encodeValue(value, scale = 1, translate = 0, timeZoneOffsetInSeconds = 0) {
        value = this.reverseTransformValue(value, scale, translate, timeZoneOffsetInSeconds)
        if (specialEncoders[this.id]) {
            return specialEncoders[this.id](value)
        } else if (encoders[this.type]) {            
            return encoders[this.type](value)
        }
        return value
    }
}

SystemType.TYPE_BOOLEAN = TYPE_BOOLEAN
SystemType.TYPE_BYTE = TYPE_BYTE
SystemType.TYPE_CHAR = TYPE_CHAR
SystemType.TYPE_INT16 = TYPE_INT16
SystemType.TYPE_UINT16 = TYPE_UINT16
SystemType.TYPE_INT32 = TYPE_INT32
SystemType.TYPE_UINT32 = TYPE_UINT32
SystemType.TYPE_INT64 = TYPE_INT64
SystemType.TYPE_UINT64 = TYPE_UINT64
SystemType.TYPE_FLOAT = TYPE_FLOAT
SystemType.TYPE_DOUBLE = TYPE_DOUBLE
SystemType.TYPE_TIMESTAMP = TYPE_TIMESTAMP
SystemType.TYPE_STRING_16 = TYPE_STRING_16
SystemType.TYPE_STRING_64 = TYPE_STRING_64
SystemType.TYPE_STRING_255 = TYPE_STRING_255
SystemType.TYPE_LINK1 = TYPE_LINK1
SystemType.TYPE_INVALID_TYPE = TYPE_INVALID_TYPE

SystemType.SYSTEM_TYPE_OBJECT_TYPE = SYSTEM_TYPE_OBJECT_TYPE
SystemType.SYSTEM_TYPE_PHONE = SYSTEM_TYPE_PHONE
SystemType.SYSTEM_TYPE_REPORTING_HOUR = SYSTEM_TYPE_REPORTING_HOUR
SystemType.SYSTEM_TYPE_REPORTING_DAY = SYSTEM_TYPE_REPORTING_DAY
SystemType.SYSTEM_TYPE_IPV4 = SYSTEM_TYPE_IPV4
SystemType.SYSTEM_TYPE_GPS_COORDINATES_POINT = SYSTEM_TYPE_GPS_COORDINATES_POINT
SystemType.SYSTEM_TYPE_GPS_COORDINATES_LINE = SYSTEM_TYPE_GPS_COORDINATES_LINE
SystemType.SYSTEM_TYPE_STATUS = SYSTEM_TYPE_STATUS
SystemType.SYSTEM_TYPE_CHANNEL_SETTING_TYPE = SYSTEM_TYPE_CHANNEL_SETTING_TYPE
SystemType.SYSTEM_TYPE_CHANNEL_SETTING_SUBTYPE = SYSTEM_TYPE_CHANNEL_SETTING_SUBTYPE
SystemType.SYSTEM_TYPE_PARAMETER_TABLE_FOOTER = SYSTEM_TYPE_PARAMETER_TABLE_FOOTER
SystemType.SYSTEM_TYPE_TIME_WAIT_CALL = SYSTEM_TYPE_TIME_WAIT_CALL
SystemType.SYSTEM_TYPE_TIME_BETWEEN_CALL = SYSTEM_TYPE_TIME_BETWEEN_CALL
SystemType.SYSTEM_TYPE_OPCUA_EXTERNAL_TAG = SYSTEM_TYPE_OPCUA_EXTERNAL_TAG
SystemType.SYSTEM_TYPE_TIME_ZONE_CLIENT = SYSTEM_TYPE_TIME_ZONE_CLIENT
SystemType.SYSTEM_TYPE_COLOR = SYSTEM_TYPE_COLOR
SystemType.SYSTEM_TYPE_CONTROLLER_ID = SYSTEM_TYPE_CONTROLLER_ID

export {

    SystemType
}
