import { UsersListStructExtended } from '../quantum-lib/Arm/Parser/Users/UsersListStructExtended.mjs';
import { UsersGroupsListStructExtended } from '../quantum-lib/Arm/Parser/Users/UsersGroupsListStructExtended.mjs';
import { UsersGroupStructExtended } from '../quantum-lib/Arm/Parser/Users/UsersGroupStructExtended.mjs';
import { ErrorsApp } from '../utils/errors-app/ErrorsApp.mjs';

import { setWorkingMode } from './services.mjs';

export class UsersService {

  static async usersListStruct() {
    const response = await fetch(`${setWorkingMode()}/users-list`, {
      method: 'get',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });

    if (!response.ok) {
      throw new ErrorsApp("Произошла ошибка при загрузке списка пользователей!", 2);
    }
    const arrayBuffer = await response.arrayBuffer();

    const isLittleEndian = true;
    const offset = 0;
    const dataView = new DataView(arrayBuffer);

    const usersListStruct = new UsersListStructExtended();

    usersListStruct.readFrom(dataView, offset, isLittleEndian);
    return usersListStruct;
  }

  static async usersGroupsListStruct() {
    const response = await fetch(`${setWorkingMode()}/groups-list`, {
      method: 'get',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });

    if (!response.ok) {
      throw new ErrorsApp("Произошла ошибка при загрузке списка групп пользователей!", 2);
    }
    const arrayBuffer = await response.arrayBuffer();

    const isLittleEndian = true;
    const offset = 0;
    const dataView = new DataView(arrayBuffer);

    const usersGroupsListStructExtended = new UsersGroupsListStructExtended();
    usersGroupsListStructExtended.readFrom(dataView, offset, isLittleEndian);
    return usersGroupsListStructExtended;
  }

  static async usersCreatingNewGroup(group) {
    const response = await fetch(`${setWorkingMode()}/creating-new-group`, {
      method: 'post',
      cache: 'reload',
      credentials: 'omit',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify(group),
    });

    if (response.status === 400) throw new ErrorsApp("Группа с таким именем уже существует!", 2);
    if (!response.ok && response.status !== 400) throw new ErrorsApp("Системная ошибка!", 2);

    const arrayBuffer = await response.arrayBuffer();

    const isLittleEndian = true;
    const offset = 0;
    const dataView = new DataView(arrayBuffer);

    const usersGroupStructExtended = new UsersGroupStructExtended();
    usersGroupStructExtended.readFrom(dataView, offset, isLittleEndian);
    return usersGroupStructExtended;
  }

  static async usersChangingExistingGroup(group, idGroup) {
    const response = await fetch(`${setWorkingMode()}/changing-existing-group/${idGroup}`, {
      method: 'put',
      cache: 'reload',
      credentials: 'omit',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify(group),
    });

    if (response.status === 400) throw new ErrorsApp('Группа с таким именем уже существует!', 2);
    if (!response.ok && response.status !== 400) throw new ErrorsApp("Системная ошибка!", 2);

    const arrayBuffer = await response.arrayBuffer();


    const isLittleEndian = true;
    const offset = 0;
    const dataView = new DataView(arrayBuffer);

    const usersGroupStructExtended = new UsersGroupStructExtended();
    usersGroupStructExtended.readFrom(dataView, offset, isLittleEndian);
    return usersGroupStructExtended;
  }

  static async usersCreatingNewUser(user) {
    const response = await fetch(`${setWorkingMode()}/creating-new-user`, {
      method: 'post',
      cache: 'reload',
      credentials: 'omit',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify(user),
    });

    if (!response.ok) {
      const errorMessage = (await response.json())?.message || "Ошибка!";
      throw new ErrorsApp(errorMessage, 2);
    } else {
      return await response.json();
    }
  }

  static async usersChangingExistingUser(user, idUser) {
    const response = await fetch(`${setWorkingMode()}/changing-existing-user/${idUser}`, {
      method: 'put',
      cache: 'reload',
      credentials: 'omit',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify(user),
    });

    if (!response.ok) {
      const errorMessage = (await response.json())?.message || "Ошибка!";
      throw new ErrorsApp(errorMessage, 2);
    } else {
      return await response.json();
    }
  }
}