import classNames from 'classnames';
import { MdAccountTree, MdEditNote, MdMenuBook } from 'react-icons/md';
import { ImUsers } from 'react-icons/im';
import { BsBorderStyle, BsDatabaseFillAdd, BsFillKeyFill } from 'react-icons/bs';
import { NavLink } from 'react-router-dom';
import { GoTasklist } from 'react-icons/go';
import { RiAlarmWarningFill } from 'react-icons/ri';
import { FaBook } from 'react-icons/fa';

import { useKernel } from '../../context/ContextKernel.mjs';
import { useUserTokenDecode } from '../../hooks/hooks.mjs';
import { flagsParser } from '../../utils/flags-parser/flagsParser.mjs';

import styles from './navList.module.scss';

export default function NavList(props) {
  const { sidebar } = props;
  const { currentLicense } = useKernel();

  const {
    hasAccessUsersModule = undefined,
    hasAccessEventLogsModule = undefined,
    hasAccessLicensesModule = undefined,
    hasAccessCalculationServerModule = undefined,
    hasAccessTaskServerModule = undefined,
    hasAccessSetpointsModule = undefined,
    hasAccessAlarmServerModule = undefined,
    hasAccessSimulatorServerModule = undefined,
    hasAccessHandbookModule = undefined,
  } = currentLicense;

  const { flags } = useUserTokenDecode();
  const {
    hasAccessRightsUsersModule,
    hasAccessRightsEventLogsModule,
    hasAccessRightsLicensesModule,
    hasAccessRightsCalculationServerModule,
    hasAccessRightsTaskServerModule,
    hasAccessRightsSetpointsModule,
    hasAccessRightsAlarmServerModule,
    hasAccessRightsSimulatorServerModule,
    hasAccessRightsHandbookModule,
  } = flagsParser(flags);

  const setStileAdaptiveMenu = classNames(styles.navListItem, sidebar && styles.navListItemSmall);
  const setStileActiveMenuLink = classNames(setStileAdaptiveMenu, styles.activeLinkNavMenu);
  const setStileMenuLink = ({ isActive }) => (isActive ? setStileActiveMenuLink : setStileAdaptiveMenu);

  const getLink = () => {
    const href = document.location.href.split('/');
    return href[href.length - 1];
  };

  const setLinkOnCurrentPageOrConfigurationSelectionPage = (linkOnCurrentPage = '/configuration-selection') => {
    const id = JSON.parse(localStorage.getItem('activeIdConfig'))?.id;
    return id ? linkOnCurrentPage : '/configuration-selection';
  };

  return (
    <ul className={styles.navList}>
      <NavLink
        to={setLinkOnCurrentPageOrConfigurationSelectionPage('/property-editor')}
        className={getLink() === 'property-editor' ? setStileActiveMenuLink : setStileAdaptiveMenu}
        title="Конфигурация"
      >
        <MdEditNote className={styles.navListItemIcon} />
        <span className={`${sidebar ? (styles.navListItemText, styles.navListItemTextSmall) : styles.navListItemText}`}>Конфигурация</span>
      </NavLink>

      {hasAccessUsersModule === 'true' && hasAccessRightsUsersModule === true && (
        <NavLink to="/users" className={setStileMenuLink} title="Пользователи">
          <ImUsers className={styles.navListItemIcon} />
          <span className={`${sidebar ? (styles.navListItemText, styles.navListItemTextSmall) : styles.navListItemText}`}>Пользователи</span>
        </NavLink>
      )}

      {hasAccessEventLogsModule === 'true' && hasAccessRightsEventLogsModule === true && (
        <NavLink to="/action-logs" className={setStileMenuLink} title="Журнал событий">
          <MdMenuBook className={styles.navListItemIcon} />
          <span className={`${sidebar ? (styles.navListItemText, styles.navListItemTextSmall) : styles.navListItemText}`}>Журнал событий</span>
        </NavLink>
      )}

      {hasAccessLicensesModule === 'true' && hasAccessRightsLicensesModule === true && (
        <NavLink to="/licenses" className={setStileMenuLink} title="Лицензии">
          <BsFillKeyFill className={styles.navListItemIcon} />
          <span className={`${sidebar ? (styles.navListItemText, styles.navListItemTextSmall) : styles.navListItemText}`}>Лицензии</span>
        </NavLink>
      )}

      {hasAccessCalculationServerModule === 'true' && hasAccessRightsCalculationServerModule === true && (
        <NavLink to={setLinkOnCurrentPageOrConfigurationSelectionPage('/settlement-server')} className={setStileMenuLink} title="Сервер расчетов">
          <MdAccountTree className={styles.navListItemIcon} />
          <span className={`${sidebar ? (styles.navListItemText, styles.navListItemTextSmall) : styles.navListItemText}`}>Сервер расчетов</span>
        </NavLink>
      )}

      {hasAccessTaskServerModule === 'true' && hasAccessRightsTaskServerModule === true && (
        <NavLink to={setLinkOnCurrentPageOrConfigurationSelectionPage('/task-server')} className={setStileMenuLink} title="Сервер задач">
          <GoTasklist className={styles.navListItemIcon} />
          <span className={`${sidebar ? (styles.navListItemText, styles.navListItemTextSmall) : styles.navListItemText}`}>Сервер задач</span>
        </NavLink>
      )}

      {hasAccessSetpointsModule === 'true' && hasAccessRightsSetpointsModule === true && (
        <NavLink to={setLinkOnCurrentPageOrConfigurationSelectionPage('/setpoints')} className={setStileMenuLink} title="Уставки">
          <BsBorderStyle className={styles.navListItemIcon} />
          <span className={`${sidebar ? (styles.navListItemText, styles.navListItemTextSmall) : styles.navListItemText}`}>Уставки</span>
        </NavLink>
      )}

      {hasAccessAlarmServerModule === 'true' && hasAccessRightsAlarmServerModule === true && (
        <NavLink to={setLinkOnCurrentPageOrConfigurationSelectionPage('/alarm')} className={setStileMenuLink} title="Тревоги">
          <RiAlarmWarningFill className={styles.navListItemIcon} />
          <span className={`${sidebar ? (styles.navListItemText, styles.navListItemTextSmall) : styles.navListItemText}`}>Тревоги</span>
        </NavLink>
      )}

      {hasAccessSimulatorServerModule === 'true' && hasAccessRightsSimulatorServerModule === true && (
        <NavLink to={setLinkOnCurrentPageOrConfigurationSelectionPage('/simulator')} className={setStileMenuLink} title="Симулятор">
          <BsDatabaseFillAdd className={styles.navListItemIcon} />
          <span className={`${sidebar ? (styles.navListItemText, styles.navListItemTextSmall) : styles.navListItemText}`}>Симулятор</span>
        </NavLink>
      )}

      {hasAccessHandbookModule === 'true' && hasAccessRightsHandbookModule === true && (
        <NavLink to={setLinkOnCurrentPageOrConfigurationSelectionPage('/reference-book')} className={setStileMenuLink} title="Справочники">
          <FaBook className={styles.navListItemIcon} />
          <span className={`${sidebar ? (styles.navListItemText, styles.navListItemTextSmall) : styles.navListItemText}`}>Справочники</span>
        </NavLink>
      )}
    </ul>
  );
}
