import { useCallback } from 'react';
import classNames from 'classnames';
import { FaUsers, FaUser } from 'react-icons/fa';

import { useKernel } from '../../context/ContextKernel.mjs';

import styles from './userGroupTreeItem.module.scss';

export default function UserGroupTreeItem(props) {
  const { userGroup, usersTree, setUsersTree, setComponentsTypeId, setIdTreeElement, activeElementTree, setActiveElementTree } = props;

  const { kernel, setContextMenu } = useKernel();

  const contextMenuSettingsTreeUserGroup = [
    {
      id: 0,
      text: 'Добавить группу',
      renderElement: true,
      onClick: () => {
        setComponentsTypeId(3);
      },
    },
    {
      id: 1,
      text: 'Добавить пользователя в текущую группу',
      renderElement: true,
      onClick: () => {
        const id = kernel.elementUsersTreeId;
        setIdTreeElement(id);
        setComponentsTypeId(1);
      },
    },
    {
      id: 2,
      text: 'Редактировать группу',
      renderElement: true,
      onClick: () => {
        const id = kernel.elementUsersTreeId;
        setIdTreeElement(id);
        setComponentsTypeId(4);
      },
    },
  ];

  const contextMenuSettingsTreeUser = [
    {
      id: 0,
      text: 'Добавить пользователя в текущую группу',
      renderElement: true,
      onClick: () => {
        const userId = kernel.elementUsersTreeId;
        const user = kernel.users[userId];
        const groupId = user.groupId;

        setIdTreeElement(groupId);
        setComponentsTypeId(1);
      },
    },
    {
      id: 1,
      text: 'Редактировать пользователя',
      renderElement: true,
      onClick: () => {
        const id = kernel.elementUsersTreeId;
        setIdTreeElement(id);
        setComponentsTypeId(2);
      },
    },
  ];

  const handleClickGroup = (id) => {
    setComponentsTypeId(4);
    setIdTreeElement(id);

    kernel.setElementUsersTreeId(id);
    setActiveElementTree(`${id}g`);
  };

  const handleDoubleClickGroup = (id) => {
    kernel.setElementUsersTreeId(id);
    setActiveElementTree(`${id}g`);

    setUsersTree(
      usersTree.map((group) => {
        if (group.id === userGroup.id) {
          group.isOpen = !group.isOpen;
        }
        return group;
      }),
    );
  };

  const handleClickUser = (id) => {
    setComponentsTypeId(2);
    setIdTreeElement(id);
    kernel.setElementUsersTreeId(id);
    setActiveElementTree(`${id}u`);
  };

  const handleContextMenuGroup = useCallback(
    (event) => {
      event.preventDefault();
      const { clientX, clientY, currentTarget } = event;
      const id = currentTarget.id;
      kernel.setElementUsersTreeId(id);

      setActiveElementTree(`${id}g`);

      setContextMenu(contextMenuSettingsTreeUserGroup, [clientX, clientY], null, currentTarget, 'mouseRight');
    },
    [setContextMenu],
  );

  const handleContextMenuUser = useCallback(
    (event) => {
      event.preventDefault();
      const { clientX, clientY, currentTarget } = event;
      const id = currentTarget.id;
      kernel.setElementUsersTreeId(id);

      setActiveElementTree(`${id}u`);

      setContextMenu(contextMenuSettingsTreeUser, [clientX, clientY], null, currentTarget, 'mouseRight');
    },
    [setContextMenu],
  );

  return (
    <div className={styles.treeUserWrapper}>
      <div
        className={classNames(styles.group, activeElementTree === `${userGroup.id}g` && styles.groupActive)}
        id={userGroup.id}
        onContextMenu={(event) => handleContextMenuGroup(event)}
        onClick={(event) => handleClickGroup(event.currentTarget.id)}
        onDoubleClick={(event) => handleDoubleClickGroup(event.currentTarget.id)}
      >
        <FaUsers className={styles.groupIcon} />
        <span className={styles.groupText}>{userGroup.name}</span>
      </div>
      <div className={styles.userContainer}>
        {userGroup.isOpen &&
          userGroup.children.map((user) => {
            return (
              <div
                key={user.id}
                id={user.id}
                className={classNames(styles.userWraper, userGroup.isOpen && styles.userWraperActive)}
                onContextMenu={(event) => handleContextMenuUser(event)}
                onClick={(event) => handleClickUser(event.currentTarget.id)}
              >
                <div className={classNames(styles.user, activeElementTree === `${user.id}u` && styles.userActive)}>
                  <FaUser className={styles.userIcon} />
                  <span className={styles.userText}>{user.name}</span>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}
