import classNames from 'classnames';
import { memo, useCallback, useEffect, useRef, useState } from 'react';

import { useKernel } from '../../context/ContextKernel.mjs';
import { IconTreeConfiguration } from '../../hoc/hoc.mjs';

import styles from './treeTemplateConfiguration.module.scss';

export default memo(function TreeTemplateConfiguration(props) {
  const { configurationTemplate } = props;

  const configurationClassify = configurationTemplate?.classify();
  const [showChildren, setShowChildren] = useState();
  const { kernel, stateElementTemplateConfigurationId, setStateElementTemplateConfigurationId } = useKernel(); //работа с global state
  const ref = useRef();

  const handleClick = useCallback(
    (id) => {
      kernel.setElementTemplateConfigurationId(BigInt(id));
      setStateElementTemplateConfigurationId(kernel.elementTemplateConfigurationId);
    },
    [kernel, setStateElementTemplateConfigurationId],
  );

  const handleDoubleClick = useCallback(() => {
    const id = configurationTemplate?.id;
    kernel.openElementTemplateTree.set(id, !showChildren);
    setShowChildren(!showChildren);
  }, [showChildren]);

  useEffect(() => {
    if (configurationTemplate) {
      const isOpen = kernel.openElementTemplateTree.get(configurationTemplate?.id);
      isOpen ? setShowChildren(true) : setShowChildren(false);
    }

    return () => {};
  }, [configurationTemplate]);

  return (
    <div className={classNames(styles.tree)}>
      {configurationTemplate ? (
        configurationTemplate.isTypeNode || configurationTemplate.idDeleted ? (
          <></>
        ) : (
          <div className={classNames(styles.treeWrapper)} data-id={configurationTemplate.id}>
            <div
              ref={ref}
              className={classNames(
                styles.parentElem,
                Number(stateElementTemplateConfigurationId) === Number(configurationTemplate.id) && styles.parentElemActive,
              )}
              onClick={(event) => handleClick(event.currentTarget.dataset.id)}
              onDoubleClick={(event) => handleDoubleClick(event.currentTarget.dataset.id)}
              data-id={configurationTemplate.id}
            >
              <IconTreeConfiguration
                showChildren={showChildren}
                typeElementTree={configurationClassify}
                isDeleted={configurationTemplate.isDeleted}
                isLink={configurationTemplate.isLink}
              />

              <span className={classNames(styles.parentElemText, configurationTemplate.isDeleted && styles.isDeleted)}>
                {kernel.getUser().settings.hasParameterIdInTree && `#${configurationTemplate.id.toString()}`} {configurationTemplate.displayName}
              </span>
            </div>
            <div
              className={
                !configurationTemplate.isTypeNode
                  ? !showChildren
                    ? styles.childrenElem
                    : classNames(styles.childrenElem, styles.childrenElemActive)
                  : styles.childrenElem
              }
            >
              {
                showChildren &&
                configurationTemplate.children.map((data) =>
                  data.isTypeNode || data.isDeleted ? (
                    <></>
                  ) : (
                    <TreeTemplateConfiguration
                      key={data.id}
                      configurationTemplate={data}
                    />
                  ),
                )}
            </div>
          </div>
        )
      ) : (
        <></>
      )}
    </div>
  );
});
