import axios from 'axios';
import isDev from 'react-is-dev';
import React from 'react';

const ifDevEnv = isDev(React);

export const API_URL = ifDevEnv ? process.env.REACT_APP_API_PATH_DEV : process.env.REACT_APP_API_PATH_PROD;

const $api = axios.create({
  baseURL: API_URL,
});

$api.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
  return config;
});

export default $api;
