import { Struct } from '../../../Scada/Parser/Struct.mjs';
import { StringStruct } from '../../../Scada/Parser/StringStruct.mjs';

class EditorSettingsStruct extends Struct {
  constructor(
    id = 0,
    systemType = 0,
    systemTypeParent = 0,
    mask = new StringStruct(''),
    canView = 0,
    canEdit = 0,
    typeBlockParent = 0,
  ) {
    super();
    this.id = id;
    this.systemType = systemType;
    this.systemTypeParent = systemTypeParent;
    this.mask = mask;
    this.canView = canView;
    this.canEdit = canEdit;
    this.typeBlockParent = typeBlockParent;
  }

  readFrom(dataView, startOffset, isLittleEndian = true) {
    let endOffset = startOffset;
    this.id = dataView.getUint32(endOffset, isLittleEndian);
    endOffset += 4;
    this.systemType = dataView.getUint32(endOffset, isLittleEndian);
    endOffset += 4;
    this.systemTypeParent = dataView.getUint32(endOffset, isLittleEndian);
    endOffset += 4;
    endOffset = this.mask.readFrom(dataView, endOffset, isLittleEndian);
    this.canView = dataView.getInt32(endOffset, isLittleEndian);
    endOffset += 4;
    this.canEdit = dataView.getInt32(endOffset, isLittleEndian);
    endOffset += 4;
    this.typeBlockParent = dataView.getUint32(endOffset, isLittleEndian);
    endOffset += 4;
    return endOffset;
  }

  writeTo(dataView, startOffset, isLittleEndian = true) {
    let endOffset = startOffset;
    dataView.setUint32(endOffset, this.id, isLittleEndian);
    endOffset += 4;
    dataView.setUint32(endOffset, this.systemType, isLittleEndian);
    endOffset += 4;
    dataView.setUint32(endOffset, this.systemTypeParent, isLittleEndian);
    endOffset += 4;
    endOffset = this.mask.writeTo(dataView, endOffset, isLittleEndian);
    dataView.setUint32(endOffset, this.canView, isLittleEndian);
    endOffset += 4;
    dataView.setUint32(endOffset, this.canEdit, isLittleEndian);
    endOffset += 4;
    dataView.setUint32(endOffset, this.typeBlockParent, isLittleEndian);
    endOffset += 4;
    return endOffset;
  }

  getCurrentSize() {
    return 0
      + 4 // id
      + 4 //systemType
      + 4 //systemTypeParent
      + this.mask.getCurrentSize() // mask
      + 4 //canView
      + 4 //canEdit
      + 4;//typeBlockParent
  }

  clone() {
    const clone = new EditorSettingsStruct(
      this.id,
      this.systemType,
      this.systemTypeParent,
      this.mask.clone(),
      this.canView,
      this.canEdit,
      this.typeBlockParent
    );
    clone.setParserOptions(this.getParserOptions());
    return clone;
  }
}

export {

  EditorSettingsStruct,
};