import { useForm } from 'react-hook-form';
import { useState } from 'react';
import classNames from 'classnames';
import { HiCheck } from 'react-icons/hi';
import { BsArrowLeftCircle } from 'react-icons/bs';

import { LoadingInUserPageForm } from '../components.mjs';
import { useKernel } from '../../context/ContextKernel.mjs';

import styles from './setTemplateTaskPopUp.module.scss';

export default function SetTemplateTaskPopUp(props) {
  const { setNamePopUpVisible, onSubmit, initialSystemType, sendingData, dataSentSuccessfully, title, placeholder } = props;
  const { kernel } = useKernel();

  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm({
    mode: 'onChange',
  });

  const [name, setName] = useState();
  const [systemTypeId, setSystemTypeId] = useState(initialSystemType);

  const handleChangeInputValue = (event) => {
    setName(event.target.value);
  };

  const handleChangeSelectValue = (event) => {
    setSystemTypeId(event.target.value);
  };

  const buttonBackClick = () => {
    setNamePopUpVisible(false);
  };

  return (
    <div className={styles.popUpWrap}>
      <div className={styles.container}>
        <div className={styles.header}>
          <h2 className={styles.title}>{title}</h2>
          <BsArrowLeftCircle className={styles.buttonBack} onClick={buttonBackClick} />
        </div>
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.containerForm}>
            <div className={styles.wrap}>
              <input
                className={classNames(styles.input, errors.name && styles.inputError)}
                type="text"
                placeholder={placeholder}
                onInput={handleChangeInputValue}
                value={name}
                {...register('name', {
                  required: 'Поле обязательно к заполнению',
                  minLength: {
                    value: 3,
                    message: 'Поле должно содержать минимум 3 символа',
                  },
                  maxLength: {
                    value: 255,
                    message: 'Поле может содержать максимум 255 символа',
                  },
                })}
              />
              {errors?.name && <p className={styles.errorMessage}>{errors?.name?.message || 'Ошибка заполнения!'}</p>}
            </div>
            <div className={styles.wrap}>
              <select className={styles.select} value={systemTypeId} onInput={handleChangeSelectValue} {...register('systemTypeId')}>
                {kernel.getSystemTypes().map((systemType) => {
                  return (
                    <>
                      {![0, 11, 12, 13, 14, 15, 16].includes(systemType.type) && (
                        <option className={styles.option} key={systemType.id} value={systemType.id}>
                          {systemType.description}
                        </option>
                      )}
                    </>
                  );
                })}
              </select>
            </div>
          </div>
          <button
            className={
              sendingData || dataSentSuccessfully
                ? styles.buttonSending
                : isValid
                  ? classNames(styles.button, styles.formValid)
                  : classNames(styles.button, styles.formNoValid)
            }
            type="submit"
            disabled={!isValid}
          >
            {dataSentSuccessfully ? <HiCheck className={styles.checkControl} /> : sendingData ? <LoadingInUserPageForm /> : 'Добавить'}
          </button>
        </form>
      </div>
    </div>
  );
}
