class LicenseType {
  constructor(kernel, licenseTypeStruct) {
    this.kernel = kernel;
    this.licenseTypeStruct = licenseTypeStruct;
    this.children = [];
    this.isOpen = false;
  }

  get id() {
    return this.licenseTypeStruct.id;
  }

  get name() {
    return this.licenseTypeStruct.name.value;
  }
}

export { LicenseType };