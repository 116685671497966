import { Struct } from '../Struct.mjs'
import { StringStruct } from '../StringStruct.mjs'

class SystemTypeStruct extends Struct {

    constructor(id = 0, type = 0, name = new StringStruct(''), description = new StringStruct(''), unitName = new StringStruct('')) {
        super()
        this.id = id
        this.type = type
        this.name = name
        this.description = description
        this.unitName = unitName
    }

    readFrom(dataView, startOffset, isLittleEndian = true) {
        let endOffset = startOffset
        this.id = dataView.getUint32(endOffset, isLittleEndian)
        endOffset += 4
        this.type = dataView.getUint32(endOffset, isLittleEndian)
        endOffset += 4
        endOffset = this.name.readFrom(dataView, endOffset, isLittleEndian)
        endOffset = this.description.readFrom(dataView, endOffset, isLittleEndian)
        endOffset = this.unitName.readFrom(dataView, endOffset, isLittleEndian)
        return endOffset
    }

    writeTo(dataView, startOffset, isLittleEndian = true) {
        let endOffset = startOffset
        dataView.setUint32(endOffset, this.id, isLittleEndian)
        endOffset += 4
        dataView.setUint32(endOffset, this.type, isLittleEndian)
        endOffset += 4
        endOffset = this.name.writeTo(dataView, endOffset, isLittleEndian)
        endOffset = this.description.writeTo(dataView, endOffset, isLittleEndian)
        endOffset = this.unitName.writeTo(dataView, endOffset, isLittleEndian)
        return endOffset
    }

    getCurrentSize() {
        return 0
            + 4 // id
            + 4 // type
            + this.name.getCurrentSize() // name
            + this.description.getCurrentSize() // description
            + this.unitName.getCurrentSize() // unitName
    }

    clone() {
        let clone = new SystemTypeStruct(
            this.id,
            this.type,
            this.name.clone(),
            this.description.clone(),
            this.unitName.clone()
        )
        clone.setParserOptions(this.getParserOptions())
        return clone
    }
}

export {
    
    SystemTypeStruct
}
