import { useCallback, useEffect, useRef, useState } from 'react';
import { LuSettings2 } from 'react-icons/lu';
import { IoMdCodeWorking } from 'react-icons/io';

import { ConfirmationPopUp, SetNamePopUp, Tabs } from '../components.mjs';
import { useKernel } from '../../context/ContextKernel.mjs';
import { useActiveConfigurationId } from '../../hooks/useActiveConfigurationId.mjs';
import { SimulatorServerService } from '../../services/services.mjs';

import { TaskEditorArguments, TaskGeneralSettings, SimulatorTaskList } from './ui/index.mjs';
import styles from './simulatorEditor.module.scss';

const tabList = [
  {
    id: 1,
    icon: <LuSettings2 className="tabListIcon" />,
    text: 'Общие',
    renderingConditions: true,
  },
  {
    id: 2,
    icon: <IoMdCodeWorking className="tabListIcon" />,
    text: 'Параметры задачи',
    renderingConditions: true,
  },
];

export default function SimulatorEditor(props) {
  const { task, setTask, sendingData, dataSentSuccessfully, setDataSentSuccessfully, setSendingData, stateElementConfigurationId } = props;

  const [tabVisible, setTabVisible] = useState(1);
  const [popUpVisible, setPopUpVisible] = useState(false);
  const [popUpDeleteSimulatorTaskVisible, setPopUpDeleteAlarmVisible] = useState(false);
  const [status, setStatus] = useState({ loading: false, success: false });
  const { kernel, addNotification } = useKernel();
  const { activeConfigurationId } = useActiveConfigurationId();
  const classify = kernel.getParameterById(stateElementConfigurationId)?.classify();
  const deleteSimulatorTaskId = useRef(null);

  const handleTabVisible = (event) => {
    const tabId = parseInt(event.currentTarget.dataset.tab);
    setTabVisible(tabId);
  };

  const createSimulatorTask = async (data) => {
    try {
      setSendingData(true);

      const newSimulatorTask = await SimulatorServerService.createSimulatorTask(activeConfigurationId, {
        parameterId: stateElementConfigurationId.toString(),
        nameTask: data.name,
      });

      kernel.addNewSimulatorTaskByParameterId(stateElementConfigurationId, newSimulatorTask);

      const simulatorTasksList = kernel.getSimulatorTasksByParameterId(stateElementConfigurationId);
      setTask({
        taskList: simulatorTasksList,
        activeTask: simulatorTasksList.find((simulatorTask) => simulatorTask.id === parseInt(newSimulatorTask.id)),
        activeTaskId: parseInt(newSimulatorTask.id),
      });

      setTimeout(() => {
        setDataSentSuccessfully(true);
        setSendingData(false);
      }, 600);

      setTimeout(() => {
        setDataSentSuccessfully(false);
        setPopUpVisible(false);
      }, 1400);
    } catch (error) {
      setSendingData(false);
      setPopUpVisible(false);
      addNotification({ messageText: error.message, statusType: error.status });
    }
  };

  const handleChangeSimulatorTaskList = (event) => {
    const simulatorTaskId = parseInt(event.currentTarget.dataset.id);
    const simulatorTaskListStruct = kernel.getSimulatorTasksByParameterId(stateElementConfigurationId); // получение задач по parameterId

    setTask({
      ...task,
      activeTask: simulatorTaskListStruct.find((simulatorTaskStruct) => simulatorTaskStruct.id === simulatorTaskId),
      activeTaskId: simulatorTaskId,
    });
  };

  const getActiveSimulatorTask = useCallback((simulatorTaskListStruct, currentActiveSimulatorTaskId) => {
    return (
      simulatorTaskListStruct.find((simulatorTaskStruct) => simulatorTaskStruct.id === parseInt(currentActiveSimulatorTaskId)) ||
      simulatorTaskListStruct[0]
    );
  }, []);

  const saveOptions = async (data) => {
    try {
      setSendingData(true);
      const simulatorTaskId = parseInt(task.activeTaskId);

      const changedSimulatorTask = await SimulatorServerService.changeSimulatorTaskOptionsByTaskId(activeConfigurationId, {
        workingStatus: data.workingStatus,
        nameTask: data.nameTask,
        simulatorTaskId: simulatorTaskId,
      });

      kernel.changeSimulatorTaskById(stateElementConfigurationId, simulatorTaskId, changedSimulatorTask); // изменяем в глобальной памяти

      const simulatorTaskList = kernel.getSimulatorTasksByParameterId(stateElementConfigurationId);

      setTask({
        taskList: simulatorTaskList,
        activeTask: simulatorTaskList.find((simulatorTask) => simulatorTask.id === simulatorTaskId),
        activeTaskId: simulatorTaskId,
      });

      setTimeout(() => {
        setDataSentSuccessfully(true);
        setSendingData(false);
      }, 600);

      setTimeout(() => {
        setDataSentSuccessfully(false);
      }, 1400);
    } catch (error) {
      setSendingData(false);
      setPopUpVisible(false);

      addNotification({ messageText: error.message, statusType: error.status });
    }
  };

  const saveArguments = async (data) => {
    try {
      setSendingData(true);
      const { frequency, parameterId, period, simulatorType, timestampCreate, timestampFrom, timestampTo, value_1, value_2, value_3 } = data;
      const simulatorTaskId = task.activeTaskId.toString();

      const changedSimulatorTask = await SimulatorServerService.changeSimulatorTaskArgumentsByTaskId(activeConfigurationId, {
        simulatorTaskId,
        frequency,
        parameterId,
        period,
        simulatorType,
        timestampCreate: timestampCreate ? timestampCreate.split('T').join(' ') : '',
        timestampFrom: timestampFrom ? timestampFrom.split('T').join(' ') : '',
        timestampTo: timestampTo ? timestampTo.split('T').join(' ') : '',
        value_1,
        value_2,
        value_3,
      });

      if (parseInt(stateElementConfigurationId) !== parseInt(parameterId)) {
        kernel.removeSimulatorTaskByTaskId(stateElementConfigurationId, changedSimulatorTask.id); // удаляем задачу у параметра у которого она была до изменения
        kernel.addNewSimulatorTaskByParameterId(parameterId, changedSimulatorTask); // добавляем задачу

        const simulatorTasksList = kernel.getSimulatorTasksByParameterId(stateElementConfigurationId);

        setTimeout(() => {
          setDataSentSuccessfully(true);
          setSendingData(false);
        }, 600);

        setTimeout(() => {
          setDataSentSuccessfully(false);
        }, 1400);

        setTask({
          taskList: simulatorTasksList,
          activeTask: simulatorTasksList.length > 0 ? simulatorTasksList[0] : {},
          activeTaskId: simulatorTasksList.length > 0 ? simulatorTasksList[0].id : null,
        });
      } else {
        // изменяем
        kernel.changeSimulatorTaskById(stateElementConfigurationId, simulatorTaskId, changedSimulatorTask);

        const simulatorTasksList = kernel.getSimulatorTasksByParameterId(stateElementConfigurationId);

        setTimeout(() => {
          setDataSentSuccessfully(true);
          setSendingData(false);
        }, 600);

        setTimeout(() => {
          setDataSentSuccessfully(false);
        }, 1400);

        setTask({
          taskList: simulatorTasksList,
          activeTask: simulatorTasksList.find((simulatorTask) => simulatorTask.id === parseInt(changedSimulatorTask.id)),
          activeTaskId: parseInt(changedSimulatorTask.id),
        });
      }
    } catch (error) {
      setSendingData(false);
      setPopUpVisible(false);

      addNotification({ messageText: error.message, statusType: error.status });
    }
  };

  const deleteSimulatorTask = async () => {
    try {
      setStatus({
        ...status,
        loading: true,
      });
      const simulatorTaskId = deleteSimulatorTaskId.current;
      const deletedSimulatorTask = kernel.getSimulatorTasksByParameterIdAndTaskId(stateElementConfigurationId, simulatorTaskId);

      await SimulatorServerService.deleteSimulatorTaskByTaskId(activeConfigurationId, { simulatorTaskId }); // удаление задачи в БД

      kernel.removeSimulatorTaskByTaskId(stateElementConfigurationId, simulatorTaskId); // удаляем из глобальной памяти задачу

      setTimeout(() => {
        setStatus({ loading: false, success: true });
      }, 350);

      setTimeout(() => {
        const simulatorTaskList = kernel.getSimulatorTasksByParameterId(deletedSimulatorTask.parameterId);

        setTask({
          taskList: simulatorTaskList.length > 0 ? simulatorTaskList : [],
          activeTask: simulatorTaskList.length > 0 ? simulatorTaskList[0] : {},
          activeTaskId: simulatorTaskList.length > 0 ? parseInt(simulatorTaskList[0].id) : null,
        });

        setStatus({ loading: false, success: false });
        setPopUpDeleteAlarmVisible(false);
      }, 1300);
    } catch (error) {
      setPopUpDeleteAlarmVisible(false);
      setStatus({ loading: false, success: false });

      addNotification({ messageText: error.message, statusType: error.status });
    }
  };

  const popUpOpen = (event) => {
    deleteSimulatorTaskId.current = event.currentTarget.dataset.id;

    setPopUpDeleteAlarmVisible(true);
  };

  const simulatorListStruct = kernel.getSimulatorTasksByParameterId(stateElementConfigurationId);
  useEffect(() => {
    if (simulatorListStruct && simulatorListStruct.length > 0) {
      const activeSimulatorTask = getActiveSimulatorTask(simulatorListStruct, task?.activeTask?.id);
      const activeSimulatorTaskId = activeSimulatorTask.id;

      setTask({ taskList: simulatorListStruct, activeTask: activeSimulatorTask, activeTaskId: activeSimulatorTaskId });
    } else {
      setTask({ taskList: [], activeTask: {}, activeTaskId: null, isLoaded: false });
    }
    return () => {};
  }, [stateElementConfigurationId]);

  if (classify === 2) {
    return (
      <div className={styles.simulatorEditor}>
        {popUpVisible && (
          <SetNamePopUp
            setNamePopUpVisible={setPopUpVisible}
            onSubmit={createSimulatorTask}
            sendingData={sendingData}
            dataSentSuccessfully={dataSentSuccessfully}
            title="Создание задачи"
            placeholder="Название задачи"
            textButton="Добавить"
          />
        )}
        {popUpDeleteSimulatorTaskVisible && (
          <ConfirmationPopUp
            actionConfirmation={deleteSimulatorTask}
            closePopup={() => setPopUpDeleteAlarmVisible(false)}
            message="Вы точно хотите удалить задачу?"
            loading={status.loading}
            success={status.success}
          />
        )}

        <div className={styles.columnTaskList}>
          <SimulatorTaskList
            state={task}
            handleChangeSimulatorList={handleChangeSimulatorTaskList}
            setPopUpVisible={setPopUpVisible}
            deleteSimulatorTask={popUpOpen}
            canDeleteSimulatorTask={true}
            canAddSimulatorTask={true}
          />
        </div>
        {task.taskList.length > 0 ? (
          <div className={styles.columnTaskForms}>
            <Tabs tabsList={tabList} tabVisible={tabVisible} handleTabVisible={handleTabVisible} />
            <div className={styles.simulatorEditorWrap}>
              {tabVisible === 1 && (
                <TaskGeneralSettings sendingData={sendingData} dataSentSuccessfully={dataSentSuccessfully} task={task} onSubmit={saveOptions} />
              )}
              {tabVisible === 2 && (
                <TaskEditorArguments
                  sendingData={sendingData}
                  dataSentSuccessfully={dataSentSuccessfully}
                  setSendingData={setSendingData}
                  task={task}
                  onSubmit={saveArguments}
                />
              )}
            </div>
          </div>
        ) : (
          <div className={styles.createTaskTextContainer}>
            <p className={styles.createTaskText}>Для данного параметра нет задач!</p>
          </div>
        )}
      </div>
    );
  } else {
    return (
      <div className={styles.simulatorEditor}>
        <div className={styles.createTaskTextContainer}>
          <p className={styles.createTaskText}>Задачи для симуляции можно создавать только для параметров!</p>
        </div>
      </div>
    );
  }
}
