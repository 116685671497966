import { useEffect, useState } from 'react';

import { Loading, TableRowSettingsPermission } from '../components.mjs';
import { RulesService, UsersService } from '../../services/services.mjs';
import { useKernel } from '../../context/ContextKernel.mjs';

import styles from './settingsPermissionObject.module.scss';

export default function SettingsPermissionObject(props) {
  const { stateElementConfigurationId } = props;

  const [accessUserGroups, setAccessUsersGroups] = useState({ groupsList: null, loading: false });
  const { kernel, addNotification } = useKernel();

  const buildRules = async () => {
    setAccessUsersGroups({
      groupsList: null,
      loading: true,
    });

    const configId = kernel.getActiveConfiguration().id;

    Promise.all([
      // получаем пользователей
      UsersService.usersListStruct(),
      // получаем группы пользователей
      UsersService.usersGroupsListStruct(),
      // получаем права из таблицы rules
      RulesService.getRulesListStruct(configId),
    ])
      .then((values) => {
        const [usersListStruct, groupListStruct, rulesListStruct] = values;

        kernel.buildParametersUserTree(usersListStruct.items, groupListStruct.items);
        kernel.buildRules(rulesListStruct.items);

        // функция для получения списка прав для конкретного свойства
        // const rules = kernel.getRulesByParameterId(stateElementConfigurationId, stateElementConfigurationId);
        const userGroups = kernel.getUsersGroups();

        setAccessUsersGroups({
          groupsList: userGroups,
          loading: false,
        });
      })
      .catch((error) => {
        addNotification({ messageText: error.message, statusType: error.status });
        setAccessUsersGroups({
          groupsList: null,
          loading: false,
        });
      });
  };

  useEffect(() => {
    // получение данных
    buildRules();
    return () => {};
  }, [stateElementConfigurationId]);

  return (
    <div className={styles.settingsPermissionObject}>
      <div className={styles.wrapTable}>
        <table className={styles.table}>
          <thead className={styles.tableThead}>
            <tr className={styles.tableTr}>
              <th className={styles.tableTh}>Группа пользователей</th>
              <th className={styles.tableTh}>Права</th>
              <th className={styles.tableTh}></th>
            </tr>
          </thead>
          <tbody className={styles.tableTbody}>
            {accessUserGroups?.groupsList ? (
              accessUserGroups.groupsList.map((group) => {
                return (
                  group.id !== 1 && (
                    <TableRowSettingsPermission
                      key={group.id}
                      group={group}
                    />
                  )
                );
              })
            ) : (
              <Loading />
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}
