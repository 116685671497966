import classNames from 'classnames';
import { useEffect, useRef } from 'react';

import { dateTimeUTCList } from './model/dateTimeUTCList.mjs';
import styles from './timeZoneClient.module.scss';

export default function TimeZoneClient(props) {
  const { value, setValue, canEdit, isWritable, isDeleted, register, unregister, name, setHandleChangeComponentTableRow, sendingData } = props;

  const initialValue = useRef(value);

  const handleChangeValue = ({ target }) => {
    if (target.value.toString() === initialValue.current.toString()) {
      setHandleChangeComponentTableRow(false);
    } else {
      setHandleChangeComponentTableRow(true);
    }
  };

  useEffect(() => {
    setValue(name, value);
    return () => {
      unregister(name);
    };
  }, []);

  useEffect(() => {
    initialValue.current = value;
    return () => {};
  }, [value]);

  return (
    <div className={styles.container}>
      <div className={styles.form}>
        <select
          className={classNames(styles.select, sendingData && styles.sending, canEdit && styles.sending, !isWritable && styles.sending, isDeleted && styles.sending)}
          onInput={handleChangeValue}
          disabled={sendingData || canEdit || !isWritable || isDeleted}
          {...register(name)}
        >
          {dateTimeUTCList.map((dateTimeUTCItem) => {
            return (
              <option className={styles.selectOption} value={dateTimeUTCItem.value}>
                {dateTimeUTCItem.text}
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
}
