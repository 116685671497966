import { Struct } from '../../../Scada/Parser/Struct.mjs';

class ParameterSortStruct extends Struct {

  constructor(
    id = 0n,
    parameterId = 0n,
    sortOrder = 0,
  ) {
    super();
    this.id = id;
    this.parameterId = parameterId;
    this.sortOrder = sortOrder;
  }

  readFrom(dataView, startOffset, isLittleEndian = true) {
    let endOffset = startOffset;
    this.id = dataView.getBigUint64(endOffset, isLittleEndian);
    endOffset += 8;
    this.parameterId = dataView.getBigUint64(endOffset, isLittleEndian);
    endOffset += 8;
    this.sortOrder = dataView.getUint32(endOffset, isLittleEndian);
    endOffset += 4;

    return endOffset;
  }

  writeTo(dataView, startOffset, isLittleEndian = true) {
    let endOffset = startOffset;
    dataView.setBigUint64(endOffset, this.id, isLittleEndian);
    endOffset += 8; 
    dataView.setBigUint64(endOffset, this.parameterId, isLittleEndian);
    endOffset += 8;
    dataView.setUint32(endOffset, this.sortOrder, isLittleEndian);
    endOffset += 4;

    return endOffset;
  }

  getCurrentSize() {
    return 0
      + 8 // id
      + 8 // parameterId
      + 4; // sortOrder
  }

  clone() {
    const clone = new ParameterSortStruct(
      this.id,
      this.parameterId,
      this.sortOrder,
    );
    clone.setParserOptions(this.getParserOptions());
    return clone;
  }
}


export {

  ParameterSortStruct,
};
