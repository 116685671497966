import { Routes, Route } from 'react-router-dom';
import { useEffect } from 'react';

import {
  Authorization,
  ConfigurationSelection,
  PropertyEditor,
  SettingsApp,
  Load,
  Users,
  EventLogs,
  Licenses,
  SectionSelection,
  SettlementServer,
  TaskServer,
  Setpoints,
  Alarm,
  ShowAdminPanel,
  SimulatorServer,
  HandBook,
} from './page/page.mjs';

import { RequireAuth } from './hoc/hoc.mjs';
import { useKernel } from './context/ContextKernel.mjs';
import { LicensesService } from './services/services.mjs';
import { NotificationList } from './components/components.mjs';

function App() {
  const { kernel, setLicense } = useKernel();

  const loadingLicenses = async () => {
    try {
      const licenses = await LicensesService.getCurrentLicense();
      setLicense(licenses);
    } catch (error) {
      console.error('error: ', error);
    }
  };

  useEffect(() => {
    kernel.initialSidebarState();
    loadingLicenses();
    setInterval(() => {
      loadingLicenses();
    }, 300000);
    return () => {};
  }, []);

  return (
    <div className="App">
      <NotificationList />
      <Routes>
        <Route path="/" element={<Authorization />} />
        <Route
          path="/section-selection"
          element={
            <RequireAuth>
              <SectionSelection />
            </RequireAuth>
          }
        />
        <Route
          path="/configuration-selection"
          element={
            <RequireAuth>
              <ConfigurationSelection />
            </RequireAuth>
          }
        />
        <Route
          path="/property-editor"
          element={
            <RequireAuth>
              <PropertyEditor />
            </RequireAuth>
          }
        />

        {/* промежуточная страница load которая авторизует пользователя и перенаправит на страницу */}
        <Route path="/c/showAdminPanel" element={<ShowAdminPanel />} />

        <Route
          path="/settings"
          element={
            <RequireAuth>
              <SettingsApp />
            </RequireAuth>
          }
        />
        <Route
          path="/users"
          element={
            <RequireAuth>
              <Users />
            </RequireAuth>
          }
        />
        <Route
          path="/action-logs"
          element={
            <RequireAuth>
              <EventLogs />
            </RequireAuth>
          }
        />
        <Route
          path="/licenses"
          element={
            <RequireAuth>
              <Licenses />
            </RequireAuth>
          }
        />
        <Route
          path="/settlement-server"
          element={
            <RequireAuth>
              <SettlementServer />
            </RequireAuth>
          }
        />
        <Route
          path="/task-server"
          element={
            <RequireAuth>
              <TaskServer />
            </RequireAuth>
          }
        />
        <Route
          path="/setpoints"
          element={
            <RequireAuth>
              <Setpoints />
            </RequireAuth>
          }
        />
        <Route
          path="/alarm"
          element={
            <RequireAuth>
              <Alarm />
            </RequireAuth>
          }
        />
        <Route
          path="/simulator"
          element={
            <RequireAuth>
              <SimulatorServer />
            </RequireAuth>
          }
        />
        <Route
          path="/reference-book"
          element={
            <RequireAuth>
              <HandBook />
            </RequireAuth>
          }
        />
        <Route path="/loading" element={<Load />} />
      </Routes>
    </div>
  );
}

export default App;
