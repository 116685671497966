import classNames from 'classnames';

import { useKernel } from '../../context/ContextKernel.mjs';

import styles from './navControl.module.scss';

export default function NavControl(props) {
  const { sidebar, sidebarControl } = props;
  const { kernel } = useKernel();

  const setSidebarControl = () => {
    sidebarControl(!sidebar);
    kernel.setSidebar(!sidebar);
  };

  return (
    <div onClick={setSidebarControl} className={`${sidebar ? classNames(styles.navControl, styles.navControlActive) : styles.navControl}`}>
      <span className={styles.navControlItem}></span>
      <span className={styles.navControlItem}></span>
      <span className={styles.navControlItem}></span>
    </div>
  );
}
