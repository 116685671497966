import { Struct } from '../../../Scada/Parser/Struct.mjs';

import { StringStruct } from '../../../Scada/Parser/StringStruct.mjs';

class AlarmStruct extends Struct {
  constructor(
    id = 0,
    typeAlarm = 0,
    parameterId = 0,
    typeSourceAlarm = 0,
    listSourcesAlarm = new StringStruct(''),
    listConstAlarm = new StringStruct(''),
    timeout = 0,
    alarmConfigId = 0,
    workingStatus = 0,
  ) {
    super();
    this.id = id;
    this.typeAlarm = typeAlarm;
    this.parameterId = parameterId;
    this.typeSourceAlarm = typeSourceAlarm;
    this.listSourcesAlarm = listSourcesAlarm;
    this.listConstAlarm = listConstAlarm;
    this.timeout = timeout;
    this.alarmConfigId = alarmConfigId;
    this.workingStatus = workingStatus;
  }


  readFrom(dataView, startOffset, isLittleEndian = true) {
    let endOffset = startOffset;

    this.id = dataView.getUint32(endOffset, isLittleEndian);
    endOffset += 4;
    this.typeAlarm = dataView.getUint32(endOffset, isLittleEndian);
    endOffset += 4;
    this.parameterId = dataView.getUint32(endOffset, isLittleEndian);
    endOffset += 4;
    this.typeSourceAlarm = dataView.getUint32(endOffset, isLittleEndian);
    endOffset += 4;
    endOffset = this.listSourcesAlarm.readFrom(dataView, endOffset, isLittleEndian);
    endOffset = this.listConstAlarm.readFrom(dataView, endOffset, isLittleEndian);
    this.timeout = dataView.getUint32(endOffset, isLittleEndian);
    endOffset += 4;
    this.alarmConfigId = dataView.getUint32(endOffset, isLittleEndian);
    endOffset += 4;
    this.workingStatus = dataView.getUint8(endOffset, isLittleEndian);
    endOffset += 1;

    return endOffset;
  }

  writeTo(dataView, startOffset, isLittleEndian = true) {
    let endOffset = startOffset;

    dataView.setUint32(endOffset, this.id, isLittleEndian);
    endOffset += 4;
    dataView.setUint32(endOffset, this.typeAlarm, isLittleEndian);
    endOffset += 4;
    dataView.setUint32(endOffset, this.parameterId, isLittleEndian);
    endOffset += 4;
    dataView.setUint32(endOffset, this.typeSourceAlarm, isLittleEndian);
    endOffset += 4;
    endOffset = this.listSourcesAlarm.writeTo(dataView, endOffset, isLittleEndian);
    endOffset = this.listConstAlarm.writeTo(dataView, endOffset, isLittleEndian);
    dataView.setUint32(endOffset, this.timeout, isLittleEndian);
    endOffset += 4;
    dataView.setUint32(endOffset, this.alarmConfigId, isLittleEndian);
    endOffset += 4;
    dataView.setUint8(endOffset, this.workingStatus, isLittleEndian);
    endOffset += 1;

    return endOffset;
  }

  getCurrentSize() {
    return 0
      + 4  // id
      + 4  // typeAlarm
      + 4  // parameterId
      + 4  // typeSourceAlarm
      + this.listSourcesAlarm.getCurrentSize()  // listSourcesAlarm
      + this.listConstAlarm.getCurrentSize()  // listConstAlarm
      + 4  // timeout
      + 4  // alarmConfigId
      + 1;  // workingStatus
  }

  clone() {
    const clone = new AlarmStruct(
      this.id,
      this.typeAlarm,
      this.parameterId,
      this.typeSourceAlarm,
      this.listSourcesAlarm.clone(),
      this.listConstAlarm.clone(),
      this.timeout,
      this.alarmConfigId,
      this.workingStatus,
    );
    clone.setParserOptions(this.getParserOptions());
    return clone;
  }
}

export {

  AlarmStruct,
};