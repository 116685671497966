import { setWorkingMode } from './services.mjs';

export class ChangePropertiesService {
  static async setPropertiesInDataBase(arrayBuffer, idConfiguration) {
    return fetch(`${setWorkingMode()}/change-properties/${idConfiguration}`, {
      method: 'post',
      cache: 'reload',
      credentials: 'omit',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/octet-stream',
      },
      body: arrayBuffer,
    });
  }
}
