import { CgShapeRhombus } from 'react-icons/cg';
import classNames from 'classnames';

import styles from './IconValueConfigurationTree.module.scss';

export default function IconValueConfigurationTree(props) {
  const { isDeleted } = props;

  return <CgShapeRhombus className={classNames(styles.iconIsValue, isDeleted && styles.isDeleted) } />;
}
