export const validationSchemeLowerEmergencyLimit = (getValues) => {
  return {
    required: 'Поле обязательно к заполнению!',
    validate: (value) => {
      const str = value.toString();
      if (str.split('')[str.length - 1] === '.') {
        return 'Неправильный формат заполнения!';
      }
      if (str.split('')[0] === '-' && str.split('').length < 2) {
        return 'Неправильный формат заполнения!';
      }
      // min
      if (parseFloat(str) < getValues('lowerInputValue')) {
        return 'Значение не должно быть меньше чем "Нижнее входное значение!"';
      }
      // max
      if (parseFloat(str) > getValues('upperInputValue')) {
        return 'Значение не должно быть больше чем "Верхнее входное значение!"';
      }

      // проверка на допустимость значения границы
      if (parseFloat(str) > getValues('upperEmergencyLimit')) {
        return 'Значение не должно быть больше чем "Верхняя аварийная граница"!';
      }
    },
  };
};

export const validationSchemeLowerWarningLimit = (getValues) => {
  return {
    required: 'Поле обязательно к заполнению!',
    validate: (value) => {
      const str = value.toString();
      if (str.split('')[str.length - 1] === '.') {
        return 'Неправильный формат заполнения!';
      }
      if (str.split('')[0] === '-' && str.split('').length < 2) {
        return 'Неправильный формат заполнения!';
      }
      // min
      if (parseFloat(str) < getValues('lowerInputValue')) {
        return 'Значение не должно быть меньше чем "Нижнее входное значение!"';
      }
      // max
      if (parseFloat(str) > getValues('upperInputValue')) {
        return 'Значение не должно быть больше чем "Верхнее входное значение!"';
      }
      if (parseFloat(str) > getValues('upperWarningLimit')) {
        return 'Значение не должно быть больше чем "Верхняя предупредительная граница"!';
      }
    },
  };
};

export const validationSchemeUpperWarningLimit = (getValues) => {
  return {
    required: 'Поле обязательно к заполнению!',
    validate: (value) => {
      const str = value.toString();
      if (str.split('')[str.length - 1] === '.') {
        return 'Неправильный формат заполнения!';
      }
      if (str.split('')[0] === '-' && str.split('').length < 2) {
        return 'Неправильный формат заполнения!';
      }
      // min
      if (parseFloat(str) < getValues('lowerInputValue')) {
        return 'Значение не должно быть меньше чем "Нижнее входное значение!"';
      }
      // max
      if (parseFloat(str) > getValues('upperInputValue')) {
        return 'Значение не должно быть больше чем "Верхнее входное значение!"';
      }

      if (parseFloat(str) < getValues('lowerWarningLimit')) {
        return 'Значение не должно быть меньше чем "Нижняя предупредительная граница"!';
      }
    },
  };
};

export const validationSchemeUpperEmergencyLimit = (getValues) => {
  return {
    required: 'Поле обязательно к заполнению!',
    validate: (value) => {
      const str = value.toString();
      if (str.split('')[str.length - 1] === '.') {
        return 'Неправильный формат заполнения!';
      }
      if (str.split('')[0] === '-' && str.split('').length < 2) {
        return 'Неправильный формат заполнения!';
      }
      // min
      if (parseFloat(str) < getValues('lowerInputValue')) {
        return 'Значение не должно быть меньше чем "Нижнее входное значение!"';
      }
      // max
      if (parseFloat(str) > getValues('upperInputValue')) {
        return 'Значение не должно быть больше чем "Верхнее входное значение!"';
      }

      // проверка на допустимость значения границы
      if (parseFloat(str) < getValues('lowerEmergencyLimit')) {
        return 'Значение не должно быть больше чем "Нижняя аварийная граница"!';
      }
    },
  };
};

export const validationSchemeLowerInputValue = (getValues) => {
  return {
    required: 'Поле обязательно к заполнению!',
    validate: (value) => {
      const str = value.toString();
      if (str.split('')[str.length - 1] === '.') {
        return 'Неправильный формат заполнения!';
      }
      if (str.split('')[0] === '-' && str.split('').length < 2) {
        return 'Неправильный формат заполнения!';
      }
      if (parseFloat(str) >= getValues('upperInputValue')) {
        return 'Значение не должно быть больше чем "Верхнее входное значение!"';
      }
    },
  };
};

export const validationSchemeLowerOutputValue = (getValues) => {
  return {
    required: 'Поле обязательно к заполнению!',
    validate: (value) => {
      const str = value.toString();
      if (str.split('')[str.length - 1] === '.') {
        return 'Неправильный формат заполнения!';
      }
      if (str.split('')[0] === '-' && str.split('').length < 2) {
        return 'Неправильный формат заполнения!';
      }
      if (parseFloat(str) >= parseFloat(getValues('upperOutputValue'))) {
        return 'Значение не должно быть больше чем "Верхнее выходное значение!"';
      }
    },
  };
};

export const validationSchemeUpperInputValue = (getValues) => {
  return {
    required: 'Поле обязательно к заполнению!',
    validate: (value) => {
      const str = value.toString();
      if (str.split('')[str.length - 1] === '.') {
        return 'Неправильный формат заполнения!';
      }
      if (str.split('')[0] === '-' && str.split('').length < 2) {
        return 'Неправильный формат заполнения!';
      }
      if (parseFloat(str) <= getValues('lowerInputValue')) {
        return 'Значение не должно быть меньше чем "Нижнее входное значение!"';
      }
    },
  };
};

export const validationSchemeUpperOutputValue = (getValues) => {
  return {
    required: 'Поле обязательно к заполнению!',
    validate: (value) => {
      const str = value.toString();
      if (str.split('')[str.length - 1] === '.') {
        return 'Неправильный формат заполнения!';
      }
      if (str.split('')[0] === '-' && str.split('').length < 2) {
        return 'Неправильный формат заполнения!';
      }
      if (parseFloat(str) <= getValues('lowerOutputValue')) {
        return 'Значение не должно быть меньше чем "Нижнее выходное значение!"';
      }
    },
  };
};