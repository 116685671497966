import { memo, useCallback, useEffect, useState } from 'react';
import { LuSettings2 } from 'react-icons/lu';
import { AiFillCode } from 'react-icons/ai';

import { Tabs } from '../tabs/Tabs';

import { CreateNewPropertyForm, CreatingCustomParametersForms, CreatingElementsUsingTemplatesForms, CustomObjectForm } from './ui/index.mjs';
import styles from './createNewConfigurationElementForm.module.scss';

const createTabList = (parameterStructExtended) => {
  return [
    {
      id: 1,
      icon: <LuSettings2 className="tabListIcon" />,
      text: 'Шаблоны',
      renderingConditions: true,
      disabled: false,
      // disabled: parameterStructExtended.isValue,
    },
    {
      id: 2,
      icon: <AiFillCode className="tabListIcon" />,
      text: 'Объект',
      renderingConditions: true,
      disabled: false,
      // disabled: parameterStructExtended.isValue,
    },
    {
      id: 3,
      icon: <AiFillCode className="tabListIcon" />,
      text: 'Параметр',
      renderingConditions: true,
      disabled: parameterStructExtended.isValue,
    },
    {
      id: 4,
      icon: <AiFillCode className="tabListIcon" />,
      text: 'Свойство',
      renderingConditions: true,
      disabled: false,
    },
  ];
};

export default memo(function CreateNewConfigurationElementForm(props) {
  const { setComponentsTypeId, setConfiguration, selectedParameter, searchState } = props;
  const [tabVisible, setTabVisible] = useState(1);

  const handleTabVisible = useCallback((event) => {
    const tabId = parseInt(event.currentTarget.dataset.tab);
    setTabVisible(tabId);
  }, []);

  useEffect(() => {
    if (selectedParameter) {
      if (selectedParameter.isValue && tabVisible === 3) {
        setTabVisible(1);
      }
    }
    return () => {};
  }, [selectedParameter]);

  return (
    <div className={styles.container}>
      <Tabs tabsList={createTabList(selectedParameter)} tabVisible={tabVisible} handleTabVisible={handleTabVisible} />

      <div className={styles.formsContainer}>
        {tabVisible === 1 && (
          <CreatingElementsUsingTemplatesForms
            setComponentsTypeId={setComponentsTypeId}
            setConfiguration={setConfiguration}
            selectedParameter={selectedParameter}
            searchState={searchState}
          />
        )}
        {tabVisible === 2 && (
          <CustomObjectForm
            setComponentsTypeId={setComponentsTypeId}
            setConfiguration={setConfiguration}
            selectedParameter={selectedParameter}
            searchState={searchState}
          />
        )}
        {tabVisible === 3 && (
          <CreatingCustomParametersForms
            setComponentsTypeId={setComponentsTypeId}
            setConfiguration={setConfiguration}
            selectedParameter={selectedParameter}
            searchState={searchState}
          />
        )}
        {tabVisible === 4 && (
          <CreateNewPropertyForm
            setComponentsTypeId={setComponentsTypeId}
            setConfiguration={setConfiguration}
            selectedParameter={selectedParameter}
            searchState={searchState}
          />
        )}
      </div>
    </div>
  );
});
