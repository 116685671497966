import { Struct } from '../../../Scada/Parser/Struct.mjs';
import { StringStruct } from '../../../Scada/Parser/StringStruct.mjs';

class SetPointsStruct extends Struct {
  constructor(
    id = 0,
    systemTypeId = 0,
    parameterId = 0n,
    value_1 = 0n,
    value_2 = 0n,
    value_3 = 0n,
    value_4 = 0n,
    value_5 = 0n,
    value_6 = 0n,
    typeAlarm = 0,
    typeSourceAlarm = 0,
    name = new StringStruct(''),
    comment = new StringStruct(''),
  ) {
    super();
    this.id = id;
    this.systemTypeId = systemTypeId;
    this.parameterId = parameterId;
    this.value_1 = value_1;
    this.value_2 = value_2;
    this.value_3 = value_3;
    this.value_4 = value_4;
    this.value_5 = value_5;
    this.value_6 = value_6;
    this.typeAlarm = typeAlarm;
    this.typeSourceAlarm = typeSourceAlarm;
    this.name = name;
    this.comment = comment;
  }


  readFrom(dataView, startOffset, isLittleEndian = true) {
    let endOffset = startOffset;
    this.id = dataView.getUint32(endOffset, isLittleEndian);
    endOffset += 4;
    this.systemTypeId = dataView.getUint32(endOffset, isLittleEndian);
    endOffset += 4;
    this.parameterId = dataView.getBigUint64(endOffset, isLittleEndian);
    endOffset += 8;
    this.value_1 = dataView.getBigUint64(endOffset, isLittleEndian);
    endOffset += 8;
    this.value_2 = dataView.getBigUint64(endOffset, isLittleEndian);
    endOffset += 8;
    this.value_3 = dataView.getBigUint64(endOffset, isLittleEndian);
    endOffset += 8;
    this.value_4 = dataView.getBigUint64(endOffset, isLittleEndian);
    endOffset += 8;
    this.value_5 = dataView.getBigUint64(endOffset, isLittleEndian);
    endOffset += 8;
    this.value_6 = dataView.getBigUint64(endOffset, isLittleEndian);
    endOffset += 8;
    this.typeAlarm = dataView.getUint32(endOffset, isLittleEndian);
    endOffset += 4;
    this.typeSourceAlarm = dataView.getUint32(endOffset, isLittleEndian);
    endOffset += 4;
    endOffset = this.name.readFrom(dataView, endOffset, isLittleEndian);
    endOffset = this.comment.readFrom(dataView, endOffset, isLittleEndian);

    return endOffset;
  }

  writeTo(dataView, startOffset, isLittleEndian = true) {
    let endOffset = startOffset;
    dataView.setUint32(endOffset, this.id, isLittleEndian);
    endOffset += 4;
    dataView.setUint32(endOffset, this.systemTypeId, isLittleEndian);
    endOffset += 4;
    dataView.setBigUint64(endOffset, this.parameterId, isLittleEndian);
    endOffset += 8;
    dataView.setBigUint64(endOffset, this.value_1, isLittleEndian);
    endOffset += 8;
    dataView.setBigUint64(endOffset, this.value_2, isLittleEndian);
    endOffset += 8;
    dataView.setBigUint64(endOffset, this.value_3, isLittleEndian);
    endOffset += 8;
    dataView.setBigUint64(endOffset, this.value_4, isLittleEndian);
    endOffset += 8;
    dataView.setBigUint64(endOffset, this.value_5, isLittleEndian);
    endOffset += 8;
    dataView.setBigUint64(endOffset, this.value_6, isLittleEndian);
    endOffset += 8;
    dataView.setUint32(endOffset, this.typeAlarm, isLittleEndian);
    endOffset += 4;
    dataView.setUint32(endOffset, this.typeSourceAlarm, isLittleEndian);
    endOffset += 4;
    endOffset = this.name.writeTo(dataView, endOffset, isLittleEndian);
    endOffset = this.comment.writeTo(dataView, endOffset, isLittleEndian);

    return endOffset;
  }

  getCurrentSize() {
    return 0
      + 4 // id
      + 4 // systemTypeId
      + 8 // parameterId
      + 8 // value_1
      + 8 // value_2
      + 8 // value_3
      + 8 // value_4
      + 8 // value_5
      + 8 // value_6
      + 4 // typeAlarm
      + 4 // typeSourceAlarm
      + this.name.getCurrentSize() // name
      + this.comment.getCurrentSize(); // comment
  }

  clone() {
    let clone = new SetPointsStruct(
      this.id,
      this.systemTypeId,
      this.parameterId,
      this.value_1,
      this.value_2,
      this.value_3,
      this.value_4,
      this.value_5,
      this.value_6,
      this.typeAlarm,
      this.typeSourceAlarm,
      this.name.clone(),
      this.comment.clone(),
    )
    clone.setParserOptions(this.getParserOptions());
    return clone;
  }
}

export {

  SetPointsStruct,
};