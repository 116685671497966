import { useEffect, useRef, useState } from 'react';
import { HiCheck } from 'react-icons/hi';
import classNames from 'classnames';

import { useKernel } from '../../context/ContextKernel.mjs';
import { SavePropertiesButtonModuleRs } from '../components.mjs';
import Utils from '../../utils/Utils.mjs';

import { options } from './model/options.mjs';
import styles from './tableRowReportingHourModuleRs.module.scss';

export default function TableRowReportingHourModuleRs(props) {
  const { selectedParameter } = props;

  const [handleChangeComponentTableRow, setHandleChangeComponentTableRow] = useState(false);
  const [sendingData, setSendingData] = useState(false);
  const [dataSentSuccessfully, setDataSentSuccessfully] = useState(false);
  const [errorParse, setErrorParse] = useState(false);
  const [changesDate, setChangesDate] = useState({ day: '', hour: '' }); // вывод даты и времени последнего изменения свойства (выводится время драйвер адреса)
  const [state, setState] = useState(); // состояние компонента
  const initialValue = useRef();
  const { kernel, addNotification, stateElementConfigurationId } = useKernel();

  const saveProperty = async () => {
    setHandleChangeComponentTableRow(false);
    setSendingData(true);

    try {
      const newChildrenDefaultValues = Utils.setNewDefaultValue(selectedParameter, state, 'reportingHour');

      await kernel.setValues([...newChildrenDefaultValues]);
      initialValue.current = state;

      setDataSentSuccessfully(true);
      setTimeout(() => {
        setDataSentSuccessfully(false);
        setSendingData(false);
      }, 1500);
    } catch (error) {
      setSendingData(false);
      addNotification({ messageText: error.message, statusType: error.status });
      setHandleChangeComponentTableRow(true);
    }
  };

  const handleChangeMainSelect = (event) => {
    if (event.target.value.toString() === initialValue.current.toString()) {
      setHandleChangeComponentTableRow(false);
    } else {
      setHandleChangeComponentTableRow(true);
    }
    setState(event.target.value);
  };

  useEffect(() => {
    // получаем значения дочерних свойств (default value)
    const defaultValueParameters = Utils.getPropertiesDefaultValueInModuleRs(selectedParameter);

    const day = Utils.convertTimestamp(defaultValueParameters[0].propertyValueTimestamp);
    const hour = Utils.convertTimestampGetHour(defaultValueParameters[0].propertyValueTimestamp);
    setChangesDate({ day: day, hour: hour });

    const defaultValues = defaultValueParameters.map((defaultValueParameter) => {
      const valueUint32 = defaultValueParameter.propertyValueRoundUp;
      return Utils.splitDefaultValue(valueUint32);
    });

    const reportingHour = defaultValues[0].reportingHour;
    const reportingHourCheck = defaultValues.every((defaultValue) => defaultValues[0].reportingHour === defaultValue.reportingHour);

    if (reportingHourCheck) {
      setState(reportingHour);
      initialValue.current = reportingHour;
    } else {
      setErrorParse(true);
      setState(16);
      initialValue.current = 16;
    }

    return () => {};
  }, [stateElementConfigurationId]);

  return (
    <tr className={styles.tableRow}>
      <td className="tableRowPropertyTd">
        <span className={styles.tableRowText} title={'Отчетный час'}>
          Отчетный час
        </span>
      </td>
      <td className="tableRowPropertyTd">
        <div className={styles.componentContainer}>
          {/* компонент */}
          <select
            className={classNames(styles.reportingHourSelect, styles.mainSelect, sendingData && styles.inputrepRtingHourSending)}
            value={state}
            onInput={handleChangeMainSelect}
            disabled={sendingData}
          >
            <>
              {errorParse && (
                <option className={classNames(styles.reportingHourSelectOption, styles.mainSelectOption)} key={16} value={16} disabled>
                  значение не распознано
                </option>
              )}
              {options.map((item) => {
                return (
                  <option className={classNames(styles.reportingHourSelectOption, styles.mainSelectOption)} key={item.value} value={item.value}>
                    {item.text}
                  </option>
                );
              })}
            </>
          </select>
          {/* компонент */}
        </div>
      </td>
      <td className="tableRowPropertyTd">
        <span className={classNames(styles.tableRowText, styles.tableDate)}>{changesDate.day}</span>
        <span className={styles.tableRowText}>{changesDate.hour}</span>
      </td>
      <td className="tableRowPropertyTd">
        {dataSentSuccessfully ? (
          <HiCheck className={styles.checkControl} />
        ) : (
          <SavePropertiesButtonModuleRs
            sendingData={sendingData}
            handleChangeComponentTableRow={handleChangeComponentTableRow}
            saveProperty={saveProperty}
          />
        )}
      </td>
    </tr>
  );
}
