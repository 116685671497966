import { InvalidSyntax } from './InvalidSyntax.mjs'

function errorUnexpectedToken(tokenizer, token, expected) {
    throw new InvalidSyntax(
        tokenizer.source,
        token
            ? token.position
            : tokenizer.source.length,
        token
            ? `unexpected token '${token.value}': ${expected} expected`
            : `unexpected end of source: ${expected} expected`
    )
}

const completeSource = (tokenizer, main) => {
    const ast = main(tokenizer)
    const token = tokenizer.top()
    if (token) {
        errorUnexpectedToken(tokenizer, token, 'end')
    }
    return ast
}

export {
    errorUnexpectedToken,
    completeSource
}
