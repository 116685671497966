import jwt_decode from 'jwt-decode';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { ShowAdminPanelService } from '../../services/ShowAdminPanelService.mjs';
import { ErrorMessagePopup } from '../../components/components.mjs';
import { useKernel } from '../../context/ContextKernel.mjs';

import styles from './showAdminPanel.module.scss';

export default function ShowAdminPanel() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const tokenScada = searchParams.get('token');

  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('Ошибка!');
  const { kernel, setStateElementConfigurationId } = useKernel();

  const showAdminPanel = async () => {
    try {
      const settings = {
        isRootUser: false,
        hasParameterIdInTree: false,
      };
      const { accessToken, configId, parameterId } = await ShowAdminPanelService.showAdminPanel({ tokenScada, settings });
      localStorage.setItem('token', accessToken);

      const user = jwt_decode(accessToken);
      user.token = accessToken;
      kernel.authUser(user);

      delete settings.isRootUser;
      localStorage.setItem('settings', JSON.stringify(settings));
      localStorage.setItem('activeIdConfig', JSON.stringify({ id: configId }));

      setStateElementConfigurationId(BigInt(parameterId));

      return navigate('/property-editor');
    } catch (error) {
      setIsError(true);
      setErrorMessage(error.message);
    }
  };

  useEffect(() => {
    showAdminPanel();
    return () => {};
  }, []);

  return (
    <div className={styles.preload}>
      {isError && (
        <ErrorMessagePopup
          errorMessage={errorMessage}
          actionPerform={() => {
            navigate('/', { replace: true });
            setIsError(false);
          }}
        />
      )}
      <div className={styles.preloadContainer}>
        <div className={styles.preloadLogo}>
          <svg className={styles.preloadLogoImg} width="70" height="60" viewBox="0 0 71 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M20.8782 27.3351L26.9959 32.8878L39.204 16.7348L57.9473 31.066L70.1064 27.4744L35.774 -1.81136e-05L20.8782 27.3351Z"
              fill="white"
            />
            <path
              d="M49.3199 31.7549L43.221 26.2171L31.0012 42.3609L12.3105 28.0714L0.162312 31.6716L34.3961 59.0679L49.3199 31.7549Z"
              fill="white"
            />
          </svg>
          <h1 className={styles.preloadLogoName}>ООО Арквантум</h1>
        </div>
        <div className={styles.preloadLoading}>
          <span className={styles.loader}></span>
        </div>
      </div>
    </div>
  );
}
