import { Struct } from '../../../Scada/Parser/Struct.mjs';
import { StringStruct } from '../../../Scada/Parser/StringStruct.mjs';

class SimulatorTaskStruct extends Struct {
  constructor(
    id = 0,
    workingStatus = 0,
    nameTask = new StringStruct(''),
    parameterId = 0n,
    timestampCreate = 0, //4 uint 32
    timestampTo = 0,
    timestampFrom = 0,
    simulatorType = 0,
    frequency = 0,
    period = 0,
    rawValue = 0n, //8 bigInt64
    strValue = new StringStruct(''),
    rawValue_1 = 0n,
    strValue_1 = new StringStruct(''),
    rawValue_2 = 0n,
    strValue_2 = new StringStruct(''),
  ) {
    super();
    this.id = id;
    this.workingStatus = workingStatus;
    this.nameTask = nameTask;
    this.parameterId = parameterId;
    this.timestampCreate = timestampCreate;
    this.timestampTo = timestampTo;
    this.timestampFrom = timestampFrom;
    this.simulatorType = simulatorType;
    this.frequency = frequency;
    this.period = period;
    this.rawValue = rawValue;
    this.strValue = strValue;
    this.rawValue_1 = rawValue_1;
    this.strValue_1 = strValue_1;
    this.rawValue_2 = rawValue_2;
    this.strValue_2 = strValue_2;
  }


  readFrom(dataView, startOffset, isLittleEndian = true) {
    let endOffset = startOffset;

    this.id = dataView.getUint32(endOffset, isLittleEndian);
    endOffset += 4;
    this.workingStatus = dataView.getUint8(endOffset, isLittleEndian);
    endOffset += 1;
    endOffset = this.nameTask.readFrom(dataView, endOffset, isLittleEndian);
    this.parameterId = dataView.getBigUint64(endOffset, isLittleEndian);
    endOffset += 8;
    this.timestampCreate = dataView.getUint32(endOffset, isLittleEndian);
    endOffset += 4;
    this.timestampTo = dataView.getUint32(endOffset, isLittleEndian);
    endOffset += 4;
    this.timestampFrom = dataView.getUint32(endOffset, isLittleEndian);
    endOffset += 4;
    this.simulatorType = dataView.getUint32(endOffset, isLittleEndian);
    endOffset += 4;
    this.frequency = dataView.getUint32(endOffset, isLittleEndian);
    endOffset += 4;
    this.period = dataView.getUint32(endOffset, isLittleEndian);
    endOffset += 4;
    this.rawValue = dataView.getBigUint64(endOffset, isLittleEndian);
    endOffset += 8;
    endOffset = this.strValue.readFrom(dataView, endOffset, isLittleEndian);
    this.rawValue_1 = dataView.getBigUint64(endOffset, isLittleEndian);
    endOffset += 8;
    endOffset = this.strValue_1.readFrom(dataView, endOffset, isLittleEndian);
    this.rawValue_2 = dataView.getBigUint64(endOffset, isLittleEndian);
    endOffset += 8;
    endOffset = this.strValue_2.readFrom(dataView, endOffset, isLittleEndian);

    return endOffset;
  }

  writeTo(dataView, startOffset, isLittleEndian = true) {
    let endOffset = startOffset;

    dataView.setUint32(endOffset, this.id, isLittleEndian);
    endOffset += 4;
    dataView.setUint8(endOffset, this.workingStatus, isLittleEndian);
    endOffset += 1;
    endOffset = this.nameTask.writeTo(dataView, endOffset, isLittleEndian);
    dataView.setBigUint64(endOffset, this.parameterId, isLittleEndian);
    endOffset += 8;
    dataView.setUint32(endOffset, this.timestampCreate, isLittleEndian);
    endOffset += 4;
    dataView.setUint32(endOffset, this.timestampTo, isLittleEndian);
    endOffset += 4;
    dataView.setUint32(endOffset, this.timestampFrom, isLittleEndian);
    endOffset += 4;
    dataView.setUint32(endOffset, this.simulatorType, isLittleEndian);
    endOffset += 4;
    dataView.setUint32(endOffset, this.frequency, isLittleEndian);
    endOffset += 4;
    dataView.setUint32(endOffset, this.period, isLittleEndian);
    endOffset += 4;
    dataView.setBigUint64(endOffset, this.rawValue, isLittleEndian);
    endOffset += 8;
    endOffset = this.strValue.writeTo(dataView, endOffset, isLittleEndian);
    dataView.setBigUint64(endOffset, this.rawValue_1, isLittleEndian);
    endOffset += 8;
    endOffset = this.strValue_1.writeTo(dataView, endOffset, isLittleEndian);
    dataView.setBigUint64(endOffset, this.rawValue_2, isLittleEndian);
    endOffset += 8;
    endOffset = this.strValue_2.writeTo(dataView, endOffset, isLittleEndian);

    return endOffset;
  }

  getCurrentSize() {
    return 0
      + 4// id
      + 1// workingStatus
      + this.nameTask.getCurrentSize()//nameTask
      + 8// parameterId
      + 4// timestampCreate
      + 4// timestampTo
      + 4// timestampFrom
      + 4// simulatorType
      + 4// frequency
      + 4// period
      + 8// rawValue
      + this.strValue.getCurrentSize()// strValue
      + 8// rawValue_1
      + this.strValue_1.getCurrentSize()// strValue_1
      + 8// rawValue_2
      + this.strValue_2.getCurrentSize() // strValue_2
  }

  clone() {
    const clone = new SimulatorTaskStruct(
      this.id,
      this.workingStatus,
      this.nameTask.clone(),
      this.timestampCreate,
      this.timestampTo,
      this.timestampFrom,
      this.simulatorType,
      this.frequency,
      this.period,
      this.rawValue,
      this.strValue.clone(),
      this.rawValue_1,
      this.strValue_1.clone(),
      this.rawValue_2,
      this.strValue_2.clone(),
    );
    clone.setParserOptions(this.getParserOptions());
    return clone;
  }
}

export {

  SimulatorTaskStruct,
};