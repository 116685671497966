import styles from './configurationList.module.scss';

export default function ConfigurationList(props) {
  const { configurationData, redirect } = props;

  return (
    <div className={styles.configurationContainer}>
      {configurationData.map((item) => (
        <div key={item.id} className={styles.configurationListItem} id={item.id} onClick={(event) => redirect(event.currentTarget.id)}>
          <div className={styles.configurationListItemId}>
            <span className={styles.configurationListItemText}> {item.id}</span>
          </div>
          <div className={styles.configurationListItemName}>
            <span className={styles.configurationListItemText}>{item.name.value}</span>
          </div>
        </div>
      ))}
    </div>
  );
}
