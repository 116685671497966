import { memo } from 'react';

import styles from './switchToggle.module.scss';

export default memo(function SwitchToggle(props) {
  const { checked, setChecked = () => {}, callback, disabled = false } = props;

  const handleSwitchToggle = () => {
    if (!disabled) {
      callback();
      setChecked((prev) => !prev);
    }
  };

  return (
    <div class={styles.toggle} onClick={handleSwitchToggle}>
      <input class={styles.toggleState} type="checkbox" name="check" checked={checked} />
      <div class={styles.toggleInner}>
        <div class={styles.indicator}></div>
      </div>
      <div class={styles.activeBg}></div>
    </div>
  );
});
