export const preloadList = () => {
  const clientWidth = window.screen.width;
  if (clientWidth < 1600) {
    return [
      { id: 1, width: '350px' },
      { id: 2, width: '310px' },
      { id: 3, width: '205px' },
      { id: 4, width: '205px' },
      { id: 5, width: '235px' },
      { id: 6, width: '310px' },
      { id: 7, width: '110px' },
      { id: 8, width: '165px' },
      { id: 9, width: '350px' },
      { id: 10, width: '235px' },
      { id: 12, width: '235px' },
      { id: 13, width: '310px' },
      { id: 14, width: '235px' },
      { id: 15, width: '310px' },
      { id: 16, width: '110px' },
      { id: 17, width: '165px' },
      { id: 18, width: '350px' },
      { id: 19, width: '350px' },
      { id: 20, width: '350px' },
      { id: 21, width: '205px' },
      { id: 22, width: '310px' },
    ];
  } else {
    return [
      { id: 1, width: '375px' },
      { id: 2, width: '335px' },
      { id: 3, width: '230px' },
      { id: 4, width: '230px' },
      { id: 5, width: '260px' },
      { id: 6, width: '335px' },
      { id: 7, width: '135px' },
      { id: 8, width: '190px' },
      { id: 9, width: '375px' },
      { id: 10, width: '260px' },
      { id: 12, width: '260px' },
      { id: 13, width: '335px' },
      { id: 14, width: '260px' },
      { id: 15, width: '335px' },
      { id: 16, width: '135px' },
      { id: 17, width: '190px' },
      { id: 18, width: '360px' },
      { id: 19, width: '375px' },
      { id: 20, width: '375px' },
      { id: 21, width: '230px' },
      { id: 22, width: '335px' },
    ];
  }
};