import jwt_decode from 'jwt-decode';
import { useEffect, useState } from 'react';

import { AuthService } from '../../services/services.mjs';
import { AuthFormPopUp, SwitchToggle } from '../../components/components.mjs';
import { useKernel } from '../../context/ContextKernel.mjs';
import { ContentApp, HeaderApp, NavApp } from '../../layout/layout.mjs';

import styles from './settingsApp.module.scss';

export default function SettingsApp() {
  const { kernel, addNotification } = useKernel();
  const settings = kernel.getUser().settings;
  const [isRootUser, setIsRootUser] = useState(settings.isRootUser);
  const [hasParameterIdInTree, setHasParameterIdInTree] = useState(settings.hasParameterIdInTree);
  const [popUpState, setPopUpState] = useState({ visible: false, loading: false, success: false });

  const changeHasParameterIdInTree = async () => {
    try {
      const settings = kernel.getUser().settings;
      settings.hasParameterIdInTree = !settings.hasParameterIdInTree;
      const response = await AuthService.changeUserSettingsInToken(settings);
      const token = response.data.accessToken;
      const user = jwt_decode(token);
      user.token = token;
      kernel.authUser(user);
      setHasParameterIdInTree((prev) => !prev);

      localStorage.setItem('settings', JSON.stringify({ hasParameterIdInTree: user.settings.hasParameterIdInTree }));
      localStorage.setItem('token', token);
    } catch (error) {
      setHasParameterIdInTree((prev) => !prev);
      addNotification({ messageText: 'Ошибка изменения настроек!', statusType: 2 });
    }
  };

  const changeIsRootUser = async (data) => {
    try {
      const settings = kernel.getUser().settings;
      settings.isRootUser = !settings.isRootUser;
      data.settings = settings;
      data.name = kernel.user.name;

      const response = await AuthService.login(data); //тут получу токен
      const token = response.data.accessToken;
      localStorage.setItem('token', token);

      const user = jwt_decode(token);
      user.token = token;
      kernel.authUser(user);

      setIsRootUser((prev) => !prev);
      setPopUpState({ ...popUpState, visible: false });
    } catch (error) {
      setPopUpState({ ...popUpState, visible: false });
      addNotification({ messageText: 'Ошибка изменения настроек!', statusType: 2 });
    }
  };

  const popUpVisible = async () => {
    if (!isRootUser) {
      setPopUpState({ ...popUpState, visible: true });
    } else {
      try {
        const settings = kernel.getUser().settings;
        settings.isRootUser = !settings.isRootUser;
        const response = await AuthService.changeUserSettingsInToken(settings);
        const token = response.data.accessToken;
        localStorage.setItem('token', token);
        
        const user = jwt_decode(token);
        user.token = token;
        kernel.authUser(user);

        setIsRootUser((prev) => !prev);
      } catch (error) {
        addNotification({ messageText: 'Ошибка изменения настроек!', statusType: 2 });
      }
    }
  };

  useEffect(() => {
    kernel.timerRefreshToken(); //запуск обновления токена
    return () => {};
  }, []);

  return (
    <div className={styles.page}>
      {popUpState.visible && (
        <AuthFormPopUp
          closePopup={() => setPopUpState({ ...popUpState, visible: false })}
          success={popUpState.success}
          textButton="Получить доступ"
          loading={popUpState.loading}
          actionConfirmation={changeIsRootUser}
          hasField={{ name: false, password: true }}
        />
      )}
      <NavApp />
      <ContentApp>
        <div className={styles.headerContainer}>
          <HeaderApp configurationName="Настройки приложения" userName={kernel.getUser().name} />
        </div>
        <div className={styles.contentContainer}>
          <div className={styles.container}>
            <div className={styles.row}>
              <span className={styles.text} title="Режим суперпользователя">
                Режим суперпользователя
              </span>
              <SwitchToggle checked={isRootUser} callback={popUpVisible} />
            </div>
            <div className={styles.row}>
              <span className={styles.text} title="Показывать id в дереве конфигурации">
                Показывать id в дереве конфигурации
              </span>
              <div className={styles.control}>
                <SwitchToggle checked={hasParameterIdInTree} callback={changeHasParameterIdInTree} />
              </div>
            </div>
          </div>
        </div>
      </ContentApp>
    </div>
  );
}
