import Utils from "../../../utils/Utils.mjs";

class Logs {
  constructor(kernel, logsStruct) {
    this.kernel = kernel;
    this.logsStruct = logsStruct;
  }

  get id() {
    return this.logsStruct.id;
  }

  get codeOperation() {
    return this.logsStruct.codeOperation;
  }

  get alarmTypeByCodeOperations() {
    return this.kernel.getCodesOperationById(this.codeOperation);
  }

  get comment() {
    return this.logsStruct.comment.value;
  }

  get datetimeEvent() {
    return this.logsStruct.datetimeEvent;
  }

  get dateEvent() {
    return Utils.convertTimestamp(this.datetimeEvent);
  }

  get hourEvent() {
    return Utils.convertTimestampGetHour(this.datetimeEvent);
  }

  get ipAddress() {
    return this.logsStruct.ipAddress.value;
  }

  get newValue() {
    return this.logsStruct.newValue.value;
  }

  get objectId() {
    return this.logsStruct.objectId;
  }

  get oldValue() {
    return this.logsStruct.oldValue.value;
  }

  get par_1() {
    return this.logsStruct.par_1;
  }

  get par_2() {
    return this.logsStruct.par_2;
  }

  get par_3() {
    return this.logsStruct.par_3;
  }

  get par_4() {
    return this.logsStruct.par_4;
  }

  get par_5() {
    return this.logsStruct.par_5;
  }

  get sourceId() {
    return this.logsStruct.sourceId;
  }

  get userById() {
    return this.kernel.getUserById(this.sourceId);
  }

  get statusOperation() {
    return this.logsStruct.statusOperation;
  }

  get uuidLicense() {
    return this.logsStruct.uuidLicense.value;
  }

  get eventLocation() {
    return this.kernel.getLicenseById(this.uuidLicense);
  }

  get columnDate() {
    return this.dateEvent;
  }

  get columnHour() {
    return this.hourEvent;
  }

  get columnAlarmType() {
    return this.alarmTypeByCodeOperations.comment;
  }

  get columnEventLocation() {
    return this.eventLocation.name;
  }

  get columnUsername() {
    return this.userById.name;
  }

  get columnIpAddress() {
    return this.ipAddress;
  }

  get columnMessage() {
    return this.comment;
  }
}

export { Logs };