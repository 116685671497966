import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import jwt_decode from 'jwt-decode';

import { AuthService } from '../../services/services.mjs';
import { useKernel } from '../../context/ContextKernel.mjs';

import styles from './load.module.scss';

export default function Load() {
  const location = useLocation();
  const navigate = useNavigate();
  const { kernel, currentLicense } = useKernel();
  const fromPage = location.state?.from?.pathname || '/';
  const token = localStorage.getItem('token');

  const {
    hasAccessUsersModule = undefined,
    hasAccessLicensesModule = undefined,
    hasAccessCalculationServerModule = undefined,
    hasAccessTaskServerModule = undefined,
    hasAccessSetpointsModule = undefined,
    hasAccessAlarmServerModule = undefined,
  } = currentLicense;

  const accessPath = {
    '/users': hasAccessUsersModule,
    '/licenses': hasAccessLicensesModule,
    '/settlement-server': hasAccessCalculationServerModule,
    '/task-server': hasAccessTaskServerModule,
    '/setpoints': hasAccessSetpointsModule,
    '/alarm': hasAccessAlarmServerModule,
  };

  useEffect(() => {
    const redirectPage = () => {
      if (accessPath[fromPage] !== undefined && accessPath[fromPage] === 'true') {
        return navigate(fromPage, { replace: false });
      }else{
        return navigate("/section-selection");
      }
    };
    const redirectLoginPage = () => {
      kernel.removeTimerRefreshToken();
      localStorage.removeItem('token');
      localStorage.removeItem('activeIdConfig');
      kernel.logoutUser();
      navigate('/', { replace: true });
    };

    const validUser = async () => {
      try {
        const response = await AuthService.valid();
        const valid = response.data.jwtValid;
        if (valid) {
          const user = jwt_decode(token);
          user.token = token;
          kernel.authUser(user);
          redirectPage();
        } else {
          return redirectLoginPage();
        }
      } catch (error) {
        return redirectLoginPage();
      }
    };

    validUser();
    return () => {};
  }, []);

  return (
    <div className={styles.preload}>
      <div className={styles.preloadContainer}>
        <div className={styles.preloadLogo}>
          <svg className={styles.preloadLogoImg} width="70" height="60" viewBox="0 0 71 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M20.8782 27.3351L26.9959 32.8878L39.204 16.7348L57.9473 31.066L70.1064 27.4744L35.774 -1.81136e-05L20.8782 27.3351Z"
              fill="white"
            />
            <path
              d="M49.3199 31.7549L43.221 26.2171L31.0012 42.3609L12.3105 28.0714L0.162312 31.6716L34.3961 59.0679L49.3199 31.7549Z"
              fill="white"
            />
          </svg>
          <h1 className={styles.preloadLogoName}>ООО Арквантум</h1>
        </div>
        <div className={styles.preloadLoading}>
          <span className={styles.loader}></span>
        </div>
      </div>
    </div>
  );
}
