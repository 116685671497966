import { HiCheck } from 'react-icons/hi';
import classNames from 'classnames';
import { GrTree } from 'react-icons/gr';
import { useForm } from 'react-hook-form';
import { useCallback, useEffect, useRef, useState } from 'react';
import { AiOutlineFieldTime, AiOutlineOrderedList } from 'react-icons/ai';

import { ConfirmationPopUp, LoadingInUserPageForm, SettlementServerPopUp } from '../../../components.mjs';
import { useKernel } from '../../../../context/ContextKernel.mjs';
import { EnumSourceAlarm, EnumTypeAlarm } from '../../../../quantum-lib/Enum/AlarmEnum.mjs';
import { AlarmListIdPopUp, InputNumber, ListConstAlarmPopUp } from '../index.mjs';
import Utils from '../../../../utils/Utils.mjs';

import { alarmConfigId, validateListSourcesAlarm, validateParameterId } from './helpers/helpers.mjs';
import styles from './alarmForm.module.scss';

export function AlarmForm(props) {
  const { onSubmit, sendingData, dataSentSuccessfully, alarmEditorState, configuration } = props;

  const { kernel } = useKernel();

  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    setValue,
    trigger,
  } = useForm({
    mode: 'onChange',
  });

  const [state, setState] = useState({});
  const [showTooltip, setShowTooltip] = useState({
    alarmConfigId: false,
    listConstAlarm: false,
    listSourcesAlarm: false,
    parameterId: false,
    timeout: false,
    typeAlarm: false,
    typeSourceAlarm: false,
    workingStatus: false,
  });
  const [activeElementTree, setActiveElementTree] = useState(0);
  const [visible, setVisible] = useState(false);
  const [visibleAlarmListIdPopUp, setVisibleAlarmListIdPopUp] = useState(false);
  const [visibleListParameterId, setVisibleListParameterId] = useState(false);
  const [visibleListConstAlarmPopUp, setVisibleListConstAlarmPopUp] = useState(false);
  const [visibleConfirmationPopUp, setVisibleConfirmationPopUp] = useState(false);
  const tmpRef = useRef();

  const handleFocus = useCallback(
    (event) => {
      const name = event.target.name;

      setShowTooltip({
        ...showTooltip,
        [name]: true,
      });
    },
    [showTooltip],
  );

  const handleBlur = useCallback(
    (event) => {
      const name = event.target.name;

      setShowTooltip({
        ...showTooltip,
        [name]: false,
      });
    },
    [showTooltip],
  );

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    if (name === 'typeSourceAlarm') {
      setVisibleConfirmationPopUp(true);
      tmpRef.current = { name, value };
    } else {
      setState({
        ...state,
        [name]: value,
      });
      setValue(name, value);
    }
  };

  const setParameterId = () => {
    const parameterId = activeElementTree.toString();

    setState({
      ...state,
      parameterId,
    });
    setValue('parameterId', parameterId);

    setVisible(false);
    setActiveElementTree(0);
  };

  const setParameterListId = () => {
    const listSourcesAlarm =
      state.listSourcesAlarm.length > 0 ? `${state.listSourcesAlarm};${activeElementTree.toString()}` : activeElementTree.toString();

    setState({
      ...state,
      listSourcesAlarm,
    });
    setValue('listSourcesAlarm', listSourcesAlarm);
    trigger('listSourcesAlarm');

    setVisibleListParameterId(false);
    setActiveElementTree(0);
  };

  const setAlarmId = (alarmId) => {
    const alarmConfigId = alarmId !== null ? `${alarmId}` : state.alarmConfigId;

    setState({
      ...state,
      alarmConfigId,
    });
    setValue('alarmConfigId', alarmConfigId);

    setVisibleAlarmListIdPopUp(false);
    setActiveElementTree(0);
  };

  const closePopUp = () => {
    setVisible(false);
    setActiveElementTree(0);
  };

  const closeListParameterIdPopUp = () => {
    setVisibleListParameterId(false);
    setActiveElementTree(0);
  };

  const closeAlarmListIdPopUp = () => {
    setVisibleAlarmListIdPopUp(false);
    setActiveElementTree(0);
  };

  const changeListConstAlarm = (items) => {
    const str = items.map((item) => `${item.type}${item.value}`).join(';');
    setValue('listConstAlarm', str);
    setState({
      ...state,
      listConstAlarm: str,
    });
    trigger('listConstAlarm');
    setVisibleListConstAlarmPopUp(false);
  };

  useEffect(() => {
    setState({
      alarmConfigId: alarmEditorState.activeAlarm.alarmConfigId,
      listConstAlarm: alarmEditorState.activeAlarm.listConstAlarm,
      listSourcesAlarm: alarmEditorState.activeAlarm.listSourcesAlarm,
      parameterId: alarmEditorState.activeAlarm.parameterId,
      timeout: alarmEditorState.activeAlarm.timeout,
      typeAlarm: alarmEditorState.activeAlarm.typeAlarm,
      typeSourceAlarm: alarmEditorState.activeAlarm.typeSourceAlarm,
      workingStatus: alarmEditorState.activeAlarm.workingStatus,
    });

    setValue('alarmConfigId', alarmEditorState.activeAlarm.alarmConfigId);
    setValue('listConstAlarm', alarmEditorState.activeAlarm.listConstAlarm);
    setValue('listSourcesAlarm', alarmEditorState.activeAlarm.listSourcesAlarm);
    setValue('parameterId', alarmEditorState.activeAlarm.parameterId);
    setValue('timeout', alarmEditorState.activeAlarm.timeout);
    setValue('typeAlarm', alarmEditorState.activeAlarm.typeAlarm);
    setValue('typeSourceAlarm', alarmEditorState.activeAlarm.typeSourceAlarm);
    setValue('workingStatus', alarmEditorState.activeAlarm.workingStatus);

    return () => {};
  }, [alarmEditorState.activeAlarmId]);

  return (
    <div className={styles.container}>
      {visible && (
        <SettlementServerPopUp
          configuration={configuration}
          activeElementTree={activeElementTree}
          setActiveElementTree={setActiveElementTree}
          closePopUp={closePopUp}
          callback={setParameterId}
          propertyVisibleInTree={true}
        />
      )}
      {visibleAlarmListIdPopUp && (
        <AlarmListIdPopUp
          configuration={configuration}
          activeElementTree={activeElementTree}
          setActiveElementTree={setActiveElementTree}
          closePopUp={closeAlarmListIdPopUp}
          callback={setAlarmId}
        />
      )}
      {visibleListParameterId && (
        <SettlementServerPopUp
          configuration={configuration}
          activeElementTree={activeElementTree}
          setActiveElementTree={setActiveElementTree}
          closePopUp={closeListParameterIdPopUp}
          callback={setParameterListId}
          propertyVisibleInTree={true}
        />
      )}
      {visibleListConstAlarmPopUp && (
        <ListConstAlarmPopUp value={state.listConstAlarm} closePopUp={() => setVisibleListConstAlarmPopUp(false)} callback={changeListConstAlarm} />
      )}
      {visibleConfirmationPopUp && (
        <ConfirmationPopUp
          closePopup={() => setVisibleConfirmationPopUp(false)}
          actionConfirmation={() => {
            const { name, value } = tmpRef.current;

            setState({
              ...state,
              [name]: value,
              listConstAlarm: '',
            });

            setValue(name, value);
            setValue('listConstAlarm', '');
            setVisibleConfirmationPopUp(false);
          }}
          message="Вы точно хотите изменить значение поля 'Тип проверки'? Данное действие приведет к очищению поля 'Список констант'"
          loading={false}
          success={false}
        />
      )}

      <div className={styles.wrap}>
        <div className={styles.editorWrap}>
          <form className={styles.form} onSubmit={handleSubmit(onSubmit)} id="setpointsArgumentsForm">
            <div className={styles.formItem}>
              <span className={styles.formItemText} title="Статус">
                Статус
              </span>
              <select
                className={classNames(styles.formItemSelect, (sendingData || dataSentSuccessfully) && styles.sending)}
                value={state.workingStatus}
                onInput={handleChange}
                disabled={sendingData || dataSentSuccessfully}
                {...register('workingStatus')}
              >
                <option className={styles.formItemOption} value={1}>
                  Включен
                </option>
                <option className={styles.formItemOption} value={0}>
                  Выключен
                </option>
              </select>
            </div>
            <div className={styles.formItem}>
              <span className={styles.formItemText} title="Id объекта">
                Id объекта
              </span>
              <InputNumber
                name="parameterId"
                value={state.parameterId}
                dataSentSuccessfully={dataSentSuccessfully}
                sendingData={sendingData}
                showTooltip={showTooltip}
                errors={errors}
                onBlur={handleBlur}
                onFocus={handleFocus}
                onInput={handleChange}
                register={{
                  ...register('parameterId', {
                    required: 'Поле обязательно для заполнения',
                    min: {
                      value: 0,
                      message: 'Id объекта должен быть положительным',
                    },
                    maxLength: {
                      value: 255,
                      message: 'Поле может содержать максимум 255 символов',
                    },
                    validate: (value) => validateParameterId(value, kernel),
                  }),
                }}
              >
                <div
                  className={classNames(styles.showParameterTree, (sendingData || dataSentSuccessfully) && styles.sending)}
                  onClick={() => {
                    !sendingData && !dataSentSuccessfully && setVisible(true);
                  }}
                >
                  <GrTree className={styles.showParameterTreeIcon} />
                </div>
              </InputNumber>
            </div>
            <div className={styles.formItem}>
              <span className={styles.formItemText} title="Id тревоги от которой выставлять задержку">
                Id тревоги от которой выставлять задержку
              </span>
              <InputNumber
                name="alarmConfigId"
                value={state.alarmConfigId}
                dataSentSuccessfully={dataSentSuccessfully}
                sendingData={sendingData}
                showTooltip={showTooltip}
                errors={errors}
                onBlur={handleBlur}
                onFocus={handleFocus}
                onInput={handleChange}
                register={{
                  ...register('alarmConfigId', {
                    // required: 'Поле обязательно для заполнения',
                    min: {
                      value: 0,
                      message: 'Id должен быть положительным',
                    },
                    maxLength: {
                      value: 255,
                      message: 'Поле может содержать максимум 255 символов',
                    },
                    validate: (value) => alarmConfigId(value, kernel),
                  }),
                }}
              >
                <div
                  className={classNames(styles.showAlarmConfigId, (sendingData || dataSentSuccessfully) && styles.sending)}
                  onClick={() => {
                    !sendingData && !dataSentSuccessfully && setVisibleAlarmListIdPopUp(true);
                  }}
                >
                  <AiOutlineFieldTime className={styles.showAlarmConfigIdIcon} />
                </div>
              </InputNumber>
            </div>
            <div className={styles.formItem}>
              <span className={styles.formItemText} title="Тип тревоги">
                Тип тревоги
              </span>
              <select
                className={classNames(styles.formItemSelect, (sendingData || dataSentSuccessfully) && styles.sending)}
                value={state.typeAlarm}
                onInput={handleChange}
                disabled={sendingData || dataSentSuccessfully}
                {...register('typeAlarm')}
              >
                {Object.values(EnumTypeAlarm).map((typeAlarm) => {
                  if (typeAlarm.value === 0) {
                    return (
                      <option disabled key={typeAlarm.value} className={styles.formItemOption} value={typeAlarm.value}>
                        {typeAlarm.text}
                      </option>
                    );
                  }
                  return (
                    <option key={typeAlarm.value} className={styles.formItemOption} value={typeAlarm.value}>
                      {typeAlarm.text}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className={styles.formItem}>
              <span className={styles.formItemText} title="Тип проверки">
                Тип проверки
              </span>
              <select
                className={classNames(styles.formItemSelect, (sendingData || dataSentSuccessfully) && styles.sending)}
                value={state.typeSourceAlarm}
                onInput={handleChange}
                disabled={sendingData || dataSentSuccessfully}
                {...register('typeSourceAlarm')}
              >
                {Object.values(EnumSourceAlarm).map((typeSourceAlarm) => {
                  if (typeSourceAlarm.value === 0) {
                    return (
                      <option disabled key={typeSourceAlarm.value} className={styles.formItemOption} value={typeSourceAlarm.value}>
                        {typeSourceAlarm.text}
                      </option>
                    );
                  }
                  return (
                    <option key={typeSourceAlarm.value} className={styles.formItemOption} value={typeSourceAlarm.value}>
                      {typeSourceAlarm.text}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className={styles.formItem}>
              <span className={styles.formItemText} title="Пауза для срабатывания тревоги">
                Пауза для срабатывания тревоги
              </span>
              <InputNumber
                name="timeout"
                value={state.timeout}
                dataSentSuccessfully={dataSentSuccessfully}
                sendingData={sendingData}
                showTooltip={showTooltip}
                errors={errors}
                onBlur={handleBlur}
                onFocus={handleFocus}
                onInput={handleChange}
                register={{
                  ...register('timeout', {
                    // required: 'Поле обязательно для заполнения',
                    min: {
                      value: 0,
                      message: 'Пауза должна быть положительной',
                    },
                    maxLength: {
                      value: 255,
                      message: 'Поле может содержать максимум 255 символов',
                    },
                    validate: (value) => {
                      const isInteger = /^\d+$/.test(value);
                      return Utils.testIsNumber(value) ? (isInteger ? true : 'Число должно быть целым') : 'Неверный формат';
                    },
                  }),
                }}
              />
            </div>
            <div className={styles.formItem}>
              <span className={styles.formItemText} title="Список идентификаторов для расчета">
                Список идентификаторов для расчета
              </span>
              <InputNumber
                name="listSourcesAlarm"
                value={state.listSourcesAlarm}
                dataSentSuccessfully={dataSentSuccessfully}
                sendingData={sendingData}
                showTooltip={showTooltip}
                errors={errors}
                onBlur={handleBlur}
                onFocus={handleFocus}
                onInput={handleChange}
                register={{
                  ...register('listSourcesAlarm', {
                    required: 'Поле обязательно для заполнения',
                    maxLength: {
                      value: 255,
                      message: 'Поле может содержать максимум 255 символов',
                    },
                    validate: (value) => validateListSourcesAlarm(value, kernel),
                  }),
                }}
              >
                <div
                  className={classNames(styles.showListAlarmId, (sendingData || dataSentSuccessfully) && styles.sending)}
                  onClick={() => {
                    !sendingData && !dataSentSuccessfully && setVisibleListParameterId(true);
                  }}
                >
                  <AiOutlineOrderedList className={styles.showListAlarmIdIcon} />
                </div>
              </InputNumber>
            </div>
            <div className={styles.formItem}>
              <span className={styles.formItemText} title="Список констант">
                Список констант
              </span>
              <InputNumber
                name="listConstAlarm"
                value={state.listConstAlarm}
                dataSentSuccessfully={dataSentSuccessfully}
                sendingData={sendingData}
                showTooltip={showTooltip}
                errors={errors}
                onBlur={handleBlur}
                onFocus={handleFocus}
                onInput={handleChange}
                readOnly={true}
                register={{
                  ...register('listConstAlarm', {
                    maxLength: {
                      value: 255,
                      message: 'Поле может содержать максимум 255 символов',
                    },
                  }),
                }}
              >
                <div
                  className={classNames(styles.showListAlarmId, (sendingData || dataSentSuccessfully) && styles.sending)}
                  onClick={() => {
                    !sendingData && !dataSentSuccessfully && setVisibleListConstAlarmPopUp(true);
                  }}
                >
                  <AiOutlineOrderedList className={styles.showListAlarmIdIcon} />
                </div>
              </InputNumber>
            </div>
          </form>
        </div>
      </div>
      <div className={styles.controlContainer}>
        <button
          type="submit"
          form="setpointsArgumentsForm"
          className={
            sendingData || dataSentSuccessfully
              ? styles.creatingNewModuleFormSubmitSending
              : classNames(styles.creatingNewModuleFormSubmit, !isValid ? styles.formNoValid : styles.formValid)
          }
          disabled={sendingData || dataSentSuccessfully || !isValid}
        >
          {dataSentSuccessfully ? <HiCheck className={styles.checkControl} /> : sendingData ? <LoadingInUserPageForm /> : 'Сохранить'}
        </button>
      </div>
    </div>
  );
}
