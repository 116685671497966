import { EnumSourceAlarm, EnumTypeAlarm } from "../../Enum/AlarmEnum.mjs";

class Setpoints {
  constructor(kernel, setPointsStruct) {
    this.kernel = kernel;
    this.setPointsStruct = setPointsStruct;
  }

  get id() {
    return this.setPointsStruct.id;
  }

  get name() {
    return this.setPointsStruct.name.value;
  }

  get comment() {
    return this.setPointsStruct.comment.value;
  }

  get parameterId() {
    return this.setPointsStruct.parameterId;
  }

  get systemTypeId() {
    return this.setPointsStruct.systemTypeId;
  }

  get parameterByParameterId() {
    return this.kernel.parameters[this.setPointsStruct.parameterId];
  }

  get value_1() {
    const decoder = this.parameterId !== 0n ? this.kernel.getParameterById(this.parameterId) : this.kernel.getSystemTypeById(this.systemTypeId);

    const type = this.parameterId !== 0n ? this.kernel.getParameterById(this.parameterId).typeNode.systemType.type : this.kernel.getSystemTypeById(this.systemTypeId).type;

    return type === 9 || type === 10 ? (decoder.parseValue(this.setPointsStruct.value_1, '')).toFixed(2) : decoder.parseValue(this.setPointsStruct.value_1, '');
  }

  get value_2() {
    const decoder = this.parameterId !== 0n ? this.kernel.getParameterById(this.parameterId) : this.kernel.getSystemTypeById(this.systemTypeId);

    const type = this.parameterId !== 0n ? this.kernel.getParameterById(this.parameterId).typeNode.systemType.type : this.kernel.getSystemTypeById(this.systemTypeId).type;

    return type === 9 || type === 10 ? (decoder.parseValue(this.setPointsStruct.value_2, '')).toFixed(2) : decoder.parseValue(this.setPointsStruct.value_2, '');
  }

  get value_3() {
    const decoder = this.parameterId !== 0n ? this.kernel.getParameterById(this.parameterId) : this.kernel.getSystemTypeById(this.systemTypeId);

    const type = this.parameterId !== 0n ? this.kernel.getParameterById(this.parameterId).typeNode.systemType.type : this.kernel.getSystemTypeById(this.systemTypeId).type;

    return type === 9 || type === 10 ? (decoder.parseValue(this.setPointsStruct.value_3, '')).toFixed(2) : decoder.parseValue(this.setPointsStruct.value_3, '');
  }

  get value_4() {
    const decoder = this.parameterId !== 0n ? this.kernel.getParameterById(this.parameterId) : this.kernel.getSystemTypeById(this.systemTypeId);

    const type = this.parameterId !== 0n ? this.kernel.getParameterById(this.parameterId).typeNode.systemType.type : this.kernel.getSystemTypeById(this.systemTypeId).type;

    return type === 9 || type === 10 ? (decoder.parseValue(this.setPointsStruct.value_4, '')).toFixed(2) : decoder.parseValue(this.setPointsStruct.value_4, '');
  }

  get value_5() {
    const decoder = this.parameterId !== 0n ? this.kernel.getParameterById(this.parameterId) : this.kernel.getSystemTypeById(this.systemTypeId);

    const type = this.parameterId !== 0n ? this.kernel.getParameterById(this.parameterId).typeNode.systemType.type : this.kernel.getSystemTypeById(this.systemTypeId).type;

    return type === 9 || type === 10 ? (decoder.parseValue(this.setPointsStruct.value_5, '')).toFixed(2) : decoder.parseValue(this.setPointsStruct.value_5, '');
  }

  get value_6() {
    const decoder = this.parameterId !== 0n ? this.kernel.getParameterById(this.parameterId) : this.kernel.getSystemTypeById(this.systemTypeId);

    const type = this.parameterId !== 0n ? this.kernel.getParameterById(this.parameterId).typeNode.systemType.type : this.kernel.getSystemTypeById(this.systemTypeId).type;
    
    return type === 9 || type === 10 ? (decoder.parseValue(this.setPointsStruct.value_6, '')).toFixed(2) : decoder.parseValue(this.setPointsStruct.value_6, '');
  }

  get typeAlarm() {
    return EnumTypeAlarm[this.setPointsStruct.typeAlarm];
  }

  get typeSourceAlarm() {
    return EnumSourceAlarm[this.setPointsStruct.typeSourceAlarm];
  }
}

export {
  Setpoints,
};