import { Base64 } from "../../../quantum-lib/Scada/Parser/Base64.mjs";
import { ParametersListStruct } from "../../../quantum-lib/Scada/Parser/Configurations/ParametersListStruct.mjs";
import { ErrorsApp } from "../../../utils/errors-app/ErrorsApp.mjs";

import { createParametersListStruct } from "./createParametersListStruct.mjs";


export const copyTreeNode = async (parameterStructExtended) => {
  const hasDeletedParameters = { status: false };
  
  if (parameterStructExtended.isDeleted) throw new ErrorsApp("Корневой элемент удален, копирование не возможно!", 2);

  const parametersList = [parameterStructExtended.parameterStruct];
  createParametersListStruct(parametersList, parameterStructExtended, hasDeletedParameters);

  const parametersListStruct = new ParametersListStruct(parametersList);
  const parametersListStructInBase64 = parametersListStruct.toBase64();

  await navigator.clipboard.writeText(parametersListStructInBase64);

  return { hasDeletedParameters };
};

export const pasteTreeNode = async () => {
  try {
    const isLittleEndian = true;
    const offset = 0;
    const textInBuffer = await navigator.clipboard.readText();
    const arrayBuffer = Base64.base64StringToArrayBuffer(textInBuffer);

    // для проверки валидности отправляемых данных (если не удалось сформировать структуру, то данные находящиеся в буфере не валидны)
    const dataView = new DataView(arrayBuffer);
    const parameterListStructStruct = new ParametersListStruct();
    parameterListStructStruct.readFrom(dataView, offset, isLittleEndian);

    if (parameterListStructStruct.items.length === 0) throw new ErrorsApp("В буфере обмена находится невалидные данные!", 2);

    return { textInBuffer, arrayBuffer, parameterListStructStruct: parameterListStructStruct.items };
  } catch (error) {
    console.error(error);
    throw new ErrorsApp("В буфере обмена находится невалидные данные!", 2);
  }
};