import { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { MdSearch } from 'react-icons/md';
import { VscInsert } from 'react-icons/vsc';
import { GrDocumentDownload } from 'react-icons/gr';

import { useKernel } from '../../../../context/ContextKernel.mjs';

import styles from './dictionarySettlementServer.module.scss';

export function DictionarySettlementServer(props) {
  const { setSnippetCodeSrvCalc, tabVisible } = props;

  const [search, setSearch] = useState({ searchValue: '', searchFilteredDictionaryList: [] });
  const [dictionary, setDictionary] = useState({});
  const [showFilteredDictionaryList, setShowFilteredDictionaryList] = useState(false);

  const { kernel } = useKernel();

  const handleChangeSearch = ({ target: { value } }) => {
    setSearch({
      ...search,
      searchValue: value,
    });

    if (value === '') {
      setDictionary({});
    }
  };

  const filterDictionary = (searchText, dictionaryList) => {
    const filteredDictionaryList = [];

    if (searchText.length === 0) return [];
    
    dictionaryList.forEach((dictionary) => {
      const keyWords = dictionary.keyWords;
      const contain = keyWords.find((keyWord) => keyWord.includes(searchText.toLowerCase()));

      if (contain) filteredDictionaryList.push(dictionary);
    });

    return filteredDictionaryList;
  };

  const handleFocus = useCallback(() => {
    setShowFilteredDictionaryList(true);
  }, []);

  const handleBlur = useCallback(() => {
    setTimeout(() => {
      setShowFilteredDictionaryList(false);
    }, 200);
  }, []);

  useEffect(() => {
    const debounce = setTimeout(() => {
      const dictionaryList = kernel.getSrvCalcDictionaryList();
      const filteredDictionary = filterDictionary(search.searchValue, dictionaryList);

      setSearch({
        ...search,
        searchFilteredDictionaryList: filteredDictionary,
      });
    }, 300);

    return () => {
      clearTimeout(debounce);
    };
  }, [search.searchValue, kernel]);

  const handleChangeSearchDictionary = useCallback(
    (event) => {
      const dataset = event.currentTarget.dataset;

      const dictionaryItem = kernel.getSrvCalcDictionaryByName(dataset.name);
      setSearch({
        searchValue: dictionaryItem.name,
        searchFilteredDictionaryList: [dictionaryItem],
      });
      setDictionary(dictionaryItem);
    },
    [kernel],
  );

  if (tabVisible !== 3) {
    return null;
  }

  return (
    <div className={styles.dictionary}>
      <div className={styles.dictionarySearch}>
        <div className={styles.dictionarySearchContainer}>
          <input
            className={styles.dictionarySearchInput}
            type="text"
            value={search.searchValue}
            onChange={handleChangeSearch}
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
          <MdSearch className={classNames(styles.dictionarySearchIcon, search.searchValue?.length > 0 && styles.dictionarySearchIconActive)} />
        </div>

        <div
          className={classNames(
            styles.dictionaryList,
            showFilteredDictionaryList && search.searchFilteredDictionaryList.length > 0 && styles.dictionaryListVisible,
          )}
        >
          <div className={styles.dictionaryListContainer}>
            {search.searchFilteredDictionaryList.map((searchFilteredDictionary, index) => {
              return (
                <div
                  key={index}
                  data-name={searchFilteredDictionary.name}
                  className={styles.dictionaryListItem}
                  onClick={(event) => handleChangeSearchDictionary(event)}
                >
                  <h3 className={styles.dictionaryListTitle}>{searchFilteredDictionary.name}</h3>
                  <span className={styles.dictionaryListText} title={searchFilteredDictionary.description}>
                    {searchFilteredDictionary.description}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className={styles.dictionaryContainer}>
        <div className={styles.dictionaryContent}>
          <>
            {dictionary.name && (
              <div className={styles.dictionaryContentContainer}>
                <h3 className={styles.dictionaryContentTitle}>{dictionary.name}</h3>
                <div className={styles.dictionaryContentTextContainer}>
                  <span className={styles.dictionaryContentText}>{dictionary.description}</span>
                </div>
              </div>
            )}

            {dictionary.syntax && (
              <div className={styles.dictionaryContentContainer}>
                <h3 className={styles.dictionaryContentTitle}>Синтаксис</h3>
                <div className={styles.dictionaryContentTextContainer}>
                  <span className={styles.dictionaryContentText}>{dictionary.syntax}</span>
                </div>
              </div>
            )}

            {dictionary.arguments && (
              <div className={styles.dictionaryContentContainer}>
                <h3 className={styles.dictionaryContentTitle}>Аргументы функции</h3>
                {dictionary.arguments.map((dictionaryArgument, index) => {
                  return (
                    <div key={index} className={styles.dictionaryContentArgument}>
                      <div className={styles.dictionaryContentTextContainer}>
                        <span className={styles.dictionaryContentText}>{dictionaryArgument.name}</span>
                      </div>
                      <div className={styles.dictionaryContentTextContainer}>
                        <div className={styles.dictionaryContentTextContainer}>
                          <span className={styles.dictionaryContentText}>{dictionaryArgument.description}</span>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}

            {dictionary.seeAlso && (
              <div className={styles.dictionaryContentContainer}>
                <h3 className={styles.dictionaryContentTitle}>Смотреть также</h3>

                <div className={styles.dictionaryContentTextContainer}>
                  {dictionary.seeAlso.map((seeAlsoItem, index) => {
                    return (
                      <span key={index} data-name={seeAlsoItem} className={styles.dictionaryContentSeeAlso} onClick={handleChangeSearchDictionary}>
                        {seeAlsoItem}
                      </span>
                    );
                  })}
                </div>
              </div>
            )}
          </>
        </div>

        <button className={styles.dictionaryCodeAddButton} onClick={() => setSnippetCodeSrvCalc(dictionary)}>
          <VscInsert className={styles.dictionaryCodeAddButtonIcon} />
          <span className={styles.dictionaryCodeAddButtonText}>Вставить код</span>
        </button>

        <div className={styles.download} title="Скачать документацию">
          <a className={styles.downloadLink} href="./uploads/Инструкция_по_настройке_расчетов_модуля_QS.SVR.CALC.docx" download>
            <GrDocumentDownload className={styles.downloadIcon} />
          </a>
        </div>
      </div>
    </div>
  );
}
