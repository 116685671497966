import { useEffect, useState, useCallback, memo } from 'react';
import { HiOutlinePlusSm } from 'react-icons/hi';
import { BsArrowLeftCircle } from 'react-icons/bs';
import { MdDelete } from 'react-icons/md';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';

import { createValidationScheme } from '../model/validation.mjs';
import { Tooltip } from '../../../../components.mjs';

import styles from './listConstAlarmPopUp.module.scss';

const ListConstAlarmItem = memo((props) => {
  const { listConstAlarmItem, callback, state, setState, setValue, register, errors, trigger } = props;
  const [itemState, setItemState] = useState({ ...listConstAlarmItem });
  const [showTooltip, setShowTooltip] = useState(false);
  const itemName = `constAlarmItem_${itemState.id}`;

  const handleChange = ({ target }) => {
    const type = target.dataset.type;
    const id = target.dataset.id;
    const value = target.value;

    if (type === 'value' && value.includes(';')) {
      setValue(itemName, itemState.value);
    } else {
      setItemState({
        ...itemState,
        [type]: value,
      });

      setState(
        state.map((obj) => {
          if (obj.id == id) {
            return { ...obj, [type]: value };
          }
          return obj;
        }),
      );
    }
  };

  const handleFocus = useCallback(() => setShowTooltip(true), []);
  const handleBlur = useCallback(() => setShowTooltip(false), []);

  useEffect(() => {
    trigger(itemName);
    return () => {};
  }, [itemName, itemState, trigger]);

  return (
    <div className={styles.row}>
      <select className={styles.select} data-type="type" data-id={itemState.id} value={itemState.type} onChange={handleChange}>
        <option className={styles.option} value="u">
          uint64
        </option>
        <option className={styles.option} value="a">
          uint32
        </option>
        <option className={styles.option} value="b">
          uint16
        </option>
        <option className={styles.option} value="c">
          uint8
        </option>
        <option className={styles.option} value="f">
          float
        </option>
        <option className={styles.option} value="g">
          double
        </option>
        <option className={styles.option} value="s">
          string
        </option>
        <option className={styles.option} value="i">
          int64
        </option>
        <option className={styles.option} value="d">
          int32
        </option>
        <option className={styles.option} value="e">
          int16
        </option>
        <option className={styles.option} value="h">
          int8
        </option>
        <option className={styles.option} value="t">
          timestamp
        </option>
      </select>
      <div className={styles.inputWrap}>
        <input
          className={classNames(styles.textField, errors[itemName] && styles.textFieldError)}
          data-type="value"
          data-id={itemState.id}
          type="text"
          value={itemState.value}
          onFocus={handleFocus}
          onInput={handleChange}
          {...register(itemName, createValidationScheme(itemState.type))}
          onBlur={handleBlur}
        />
        {showTooltip && errors[itemName] && <Tooltip message={errors[itemName]?.message} />}
      </div>
      <button data-id={itemState.id} type="button" className={styles.delete} onClick={callback}>
        <MdDelete className={styles.deleteIcon} />
      </button>
    </div>
  );
});

export function ListConstAlarmPopUp(props) {
  const { value, closePopUp, callback } = props;
  const [state, setState] = useState([]);
  const {
    register,
    formState: { errors, isValid },
    setValue,
    trigger,
  } = useForm({
    mode: 'onChange',
  });

  const setListConstAlarmItem = useCallback(() => {
    const listConstAlarmItem = value !== '' ? value.split(';') : [];
    return listConstAlarmItem.map((item, index) => {
      return { id: index, type: item[0], value: item.slice(1, item.length) };
    });
  }, [value]);

  const removeListConstAlarmItem = ({ currentTarget }) => {
    const id = currentTarget.dataset.id;
    setState(state.filter((item) => item.id != id));
  };

  const createListConstAlarmItem = () => {
    setState([...state, { id: state.length, type: 'u', value: 0 }]);
    setValue(`constAlarmItem_${state.length}`, 0);
  };

  useEffect(() => {
    const listConstAlarm = setListConstAlarmItem();
    setState(listConstAlarm);

    return () => {};
  }, []);

  return (
    <div className={styles.wrap}>
      <div className={styles.container}>
        <div className={styles.header}>
          <h2 className={styles.title}>Создайте список констант</h2>
          <BsArrowLeftCircle className={styles.buttonBack} onClick={closePopUp} />
        </div>

        <form id="listConstAlarmForm"></form>
        <div className={styles.block}>
          {state.map((item) => {
            return (
              <ListConstAlarmItem
                key={item.id}
                listConstAlarmItem={item}
                callback={removeListConstAlarmItem}
                state={state}
                setState={setState}
                setValue={setValue}
                register={register}
                errors={errors}
                trigger={trigger}
              />
            );
          })}
          <button className={styles.addConstAlarm} onClick={createListConstAlarmItem}>
            <HiOutlinePlusSm className={styles.addConstAlarmIcon} />
          </button>
        </div>
        <div className={styles.control}>
          <button className={classNames(styles.controlButton)} form="listConstAlarmForm" onClick={() => callback(state)} disabled={!isValid}>
            Создать
          </button>
        </div>
      </div>
    </div>
  );
}
