import classNames from 'classnames';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { HiCheck } from 'react-icons/hi';

import { LoadingInUserPageForm } from '../../../components.mjs';
import { useKernel } from '../../../../context/ContextKernel.mjs';

import styles from './generalSettings.module.scss';

export function GeneralSettings(props) {
  const { codeEditorStateActiveTask, sendingData, dataSentSuccessfully, saveSetting, generalSettings, setGeneralSettings, treeTab } = props;
  const { kernel } = useKernel();

  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    trigger,
    setValue,
  } = useForm({
    mode: 'onChange',
  });

  const handleChangeNameTask = ({ target: { value } }) => {
    setGeneralSettings({
      ...generalSettings,
      nameTask: value,
    });
  };

  const handleChangeSelectForm = ({ target }) => {
    const name = target.name;
    const value = target.value;

    setGeneralSettings({
      ...generalSettings,
      [name]: value,
    });
  };

  useEffect(() => {
    const nameTask = codeEditorStateActiveTask?.nameTask ?? '';
    const workingStatus = codeEditorStateActiveTask?.workingStatus ?? 0;
    const systemTypeId = codeEditorStateActiveTask?.systemTypeId ?? 1;
    const blockTypeIdParent = codeEditorStateActiveTask?.blockTypeParentStruct?.id ?? 1;
    const blockTypeId = codeEditorStateActiveTask?.blockTypeStruct?.id ?? 1;

    setGeneralSettings({ nameTask, workingStatus });
    setValue('nameTask', nameTask);
    setValue('workingStatus', workingStatus);
    setValue('systemTypeId', systemTypeId);
    setValue('blockTypeIdParent', blockTypeIdParent);
    setValue('blockTypeId', blockTypeId);
    trigger('nameTask');
    return () => {};
  }, [codeEditorStateActiveTask]);

  return (
    <form className={styles.codeEditorContainer} onSubmit={handleSubmit(saveSetting)}>
      <div className={styles.generalSettingsList}>
        <div className={styles.generalSettingsListItem}>
          <span className={styles.generalSettingsListItemText} title="Название задачи">
            Название задачи
          </span>
          <div className={styles.inputWrap}>
            <input
              className={classNames(styles.generalSettingsListItemField, errors?.nameTask && styles.generalSettingsListItemFieldError)}
              type="text"
              placeholder="Введите название задачи"
              value={generalSettings.nameTask}
              onInput={handleChangeNameTask}
              {...register('nameTask', {
                required: 'Поле обязательно к заполнению',
                minLength: {
                  value: 3,
                  message: 'Поле должно содержать минимум 3 символа',
                },
                maxLength: {
                  value: 255,
                  message: 'Поле может содержать максимум 255 символа',
                },
              })}
            />
            {errors?.nameTask && <p className={styles.errorMessage}>{errors?.nameTask?.message || 'Ошибка заполнения!'}</p>}
          </div>
        </div>
        <div className={styles.generalSettingsListItem}>
          <span className={styles.generalSettingsListItemText} title="Статус">
            Статус
          </span>
          <select
            className={styles.generalSettingsListItemSelect}
            value={generalSettings.workingStatus}
            onInput={handleChangeSelectForm}
            {...register('workingStatus')}
          >
            <option className={styles.generalSettingsListItemSelectOption} value="0">
              Выключен
            </option>
            <option className={styles.generalSettingsListItemSelectOption} value="1">
              Включен
            </option>
          </select>
        </div>
        {treeTab === 2 && (
          <>
            <div className={styles.generalSettingsListItem}>
              <span className={styles.generalSettingsListItemText}>Системный тип</span>
              <select
                className={styles.generalSettingsListItemSelect}
                value={generalSettings.systemTypeId}
                onInput={handleChangeSelectForm}
                {...register('systemTypeId')}
              >
                {kernel.getSystemTypes().map((systemType) => {
                  return (
                    <option className={styles.generalSettingsListItemSelectOption} value={systemType.id}>
                      {systemType.description}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className={styles.generalSettingsListItem}>
              <span className={styles.generalSettingsListItemText}>Тип блока</span>
              <select
                className={styles.generalSettingsListItemSelect}
                value={generalSettings.blockTypeId}
                onInput={handleChangeSelectForm}
                {...register('blockTypeId')}
              >
                {kernel.getBlockTypes().map((blockType) => {
                  return (
                    <option className={styles.generalSettingsListItemSelectOption} value={blockType.id}>
                      {blockType.description}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className={styles.generalSettingsListItem}>
              <span className={styles.generalSettingsListItemText}>Тип блока родителя</span>
              <select
                className={styles.generalSettingsListItemSelect}
                value={generalSettings.blockTypeIdParent}
                onInput={handleChangeSelectForm}
                {...register('blockTypeIdParent')}
              >
                {kernel.getBlockTypes().map((blockType) => {
                  return (
                    <option className={styles.generalSettingsListItemSelectOption} value={blockType.id}>
                      {blockType.description}
                    </option>
                  );
                })}
              </select>
            </div>
          </>
        )}
      </div>

      <div className={styles.controlContainer}>
        <button
          className={
            sendingData || dataSentSuccessfully
              ? styles.creatingNewModuleFormSubmitSending
              : classNames(styles.creatingNewModuleFormSubmit, isValid ? styles.formValid : styles.formNoValid)
          }
          type="submit"
          disabled={sendingData || dataSentSuccessfully || !isValid}
        >
          {dataSentSuccessfully ? <HiCheck className={styles.checkControl} /> : sendingData ? <LoadingInUserPageForm /> : 'Сохранить'}
        </button>
      </div>
    </form>
  );
}
