import { useEffect, useRef } from 'react';
import classNames from 'classnames';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';

import { Tooltip } from '../components.mjs';

import styles from './inputNumberFractional.module.scss';

export default function InputNumberFractional(props) {
  const {
    value,
    setValue,
    getValues,
    trigger,
    placeholder,
    canEdit,
    isWritable,
    isDeleted,
    step,
    register,
    unregister,
    name,
    handleFocus,
    showTooltip,
    errors,
    validationScheme,
    setHandleChangeComponentTableRow,
    sendingData,
  } = props;
  const initialValue = useRef(value);

  const incrementValue = () => {
    if (!sendingData && !canEdit && isWritable && !isDeleted) {
      const newNumber = Number(getValues(name)) + Number(step);
      setValue(name, newNumber.toString());
      trigger(name);

      if (newNumber.toString() === initialValue.current.toString()) {
        setHandleChangeComponentTableRow(false);
      } else {
        setHandleChangeComponentTableRow(true);
      }
    }
  };

  const decrementValue = () => {
    if (!sendingData && !canEdit && isWritable && !isDeleted) {
      const newNumber = Number(getValues(name)) - Number(step);
      setValue(name, newNumber.toString());
      trigger(name);

      if (newNumber.toString() === initialValue.current.toString()) {
        setHandleChangeComponentTableRow(false);
      } else {
        setHandleChangeComponentTableRow(true);
      }
    }
  };

  const updateValue = ({ target }) => {
    setValue(name, target.value);
    if (target.value.toString() === initialValue.current.toString()) {
      setHandleChangeComponentTableRow(false);
    } else {
      setHandleChangeComponentTableRow(true);
    }
  };

  useEffect(() => {
    setValue(name, value.toString());
    return () => {
      unregister([name]);
    };
  }, []);

  useEffect(() => {
    initialValue.current = value;
    return () => {};
  }, [value]);

  return (
    <div className={styles.inputNumberFractionalContainer}>
      <div className={styles.inputNumberFractionalForm}>
        <div className={styles.inputNumberFractionalControlsContainer}>
          <input
            type="text"
            placeholder={placeholder}
            step={Number(step)}
            className={classNames(
              styles.inputNumberFractional,
              errors[name] && styles.inputNumberFractionalError,
              sendingData && styles.inputNumberFractionalSending,
              canEdit && styles.inputNumberFractionalSending,
              !isWritable && styles.inputNumberFractionalSending,
              isDeleted && styles.inputNumberFractionalSending,
            )}
            onFocus={handleFocus}
            onInput={updateValue}
            readOnly={sendingData || canEdit || !isWritable || isDeleted}
            {...register(name, validationScheme)}
          />
          <div className={styles.inputNumberFractionalControls}>
            <MdKeyboardArrowUp
              className={
                !canEdit
                  ? classNames(
                    styles.arrow,
                    sendingData && styles.inputNumberFractionalSending,
                    isWritable && styles.arrowUp,
                    !isWritable && styles.inputNumberFractionalSending,
                    isDeleted && styles.inputNumberFractionalSending,
                  )
                  : classNames(
                    styles.arrow,
                    sendingData && styles.inputNumberFractionalSending,
                    canEdit && styles.inputNumberFractionalSending,
                    isDeleted && styles.inputNumberFractionalSending,
                  )
              }
              onClick={incrementValue}
            />
            <MdKeyboardArrowDown
              className={
                !canEdit
                  ? classNames(
                    styles.arrow,
                    sendingData && styles.inputNumberFractionalSending,
                    isWritable && styles.arrowDown,
                    !isWritable && styles.inputNumberFractionalSending,
                    isDeleted && styles.inputNumberFractionalSending,
                  )
                  : classNames(
                    styles.arrow,
                    sendingData && styles.inputNumberFractionalSending,
                    canEdit && styles.inputNumberFractionalSending,
                    isDeleted && styles.inputNumberFractionalSending,
                  )
              }
              onClick={decrementValue}
            />
          </div>
        </div>
        {showTooltip && errors[name] && <Tooltip message={`${errors[name]?.message || 'Ошибка заполнения!'} `} />}
      </div>
    </div>
  );
}
