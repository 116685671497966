export const TypeEnum = {
  0: { value: 0, text: "Bool" },
  1: { value: 1, text: "Byte" },
  2: { value: 2, text: "Char" },
  3: { value: 3, text: "Int16" },
  4: { value: 4, text: "UInt16" },
  5: { value: 5, text: "Int32" },
  6: { value: 6, text: "UInt32" },
  7: { value: 7, text: "Int64" },
  8: { value: 8, text: "UInt64" },
  9: { value: 9, text: "Float" },
  10: { value: 10, text: "Double" },
  11: { value: 11, text: "TimeStamp" },
  12: { value: 12, text: "STRING_16" },
  13: { value: 13, text: "STRING_64" },
  14: { value: 14, text: "STRING_255" },
  15: { value: 15, text: "LINK1" },
  16: { value: 16, text: "InvalidType" },
};