import { ErrorsApp } from '../utils/errors-app/ErrorsApp.mjs';

import { setWorkingMode } from './services.mjs';

export class CreateNewObjectService {
  static async createNewProperty(configId, params) {
    const response = await fetch(`${setWorkingMode()}/create-new-property/${configId}`, {
      method: 'post',
      cache: 'reload',
      credentials: 'omit',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify(params),
    });

    if (!response.ok) {
      const errorMessage = (await response.json())?.message || "Ошибка!";
      throw new ErrorsApp(errorMessage, 2);
    }
  }

  static async createNewCustomParameter(configId, params){
    const response = await fetch(`${setWorkingMode()}/create-new-custom-parameter/${configId}`, {
      method: 'post',
      cache: 'reload',
      credentials: 'omit',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify(params),
    });

    if (!response.ok) {
      const errorMessage = (await response.json())?.message || "Ошибка!";
      throw new ErrorsApp(errorMessage, 2);
    }
  }

  static async createNewCustomObject(configId, params){
    const response = await fetch(`${setWorkingMode()}/create-new-custom-object/${configId}`, {
      method: 'post',
      cache: 'reload',
      credentials: 'omit',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify(params),
    });

    if (!response.ok) {
      const errorMessage = (await response.json())?.message || "Ошибка!";
      throw new ErrorsApp(errorMessage, 2);
    }
  }
}
