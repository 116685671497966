import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { HiCheck } from 'react-icons/hi';
import { BsArrowLeftCircle } from 'react-icons/bs';

import { LoadingInUserPageForm } from '../components.mjs';

import styles from './setNamePopUp.module.scss';

export default function SetNamePopUp(props) {
  const {
    value = '',
    name = 'name',
    setNamePopUpVisible,
    onSubmit,
    sendingData,
    dataSentSuccessfully,
    title,
    placeholder,
    textButton = 'Добавить',
  } = props;

  const {
    register,
    setValue,
    formState: { errors, isValid },
    handleSubmit,
    setFocus,
  } = useForm({
    mode: 'onChange',
  });

  const buttonBackClick = () => {
    setNamePopUpVisible(false);
  };

  function closePopupOnClick(event){
    if(event.target !== undefined && event.target.className === styles.setTaskNamePopUpWrap){
      buttonBackClick();
    }
  }

  function closePopupOnEsc(event){
    if(event.key === 'Escape'){
      buttonBackClick();
    }
  }

  useEffect(() => {
    document.body.addEventListener('click', closePopupOnClick);
    document.body.addEventListener('keydown', closePopupOnEsc);
    return () => {
      document.body.removeEventListener('click', closePopupOnClick);
      document.body.removeEventListener('keydown', closePopupOnEsc);
    };
  }, []);

  useEffect(() => {
    setValue(name, value);
    setFocus(name);
    return () => {};
  }, []);

  return (
    <div className={styles.setTaskNamePopUpWrap}>
      <div className={styles.container}>
        <div className={styles.header}>
          <h2 className={styles.title}>{title}</h2>
          <BsArrowLeftCircle className={styles.buttonBack} onClick={buttonBackClick} />
        </div>
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.containerForm}>
            <div className={styles.inputWrap}>
              <input
                className={classNames(styles.input, errors[name] && styles.inputError)}
                type="text"
                placeholder={placeholder}
                {...register(name, {
                  required: 'Поле обязательно к заполнению',
                  minLength: {
                    value: 3,
                    message: 'Поле должно содержать минимум 3 символа',
                  },
                  maxLength: {
                    value: 255,
                    message: 'Поле может содержать максимум 255 символа',
                  },
                })}
              />
              {errors[name] && <p className={styles.errorMessage}>{errors[name].message || 'Ошибка заполнения!'}</p>}
            </div>
          </div>
          <button
            className={
              sendingData || dataSentSuccessfully
                ? styles.buttonSending
                : isValid
                  ? classNames(styles.button, styles.formValid)
                  : classNames(styles.button, styles.formNoValid)
            }
            type="submit"
            disabled={!isValid}
          >
            {dataSentSuccessfully ? <HiCheck className={styles.checkControl} /> : sendingData ? <LoadingInUserPageForm /> : textButton}
          </button>
        </form>
      </div>
    </div>
  );
}
