import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { HiCheck } from 'react-icons/hi';

import { SavePropertiesButtonModuleRs } from '../components.mjs';
import { useKernel } from '../../context/ContextKernel.mjs';
import Utils from '../../utils/Utils.mjs';

import { deadZoneOptions } from './model/options.mjs';
import styles from './tableRowDeadZone.module.scss';

export default function TableRowDeadZone(props) {
  const { selectedParameter } = props;

  const [handleChangeComponentTableRow, setHandleChangeComponentTableRow] = useState(false);
  const [sendingData, setSendingData] = useState(false);
  const [dataSentSuccessfully, setDataSentSuccessfully] = useState(false);
  const [errorParse, setErrorParse] = useState(false);
  const [changesDate, setChangesDate] = useState({ day: '', hour: '' }); // вывод даты и времени последнего изменения свойства (выводится время драйвер адреса)
  const [state, setState] = useState(); // состояние компонента

  const initialValue = useRef();
  const dataUpdateStatus = useRef(false); // изменилось ли значение компонента состояние компонента или нет
  const { kernel, addNotification, stateElementConfigurationId } = useKernel();

  const saveProperty = async () => {
    setHandleChangeComponentTableRow(false);
    setSendingData(true);

    try {
      const newDefaultValuesDeadZone = Utils.setNewDefaultValueDeadZone(selectedParameter, state, 'deadZone');

      await kernel.setValues([newDefaultValuesDeadZone]);
      initialValue.current = state;

      setDataSentSuccessfully(true);
      setTimeout(() => {
        setDataSentSuccessfully(false);
        setSendingData(false);
      }, 1500);
    } catch (error) {
      setSendingData(false);
      addNotification({ messageText: error.message, statusType: error.status });
      setHandleChangeComponentTableRow(true);
    }
  };

  const handleChange = (event) => {
    setState(event.target.value);

    if (parseInt(event.target.value) === initialValue.current && !errorParse) {
      dataUpdateStatus.current = false;
    } else {
      dataUpdateStatus.current = true;
    }

    if (dataUpdateStatus.current) {
      setHandleChangeComponentTableRow(true);
      setErrorParse(false);
    } else {
      setHandleChangeComponentTableRow(false);
    }
  };

  useEffect(() => {
    // получаем значения дочерних свойств (default value)
    const defaultValueParameter = Utils.getPropertiesDefaultValueDeadZone(selectedParameter);

    const day = Utils.convertTimestamp(defaultValueParameter.propertyValueTimestamp);
    const hour = Utils.convertTimestampGetHour(defaultValueParameter.propertyValueTimestamp);
    setChangesDate({ day: day, hour: hour });

    const defaultValueUint32 = defaultValueParameter.propertyValue;
    const defaultValue = Utils.splitDefaultValueDeadZone(defaultValueUint32);
    const deadZone = defaultValue.deadZone;

    setState(deadZone);
    initialValue.current = deadZone;

    return () => {};
  }, [stateElementConfigurationId]);

  return (
    <tr className={styles.tableRow}>
      <td className="tableRowPropertyTd">
        <span className={styles.tableRowText} title={'Мертвая зона'}>
          Мертвая зона
        </span>
      </td>
      <td className="tableRowPropertyTd">
        <div className={styles.componentContainer}>
          <select className={classNames(styles.select, sendingData && styles.sending)} value={state} onInput={handleChange} disabled={sendingData}>
            {deadZoneOptions.map((deadZoneOption) => {
              return (
                <option key={deadZoneOption.valueOption} value={deadZoneOption.valueOption} className={styles.selectOption}>
                  {deadZoneOption.textOption}
                </option>
              );
            })}
            {errorParse && (
              <option key={11} value={11} className={styles.selectOption}>
                Значение не определено
              </option>
            )}
          </select>
        </div>
      </td>
      <td className="tableRowPropertyTd">
        <span className={classNames(styles.tableRowText, styles.tableDate)}>{changesDate.day}</span>
        <span className={styles.tableRowText}>{changesDate.hour}</span>
      </td>
      <td className="tableRowPropertyTd">
        {dataSentSuccessfully ? (
          <HiCheck className={styles.checkControl} />
        ) : (
          <SavePropertiesButtonModuleRs
            sendingData={sendingData}
            handleChangeComponentTableRow={handleChangeComponentTableRow}
            saveProperty={saveProperty}
          />
        )}
      </td>
    </tr>
  );
}
