import { Struct } from '../Struct.mjs'
import { StringStruct } from '../StringStruct.mjs'

const RESULT_PAUSE = 0
const RESULT_OK = 1
const RESULT_NOT_FOUND = 2
const RESULT_PERMISSION_DENIED = 3

class ResponseParameterValueDataStruct extends Struct {

    constructor(resultCode = 0, parameterId = 0n, timestamp = 0, rawValue = 0n, strValue = new StringStruct('')) {
        super()
        this.resultCode = resultCode
        this.parameterId = parameterId
        this.timestamp = timestamp
        this.rawValue = rawValue
        this.strValue = strValue
    }

    readFrom(dataView, startOffset, isLittleEndian = true) {
// window.debugDataView = dataView
// window.debugStartOffset = startOffset
// console.log(dataView, startOffset)
        let endOffset = startOffset
        this.resultCode = dataView.getUint8(endOffset)
        endOffset += 1
// console.log('resultCode', this.resultCode, endOffset)
        this.parameterId = dataView.getBigUint64(endOffset, isLittleEndian)
        endOffset += 8
// console.log('parameterId', this.parameterId, endOffset)
        this.timestamp = dataView.getUint32(endOffset, isLittleEndian)
        endOffset += 4
// console.log('timestamp', this.timestamp, endOffset)
        this.rawValue = dataView.getBigUint64(endOffset, isLittleEndian)
        endOffset += 8
// console.log('rawValue', this.rawValue, endOffset)
        endOffset = this.strValue.readFrom(dataView, endOffset, isLittleEndian)
// console.log('strValue', this.strValue.value, endOffset)
        return endOffset
    }

    writeTo(dataView, startOffset, isLittleEndian = true) {
        let endOffset = startOffset
        dataView.setUint8(endOffset, this.resultCode)
        endOffset += 1
        dataView.setBigUint64(endOffset, this.parameterId, isLittleEndian)
        endOffset += 8
        dataView.setUint32(endOffset, this.timestamp, isLittleEndian)
        endOffset += 4
        dataView.setBigUint64(endOffset, this.rawValue, isLittleEndian)
        endOffset += 8
        endOffset = this.strValue.writeTo(dataView, endOffset, isLittleEndian)
        return endOffset
    }

    getCurrentSize() {
        return 0
            + 1 // resultCode
            + 8 // parameterId
            + 4 // timestamp
            + 8 // rawValue
            + this.strValue.getCurrentSize() // strValue
    }

    clone() {
        let clone = new ResponseParameterValueDataStruct(
            this.resultCode,
            this.parameterId,
            this.timestamp,
            this.rawValue,
            this.strValue.clone()
        )
        clone.setParserOptions(this.getParserOptions())
        return clone
    }

    get isOk() {
        return this.resultCode === RESULT_OK
    }
}

ResponseParameterValueDataStruct.RESULT_PAUSE = RESULT_PAUSE
ResponseParameterValueDataStruct.RESULT_OK = RESULT_OK
ResponseParameterValueDataStruct.RESULT_NOT_FOUND = RESULT_NOT_FOUND
ResponseParameterValueDataStruct.RESULT_PERMISSION_DENIED = RESULT_PERMISSION_DENIED

export {
    
    ResponseParameterValueDataStruct
}
