class Alarm {
  constructor(kernel, alarmStruct) {
    this.kernel = kernel;
    this.alarmStruct = alarmStruct;
  }


  get id() {
    return this.alarmStruct.id;
  }

  get typeAlarm() {
    return this.alarmStruct.typeAlarm;
  }

  get parameterId() {
    return this.alarmStruct.parameterId;
  }

  get parameter() {
    return this.kernel.parameters[this.alarmStruct.parameterId];
  }

  get typeSourceAlarm() {
    return this.alarmStruct.typeSourceAlarm;
  }

  get listSourcesAlarm() {
    return this.alarmStruct.listSourcesAlarm.value;
  }

  get listConstAlarm() {
    return this.alarmStruct.listConstAlarm.value;
  }

  get timeout() {
    return this.alarmStruct.timeout;
  }

  get alarmConfigId() {
    return this.alarmStruct.alarmConfigId;
  }

  get workingStatus() {
    return this.alarmStruct.workingStatus;
  }

}

export { Alarm };