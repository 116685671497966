import classNames from 'classnames';
import { useState } from 'react';
import { MdDelete } from 'react-icons/md';

import styles from './simulatorTaskListItem.module.scss';

export function SimulatorTaskListItem(props) {
  const { item, state, text, handleChangeList, deleteCallBack, canDelete } = props;
  const [hasHover, setHasHover] = useState(false);
  
  return (
    <div>
      <div
        key={item.id}
        data-id={item.id}
        title={text}
        className={classNames(
          styles.simulatorTaskListItem,
          state.activeTaskId === item.id && styles.simulatorTaskListItemActive,
          hasHover && styles.simulatorTaskListItemDelete,
        )}
        onClick={handleChangeList}
      >
        <span className={styles.simulatorTaskListItemText}>{text}</span>
        {canDelete && (
          <MdDelete
            className={styles.deleteIcon}
            data-id={item.id}
            onMouseEnter={() => setHasHover(true)}
            onMouseLeave={() => setHasHover(false)}
            onClick={deleteCallBack}
          />
        )}
      </div>
    </div>
  );
}
