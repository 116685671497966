import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { IoSettingsSharp } from 'react-icons/io5';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import classNames from 'classnames';

import TableSettingsMenu from '../table-settings-menu/TableSettingsMenu.jsx';
import { useKernel } from '../../../../context/ContextKernel.mjs';
import { useLogs } from '../../../../context/ContextLogs.mjs';
import { LoggingService } from '../../../../services/LoggingService.mjs';

import styles from './tableSettingsControl.module.scss';

export default memo(function TableSettingsControl(props) {
  const { columnTableHeadState, setColumnTableHeadState } = props;

  const [tableSettingsVisible, setTableSettingsVisible] = useState(false);
  const { kernel, addNotification } = useKernel();
  const { setTableContent, tableContent, offset, limit, formFieldValue } = useLogs();
  const settingsMenuRef = useRef();

  const closeTableSettingsMenu = useCallback(({ target }) => {
    if (settingsMenuRef.current && !settingsMenuRef.current.contains(target)) {
      setTableSettingsVisible(false);
    }
  }, []);

  const closeTableSettingsMenuByESC = useCallback(({ keyCode }) => {
    if (keyCode === 27) {
      setTableSettingsVisible(false);
    }
  }, []);

  const logsPageDecrement = useCallback(async () => {
    if (tableContent.stepPageLogs > 1) {
      try {
        offset.current = limit.current * (tableContent.stepPageLogs - 2);
        setTableContent({ ...tableContent, sending: true });

        // взять из контекста
        formFieldValue.current.offset = offset.current;

        const logs = await LoggingService.getLogsByQueryParameters(formFieldValue.current);
        const Logs = kernel.buildLogs(logs.items);
        setTableContent({
          ...tableContent,
          sending: false,
          rows: Logs,
          stepPageLogs: tableContent.stepPageLogs - 1,
          logsCount: logs.count,
        });
      } catch (error) {
        addNotification({ messageText: error.message, statusType: error.status });
      }
    }
  }, [tableContent.stepPageLogs, tableContent.logsCount]);

  const logsPageIncrement = useCallback(async () => {
    if (tableContent.stepPageLogs < Math.floor((tableContent.logsCount + limit.current) / limit.current) && tableContent.logsCount > 0) {
      try {
        
        offset.current = limit.current * (tableContent.stepPageLogs );
        setTableContent({ ...tableContent, sending: true });

        // взять из контекста
        formFieldValue.current.offset = offset.current;

        const logs = await LoggingService.getLogsByQueryParameters(formFieldValue.current);
        const Logs = kernel.buildLogs(logs.items);
        
        setTableContent({
          ...tableContent,
          sending: false,
          rows: Logs,
          stepPageLogs: tableContent.stepPageLogs + 1,
          logsCount: logs.count,
        });
      } catch (error) {
        addNotification({ messageText: error.message, statusType: error.status });
      }
    }
  }, [tableContent.stepPageLogs, tableContent.logsCount]);

  useEffect(() => {
    document.addEventListener('mousedown', closeTableSettingsMenu);
    document.addEventListener('keydown', closeTableSettingsMenuByESC);
    return () => {
      document.removeEventListener('mousedown', closeTableSettingsMenu);
      document.removeEventListener('keydown', closeTableSettingsMenuByESC);
    };
  }, []);

  return (
    <div className={styles.container}>
      <div ref={settingsMenuRef} className={styles.tableSettingsContainer}>
        <IoSettingsSharp
          className={classNames(styles.settingsIcon, tableSettingsVisible && styles.settingsIconActive)}
          onClick={() => setTableSettingsVisible((prev) => !prev)}
        />
        {tableSettingsVisible && (
          <TableSettingsMenu
            settingsMenuState={columnTableHeadState}
            setSettingsMenuState={setColumnTableHeadState}
            className={styles.tableSettingsMenu}
          />
        )}
      </div>
      <div className={styles.dataTableControlContainer}>
        <div className={styles.pageSwitchersContainer}>
          <IoIosArrowBack className={classNames(styles.iconArrow, styles.leftIconArrow)} onClick={logsPageDecrement} />
          <span className={classNames(styles.text, styles.textAccent)}>{tableContent.stepPageLogs}</span>
          <span className={classNames(styles.text, styles.separator)}>из</span>
          <span className={styles.text}>
            {tableContent.logsCount !== 0 ? Math.floor((tableContent.logsCount + limit.current) / limit.current) : 0}
          </span>
          <IoIosArrowForward className={classNames(styles.iconArrow, styles.rightIconArrow)} onClick={logsPageIncrement} />
        </div>
      </div>
    </div>
  );
});
