import classNames from 'classnames';

import Utils from '../../../../utils/Utils.mjs';

import styles from './buildHourOfDaySelect.module.scss';

export const BuildHourOfDaySelect = (props) => {
  const { sendingData, isWritable, isDeleted, canEdit, hourOfDaySelectValue, handleChangeHourOfDaySelect } = props;
  const hourOfDay = Utils.setHourOfDayValue();

  return (
    <select
      className={classNames(
        styles.reportingHourSelect,
        styles.hourOfDaySelect,
        sendingData && styles.inputrepRtingHourSending,
        canEdit && styles.inputrepRtingHourSending,
        !isWritable && styles.inputrepRtingHourSending,
        isDeleted && styles.inputrepRtingHourSending,
      )}
      value={hourOfDaySelectValue}
      onInput={handleChangeHourOfDaySelect}
      disabled={sendingData || canEdit || !isWritable || isDeleted}
    >
      {hourOfDay.map((item) => {
        return (
          <option className={classNames(styles.reportingHourSelectOption, styles.hourOfDaySelectOption)} key={item.value} value={item.value}>
            {item.text}
          </option>
        );
      })}
    </select>
  );
};
