import { errorUnexpectedToken, completeSource } from './Helpers.mjs'

const TT_OPEN_PARENTHESIS = 1 // '('
const TT_CLOSE_PARENTHESIS = 2 // ')'
const TT_NAME = 3 // 'name'
const TT_PARAMETER_ID = 4 // 'parameterId'
const TT_SYSTEM_TYPE_ID = 5 // 'systemTypeId'
const TT_BLOCK_TYPE_ID = 6 // 'blockTypeId'
const TT_CONSTANT = 7 // 'const'
const TT_SEMICOLON = 8 // ';'

function *lexer(source) {
    const rx = /([A-Z]+|\(|\)|\;|\$\d+|\@\d+|\#\d+|-?\d+(?:\.\d+)?|[ \t\r\n]|.)/g
    let position = 0
    let lineno = 0
    let charno = 0
    for (let m; m = rx.exec(source);) {
        const value = m[1]
        const c = value.charAt(0)
        const cc = c.charCodeAt(0)
        const token = { value, position, source, lineno, charno }
        position += value.length
        charno += value.length
        if (c === '(') {
            token.type = TT_OPEN_PARENTHESIS
        } else if (c === ')') {
            token.type = TT_CLOSE_PARENTHESIS
        } else if (c === '$') {
            token.type = TT_PARAMETER_ID
        } else if (c === '@') {
            token.type = TT_SYSTEM_TYPE_ID
        } else if (c === '#') {
            token.type = TT_BLOCK_TYPE_ID
        } else if ('A'.charCodeAt(0) <= cc && cc <= 'Z'.charCodeAt(0)) {
            token.type = TT_NAME
        } else if (c === '-' || ('0'.charCodeAt(0) <= cc && cc <= '9'.charCodeAt(0))) {
            token.type = TT_CONSTANT
        } else if (c === ';') {
            token.type = TT_SEMICOLON
        } else if (c === ' ' || c === '\t' || c === '\r' || c === '\n') {
            if (c === '\n') {
                lineno ++
                charno = 0
            }
            continue
        }
        yield token
    }
}

function tokenizer(source) {
    const lex = lexer(source)
    let current = lex.next()?.value
    const top = () => current
    const next = () => {
        const prev = current
        current = lex.next()?.value
        return prev
    }
    return { source, top, next }
}

const NT_CONSTANT = 1 // 'const'
const NT_IDENTIFIER = 2 // 'id'
const NT_FUNCTION_CALL = 3 // 'call'

function astNodeConstant(token) {
    return {
        type: NT_CONSTANT,
        value: Number(token.value.substring(1)),
        from: token.position,
        to: token.position + token.value.length,
        source: token.source,
    }
}

function astNodeIdentifier(token) {
    return {
        type: NT_IDENTIFIER,
        value: BigInt(token.value.substring(1)),
        from: token.position,
        to: token.position + token.value.length,
        source: token.source,
    }
}

function astNodeFunctionCall(token, args) {
    return {
        type: NT_FUNCTION_CALL,
        name: token.value,
        from: token.position,
        to: token.position + token.value.length,
        source: token.source,
        args
    }
}

function parseFunctionCall(tokenizer) {
    const functionName = tokenizer.next()
    if (!functionName || functionName.type !== TT_NAME) {
        errorUnexpectedToken(tokenizer, token, `function's name`)
    }
    const openParenthesis = tokenizer.next()
    if (!openParenthesis || openParenthesis.type !== TT_OPEN_PARENTHESIS) {
        errorUnexpectedToken(tokenizer, token, `'('`)
    }
    const args = []
    let requireArgument = false
    while (true) {
        const token = tokenizer.top()
        if (!token) {
            errorUnexpectedToken(tokenizer, token, `')'`)
        } else if (token?.type === TT_CLOSE_PARENTHESIS) {
            if (requireArgument) {
                errorUnexpectedToken(tokenizer, token, `expression as function's argument`)
            }
            break
        } else {
            args.push(parseExpression(tokenizer))
            requireArgument = false
            const nextToken = tokenizer.top()
            if (nextToken?.type === TT_SEMICOLON) {
                requireArgument = true
                tokenizer.next()
            }
        }
    }
    const closeParenthesis = tokenizer.next()
    if (!closeParenthesis || closeParenthesis.type !== TT_CLOSE_PARENTHESIS) {
        errorUnexpectedToken(tokenizer, token, `')'`)
    }
    return astNodeFunctionCall(functionName, args)
}

function parseConstant(tokenizer) {
    const token = tokenizer.next()
    if (!token || token.type !== TT_CONSTANT) {
        errorUnexpectedToken(tokenizer, token, 'constant')
    }
    return astNodeConstant(token)
}

function parseIdentifier(tokenizer) {
    const token = tokenizer.next()
    if (!token || !(token.type === TT_PARAMETER_ID || token.type === TT_SYSTEM_TYPE_ID || token.type === TT_BLOCK_TYPE_ID)) {
        errorUnexpectedToken(tokenizer, token, 'identifier')
    }
    return astNodeIdentifier(token)
}

function parseExpression(tokenizer) {
    const token = tokenizer.top()
    const tt = token?.type
    if (tt === TT_CONSTANT) {
        return parseConstant(tokenizer)
    } else if (tt === TT_PARAMETER_ID || tt === TT_SYSTEM_TYPE_ID || tt === TT_BLOCK_TYPE_ID) {
        return parseIdentifier(tokenizer)
    } else if (tt === TT_NAME) {
        return parseFunctionCall(tokenizer)
    }
    errorUnexpectedToken(tokenizer, token, 'expression')
}

const parse = source => completeSource(tokenizer(source), parseExpression)

export {

    NT_CONSTANT,
    NT_IDENTIFIER,
    NT_FUNCTION_CALL,

    parse
}
