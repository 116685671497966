import { UsersListStruct } from '../../../Scada/Parser/Users/UsersListStruct.mjs';
import { UserStructExtended } from './UserStructExtended.mjs';


class UsersListStructExtended extends UsersListStruct {

    constructor(users = []) {
      //вот тут переписать
        super();
        this.items = users;
    }

    readFrom(dataView, startOffset, isLittleEndian = true) {
        let endOffset = startOffset;
        let numItems = dataView.getUint32(endOffset, isLittleEndian);
        endOffset += 4;
        this.items = [];

        for (let i = 0; i < numItems; i ++) {
            let itemStruct = new UserStructExtended();
            endOffset = itemStruct.readFrom(dataView, endOffset, isLittleEndian);
            this.items.push(itemStruct);
        }
        return endOffset;
    }

    writeTo(dataView, startOffset, isLittleEndian = true) {
        let endOffset = startOffset;
        dataView.setUint32(endOffset, this.items.length, isLittleEndian);
        endOffset += 4;
        for (let i = 0; i < this.items.length; i ++) {
            endOffset = this.items[i].writeTo(dataView, endOffset, isLittleEndian);
        }
        return endOffset;
    }

    get numItems() {
        return this.items.length;
    }

    getCurrentSize() {
        return 0
            + 4 // numItems
            + this.items.reduce((size, itemStruct) => size + itemStruct.getCurrentSize(), 0) // items
    }

    clone() {
        let clone = new UsersListStruct(
            this.items.map(itemStruct => itemStruct.clone())
        );
        clone.setParserOptions(this.getParserOptions());
        return clone;
    }
}

export {UsersListStructExtended};
