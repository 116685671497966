import { VscDebugBreakpointLog } from 'react-icons/vsc';
import classNames from 'classnames';

import styles from './iconObjectConfigurationTree.module.scss';

export default function IconObjectConfigurationTree(props) {
  const { isDeleted, isLink } = props;

  return <VscDebugBreakpointLog className={classNames(styles.icon, isLink && styles.link, isDeleted && styles.isDeleted)} />;
}
