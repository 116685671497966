class TemplateSetpoints {
  constructor(kernel, templateSetpointsList, systemTypeId) {
    this.kernel = kernel;
    this.templateSetpointsList = templateSetpointsList;
    this.systemTypeId = systemTypeId;
  }

  get setpointsList() {
    return this.templateSetpointsList;
  }

  get systemType() {
    return this.kernel.systemTypes[this.systemTypeId];
  }

  get systemTypeDescription() {
    return this.kernel.systemTypes[this.systemTypeId].description;
  }
}

export { TemplateSetpoints };