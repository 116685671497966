import Utils from "../../../utils/Utils.mjs";

const TYPE_BYTE = 1;
const TYPE_CHAR = 2;
const TYPE_INT16 = 3;
const TYPE_UINT16 = 4;
const TYPE_INT32 = 5;
const TYPE_UINT32 = 6;
const TYPE_INT64 = 7;
const TYPE_UINT64 = 8;
const TYPE_FLOAT = 9;
const TYPE_DOUBLE = 10;


export const validationScheme = {
  [TYPE_BYTE]: {
    minLength: {
      value: 1,
      message: 'Поле должно содержать минимум 1 символ',
    },
    maxLength: {
      value: 3,
      message: 'Поле может содержать максимум 3 символов',
    },
    min: { value: 0, message: 'Минимальное допустимое значение 0' },
    max: { value: 255, message: 'Максимальное допустимое значение 255' },
  },
  [TYPE_CHAR]: {
    min: {
      value: 0,
      message: 'Минимальное значение 0',
    },
    max: {
      value: 255,
      message: 'Минимальное значение 255',
    },
    minLength: {},
    maxLength: {
      value: 1,
      message: 'Поле может содержать максимум 3 символа',
    },
  },
  [TYPE_INT16]: {
    minLength: {
      value: 1,
      message: 'Поле должно содержать минимум 1 символ',
    },
    maxLength: {
      value: 7,
      message: 'Поле может содержать максимум 7 символов',
    },
    min: { value: -32768, message: 'Минимальное допустимое значение -32768' },
    max: { value: 32768, message: 'Максимальное допустимое значение 32768' },
  },
  [TYPE_UINT16]: {
    minLength: {
      value: 1,
      message: 'Поле должно содержать минимум 1 символ',
    },
    maxLength: {
      value: 5,
      message: 'Поле может содержать максимум 5 символов',
    },
    min: { value: 0, message: 'Минимальное допустимое значение 0' },
    max: { value: 65535, message: 'Максимальное допустимое значение 65535' },
  },
  [TYPE_INT32]: {
    minLength: {
      value: 1,
      message: 'Поле должно содержать минимум 1 символ',
    },
    maxLength: {
      value: 12,
      message: 'Поле может содержать максимум 12 символ',
    },
    min: { value: -2147483648, message: 'Минимальное допустимое значение -2147483648' },
    max: { value: 2147483648, message: 'Максимальное допустимое значение 2147483648' },
  },
  [TYPE_UINT32]: {
    minLength: {
      value: 1,
      message: 'Поле должно содержать минимум 1 символ',
    },
    maxLength: {
      value: 10,
      message: 'Поле может содержать максимум 10 символов',
    },
    min: { value: 0, message: 'Минимальное допустимое значение 0' },
    max: { value: 4294967295, message: 'Максимальное допустимое значение 4294967295' },
  },
  [TYPE_INT64]: {},
  [TYPE_UINT64]: {
    minLength: {
      value: 1,
      message: 'Поле должно содержать минимум 1 символ',
    },
    maxLength: {
      value: 20,
      message: 'Поле может содержать максимум 20 символов',
    },
    min: { value: 0, message: 'Минимальное допустимое значение 0' },
    max: {},
  },
  [TYPE_FLOAT]: {},
  [TYPE_DOUBLE]: {},
};


const createValidationFunction = (type) => type === 9 || type === 10 ?
  (value) => {
    const str = value.toString();
    if (str.split('')[str.length - 1] === '.') {
      return 'Неправильный формат заполнения!';
    } else {
      return Utils.testIsNumber(value) ? null : 'Неправильный формат заполнения!';
    }
  } :
  (value) => Utils.testIsInteger(value) ? null : 'Допустимый формат ввода - целые числа';


export const createValidationScheme = (type) => {
  const validate = createValidationFunction(type);
  const min = validationScheme[type]?.min || {};
  const max = validationScheme[type]?.max || {};
  const minLength = validationScheme[type]?.minLength || {};
  const maxLength = validationScheme[type]?.maxLength || {};

  return {
    required: 'Поле обязательно к заполнению',
    min,
    max,
    minLength,
    maxLength,
    validate,
    deps: ["systemTypeId", "parameterId"],
  };
};