class CodesOperations {
  constructor(kernel, codesOperationsStruct) {
    this.kernel = kernel;
    this.codesOperationsStruct = codesOperationsStruct;
  }

  get id() {
    return this.codesOperationsStruct.id;
  }

  get name() {
    return this.codesOperationsStruct.name.value;
  }

  get description() {
    return this.codesOperationsStruct.description.value;
  }

  get comment() {
    return this.codesOperationsStruct.comment.value;
  }

  get licensesType() {
    return this.codesOperationsStruct.licensesType;
  }
}

export { CodesOperations };