import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';

import { Tooltip } from '../components.mjs';

import styles from './inputColor.module.scss';

export default function InputColor(props) {
  const {
    value,
    setValue,
    placeholder,
    canEdit,
    isWritable,
    isDeleted,
    register,
    unregister,
    name,
    handleFocus,
    showTooltip,
    errors,
    validationScheme,
    setHandleChangeComponentTableRow,
    sendingData,
  } = props;

  const initialValue = useRef(value);
  const [colorValue, setColorValue] = useState(value);

  const updateValue = ({ target }) => {
    if (target.value === initialValue.current.toString()) {
      setHandleChangeComponentTableRow(false);
    } else {
      setHandleChangeComponentTableRow(true);
    }

    setValue(name, target.value);
    setColorValue(target.value);
  };

  const handleChangeInputColor = ({ target }) => {
    if (target.value === initialValue.current.toString()) {
      setHandleChangeComponentTableRow(false);
    } else {
      setHandleChangeComponentTableRow(true);
    }

    setValue(name, target.value);
    setColorValue(target.value);
  };

  useEffect(() => {
    setValue(name, value.toString());
    return () => {
      unregister([name]);
    };
  }, []);

  useEffect(() => {
    initialValue.current = value;
    return () => {};
  }, [value]);

  return (
    <div className={styles.container}>
      <div className={styles.from}>
        <div className={styles.controlsContainer}>
          <input
            type="text"
            placeholder={placeholder}
            className={classNames(
              styles.inputText,
              errors[name] && styles.error,
              sendingData && styles.sending,
              canEdit && styles.sending,
              !isWritable && styles.sending,
              isDeleted && styles.sending,
            )}
            {...register(name, validationScheme)}
            onFocus={handleFocus}
            onInput={updateValue}
            readOnly={sendingData || canEdit || !isWritable || isDeleted}
          />
          <input
            type="color"
            className={classNames(
              styles.inputColor,
              sendingData && styles.sending,
              canEdit && styles.sending,
              !isWritable && styles.sending,
              isDeleted && styles.sending,
            )}
            value={colorValue}
            onChange={handleChangeInputColor}
            disabled={sendingData || canEdit || !isWritable || isDeleted}
          />
        </div>
        {showTooltip && errors[name] && (
          <Tooltip message={`${errors[name]?.message || 'Ошибка заполнения! Поле должно содержать значение HEX кодировки!'} `} />
        )}
      </div>
    </div>
  );
}
