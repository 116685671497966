import classNames from 'classnames';

import Utils from '../../../../utils/Utils.mjs';

import styles from './buildAlignmentEqualHourMarksSelect.module.scss';

export const BuildAlignmentEqualHourMarksSelect = (props) => {
  const { sendingData, isWritable, isDeleted, canEdit, alignmentEqualHourMarksSelectValue, handleChangeAlignmentEqualHourMarksSelect } = props;
  const optionValue = Utils.setAlignmentEqualHourMarksValue();

  return (
    <select
      className={classNames(
        styles.reportingHourSelect,
        styles.alignmentEqualHourMarksSelect,
        sendingData && styles.inputrepRtingHourSending,
        canEdit && styles.inputrepRtingHourSending,
        !isWritable && styles.inputrepRtingHourSending,
        isDeleted && styles.inputrepRtingHourSending,
      )}
      value={alignmentEqualHourMarksSelectValue}
      onInput={handleChangeAlignmentEqualHourMarksSelect}
      disabled={sendingData || canEdit || !isWritable || isDeleted}
    >
      {optionValue.map((item) => {
        return (
          <option className={classNames(styles.reportingHourSelectOption, styles.dotlessAlignmentSelectOption)} key={item.value} value={item.value}>
            {item.text}
          </option>
        );
      })}
    </select>
  );
};
