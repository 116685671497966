import { Struct } from '../../../Scada/Parser/Struct.mjs';
import { StringStruct } from '../../../Scada/Parser/StringStruct.mjs';

class LicensesTypeStruct extends Struct {

  constructor(id = 0, name = new StringStruct('')) {
    super();
    this.id = id;
    this.name = name;
  }

  readFrom(dataView, startOffset, isLittleEndian = true) {
    let endOffset = startOffset;
    this.id = dataView.getUint32(endOffset, isLittleEndian);
    endOffset += 4;
    endOffset = this.name.readFrom(dataView, endOffset, isLittleEndian);
    return endOffset;
  }

  writeTo(dataView, startOffset, isLittleEndian = true) {
    let endOffset = startOffset;
    dataView.setUint32(endOffset, this.id, isLittleEndian);
    endOffset += 4;
    endOffset = this.name.writeTo(dataView, endOffset, isLittleEndian);
    return endOffset;
  }

  getCurrentSize() {
    return 0
      + 4 // id
      + this.name.getCurrentSize(); // name
  }

  clone() {
    let clone = new LicensesTypeStruct(
      this.id,
      this.name.clone(),
    )
    clone.setParserOptions(this.getParserOptions());
    return clone;
  }
}

export { LicensesTypeStruct };
