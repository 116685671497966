import { useState, useEffect } from 'react';

import { VersionProjectServices } from '../../services/services.mjs';
import styles from '../version-project/versionProject.module.scss';

export default function VersionProject() {
  const [version, setVersion] = useState(null);

  useEffect(() => {
    VersionProjectServices().then(({ version }) => {
      setVersion(version);
    });
    return () => {};
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.versionProjectWrap}>
        {version !== null ? <span className={styles.text}>Версия: {version}</span> : <span className={styles.loader}></span>}
      </div>
    </div>
  );
}
