import $api from '../axios/axios.mjs';

export class AuthService {
  static async login(user) {
    return $api.post('/login', user);
  }

  static async valid() {
    return $api.get('/valid');
  }

  static async refreshToken() {
    return $api.get('/refresh');
  }

  static async changeUserSettingsInToken(settings) {
    return $api.post('/change-user-settings-in-token', { settings });
  }
}
