import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { NavApp, ContentApp, HeaderApp } from '../../layout/layout.mjs';
import { ErrorMessagePopup, EventLogContainer, Loading } from '../../components/components.mjs';
import { useKernel } from '../../context/ContextKernel.mjs';
import { flagsParser } from '../../utils/flags-parser/flagsParser.mjs';
import { useUserTokenDecode } from '../../hooks/hooks.mjs';
import { UsersService } from '../../services/UsersService.mjs';
import { LicensesService } from '../../services/LicensesService.mjs';
import { LoggingService } from '../../services/LoggingService.mjs';
import { ConfigurationService } from '../../services/ConfigurationServices.mjs';
import { LogsProvider } from '../../context/ContextLogs.mjs';

import styles from './eventLogs.module.scss';

export default function EventLogs() {
  const navigate = useNavigate();
  const { kernel, currentLicense } = useKernel();

  const [userName, setUserName] = useState('');
  const [isError, setIsError] = useState(false); //если есть какая-то ошибка
  const [errorMessage, setErrorMessage] = useState('Ошибка!'); //сообщение об ошибке
  const { flags } = useUserTokenDecode();
  const { hasAccessRightsEventLogsModule } = flagsParser(flags);
  const [sendingData, setSendingData] = useState(true);

  const actionPerformErrorMessagePopup = () => {
    setIsError(false);
    navigate('/section-selection');
  };

  const initialPageUserState = () => {
    setUserName(kernel.getUser().name);
  };

  const initialDataPage = async () => {
    Promise.all([
      //получаем группы пользователей
      UsersService.usersGroupsListStruct(),
      //получаем пользователей
      UsersService.usersListStruct(),
      // получаем лицензии
      LicensesService.licensesListStruct(),
      // получаем типы лицензий
      LicensesService.licensesTypesListStruct(),
      // получаем коды операций
      LoggingService.getCodesOperations(),
      // список конфигураций
      ConfigurationService.getConfigurationElem(),
    ])
      .then((values) => {
        const [usersGroupsListStruct, usersListStruct, licensesListStruct, licensesTypesListStruct, codesOperationsListStruct, configurationItems] =
          values;

        kernel.buildParametersUserTree(usersListStruct.items, usersGroupsListStruct.items);
        kernel.buildLicensesTree(licensesListStruct.items, licensesTypesListStruct.items);
        kernel.buildCodesOperation(codesOperationsListStruct.items);
        kernel.configurationList = configurationItems.items;

        setSendingData(false);
      })
      .catch((error) => {
        setIsError(true);
        setErrorMessage('Ошибка загрузки данных!');
        setSendingData(false);
        console.error(error);
      });
  };

  useEffect(() => {
    initialPageUserState();
    initialDataPage();
    kernel.timerRefreshToken(); //запуск обновления токена
    return () => {};
  }, []);

  useEffect(() => {
    const { hasAccessEventLogsModule = undefined } = currentLicense;
    if (hasAccessEventLogsModule !== 'true' || hasAccessEventLogsModule === undefined || hasAccessRightsEventLogsModule === false) {
      navigate('/section-selection');
    }
    return () => {};
  }, [currentLicense]);

  return (
    <div className={styles.eventLogs}>
      {isError && <ErrorMessagePopup errorMessage={errorMessage} actionPerform={actionPerformErrorMessagePopup} />}

      <NavApp />
      <ContentApp>
        <div className={styles.headerContainer}>
          <HeaderApp configurationName="Журнал событий" userName={userName} />
        </div>
        <div className={styles.contentContainer}>
          {sendingData ? (
            <Loading />
          ) : (
            <LogsProvider>
              <EventLogContainer />
            </LogsProvider>
          )}
        </div>
      </ContentApp>
    </div>
  );
}
