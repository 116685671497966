export const searchOption = (optionsList, searchValue) => {
  const newOptionList = [];
  searchValue = searchValue.toLowerCase();

  if (searchValue.length === 0) return optionsList;

  optionsList.forEach((option) => {
    const keyWord = option.label.toLowerCase();
    const contain = keyWord.includes(searchValue);

    if (contain) newOptionList.push(option);
  });

  return newOptionList;
};