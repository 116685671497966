import classNames from 'classnames';
import { useEffect, useRef } from 'react';

import { setPositionContextMenu } from './model/setPositionContextMenu.mjs';
import styles from './contextMenu.module.scss';

export default function ContextMenu(props) {
  const { contextMenuState } = props;
  const { position, currentTarget, contextMenuItems, preferredPosition = 'right' } = contextMenuState;
  const contextMenuElement = useRef();

  useEffect(() => {
    if (currentTarget) {
      const targetElement = currentTarget; // местоположение элемента на котором было вызвано контекстное меню
      const { leftPosition, topPosition } = setPositionContextMenu(targetElement, position, contextMenuElement, preferredPosition);

      contextMenuElement.current.style.left = leftPosition;
      contextMenuElement.current.style.top = topPosition;
    }
    return () => {};
  }, [currentTarget, position, preferredPosition]);

  return (
    <div ref={contextMenuElement} className={styles.contextMenu}>
      <ul className={styles.contextMenuCommandList}>
        {contextMenuItems.map(({ id, icon, renderElement, isActive, text, onClick, className = null }) => {
          return renderElement ? (
            <li
              key={id}
              id={id}
              className={classNames(styles.contextMenuCommandListItem, isActive && styles.contextMenuCommandListItemActive, className)}
              onClick={onClick}
            >
              {icon && <div className={styles.iconContainer}>{icon}</div>}
              <span>{text}</span>
            </li>
          ) : (
            <></>
          );
        })}
      </ul>
    </div>
  );
}

// preferredPosition (top | bottom | left | right | mouseTop | mouseBottom | mouseLeft | mouseRight)
