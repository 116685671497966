import { useState } from 'react';

import { useKernel } from '../../context/ContextKernel.mjs';

import { Notification } from './ui/notification/Notification';
import styles from './notificationList.module.scss';

export default function NotificationList() {
  const { notificationList } = useKernel();
  const [isCursorHovered, setIsCursorHovered] = useState(false);

  return (
    <div className={styles.container} onMouseOver={() => setIsCursorHovered(true)} onMouseOut={() => setIsCursorHovered(false)}>
      {notificationList.map((notification) => {
        return <Notification key={notification.id} notification={notification} isCursorHovered={isCursorHovered} />;
      })}
    </div>
  );
}
