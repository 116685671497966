import classNames from 'classnames';
import moment from 'moment';
import { useCallback, useEffect, useRef, useState } from 'react';
import { HiCheck } from 'react-icons/hi';
import { useForm } from 'react-hook-form';
import { GrTree } from 'react-icons/gr';
import { FaCalendar } from 'react-icons/fa6';

import InputNumber from '../input-number/InputNumber';
import { ConfirmationPopUp, LoadingInUserPageForm, SettlementServerPopUp, Tooltip } from '../../../components.mjs';

import Utils from '../../../../utils/Utils.mjs';
import { useKernel } from '../../../../context/ContextKernel.mjs';
import { EnumTypeSimulator } from '../../../../quantum-lib/Enum/SimulatorServerEnum.mjs';
import { ValueField } from '../index.mjs';
import { PARAMETER_CLASS_VALUE } from '../../../../global/variables/objectTypeVariables.mjs';

import styles from './taskEditorArguments.module.scss';

export function TaskEditorArguments(props) {
  const { sendingData, dataSentSuccessfully, task, onSubmit } = props;

  const [state, setState] = useState({});
  const [showTooltip, setShowTooltip] = useState({
    parameterId: false,
    period: false,
    frequency: false,
    value_1: false,
    value_2: false,
    value_3: false,
  });
  const [visible, setVisible] = useState(false);
  const [activeElementTree, setActiveElementTree] = useState(0);
  const [showConfirmPopUp, setShowConfirmPopUp] = useState(false);
  const { kernel, configuration } = useKernel();
  const tmpSimulatorTypeValue = useRef(null);
  const initialMode = useRef(null);

  const handleFocus = useCallback(
    (event) => {
      const name = event.target.name;

      setShowTooltip({
        ...showTooltip,
        [name]: true,
      });
    },
    [showTooltip],
  );

  const handleBlur = useCallback(
    (event) => {
      const name = event.target.name;

      setShowTooltip({
        ...showTooltip,
        [name]: false,
      });
    },
    [showTooltip],
  );

  const {
    register,
    unregister,
    formState: { errors, isValid },
    handleSubmit,
    setValue,
    trigger,
    getValues,
  } = useForm({
    mode: 'onChange',
  });

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    if (name === 'simulatorType') {
      const enumTypeSimulatorValue = EnumTypeSimulator[value];
      const mode = enumTypeSimulatorValue.mode;

      if (mode !== initialMode.current) {
        tmpSimulatorTypeValue.current = { name, value, mode };
        setShowConfirmPopUp(true);
      } else {
        setState({
          ...state,
          [name]: value,
        });
        setValue(name, value);
      }
    } else {
      setState({
        ...state,
        [name]: value,
      });
      setValue(name, value);
    }
  };

  const setParameterId = () => {
    const parameterId = parseInt(activeElementTree);

    setState({
      ...state,
      parameterId,
    });
    setValue('parameterId', parameterId);

    setVisible(false);
    setActiveElementTree(0);
  };

  const closePopUp = () => {
    setVisible(false);
    setActiveElementTree(0);
  };

  const focusInputDateTimeLocal = (event) => {
    const element = event.currentTarget;
    const inputElement = element.previousElementSibling;
    inputElement.showPicker();
  };

  const handleChangeMode = () => {
    const { name, value, mode } = tmpSimulatorTypeValue.current;

    setState({
      ...state,
      [name]: value,
      value_1: mode === 'number' ? 0 : '',
      value_2: mode === 'number' ? 0 : '',
      value_3: mode === 'number' ? 0 : '',
    });
    setValue(name, value);
    setValue('value_1', mode === 'number' ? 0 : '');
    setValue('value_2', mode === 'number' ? 0 : '');
    setValue('value_3', mode === 'number' ? 0 : '');
    setShowConfirmPopUp(false);
    initialMode.current = mode;
  };

  const validateParameterId = (value) => {
    const isInteger = Utils.testIsInteger(value);
    if (!isInteger) return 'Допустимый формат ввода - целые числа!';

    const parameterStruct = kernel.getParameterById(value);
    if (!parameterStruct) return 'Такого параметра не существует!';

    const parameterIsDeleted = parameterStruct.isDeleted;
    if (parameterIsDeleted) return 'Для удаленных объектов нельзя создавать уставки!';

    const classify = parameterStruct.classify();
    if (classify !== PARAMETER_CLASS_VALUE) return 'Уставки можно создавать только для параметров!';

    return true;
  };

  useEffect(() => {
    initialMode.current = EnumTypeSimulator[task.activeTask.simulatorType].mode;

    setState({
      simulatorType: task.activeTask.simulatorType,
      parameterId: parseInt(task.activeTask.parameterId),
      period: task.activeTask.period,
      frequency: task.activeTask.frequency,
      timestampCreate: moment(new Date(task.activeTask.timestampCreate * 1000)).format('YYYY-MM-DD HH:mm:ss'),
      timestampFrom: moment(new Date(task.activeTask.timestampFrom * 1000)).format('YYYY-MM-DD HH:mm:ss'),
      timestampTo: moment(new Date(task.activeTask.timestampTo * 1000)).format('YYYY-MM-DD HH:mm:ss'),
      value_1: task.activeTask.value_1,
      value_2: task.activeTask.value_2,
      value_3: task.activeTask.value_3,
    });

    setValue('simulatorType', task.activeTask.simulatorType);
    setValue('parameterId', parseInt(task.activeTask.parameterId));
    setValue('period', task.activeTask.period);
    setValue('frequency', task.activeTask.frequency);
    setValue('timestampCreate', moment(new Date(task.activeTask.timestampCreate * 1000)).format('YYYY-MM-DD HH:mm:ss'));
    setValue('timestampFrom', moment(new Date(task.activeTask.timestampFrom * 1000)).format('YYYY-MM-DD HH:mm:ss'));
    setValue('timestampTo', moment(new Date(task.activeTask.timestampTo * 1000)).format('YYYY-MM-DD HH:mm:ss'));
    setValue('value_1', task.activeTask.value_1);
    setValue('value_2', task.activeTask.value_2);
    setValue('value_3', task.activeTask.value_3);

    trigger(['parameterId', 'period', 'frequency', 'timestampCreate', 'timestampFrom', 'timestampTo', 'value_1', 'value_2', 'value_3']);

    return () => {};
  }, [task.activeTaskId]);

  return (
    <div className={styles.taskEditorContainer}>
      {visible && (
        <SettlementServerPopUp
          configuration={configuration}
          activeElementTree={activeElementTree}
          setActiveElementTree={setActiveElementTree}
          closePopUp={closePopUp}
          callback={setParameterId}
          propertyVisibleInTree={false}
          parameterDisabled="true"
        />
      )}
      {showConfirmPopUp && (
        <ConfirmationPopUp
          actionConfirmation={handleChangeMode}
          closePopup={() => setShowConfirmPopUp(false)}
          message="При смене типа задачи, будут очищены поля для значений. Продолжить?"
          loading={false}
          success={false}
        />
      )}
      <div className={styles.serverSettingsContainer}>
        <div className={styles.editorWrap}>
          <form className={styles.form} onSubmit={handleSubmit(onSubmit)} id="taskEditorArgumentsForm">
            <div className={styles.formItem}>
              <span className={styles.formItemText} title="Тип задачи">
                Тип задачи
              </span>
              <div className={styles.formItemFieldContainer}>
                <select
                  className={classNames(styles.formItemSelect, (sendingData || dataSentSuccessfully) && styles.sending)}
                  value={state.simulatorType}
                  onInput={handleChange}
                  disabled={sendingData || dataSentSuccessfully}
                  {...register('simulatorType')}
                >
                  {Object.values(EnumTypeSimulator).map((option) => {
                    return (
                      <option key={option.value} className={styles.formItemOption} value={option.value}>
                        {option.text}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className={styles.formItem}>
              <span className={styles.formItemText} title="Id параметра">
                Id параметра
              </span>
              <div className={styles.formItemFieldContainer}>
                <InputNumber
                  name="parameterId"
                  value={state.parameterId}
                  dataSentSuccessfully={dataSentSuccessfully}
                  sendingData={sendingData}
                  showTooltip={showTooltip}
                  errors={errors}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                  onInput={handleChange}
                  register={{
                    ...register('parameterId', {
                      validate: (value) => validateParameterId(value),
                    }),
                  }}
                >
                  <div
                    className={classNames(styles.showParameterTree, (sendingData || dataSentSuccessfully) && styles.sending)}
                    onClick={() => {
                      !sendingData && !dataSentSuccessfully && setVisible(true);
                    }}
                  >
                    <GrTree className={styles.showParameterTreeIcon} />
                  </div>
                </InputNumber>
              </div>
            </div>
            <div className={styles.formItem}>
              <span className={styles.formItemText} title="Период">
                Период
              </span>
              <div className={styles.formItemFieldContainer}>
                <InputNumber
                  name="period"
                  value={state.period}
                  dataSentSuccessfully={dataSentSuccessfully}
                  sendingData={sendingData}
                  showTooltip={showTooltip}
                  errors={errors}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                  onInput={handleChange}
                  register={{
                    ...register('period', {
                      maxLength: {
                        value: 255,
                        message: 'Поле может содержать максимум 255 символов',
                      },
                      min: {
                        value: 1,
                        message: 'Значение должно быть больше 1',
                      },
                      validate: (value) => {
                        return Utils.testIsInteger(value) ? null : 'Неверный формат';
                      },
                    }),
                  }}
                />
              </div>
            </div>
            <div className={styles.formItem}>
              <span className={styles.formItemText} title="Частота генерации">
                Частота генерации
              </span>
              <div className={styles.formItemFieldContainer}>
                <InputNumber
                  name="frequency"
                  value={state.frequency}
                  dataSentSuccessfully={dataSentSuccessfully}
                  sendingData={sendingData}
                  showTooltip={showTooltip}
                  errors={errors}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                  onInput={handleChange}
                  register={{
                    ...register('frequency', {
                      maxLength: {
                        value: 255,
                        message: 'Поле может содержать максимум 255 символов',
                      },
                      min: {
                        value: 1,
                        message: 'Значение должно быть больше 1',
                      },
                      validate: (value) => {
                        return Utils.testIsInteger(value) ? null : 'Неверный формат';
                      },
                    }),
                  }}
                />
              </div>
            </div>
            <div className={styles.formItem}>
              <span className={styles.formItemText} title="Дата создания">
                Дата создания
              </span>
              <div className={styles.formItemFieldContainer}>
                <div className={styles.dateWrap}>
                  <input
                    className={classNames(
                      styles.inputTypeDateTime,
                      (sendingData || dataSentSuccessfully) && styles.sending,
                      errors.timestampCreate && styles.error,
                    )}
                    type="datetime-local"
                    value={state.timestampCreate}
                    onInput={({ target }) => setState({ ...state, timestampCreate: target.value })}
                    onFocus={handleFocus}
                    disabled={sendingData || dataSentSuccessfully}
                    {...register('timestampCreate', {
                      required: { message: 'Поле обязательно для заполнения!' },
                      validate: (valueField) => {
                        if (valueField.length == 0) return 'Неверный формат!';
                      },
                    })}
                    onBlur={handleBlur}
                  />
                  <button type="button" disabled={sendingData || dataSentSuccessfully} onClick={focusInputDateTimeLocal}>
                    <FaCalendar className={classNames(styles.iconCalendar, (sendingData || dataSentSuccessfully) && styles.sending)} />
                  </button>
                  {showTooltip.timestampCreate && errors.timestampCreate && (
                    <Tooltip message={`${errors.timestampCreate?.message || 'Ошибка заполнения!'} `} />
                  )}
                </div>
              </div>
            </div>
            <div className={styles.formItem}>
              <span className={styles.formItemText} title="Дата начала генерации данных">
                Дата начала
              </span>
              <div className={styles.formItemFieldContainer}>
                <div className={styles.dateWrap}>
                  <input
                    className={classNames(
                      styles.inputTypeDateTime,
                      (sendingData || dataSentSuccessfully) && styles.sending,
                      errors.timestampFrom && styles.error,
                    )}
                    type="datetime-local"
                    value={state.timestampFrom}
                    onInput={({ target }) => setState({ ...state, timestampFrom: target.value })}
                    onFocus={handleFocus}
                    disabled={sendingData || dataSentSuccessfully}
                    {...register('timestampFrom', {
                      required: { message: 'Поле обязательно для заполнения!' },
                      validate: (valueField) => {
                        if (valueField.length == 0) return 'Неверный формат!';

                        const timestampFromValueStr = getValues('timestampFrom');
                        const timestampToValueStr = getValues('timestampTo');

                        const timestampFromValue = timestampFromValueStr ? timestampFromValueStr.split('T').join('T') : '';
                        const timestampToValue = timestampToValueStr ? timestampToValueStr.split('T').join('T') : '';
                        const dateTimeToTimeStamp = new Date(timestampToValue);
                        const dateTimeFromTimeStamp = new Date(timestampFromValue);

                        if (dateTimeToTimeStamp < dateTimeFromTimeStamp) return "Дата 'c' не должна быть больше даты 'по'!";
                      },
                    })}
                    onBlur={handleBlur}
                  />
                  <button type="button" disabled={sendingData || dataSentSuccessfully} onClick={focusInputDateTimeLocal}>
                    <FaCalendar className={classNames(styles.iconCalendar, (sendingData || dataSentSuccessfully) && styles.sending)} />
                  </button>
                  {showTooltip.timestampFrom && errors.timestampFrom && (
                    <Tooltip message={`${errors.timestampFrom?.message || 'Ошибка заполнения!'} `} />
                  )}
                </div>
              </div>
            </div>
            <div className={styles.formItem}>
              <span className={styles.formItemText} title="Дата конца генерации данных">
                Дата конца
              </span>
              <div className={styles.formItemFieldContainer}>
                <div className={styles.dateWrap}>
                  <input
                    className={classNames(styles.inputTypeDateTime, (sendingData || dataSentSuccessfully) && styles.sending, errors.timestampTo && styles.error)}
                    type="datetime-local"
                    value={state.timestampTo}
                    onInput={({ target }) => setState({ ...state, timestampTo: target.value })}
                    onFocus={handleFocus}
                    disabled={sendingData || dataSentSuccessfully}
                    {...register('timestampTo', {
                      required: { message: 'Поле обязательно для заполнения!' },
                      validate: (valueField) => {
                        if (valueField.length == 0) return 'Неверный формат!';

                        const timestampFromValueStr = getValues('timestampFrom');
                        const timestampToValueStr = getValues('timestampTo');

                        const timestampFromValue = timestampFromValueStr ? timestampFromValueStr.split('T').join('T') : '';
                        const timestampToValue = timestampToValueStr ? timestampToValueStr.split('T').join('T') : '';
                        const dateTimeToTimeStamp = new Date(timestampToValue);
                        const dateTimeFromTimeStamp = new Date(timestampFromValue);

                        if (dateTimeToTimeStamp < dateTimeFromTimeStamp) return "Дата 'c' не должна быть больше даты 'по'!";
                      },
                    })}
                    onBlur={handleBlur}
                  />
                  <button type="button" disabled={sendingData || dataSentSuccessfully} onClick={focusInputDateTimeLocal}>
                    <FaCalendar className={classNames(styles.iconCalendar, (sendingData || dataSentSuccessfully) && styles.sending)} />
                  </button>
                  {showTooltip.timestampTo && errors.timestampTo && <Tooltip message={`${errors.timestampTo?.message || 'Ошибка заполнения!'} `} />}
                </div>
              </div>
            </div>
            <div className={styles.formItem}>
              <span className={styles.formItemText} title="Значение 1">
                Значение 1
              </span>
              <div className={styles.formItemFieldContainer}>
                <ValueField
                  state={state}
                  name="value_1"
                  setValue={setValue}
                  placeholder={'Значение 1'}
                  register={register}
                  unregister={unregister}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  handleFocus={handleFocus}
                  showTooltip={showTooltip}
                  errors={errors}
                  sendingData={sendingData || dataSentSuccessfully}
                  dataSentSuccessfully={dataSentSuccessfully}
                />
              </div>
            </div>
            <div className={styles.formItem}>
              <span className={styles.formItemText} title="Значение 2">
                Значение 2
              </span>
              <div className={styles.formItemFieldContainer}>
                <ValueField
                  state={state}
                  name="value_2"
                  setValue={setValue}
                  placeholder={'Значение 2'}
                  register={register}
                  unregister={unregister}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  handleFocus={handleFocus}
                  showTooltip={showTooltip}
                  errors={errors}
                  sendingData={sendingData || dataSentSuccessfully}
                  dataSentSuccessfully={dataSentSuccessfully}
                />
              </div>
            </div>
            <div className={styles.formItem}>
              <span className={styles.formItemText} title="Значение 3">
                Значение 3
              </span>
              <div className={styles.formItemFieldContainer}>
                <ValueField
                  state={state}
                  name="value_3"
                  setValue={setValue}
                  placeholder={'Значение 3'}
                  register={register}
                  unregister={unregister}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  handleFocus={handleFocus}
                  showTooltip={showTooltip}
                  errors={errors}
                  sendingData={sendingData || dataSentSuccessfully}
                  dataSentSuccessfully={dataSentSuccessfully}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className={styles.controlContainer}>
        <button
          type="submit"
          form="taskEditorArgumentsForm"
          className={
            sendingData || dataSentSuccessfully
              ? styles.creatingNewModuleFormSubmitSending
              : classNames(styles.creatingNewModuleFormSubmit, !isValid ? styles.formNoValid : styles.formValid)
          }
          disabled={sendingData || dataSentSuccessfully || !isValid}
        >
          {dataSentSuccessfully ? <HiCheck className={styles.checkControl} /> : sendingData ? <LoadingInUserPageForm /> : 'Сохранить'}
        </button>
      </div>
    </div>
  );
}
