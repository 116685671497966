import { Struct } from '../Struct.mjs'
import { StringStruct } from '../StringStruct.mjs'

const SNAPSHOT_NONE = 0x00
const SNAPSHOT_FAST = 0x0A
const SNAPSHOT_MEDIUM = 0x0B
const SNAPSHOT_SLOW = 0x0C

const ACCESS_READ = 1 << 0
const ACCESS_WRITE = 1 << 1
const ACCESS_ACK = 1 << 2
const ACCESS_DELETED = 1 << 3

class ParameterStruct extends Struct {

    constructor(
        id = 0n,
        parentId = 0n,
        systemType = 0,
        snapshotType = 0,
        countValue = 0,
        accessBits = 0,
        timestamp = 0,
        rawValue = 0n,
        strValue = new StringStruct('')
    ) {
        super()
        this.id = id
        this.parentId = parentId
        this.systemType = systemType
        this.snapshotType = snapshotType
        this.countValue = countValue
        this.accessBits = accessBits
        this.timestamp = timestamp
        this.rawValue = rawValue
        this.strValue = strValue
    }

    readFrom(dataView, startOffset, isLittleEndian = true) {
        let endOffset = startOffset
        this.id = dataView.getBigUint64(endOffset, isLittleEndian)
        endOffset += 8
        this.parentId = dataView.getBigUint64(endOffset, isLittleEndian)
        endOffset += 8
        this.systemType = dataView.getUint32(endOffset, isLittleEndian)
        endOffset += 4
        this.snapshotType = dataView.getUint8(endOffset)
        endOffset += 1
        this.countValue = dataView.getUint8(endOffset)
        endOffset += 1
        this.accessBits = dataView.getUint8(endOffset)
        endOffset += 1
        this.timestamp = dataView.getUint32(endOffset, isLittleEndian)
        endOffset += 4
        this.rawValue = dataView.getBigUint64(endOffset, isLittleEndian)
        endOffset += 8
        endOffset = this.strValue.readFrom(dataView, endOffset, isLittleEndian)
        return endOffset
    }

    writeTo(dataView, startOffset, isLittleEndian = true) {
        let endOffset = startOffset
        dataView.setBigUint64(endOffset, this.id, isLittleEndian)
        endOffset += 8
        dataView.setBigUint64(endOffset, this.parentId, isLittleEndian)
        endOffset += 8
        dataView.setUint32(endOffset, this.systemType, isLittleEndian)
        endOffset += 4
        dataView.setUint8(endOffset, this.snapshotType)
        endOffset += 1
        dataView.setUint8(endOffset, this.countValue)
        endOffset += 1
        dataView.setUint8(endOffset, this.accessBits)
        endOffset += 1
        dataView.setUint32(endOffset, this.timestamp, isLittleEndian)
        endOffset += 4
        dataView.setBigUint64(endOffset, this.rawValue, isLittleEndian)
        endOffset += 8
        endOffset = this.strValue.writeTo(dataView, endOffset, isLittleEndian)
        return endOffset
    }

    getCurrentSize() {
        return 0
            + 8 // id
            + 8 // parentId
            + 4 // systemType
            + 1 // snapshotType
            + 1 // countValue
            + 1 // accessBits
            + 4 // timestamp
            + 8 // rawValue
            + this.strValue.getCurrentSize() // strValue
    }

    clone() {
        const clone = new ParameterStruct(
            this.id,
            this.parentId,
            this.systemType,
            this.snapshotType,
            this.countValue,
            this.accessBits,
            this.timestamp,
            this.rawValue,
            this.strValue.clone()
        )
        clone.setParserOptions(this.getParserOptions())
        return clone
    }

    get isReadable() {
        return this.accessBits & ACCESS_READ
            ? true : false
    }

    get isWritable() {
        return this.accessBits & ACCESS_WRITE
            ? true : false
    }

    get isAcknowledgeable() {
        return this.accessBits & ACCESS_ACK
            ? true : false
    }

    get isDeleted() {
        return this.accessBits & ACCESS_DELETED
            ? true : false
    }
}

ParameterStruct.ACCESS_READ = ACCESS_READ
ParameterStruct.ACCESS_WRITE = ACCESS_WRITE
ParameterStruct.ACCESS_ACK = ACCESS_ACK
ParameterStruct.ACCESS_DELETED = ACCESS_DELETED

ParameterStruct.SNAPSHOT_NONE = SNAPSHOT_NONE
ParameterStruct.SNAPSHOT_FAST = SNAPSHOT_FAST
ParameterStruct.SNAPSHOT_MEDIUM = SNAPSHOT_MEDIUM
ParameterStruct.SNAPSHOT_SLOW = SNAPSHOT_SLOW

export {
    
    ParameterStruct
}
