class SettlementServerSetup {

  constructor(kernel, settlementServerSetupStruct) {
    this.kernel = kernel;
    this.settlementServerSetupStruct = settlementServerSetupStruct;
  }

  get id() {
    return this.settlementServerSetupStruct.id;
  }

  get parameterId() {
    return this.settlementServerSetupStruct.parameterId;
  }

  get workingStatus() {
    return this.settlementServerSetupStruct.workingStatus;
  }

  get launchParameters() {
    return this.settlementServerSetupStruct.launchParameters.value;
  }

  get workParameters() {
    return this.settlementServerSetupStruct.workParameters.value;
  }

  get nameTask() {
    return this.settlementServerSetupStruct.nameTask.value;
  }

  get blockTypeStruct() {
    return this.kernel.blockTypes[this.settlementServerSetupStruct.blockType];
  }

  get blockTypeParentStruct() {
    return this.kernel.blockTypes[this.settlementServerSetupStruct.blockTypeParent];
  }

  get systemTypeStruct() {
    return this.kernel.systemTypes[this.settlementServerSetupStruct.systemType];
  }

  get systemTypeId() {
    return this.kernel.systemTypes[this.settlementServerSetupStruct.systemType]?.id ?? 0;
  }
}

export { SettlementServerSetup };
