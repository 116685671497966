import { useCallback, useState, useContext, useEffect, createContext } from 'react';

import { ContextMenu } from '../components/components.mjs';
import { Kernel } from '../kernel/Kernel.mjs';

const KernelContext = createContext({
  currentLicense: {},
  setCurrentLicense: () => { },
});

const kernelState = new Kernel();
window.kernel = kernelState;

export const KernelProvider = ({ children }) => {
  const [kernel, _] = useState(kernelState);
  const [currentLicense, setCurrentLicense] = useState({});
  const [contextMenuState, setContextMenuState] = useState({ position: undefined, contextMenuItems: [], contextData: {} });
  const [notificationList, setNotificationList] = useState([]);
  const [stateElementConfigurationId, setStateElementConfigurationId] = useState(0n); // состояние дерева конфигурации
  const [stateElementTemplateConfigurationId, setStateElementTemplateConfigurationId] = useState(0n); // состояние дерева шаблонной конфигурации
  const [configuration, setConfiguration] = useState(null);
  const [searchConfigurationState, setSearchConfigurationState] = useState('');
  const [showInformationParameter, setShowInformationParameter] = useState({ open: false, id: 0 });

  const unmountNotification = useCallback((id) => {
    setNotificationList((prev) => prev.filter((notification) => notification.id !== id));
  }, []);

  const addNotification = useCallback(({ messageText, statusType }) => {
    setNotificationList((prev) => [...prev, { id: Math.random(), messageText, statusType }]);
  }, []);

  //функция для работы с контекстом универсальным компонентом ContextMenu
  const setContextMenu = useCallback((items, position, contextData, currentTarget, preferredPosition) => {
    setContextMenuState({ position, contextMenuItems: items, contextData, currentTarget, preferredPosition });
  }, []);

  const closeMenu = useCallback(() => {
    setContextMenuState((state) => {
      return {
        ...state,
        position: undefined,
      };
    });
  }, []);

  const closeMenuEsc = useCallback((event) => {
    if (event.code === "Escape") {
      setContextMenuState((state) => {
        return {
          ...state,
          position: undefined,
        };
      });
    }
  }, []);

  const setLicense = (licenses) => {
    setCurrentLicense(licenses);
  };

  useEffect(() => {
    document.body.addEventListener('click', closeMenu);
    document.body.addEventListener('keydown', closeMenuEsc);
    return () => {
      document.body.removeEventListener('click', closeMenu);
      document.body.removeEventListener('keydown', closeMenuEsc);
    };
  }, [closeMenu]);

  return (
    <KernelContext.Provider value={{ kernel, setContextMenu, setLicense, currentLicense, notificationList, addNotification, unmountNotification, stateElementConfigurationId, setStateElementConfigurationId, stateElementTemplateConfigurationId, setStateElementTemplateConfigurationId, configuration, setConfiguration, searchConfigurationState, setSearchConfigurationState, showInformationParameter, setShowInformationParameter }}>
      {contextMenuState?.position && <ContextMenu contextMenuState={contextMenuState} />}
      {children}
    </KernelContext.Provider>
  );
};

export const useKernel = () => useContext(KernelContext);
