import { useEffect, useRef, useState } from 'react';
import { RiRoadMapFill } from 'react-icons/ri';
import classNames from 'classnames';

import { PolylineEditor, Tooltip } from '../components.mjs';
import Utils from '../../utils/Utils.mjs';

import styles from './inputCoordinatesLine.module.scss';

export default function InputCoordinatesLine(props) {
  const {
    value,
    setValue,
    getValues,
    saveProperty,
    placeholder,
    canEdit,
    isWritable,
    isDeleted,
    register,
    unregister,
    name,
    handleFocus,
    errors,
    showTooltip,
    validationScheme,
    setHandleChangeComponentTableRow,
    sendingData,
    dataSentSuccessfully,
    coordObject,
  } = props;

  const [inputCoordinatesLineInitialValueValid, setInputCoordinatesLineInitialValueValid] = useState(true); //состояние которое хранит в себе проверку на валидность присланных данных с БД
  const [incorrectValue, setIncorrectValue] = useState(); //некорректное значение которое пришло с базы
  const [autoFocusState, setAutoFocusState] = useState(false); //автофокус на input принимает значение true в случае если с базы пришли некорректные значения
  const [openPolylineEditor, setOpenPolylineEditor] = useState(false);

  const initialValue = useRef(value);

  const setInputValue = ({ target }) => {
    if (target.value.toString() === initialValue.current.toString()) {
      setHandleChangeComponentTableRow(false);
    } else {
      setHandleChangeComponentTableRow(true);
    }
    setValue(name, target.value);
  };

  useEffect(() => {
    const testInputCoordinatesLineInitialValue = Utils.testInputCoordinatesLineInitialValue(value);

    setInputCoordinatesLineInitialValueValid(testInputCoordinatesLineInitialValue);

    if (testInputCoordinatesLineInitialValue) {
      setValue(name, value);
    } else {
      setIncorrectValue(value);
    }
    return () => {
      unregister([name]);
    };
  }, []);

  useEffect(() => {
    initialValue.current = value;
    return () => {};
  }, [value]);

  return (
    <>
      {openPolylineEditor && (
        <PolylineEditor
          saveProperty={saveProperty}
          setOpenPolylineEditor={setOpenPolylineEditor}
          setValue={setValue}
          name={name}
          coordinatesLineValue={getValues(name)}
          sendingData={sendingData}
          dataSentSuccessfully={dataSentSuccessfully}
          coordObject={coordObject}
        />
      )}

      <div className={styles.inputCoordinatesLineContainer}>
        <div className={styles.inputCoordinatesLineForm}>
          {inputCoordinatesLineInitialValueValid ? (
            <div className={styles.inputCoordinatesLineWrap}>
              <button type="button" className={styles.button} disabled={sendingData || canEdit || !isWritable || isDeleted}>
                <RiRoadMapFill className={styles.inputCoordinatesLineButtonMap} onClick={() => setOpenPolylineEditor(true)} />
              </button>

              <textarea
                placeholder={placeholder}
                className={classNames(
                  styles.inputCoordinatesLineValue,
                  errors[name] && styles.inputCoordinatesLineValueError,
                  sendingData && styles.inputCoordinatesLineSending,
                  canEdit && styles.inputCoordinatesLineSending,
                  !isWritable && styles.inputCoordinatesLineSending,
                  isDeleted && styles.inputCoordinatesLineSending,
                )}
                onFocus={handleFocus}
                onInput={setInputValue}
                autoFocus={autoFocusState}
                readOnly={sendingData || canEdit || !isWritable || isDeleted}
                {...register(name, validationScheme)}
              ></textarea>
              {showTooltip && errors[name] && <Tooltip message={`${errors[name]?.message || 'Введенные координаты не валидны!'} `} />}
            </div>
          ) : (
            <div className={styles.inputTextContainer}>
              <input
                type="text"
                placeholder="Некорректные данные"
                value={incorrectValue}
                className={classNames(styles.inputText, styles.inputTextError)}
                onFocus={() => {
                  setValue(name, '');
                  setHandleChangeComponentTableRow(true);
                  setInputCoordinatesLineInitialValueValid(true);
                  setAutoFocusState(true);
                }}
              />
              {<Tooltip message={'Значения, хранящиеся в БД, не соответствуют системному типу!'} />}
            </div>
          )}
        </div>
      </div>
    </>
  );
}
