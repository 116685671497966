import { AiOutlineCloseCircle } from 'react-icons/ai';

import styles from './errorMessagePopup.module.scss';

function ErrorMessagePopup(props) {
  const { errorMessage, actionPerform } = props;

  const handleCloseErrorMessagePopup = () => {
    actionPerform();
  };

  return (
    <div className={styles.wrap}>
      <div className={styles.errorMessagePopup}>
        <AiOutlineCloseCircle className={styles.errorMessageClose} onClick={handleCloseErrorMessagePopup} />
        <span className={styles.errorMessageText}>{errorMessage}</span>
        <button className={styles.errorMessageButtonOk} onClick={handleCloseErrorMessagePopup}>
          ок
        </button>
      </div>
    </div>
  );
}

export default ErrorMessagePopup;
