import { Struct } from '../../../Scada/Parser/Struct.mjs';
import { StringStruct } from '../../../Scada/Parser/StringStruct.mjs';

class LogsExtStruct extends Struct {
  constructor(
    id = 0,
    uuidLicense = new StringStruct(""),
    codeOperation = 0,
    statusOperation = 0,
    oldValue = new StringStruct(""),
    newValue = new StringStruct(""),
    objectId = 0n,
    par_1 = 0n,
    par_2 = 0n,
    par_3 = 0n,
    par_4 = 0n,
    par_5 = 0n,
    sourceId = 0,
    ipAddress = new StringStruct(""),
    datetimeEvent = 0, // unint32
    comment = new StringStruct(''),
  ) {
    super();
    this.id = id;
    this.uuidLicense = uuidLicense;
    this.codeOperation = codeOperation;
    this.statusOperation = statusOperation;
    this.oldValue = oldValue;
    this.newValue = newValue;
    this.objectId = objectId;
    this.par_1 = par_1;
    this.par_2 = par_2;
    this.par_3 = par_3;
    this.par_4 = par_4;
    this.par_5 = par_5;
    this.sourceId = sourceId;
    this.ipAddress = ipAddress;
    this.datetimeEvent = datetimeEvent;
    this.comment = comment;
  }


  readFrom(dataView, startOffset, isLittleEndian = true) {
    let endOffset = startOffset;

    this.id = dataView.getUint32(endOffset, isLittleEndian);
    endOffset += 4;
    endOffset = this.uuidLicense.readFrom(dataView, endOffset, isLittleEndian);
    this.codeOperation = dataView.getUint32(endOffset, isLittleEndian);
    endOffset += 4;
    this.statusOperation = dataView.getUint32(endOffset, isLittleEndian);
    endOffset += 4;
    endOffset = this.oldValue.readFrom(dataView, endOffset, isLittleEndian);
    endOffset = this.newValue.readFrom(dataView, endOffset, isLittleEndian);
    this.objectId = dataView.getBigUint64(endOffset, isLittleEndian);
    endOffset += 8;
    this.par_1 = dataView.getBigUint64(endOffset, isLittleEndian);
    endOffset += 8;
    this.par_2 = dataView.getBigUint64(endOffset, isLittleEndian);
    endOffset += 8;
    this.par_3 = dataView.getBigUint64(endOffset, isLittleEndian);
    endOffset += 8;
    this.par_4 = dataView.getBigUint64(endOffset, isLittleEndian);
    endOffset += 8;
    this.par_5 = dataView.getBigUint64(endOffset, isLittleEndian);
    endOffset += 8;
    this.sourceId = dataView.getUint32(endOffset, isLittleEndian);
    endOffset += 4;
    endOffset = this.ipAddress.readFrom(dataView, endOffset, isLittleEndian);
    this.datetimeEvent = dataView.getUint32(endOffset, isLittleEndian);
    endOffset += 4;
    endOffset = this.comment.readFrom(dataView, endOffset, isLittleEndian);

    return endOffset;
  }

  writeTo(dataView, startOffset, isLittleEndian = true) {
    let endOffset = startOffset;

    dataView.setUint32(endOffset, this.id, isLittleEndian);
    endOffset += 4;
    endOffset = this.uuidLicense.writeTo(dataView, endOffset, isLittleEndian);
    dataView.setUint32(endOffset, this.codeOperation, isLittleEndian);
    endOffset += 4;
    dataView.setUint32(endOffset, this.statusOperation, isLittleEndian);
    endOffset += 4;
    endOffset = this.oldValue.writeTo(dataView, endOffset, isLittleEndian);
    endOffset = this.newValue.writeTo(dataView, endOffset, isLittleEndian);
    dataView.setBigUint64(endOffset, this.objectId, isLittleEndian);
    endOffset += 8;
    dataView.setBigUint64(endOffset, this.par_1, isLittleEndian);
    endOffset += 8;
    dataView.setBigUint64(endOffset, this.par_2, isLittleEndian);
    endOffset += 8;
    dataView.setBigUint64(endOffset, this.par_3, isLittleEndian);
    endOffset += 8;
    dataView.setBigUint64(endOffset, this.par_4, isLittleEndian);
    endOffset += 8;
    dataView.setBigUint64(endOffset, this.par_5, isLittleEndian);
    endOffset += 8;
    dataView.setUint32(endOffset, this.sourceId, isLittleEndian);
    endOffset += 4;
    endOffset = this.ipAddress.writeTo(dataView, endOffset, isLittleEndian);
    dataView.setUint32(endOffset, this.datetimeEvent, isLittleEndian);
    endOffset += 4;
    endOffset = this.comment.writeTo(dataView, endOffset, isLittleEndian);

    return endOffset;
  }

  getCurrentSize() {
    return 0
      + 4  // id
      + this.uuidLicense.getCurrentSize()  // uuidLicense
      + 4  // codeOperation
      + 4  // statusOperation
      + this.oldValue.getCurrentSize()  // oldValue
      + this.newValue.getCurrentSize()  // newValue
      + 8  // objectId
      + 8  // par_1
      + 8  // par_2
      + 8  // par_3
      + 8  // par_4
      + 8  // par_5
      + 4  // sourceId
      + this.ipAddress.getCurrentSize()  // ipAddress
      + 4  // datetimeEvent
      + this.comment.getCurrentSize();  // comment
  }

  clone() {
    let clone = new LogsExtStruct(
      this.id,
      this.uuidLicense.clone(),
      this.codeOperation,
      this.statusOperation,
      this.oldValue.clone(),
      this.newValue.clone(),
      this.objectId,
      this.par_1,
      this.par_2,
      this.par_3,
      this.par_4,
      this.par_5,
      this.sourceId,
      this.ipAddress.clone(),
      this.datetimeEvent,
      this.comment.clone(),
    )
    clone.setParserOptions(this.getParserOptions());
    return clone;
  }
}

export {

  LogsExtStruct,
};