import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { ImInsertTemplate } from 'react-icons/im';
import { PiFunctionLight } from 'react-icons/pi';

import { flagsParser } from '../../utils/flags-parser/flagsParser.mjs';
import { useKernel } from '../../context/ContextKernel.mjs';
import { SettlementServerSetupService } from '../../services/services.mjs';
import { Checkbox, CodeEditor, ErrorMessagePopup, InputSearch, TreeConfiguration, TreeTemplateTask } from '../../components/components.mjs';
import { ContentApp, HeaderApp, NavApp } from '../../layout/layout.mjs';
import { ReactComponent as BsArrowRightCircleFill } from '../../assets/icon/BsArrowLeftCircle.svg';
import { useActiveConfigurationId, useUserTokenDecode } from '../../hooks/hooks.mjs';
import { filterConfigurationTree } from '../../utils/filter-configuration-tree/filterConfigurationTree.mjs';

import styles from './settlementServer.module.scss';

export default function SettlementServer() {
  const location = useLocation();
  const navigate = useNavigate();
  const [activeElementTree, setActiveElementTree] = useState(0);
  const [configurationName, setConfigurationName] = useState(null);
  const [userName, setUserName] = useState('');
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('Ошибка!');
  const [componentsTypeId, setComponentsTypeId] = useState(0);
  const [selectedParameter, setSelectedParameter] = useState({});
  const [checked, setChecked] = useState(false);
  const [rotate, setRotate] = useState(false);
  const [codeEditorState, setCodeEditorState] = useState({ taskList: [], activeTask: {}, activeTaskId: null });
  const [treeTemplateState, setTreeTemplateState] = useState([]);
  const [treeTab, setTreeTab] = useState(1);
  const {
    kernel,
    currentLicense,
    setStateElementConfigurationId,
    searchConfigurationState,
    setSearchConfigurationState,
    configuration,
    setConfiguration,
  } = useKernel();
  const { activeConfigurationId } = useActiveConfigurationId();
  const { flags } = useUserTokenDecode();
  const { hasAccessRightsCalculationServerModule } = flagsParser(flags);

  const buildConfigurationTree = async () => {
    try {
      const { tree } = await kernel.buildTree(location, activeConfigurationId);

      setConfiguration(tree);
      setConfigurationName(kernel.configuration.name);
      setUserName(kernel.getUser().name);
    } catch (error) {
      setIsError(true);
      setErrorMessage('Ошибка загрузки конфигурации!');
    }
  };

  const getSettlementServerSetup = async () => {
    try {
      const settlementServerSetupStruct = await SettlementServerSetupService.getSettlementServerSetupList(activeConfigurationId);
      kernel.buildSettlementServerSetup(settlementServerSetupStruct.items);
    } catch (error) {
      setIsError(true);
      setErrorMessage('Ошибка загрузки данных сервера расчетов!');
    }
  };

  const getSrvCalcDictionary = async () => {
    try {
      const srvCalcDictionary = await SettlementServerSetupService.getSrvCalcDictionary();
      kernel.setSrvCalcDictionary(srvCalcDictionary);
    } catch (error) {
      setIsError(true);
      setErrorMessage('Ошибка загрузки словаря функций!');
    }
  };

  const handleChangeTreeTabItem = ({ currentTarget: { dataset } }) => {
    setTreeTab(parseInt(dataset.treeTabId));
  };

  useEffect(() => {
    setConfiguration(null);

    if (searchConfigurationState !== '') {
      setChecked(false);
      setStateElementConfigurationId(0n);
    }

    const debounce = setTimeout(() => {
      if (!checked) {
        const { treeConfiguration } = filterConfigurationTree(searchConfigurationState, kernel);
        setConfiguration(treeConfiguration);
      } else {
        const { treeConfiguration } = filterConfigurationTree('#0', kernel, 1);
        setConfiguration(treeConfiguration);
      }
    }, 300);

    return () => {
      clearTimeout(debounce);
    };
  }, [searchConfigurationState, checked]);

  useEffect(() => {
    setConfiguration(null);
    buildConfigurationTree();
    getSrvCalcDictionary();
    getSettlementServerSetup();
    kernel.timerRefreshToken(); //запуск обновления токена
    kernel.setNavigatePath('/settlement-server');
    return () => {};
  }, []);

  useEffect(() => {
    setActiveElementTree(0n);
    setStateElementConfigurationId(0n);
    setSearchConfigurationState('');
    return () => {};
  }, [treeTab]);

  useEffect(() => {
    const { hasAccessCalculationServerModule = undefined } = currentLicense;
    if (
      hasAccessCalculationServerModule !== 'true' ||
      hasAccessCalculationServerModule === undefined ||
      hasAccessRightsCalculationServerModule === false
    ) {
      navigate('/section-selection');
    }
    return () => {};
  }, [currentLicense]);

  return (
    <div className={styles.settlementServer}>
      {isError && (
        <ErrorMessagePopup
          errorMessage={errorMessage}
          actionPerform={() => {
            setIsError(false);
            kernel.setNavigatePath('/settlement-server');
            navigate('/section-selection');
          }}
        />
      )}
      <NavApp />
      <ContentApp>
        <div className={styles.headerContainer}>
          <HeaderApp configurationName={configurationName} userName={userName} />
        </div>
        <div className={styles.contentContainer}>
          <div
            className={classNames('treeContainer', rotate && 'collapsedTree')}
            onContextMenu={(event) => {
              event.preventDefault();
            }}
          >
            <div className={classNames(styles.treeTab, rotate && 'collapsedTreeTab')}>
              <div
                data-tree-tab-id={1}
                className={classNames(styles.treeTabItem, treeTab === 1 && styles.treeTabItemActive)}
                onClick={handleChangeTreeTabItem}
              >
                <PiFunctionLight className={styles.treeTabIcon} />
                <span className={styles.treeTabItemText}>Специальные</span>
              </div>
              <div
                data-tree-tab-id={2}
                className={classNames(styles.treeTabItem, treeTab === 2 && styles.treeTabItemActive)}
                onClick={handleChangeTreeTabItem}
              >
                <ImInsertTemplate className={styles.treeTabIcon} />
                <span className={styles.treeTabItemText}>Шаблонные</span>
              </div>
            </div>
            {treeTab === 1 ? (
              <div>
                <InputSearch
                  searchState={searchConfigurationState}
                  setSearchState={setSearchConfigurationState}
                  classes={rotate && styles.collapsedSearch}
                />
                <Checkbox
                  className={classNames(styles.checkbox, rotate && 'collapsedTreeWrap')}
                  isVisible={checked}
                  handleChange={() => setChecked(!checked)}
                  caption={'Отображать только параметры с задачами'}
                  disabled={configuration === null ? true : searchConfigurationState !== '' ? true : false}
                />
              </div>
            ) : (
              <div></div>
            )}
            <span className={styles.treeTabItemText}></span>
            <div className={classNames(styles.treeWrap, rotate && 'collapsedTreeWrap')}>
              {treeTab === 1 ? (
                <TreeConfiguration
                  configuration={configuration}
                  setConfiguration={setConfiguration}
                  setComponentsTypeId={setComponentsTypeId}
                  setSelectedParameter={setSelectedParameter}
                />
              ) : (
                <TreeTemplateTask
                  configuration={configuration}
                  setCodeEditorState={setCodeEditorState}
                  activeElementTree={activeElementTree}
                  setActiveElementTree={setActiveElementTree}
                  treeTemplateState={treeTemplateState}
                  setTreeTemplateState={setTreeTemplateState}
                />
              )}
            </div>
            <BsArrowRightCircleFill
              className={classNames(styles.treeControl, rotate && styles.treeControlActive)}
              onClick={() => setRotate(!rotate)}
            />
          </div>
          <div className={styles.codeEditorContainer}>
            <CodeEditor
              configuration={configuration}
              codeEditorState={codeEditorState}
              setCodeEditorState={setCodeEditorState}
              treeTab={treeTab}
              setTreeTemplateState={setTreeTemplateState}
              activeElementTree={activeElementTree}
              setActiveElementTree={setActiveElementTree}
            />
          </div>
        </div>
      </ContentApp>
    </div>
  );
}
