import { memo } from 'react';

import { PreloadList, UserGroupTreeItem } from '../components.mjs';

import { preloadList } from './model/preloadList.mjs';
import styles from './treeUser.module.scss';


export default memo(function TreeUser(props) {
  const { usersTree, initialStateUserTree, setUsersTree, setComponentsTypeId, setIdTreeElement, activeElementTree, setActiveElementTree } = props;

  return (
    <div className={styles.treeUser}>
      {initialStateUserTree.length !== 0 ? (
        usersTree.map((userGroup) =>
          userGroup.id !== 1 ? (
            <UserGroupTreeItem
              key={userGroup.id}
              userGroup={userGroup}
              usersTree={usersTree}
              setUsersTree={setUsersTree}
              setComponentsTypeId={setComponentsTypeId}
              setIdTreeElement={setIdTreeElement}
              activeElementTree={activeElementTree}
              setActiveElementTree={setActiveElementTree}
            />
          ) : (
            <></>
          ),
        )
      ) : (
        <PreloadList preloadSizeElement={preloadList()} />
      )}
    </div>
  );
});
