import { useEffect } from 'react';
import classNames from 'classnames';

import { getItemValue } from '../../model/getItemValue.mjs';

import styles from './customSelectOptionList.module.scss';

export default function CustomSelectOptionList(props) {
  const {
    optionsList = [],
    itemsList = [],
    isOpen = false,
    isMulti,
    setItemsList,
    setOptionsList,
    setIsOpen,
    optionsListRef,
    inputState,
    setInputState,
    elementRef,
    // currentOption,
  } = props;

  const chooseItem = ({ currentTarget }) => {
    const valueId = currentTarget.dataset.value;
    const { newOptionsList, valueItems } = getItemValue(optionsListRef.current, itemsList, valueId, isMulti);
    setOptionsList(newOptionsList);
    setItemsList(valueItems);
    setIsOpen(false);

    const clientWidth = window.innerWidth;
    const size = clientWidth > 1400 ? `${(valueItems[0].label.length + 1) * 9}px` : `${(valueItems[0].label.length + 1) * 8}px`;

    setInputState({ value: '', size });
  };

  useEffect(() => {
    const [item] = optionsList;
    // currentOption.current = item;
    return () => {};
  }, []);

  if (isMulti) {
    return (
      <div ref={elementRef} className={classNames(styles.optionList, isOpen && styles.optionListOpen)}>
        {optionsList.length > 0 ? (
          optionsList.map(({ value, label, isDisabled }) => {
            return (
              <div key={value} className={classNames(styles.option, isDisabled && styles.disabled)} data-value={value} onClick={chooseItem}>
                <span className={styles.optionText}>{label}</span>
              </div>
            );
          })
        ) : (
          <div className={classNames(styles.option, styles.noOptions)}>
            <span className={styles.optionText}>Нет параметров</span>
          </div>
        )}
      </div>
    );
  }

  if (!isMulti) {
    const [activeItemValue] = itemsList;

    return (
      <div ref={elementRef} className={classNames(styles.optionList, isOpen && styles.optionListOpen)}>
        {optionsList.length > 0 ? (
          optionsList.map(({ value, label, isDisabled }) => {
            return (
              <div
                key={value}
                className={classNames(
                  styles.option,
                  isDisabled && styles.disabled,
                  activeItemValue?.value === value && styles.optionActive,
                  // activeItemValue?.value !== value && currentOption.current?.value === value && styles.optionCurrent,
                )}
                data-value={value}
                onClick={chooseItem}
              >
                <span className={styles.optionText}>{label}</span>
              </div>
            );
          })
        ) : (
          <div className={classNames(styles.option, styles.noOptions)}>
            <span className={styles.optionText}>Нет параметров</span>
          </div>
        )}
      </div>
    );
  }
}
