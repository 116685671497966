import {
  EnumTypeTask,
  EnumTypeTaskImplementation,
} from '../../Enum/TaskServerEnum.mjs';

class TaskServer {
  constructor(kernel, taskServerStruct) {
    this.kernel = kernel;
    this.taskServerStruct = taskServerStruct;
  };

  get id() {
    return this.taskServerStruct.id;
  }

  get objectId() {
    return this.taskServerStruct.objectId.toString();
  }

  get parameter_1() {
    return this.taskServerStruct.parameter_1;
  }

  get parameter_2() {
    return this.taskServerStruct.parameter_2;
  }

  get parameter_3() {
    return this.taskServerStruct.parameter_3;
  }

  get parameter_4() {
    return this.taskServerStruct.parameter_4;
  }

  get parameter_5() {
    return this.taskServerStruct.parameter_5;
  }

  get script() {
    return this.taskServerStruct.script.value;
  }

  get nameTask() {
    return this.taskServerStruct.nameTask.value;
  }

  get firstSettingParameter() {
    return this.taskServerStruct.firstSettingParameter;
  }

  get secondSettingParameter() {
    return this.taskServerStruct.secondSettingParameter;
  }

  get typeImplementation() {
    const value = this.taskServerStruct.typeImplementation;
    return { value, text: EnumTypeTaskImplementation[value] };
  }

  get typeTask() {
    const value = this.taskServerStruct.typeTask;
    const text = EnumTypeTask[value].text;
    return { value, text };
  }

  get workingStatus() {
    return this.taskServerStruct.workingStatus;
  }
}

export { TaskServer };