import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import { RxCross2 } from 'react-icons/rx';

import { Tooltip } from '../components.mjs';
import { ConfigurationService } from '../../services/services.mjs';
import { useActiveConfigurationId } from '../../hooks/useActiveConfigurationId.mjs';
import { useKernel } from '../../context/ContextKernel.mjs';
import Utils from '../../utils/Utils.mjs';

import styles from './controllerId.module.scss';

export default function ControllerId(props) {
  const {
    value,
    setValue,
    getValues,
    trigger,
    placeholder,
    canEdit,
    isWritable,
    isDeleted,
    min,
    max,
    step,
    register,
    unregister,
    name,
    handleFocus,
    showTooltip,
    errors,
    validationScheme,
    setHandleChangeComponentTableRow,
    sendingData,
    handleBlur,
    setComponentsTypeId,
  } = props;

  const initialValue = useRef(value);
  const [parameterList, setParameterList] = useState([]);
  const [hasControllerIdInConfiguration, setHasControllerIdInConfiguration] = useState(false);
  const { activeConfigurationId } = useActiveConfigurationId();
  const { kernel, stateElementConfigurationId, setStateElementConfigurationId, setConfiguration, setSearchConfigurationState } = useKernel();
  const location = useLocation();

  const checkControllerIdAndGenerateCustomError = async (value) => {
    try {
      value = value.toString();
      const str = value.toString();
      if (str.split('').includes('.')) return;

      const isNumber = Utils.testIsNumber(value);
      if (!isNumber) return;

      const isInteger = Utils.testIsInteger(value);
      if (!isInteger) return;

      const list = await ConfigurationService.checkControllerId(activeConfigurationId, { controllerId: value });

      // в случае если конфигурация не актуальная, то обновляем ее
      for (let i = 0; i < list.length; i++) {
        const { parametr_id } = list[i];
        const parameterStructExtended = kernel.getParameterById(parametr_id);

        if (!parameterStructExtended) {
          setConfiguration(null);
          const { tree } = await kernel.buildTree(location, activeConfigurationId);
          setConfiguration(tree);
          break;
        }
      }

      const parameterStruct = kernel.getParameterById(stateElementConfigurationId);
      const controllerId = parameterStruct.properties.find((parameter) => parameter.systemTypeId === 44).id;

      if (list.length > 0 && (controllerId.toString() !== list[0].parametr_id || list.length > 1)) {
        setParameterList(list);
        setHasControllerIdInConfiguration(true);
      } else {
        setHasControllerIdInConfiguration(false);
      }
    } catch (error) {
      console.error('error: ', error);
    }
  };

  const incrementValue = async () => {
    if (BigInt(getValues(name)) !== BigInt(max) && !sendingData && !canEdit && isWritable && !isDeleted) {
      const newNumber = BigInt(getValues(name)) + BigInt(step);
      setValue(name, newNumber.toString());
      trigger(name);

      if (newNumber.toString() === initialValue.current.toString()) {
        setHandleChangeComponentTableRow(false);
      } else {
        setHandleChangeComponentTableRow(true);
      }

      await checkControllerIdAndGenerateCustomError(newNumber);
    }
  };

  const decrementValue = async () => {
    if (BigInt(getValues(name)) !== BigInt(min) && !sendingData && !canEdit && isWritable && !isDeleted) {
      const newNumber = BigInt(getValues(name)) - BigInt(step);
      setValue(name, newNumber.toString());
      trigger(name);

      if (newNumber.toString() === initialValue.current.toString()) {
        setHandleChangeComponentTableRow(false);
      } else {
        setHandleChangeComponentTableRow(true);
      }

      await checkControllerIdAndGenerateCustomError(newNumber);
    }
  };

  const updateValue = async ({ target }) => {
    if (target.value === initialValue.current.toString()) {
      setHandleChangeComponentTableRow(false);
    } else {
      setHandleChangeComponentTableRow(true);
    }

    setValue(target.value);
    trigger(name);

    await checkControllerIdAndGenerateCustomError(target.value);
  };

  useEffect(() => {
    setValue(name, value.toString());
    trigger(name);

    return () => {
      unregister([name]);
    };
  }, []);

  useEffect(() => {
    initialValue.current = value;
    checkControllerIdAndGenerateCustomError(value);
    return () => {};
  }, [value]);

  return (
    <div className={styles.inputNumberContainer}>
      <div className={styles.inputNumberForm}>
        <div className={styles.inputNumberControlsContainer}>
          <input
            type="text"
            placeholder={placeholder}
            min={Number(min)}
            max={Number(max)}
            step={Number(step)}
            className={classNames(
              styles.inputNumber,
              errors[name] && styles.inputNumberError,
              sendingData && styles.inputNumberSending,
              canEdit && styles.inputNumberSending,
              !isWritable && styles.inputNumberSending,
              isDeleted && styles.inputNumberSending,
              hasControllerIdInConfiguration && !errors[name] && styles.warning,
            )}
            onFocus={handleFocus}
            onInput={updateValue}
            readOnly={sendingData || canEdit || !isWritable || isDeleted}
            {...register(name, validationScheme)}
            onBlur={() => {}} // переопределил метод tooltip будет всегда пока не изменят значение на верное
          />
          <div className={styles.inputNumberControls}>
            <MdKeyboardArrowUp
              className={
                !canEdit
                  ? classNames(
                    styles.arrow,
                    sendingData && styles.inputNumberSending,
                    !isWritable && styles.inputNumberSending,
                    isDeleted && styles.inputNumberSending,
                    isWritable && styles.arrowUp,
                  )
                  : classNames(
                    styles.arrow,
                    sendingData && styles.inputNumberSending,
                    canEdit && styles.inputNumberSending,
                    isDeleted && styles.inputNumberSending,
                  )
              }
              onClick={incrementValue}
            />
            <MdKeyboardArrowDown
              className={
                !canEdit
                  ? classNames(
                    styles.arrow,
                    sendingData && styles.inputNumberSending,
                    !isWritable && styles.inputNumberSending,
                    isDeleted && styles.inputNumberSending,
                    isWritable && styles.arrowDown,
                  )
                  : classNames(
                    styles.arrow,
                    sendingData && styles.inputNumberSending,
                    canEdit && styles.inputNumberSending,
                    isDeleted && styles.inputNumberSending,
                  )
              }
              onClick={decrementValue}
            />
          </div>
        </div>
        {showTooltip && errors[name] && <Tooltip message={`${errors[name]?.message || 'Ошибка заполнения!'} `} />}
        {showTooltip && hasControllerIdInConfiguration && !errors[name] && (
          <Tooltip status={0}>
            <div className={styles.contentContainer}>
              <button className={styles.close} type="button" onClick={handleBlur}>
                <RxCross2 className={styles.closeIcon} />
              </button>

              <div className={styles.wrap}>
                <span className={styles.errorText}>Введенный идентификатор контроллера уже существует!</span>
                <ul className={styles.parameterList}>
                  {parameterList.map(({ parametr_id }) => {
                    return (
                      <li
                        className={styles.parameterListItem}
                        onClick={async () => {
                          // костыльное решение
                          setConfiguration(null);
                          setSearchConfigurationState('');
                          const { tree } = await kernel.buildTree(location, activeConfigurationId);

                          const parameterId = kernel.getParameterById(parametr_id).parent.id;
                          const parameterStruct = kernel.getParameterById(parameterId);
                          const path = parameterStruct.path;

                          path.forEach((parameterExtended) => {
                            kernel.openElementTreeTmp.set(parameterExtended.id, true);
                          });
                          kernel.openElementTreeTmp.set(BigInt(parameterId), false);

                          kernel.openElementTree.clear(); // закрываем все
                          const openedElementConfigurationTree = kernel.openElementTreeTmp;
                          Array.from(openedElementConfigurationTree, ([key, value]) => kernel.openElementTree.set(key, value)); // восстанавливаем состояние дерева (открытые узлы)

                          setConfiguration(tree);
                          kernel.setElementConfigurationId(BigInt(parameterId));
                          setStateElementConfigurationId(kernel.elementConfigurationId);
                          setComponentsTypeId(0);
                        }}
                      >
                        <span className={styles.parameterIdText}>#{kernel.getParameterById(parametr_id).parent.id.toString()}</span>
                        <span className={styles.parameterDisplayNameText}>{kernel.getParameterById(parametr_id).parent.displayName}</span>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </Tooltip>
        )}
      </div>
    </div>
  );
}
