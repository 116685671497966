import { useForm } from 'react-hook-form';
import { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { HiCheck } from 'react-icons/hi';

import { LoadingInUserPageForm, Tooltip } from '../../../components.mjs';
import { useKernel } from '../../../../context/ContextKernel.mjs';

import styles from './setpointOptions.module.scss';

export function SetpointOptions(props) {
  const { activeSetpoint, sendingData, dataSentSuccessfully, saveSetting, setpointOptions, setSetpointOptions } = props;
  const { kernel } = useKernel();

  const parameterStruct = kernel.getParameterById(activeSetpoint.parameterId);

  const [showTooltip, setShowTooltip] = useState({
    comment: false,
    name: false,
  });

  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    trigger,
    setValue,
  } = useForm({
    mode: 'onChange',
  });

  const handleChangeName = ({ target: { value } }) => {
    setSetpointOptions({
      ...setpointOptions,
      name: value,
    });
  };

  const handleChangeComment = ({ target: { value } }) => {
    setSetpointOptions({
      ...setpointOptions,
      comment: value,
    });
  };

  const handleFocus = useCallback(
    (event) => {
      const name = event.target.name;

      setShowTooltip({
        ...showTooltip,
        [name]: true,
      });
    },
    [showTooltip],
  );

  const handleBlur = useCallback(
    (event) => {
      const name = event.target.name;

      setShowTooltip({
        ...showTooltip,
        [name]: false,
      });
    },
    [showTooltip],
  );

  useEffect(() => {
    const name = activeSetpoint.name;
    const comment = activeSetpoint.comment;

    setSetpointOptions({ name, comment });

    setValue('name', name);
    setValue('comment', comment);

    trigger('name');
    trigger('comment');
    return () => {};
  }, [activeSetpoint]);

  return (
    <div className={styles.container}>
      <div className={styles.wrap}>
        <div className={styles.editorWrap}>
          <form className={styles.form} onSubmit={handleSubmit(saveSetting)} id="setpointsOptionsForm">
            <div className={styles.formItem}>
              <span className={styles.formItemText} title="Имя">
                Имя
              </span>
              <div className={styles.inputWrap}>
                <input
                  className={classNames(
                    styles.textField,
                    errors.name && styles.textFieldError,
                    (sendingData || dataSentSuccessfully || parameterStruct.isDeleted) && styles.sending,
                  )}
                  type="text"
                  value={setpointOptions.name}
                  onInput={handleChangeName}
                  onFocus={handleFocus}
                  disabled={sendingData || dataSentSuccessfully || parameterStruct.isDeleted}
                  {...register('name', {
                    required: 'Поле обязательно для заполнения',
                    maxLength: {
                      value: 64,
                      message: 'Поле может содержать максимум 64 символов',
                    },
                  })}
                  onBlur={handleBlur}
                />
                {showTooltip.name && errors.name && <Tooltip message={errors.name?.message} />}
              </div>
            </div>
            <div className={classNames(styles.formItem)}>
              <span className={styles.formItemText} title="Комментарий">
                Комментарий
              </span>
              <div className={styles.inputWrap}>
                <textarea
                  className={classNames(
                    styles.textField,
                    styles.textArea,
                    errors.comment && styles.textFieldError,
                    (sendingData || dataSentSuccessfully || parameterStruct.isDeleted) && styles.sending,
                  )}
                  type="text"
                  value={setpointOptions.comment}
                  onInput={handleChangeComment}
                  onFocus={handleFocus}
                  disabled={sendingData || dataSentSuccessfully || parameterStruct.isDeleted}
                  {...register('comment', {
                    maxLength: {
                      value: 255,
                      message: 'Поле может содержать максимум 255 символов',
                    },
                  })}
                  onBlur={handleBlur}
                />
                {showTooltip.comment && errors.comment && <Tooltip message={errors.comment?.message} />}
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className={styles.controlContainer}>
        <button
          className={
            sendingData || dataSentSuccessfully
              ? styles.creatingNewModuleFormSubmitSending
              : classNames(styles.creatingNewModuleFormSubmit, isValid && !parameterStruct.isDeleted ? styles.formValid : styles.formNoValid)
          }
          type="submit"
          form="setpointsOptionsForm"
          disabled={sendingData || dataSentSuccessfully || !isValid || parameterStruct.isDeleted}
        >
          {dataSentSuccessfully ? <HiCheck className={styles.checkControl} /> : sendingData ? <LoadingInUserPageForm /> : 'Сохранить'}
        </button>
      </div>
    </div>
  );
}
