import classNames from 'classnames';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { MdOutlineErrorOutline } from 'react-icons/md';
import { IoWarningOutline } from 'react-icons/io5';
import { HiCheck } from 'react-icons/hi';
import { useEffect, useRef, useState } from 'react';

import { useKernel } from '../../../../context/ContextKernel.mjs';

import styles from './notification.module.scss';

const SUCCESS = 1;
const ERROR = 2;
const WARNING = 3;
const timer = parseInt(process.env.REACT_APP_NOTIFICATION_UNMOUNT);

export function Notification({ notification, isCursorHovered }) {
  const { id, messageText, statusType } = notification;
  const { unmountNotification } = useKernel();
  const [closing, setClosing] = useState(false);
  const [timerValue, setTimerValue] = useState(timer);
  const timerID = useRef();

  useEffect(() => {
    timerID.current = setInterval(() => {
      if (isCursorHovered) {
        return;
      }else{

        if (timerValue === 1) setClosing(true);

        if (timerValue + 0.5 <= 0.5) {
          unmountNotification(id);
          clearInterval(timerID.current);
        }

        if (timerValue >= 1) setTimerValue((prev) => prev - 1);
      }
    }, 500);

    return () => {
      clearInterval(timerID.current);
    };
  }, [id, isCursorHovered, timerValue, unmountNotification]);

  return (
    <div
      className={classNames(
        styles.container,
        closing && styles.closing,
        SUCCESS === statusType && styles.success,
        ERROR === statusType && styles.error,
        WARNING === statusType && styles.warning,
      )}
      title={messageText}
    >
      <button className={styles.close} type="button" onClick={() => unmountNotification(id)}>
        <AiOutlineCloseCircle className={styles.closeIcon} />
      </button>
      <div className={styles.wrap}>
        {SUCCESS === statusType && <HiCheck className={styles.icon} />}
        {ERROR === statusType && <MdOutlineErrorOutline className={styles.icon} />}
        {WARNING === statusType && <IoWarningOutline className={styles.icon} />}
        <span className={styles.messageText}>{messageText}</span>
      </div>
    </div>
  );
}
