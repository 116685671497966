import { Struct } from '../../../Scada/Parser/Struct.mjs';
import { StringStruct } from '../../../Scada/Parser/StringStruct.mjs';

class CodesOperationsStruct extends Struct {
  constructor(
    id = 0,
    name = new StringStruct(""),
    description = new StringStruct(""),
    comment = new StringStruct(""),
    licensesType = 0,
  ) {
    super();
    this.id = id;
    this.name = name;
    this.description = description;
    this.comment = comment;
    this.licensesType = licensesType;
  }

  readFrom(dataView, startOffset, isLittleEndian = true) {
    let endOffset = startOffset;

    this.id = dataView.getUint32(endOffset, isLittleEndian);
    endOffset += 4;
    endOffset = this.name.readFrom(dataView, endOffset, isLittleEndian);
    endOffset = this.description.readFrom(dataView, endOffset, isLittleEndian);
    endOffset = this.comment.readFrom(dataView, endOffset, isLittleEndian);
    this.licensesType = dataView.getUint32(endOffset, isLittleEndian);
    endOffset += 4;

    return endOffset;
  }

  writeTo(dataView, startOffset, isLittleEndian = true) {
    let endOffset = startOffset;

    dataView.setUint32(endOffset, this.id, isLittleEndian);
    endOffset += 4;
    endOffset = this.name.writeTo(dataView, endOffset, isLittleEndian);
    endOffset = this.description.writeTo(dataView, endOffset, isLittleEndian);
    endOffset = this.comment.writeTo(dataView, endOffset, isLittleEndian);
    dataView.setUint32(endOffset, this.licensesType, isLittleEndian);
    endOffset += 4;

    return endOffset;
  }

  getCurrentSize() {
    return 0
      + 4  // id
      + this.name.getCurrentSize() // name
      + this.description.getCurrentSize() // description
      + this.comment.getCurrentSize() // comment
      + 4 // licensesType

  }

  clone() {
    let clone = new CodesOperationsStruct(
      this.id,
      this.name.clone(),
      this.description.clone(),
      this.comment.clone(),
      this.licensesType,
    )
    clone.setParserOptions(this.getParserOptions());
    return clone;
  }
}

export {

  CodesOperationsStruct,
};