import classNames from 'classnames';
import { HiCheck } from 'react-icons/hi';
import { useForm } from 'react-hook-form';
import { useCallback, useEffect, useState } from 'react';
import { GrTree } from 'react-icons/gr';

import InputNumber from '../input-number/InputNumber';
import Utils from '../../../../utils/Utils.mjs';
import { LoadingInUserPageForm, SettlementServerPopUp } from '../../../components.mjs';
import { EnumTypeTask, EnumTypeTaskImplementation } from '../../../../quantum-lib/Enum/TaskServerEnum.mjs';
import { useKernel } from '../../../../context/ContextKernel.mjs';
import { TaskServerService } from '../../../../services/services.mjs';
import { useActiveConfigurationId } from '../../../../hooks/hooks.mjs';

import styles from './taskEditorArguments.module.scss';

export function TaskEditorArguments(props) {
  const { sendingData, dataSentSuccessfully, activeTask, setSendingData, setDataSentSuccessfully, setTask, task, configuration } = props;

  const [state, setState] = useState({
    typeTask: 0,
    objectId: '',
    parameter_1: '',
    parameter_2: '',
    parameter_3: '',
    parameter_4: '',
    parameter_5: '',
    typeImplementation: 0,
    firstSettingParameter: '',
    secondSettingParameter: '',
  });
  const [showTooltip, setShowTooltip] = useState({
    objectId: false,
    parameter_1: false,
    parameter_2: false,
    parameter_3: false,
    parameter_4: false,
    parameter_5: false,
    firstSettingParameter: false,
    secondSettingParameter: false,
  });
  const [visible, setVisible] = useState(false);
  const [activeElementTree, setActiveElementTree] = useState(0);
  const { kernel, addNotification } = useKernel();
  const { activeConfigurationId } = useActiveConfigurationId();

  const handleFocus = useCallback(
    (event) => {
      const name = event.target.name;

      setShowTooltip({
        ...showTooltip,
        [name]: true,
      });
    },
    [showTooltip],
  );

  const handleBlur = useCallback(
    (event) => {
      const name = event.target.name;

      setShowTooltip({
        ...showTooltip,
        [name]: false,
      });
    },
    [showTooltip],
  );

  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    setValue,
  } = useForm({
    mode: 'onChange',
  });

  const onSubmit = async (data) => {
    try {
      setSendingData(true);

      // запрос на изменение данных
      data.taskId = activeTask.id;
      await TaskServerService.changeTaskArguments(activeConfigurationId, data);
      kernel.changedTask(data, task.activeTask);

      setTask({
        ...task,
        taskList: Object.values(kernel.getTasksListForTaskServer()),
        activeTask: kernel.getTaskByIdAndTypeTask(data.taskId, data.typeTask),
      });

      setTimeout(() => {
        setDataSentSuccessfully(true);
        setSendingData(false);
      }, 800);

      setTimeout(() => {
        setDataSentSuccessfully(false);
      }, 1600);
    } catch (error) {
      setSendingData(false);
      addNotification({ messageText: error.message, statusType: error.status });
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    setState({
      ...state,
      [name]: value,
    });
    setValue(name, value);
  };

  const setParameterId = () => {
    const parameterId = activeElementTree.toString();

    setState({
      ...state,
      objectId: parameterId,
    });
    setValue('objectId', parameterId);

    setVisible(false);
    setActiveElementTree(0);
  };

  const closePopUp = () => {
    setVisible(false);
    setActiveElementTree(0);
  };

  useEffect(() => {
    setState({
      typeTask: activeTask.typeTask.value,
      objectId: activeTask.objectId,
      parameter_1: activeTask.parameter_1,
      parameter_2: activeTask.parameter_2,
      parameter_3: activeTask.parameter_3,
      parameter_4: activeTask.parameter_4,
      parameter_5: activeTask.parameter_5,
      typeImplementation: activeTask.typeImplementation.value,
      firstSettingParameter: activeTask.firstSettingParameter,
      secondSettingParameter: activeTask.secondSettingParameter,
    });

    setValue('typeTask', activeTask.typeTask.value);
    setValue('objectId', activeTask.objectId);
    setValue('parameter_1', activeTask.parameter_1);
    setValue('parameter_2', activeTask.parameter_2);
    setValue('parameter_3', activeTask.parameter_3);
    setValue('parameter_4', activeTask.parameter_4);
    setValue('parameter_5', activeTask.parameter_5);
    setValue('typeImplementation', activeTask.typeImplementation.value);
    setValue('firstSettingParameter', activeTask.firstSettingParameter);
    setValue('secondSettingParameter', activeTask.secondSettingParameter);

    return () => {};
  }, [activeTask?.id]);

  return (
    <div className={styles.taskEditorContainer}>
      {visible && (
        <SettlementServerPopUp
          configuration={configuration}
          activeElementTree={activeElementTree}
          setActiveElementTree={setActiveElementTree}
          closePopUp={closePopUp}
          callback={setParameterId}
          propertyVisibleInTree="true"
        />
      )}
      <div className={styles.serverSettingsContainer}>
        <div className={styles.editorWrap}>
          <form className={styles.form} onSubmit={handleSubmit(onSubmit)} id="taskEditorArgumentsForm">
            <div className={styles.formItem}>
              <span className={styles.formItemText} title="Тип задачи">
                Тип задачи
              </span>
              <select
                className={classNames(styles.formItemSelect, (sendingData || dataSentSuccessfully) && styles.sending)}
                value={state.typeTask}
                onInput={handleChange}
                disabled={sendingData || dataSentSuccessfully}
                {...register('typeTask')}
              >
                {Object.values(EnumTypeTask).map((typeTask) => {
                  return (
                    <option key={typeTask.value} className={styles.formItemOption} value={typeTask.value}>
                      {typeTask.text}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className={styles.formItem}>
              <span className={styles.formItemText} title="Id объекта">
                Id объекта
              </span>
              <InputNumber
                name="objectId"
                value={state.objectId}
                dataSentSuccessfully={dataSentSuccessfully}
                sendingData={sendingData}
                showTooltip={showTooltip}
                errors={errors}
                onBlur={handleBlur}
                onFocus={handleFocus}
                onInput={handleChange}
                register={{
                  ...register('objectId', {
                    min: {
                      value: 0,
                      message: 'Id объекта должен быть положительным',
                    },
                    maxLength: {
                      value: 255,
                      message: 'Поле может содержать максимум 255 символов',
                    },
                    validate: (value) => {
                      return Utils.testIsNumber(value) ? null : 'Неверный формат';
                    },
                  }),
                }}
              >
                <div
                  className={classNames(styles.showParameterTree, (sendingData || dataSentSuccessfully) && styles.sending)}
                  onClick={() => {
                    !sendingData && !dataSentSuccessfully && setVisible(true);
                  }}
                >
                  <GrTree className={styles.showParameterTreeIcon} />
                </div>
              </InputNumber>
            </div>
            <div className={styles.formItem}>
              <span className={styles.formItemText} title="Параметр 1">
                Параметр 1
              </span>
              <InputNumber
                name="parameter_1"
                value={state.parameter_1}
                dataSentSuccessfully={dataSentSuccessfully}
                sendingData={sendingData}
                showTooltip={showTooltip}
                errors={errors}
                onBlur={handleBlur}
                onFocus={handleFocus}
                onInput={handleChange}
                register={{
                  ...register('parameter_1', {
                    maxLength: {
                      value: 255,
                      message: 'Поле может содержать максимум 255 символов',
                    },
                    validate: (value) => {
                      return Utils.testIsNumber(value) ? null : 'Неверный формат';
                    },
                  }),
                }}
              />
            </div>
            <div className={styles.formItem}>
              <span className={styles.formItemText} title="Параметр 2">
                Параметр 2
              </span>
              <InputNumber
                name="parameter_2"
                value={state.parameter_2}
                dataSentSuccessfully={dataSentSuccessfully}
                sendingData={sendingData}
                showTooltip={showTooltip}
                errors={errors}
                onBlur={handleBlur}
                onFocus={handleFocus}
                onInput={handleChange}
                register={{
                  ...register('parameter_2', {
                    maxLength: {
                      value: 255,
                      message: 'Поле может содержать максимум 255 символов',
                    },
                    validate: (value) => {
                      return Utils.testIsNumber(value) ? null : 'Неверный формат';
                    },
                  }),
                }}
              />
            </div>
            <div className={styles.formItem}>
              <span className={styles.formItemText} title="Параметр 3">
                Параметр 3
              </span>
              <InputNumber
                name="parameter_3"
                value={state.parameter_3}
                dataSentSuccessfully={dataSentSuccessfully}
                sendingData={sendingData}
                showTooltip={showTooltip}
                errors={errors}
                onBlur={handleBlur}
                onFocus={handleFocus}
                onInput={handleChange}
                register={{
                  ...register('parameter_3', {
                    maxLength: {
                      value: 255,
                      message: 'Поле может содержать максимум 255 символов',
                    },
                    validate: (value) => {
                      return Utils.testIsNumber(value) ? null : 'Неверный формат';
                    },
                  }),
                }}
              />
            </div>
            <div className={styles.formItem}>
              <span className={styles.formItemText} title="Параметр 4">
                Параметр 4
              </span>
              <InputNumber
                name="parameter_4"
                value={state.parameter_4}
                dataSentSuccessfully={dataSentSuccessfully}
                sendingData={sendingData}
                showTooltip={showTooltip}
                errors={errors}
                onBlur={handleBlur}
                onFocus={handleFocus}
                onInput={handleChange}
                register={{
                  ...register('parameter_4', {
                    maxLength: {
                      value: 255,
                      message: 'Поле может содержать максимум 255 символов',
                    },
                    validate: (value) => {
                      return Utils.testIsNumber(value) ? null : 'Неверный формат';
                    },
                  }),
                }}
              />
            </div>
            <div className={styles.formItem}>
              <span className={styles.formItemText} title="Параметр 5">
                Параметр 5
              </span>
              <InputNumber
                name="parameter_5"
                value={state.parameter_5}
                dataSentSuccessfully={dataSentSuccessfully}
                sendingData={sendingData}
                showTooltip={showTooltip}
                errors={errors}
                onBlur={handleBlur}
                onFocus={handleFocus}
                onInput={handleChange}
                register={{
                  ...register('parameter_5', {
                    maxLength: {
                      value: 255,
                      message: 'Поле может содержать максимум 255 символов',
                    },
                    validate: (value) => {
                      return Utils.testIsNumber(value) ? null : 'Неверный формат';
                    },
                  }),
                }}
              />
            </div>
            <div className={styles.formItem}>
              <span className={styles.formItemText} title="Тип выполнения">
                Тип выполнения
              </span>
              <select
                className={classNames(styles.formItemSelect, (sendingData || dataSentSuccessfully) && styles.sending)}
                value={state.typeImplementation}
                onInput={handleChange}
                disabled={sendingData || dataSentSuccessfully}
                {...register('typeImplementation')}
              >
                {Object.values(EnumTypeTaskImplementation).map((typeTaskImplementation) => {
                  return (
                    <option key={typeTaskImplementation.value} className={styles.formItemOption} value={typeTaskImplementation.value}>
                      {typeTaskImplementation.text}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className={styles.formItem}>
              <span className={styles.formItemText} title="Время 1">
                Время 1
              </span>
              <InputNumber
                name="firstSettingParameter"
                value={state.firstSettingParameter}
                dataSentSuccessfully={dataSentSuccessfully}
                sendingData={sendingData}
                showTooltip={showTooltip}
                errors={errors}
                onBlur={handleBlur}
                onFocus={handleFocus}
                onInput={handleChange}
                register={{
                  ...register('firstSettingParameter', {
                    maxLength: {
                      value: 255,
                      message: 'Поле может содержать максимум 255 символов',
                    },
                    validate: (value) => {
                      return Utils.testIsNumber(value) ? null : 'Неверный формат';
                    },
                  }),
                }}
              />
            </div>
            <div className={styles.formItem}>
              <span className={styles.formItemText} title="Время 2">
                Время 2
              </span>
              <InputNumber
                name="secondSettingParameter"
                value={state.secondSettingParameter}
                dataSentSuccessfully={dataSentSuccessfully}
                sendingData={sendingData}
                showTooltip={showTooltip}
                errors={errors}
                onBlur={handleBlur}
                onFocus={handleFocus}
                onInput={handleChange}
                register={{
                  ...register('secondSettingParameter', {
                    maxLength: {
                      value: 255,
                      message: 'Поле может содержать максимум 255 символов',
                    },
                    validate: (value) => {
                      return Utils.testIsNumber(value) ? null : 'Неверный формат';
                    },
                  }),
                }}
              />
            </div>
          </form>
        </div>
      </div>
      <div className={styles.controlContainer}>
        <button
          type="submit"
          form="taskEditorArgumentsForm"
          className={
            sendingData || dataSentSuccessfully
              ? styles.creatingNewModuleFormSubmitSending
              : classNames(styles.creatingNewModuleFormSubmit, !isValid ? styles.formNoValid : styles.formValid)
          }
          disabled={sendingData || dataSentSuccessfully || !isValid}
        >
          {dataSentSuccessfully ? <HiCheck className={styles.checkControl} /> : sendingData ? <LoadingInUserPageForm /> : 'Сохранить'}
        </button>
      </div>
    </div>
  );
}
