import { PARAMETER_CLASS_FOLDER, PARAMETER_CLASS_OBJECT, PARAMETER_CLASS_PROPERTY, PARAMETER_CLASS_VALUE } from '../../../global/variables/objectTypeVariables.mjs';

export const getObjectType = (classify) => {
  switch (classify) {
    case PARAMETER_CLASS_FOLDER: return "Папка";
    case PARAMETER_CLASS_OBJECT: return "Объект";
    case PARAMETER_CLASS_VALUE: return "Параметр";
    case PARAMETER_CLASS_PROPERTY: return "Свойство";
    default: return "Объект неизвестного типа";
  };
};