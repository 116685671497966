import classNames from 'classnames';
import { CgShapeRhombus } from 'react-icons/cg';
import { ImFolder, ImFolderOpen } from 'react-icons/im';

import { useKernel } from '../../context/ContextKernel.mjs';

import styles from './licensesTreeItem.module.scss';

export default function LicensesTreeItem(props) {
  const { licensesTreeItem, licensesTree, setLicensesTree, activeElementTree, setActiveElementTree, setComponentsTypeId, setIdTreeElement } = props;

  const { kernel } = useKernel();

  const handleClickLicensesType = (id) => {
    setComponentsTypeId(0);
    kernel.setElementLicensesTreeId(id);
    setActiveElementTree(`${id}licType`);
  };

  const handleDoubleClickLicensesType = (id) => {
    kernel.setElementLicensesTreeId(id);
    setActiveElementTree(`${id}licType`);
    setLicensesTree(
      licensesTree.map((licenseType) => {
        if (licenseType.id === licensesTreeItem.id) {
          licenseType.isOpen = !licenseType.isOpen;
        }
        return licenseType;
      }),
    );
  };

  const handleClickLicenses = (id) => {
    setComponentsTypeId(3);
    setIdTreeElement(id);
    kernel.setElementLicensesTreeId(id);
    setActiveElementTree(`${id}lic`);
  };

  return (
    <div className={styles.licensesTreeItem}>
      <div
        className={classNames(styles.licenseType, activeElementTree === `${licensesTreeItem.id}licType` && styles.licenseTypeActive)}
        data-id={licensesTreeItem.id}
        onContextMenu={(event) => event.preventDefault()}
        onClick={(event) => handleClickLicensesType(event.currentTarget.dataset.id)}
        onDoubleClick={(event) => handleDoubleClickLicensesType(event.currentTarget.dataset.id)}
      >
        {licensesTreeItem.isOpen ? <ImFolderOpen className={styles.iconIsOpen} /> : <ImFolder className={styles.iconIsClose} />}

        <span className={styles.licenseTypeText}>{licensesTreeItem.name}</span>
      </div>
      <div className={styles.licenseContainer}>
        {licensesTreeItem.isOpen &&
          licensesTreeItem.children.map((license) => {
            return (
              <div
                key={license.id}
                data-id={license.id}
                className={classNames(styles.licenseWraper, licensesTreeItem.isOpen && styles.licenseWraperActive)}
                onContextMenu={(event) => event.preventDefault()}
                onClick={(event) => handleClickLicenses(event.currentTarget.dataset.id)}
              >
                <div className={classNames(styles.license, activeElementTree === `${license.id}lic` && styles.licenseActive)}>
                  <CgShapeRhombus className={styles.iconIsValue} />
                  <span className={styles.licenseText}>{license.name}</span>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}
