import classNames from 'classnames';
import { useEffect, useRef } from 'react';

import { useKernel } from '../../context/ContextKernel.mjs';

import styles from './choiceTypeBlockProperties.module.scss';

export default function ChoiceTypeBlockProperties(props) {
  const {
    value,
    setValue,
    canEdit,
    isWritable,
    isDeleted,
    register,
    unregister,
    name,
    setHandleChangeComponentTableRow,
    sendingData,
  } = props;

  const initialValue = useRef(value);
  const { kernel } = useKernel();
  const blockTypesList = kernel.getBlockTypes();

  const handleChangeChoiceTypeBlockPropertiesValue = ({target}) => {
    if (target.value.toString() === initialValue.current.toString()) {
      setHandleChangeComponentTableRow(false);
    } else {
      setHandleChangeComponentTableRow(true);
    }
  };

  useEffect(() => {
    setValue(name, value);
    return () => {
      unregister(name);
    };
  }, []);

  useEffect(() => {
    initialValue.current = value;
    return () => {};
  }, [value]);

  return (
    <div className={styles.choiceTypeBlockPropertiesContainer}>
      <div className={styles.choiceTypeBlockPropertiesForm}>
        <select
          className={classNames(
            styles.choiceTypeBlockProperties,
            sendingData && styles.choiceTypeBlockPropertiesSending,
            canEdit && styles.choiceTypeBlockPropertiesSending,
            !isWritable && styles.choiceTypeBlockPropertiesIsWritableFalse,
            isDeleted && styles.choiceTypeBlockPropertiesIsWritableFalse,
          )}
          onInput={handleChangeChoiceTypeBlockPropertiesValue}
          disabled={sendingData || canEdit || !isWritable || isDeleted}
          {...register(name)}
        >
          {blockTypesList.map((blockTypes) => {
            return (
              <option className={styles.choiceTypeBlockPropertiesOption} key={blockTypes.id} value={blockTypes.id}>
                {blockTypes.description}
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
}
