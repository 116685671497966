import { MdPersonAdd, MdOutlineGroupAdd } from 'react-icons/md';

import styles from './selectActionsOnUsersPage.module.scss';

export default function SelectActionsOnUsersPage(props) {
  const { setComponentsTypeId, setIdTreeElement } = props;

  const setCreateNewUserComponent = () => {
    setIdTreeElement(2);
    setComponentsTypeId(1);
  };

  const setCreateNewGroupComponent = () => {
    setComponentsTypeId(3);
  };

  return (
    <div className={styles.selectActionsOnUsersPage}>
      <h1 className={styles.selectActionsOnUsersPageTitle}>Выберите действие </h1>
      <div className={styles.selectActionsOnUsersPageControlBlock}>
        <div className={styles.selectActionsOnUsersPageControlBlockItem} onClick={setCreateNewUserComponent}>
          <div className={styles.selectActionsOnUsersPageControlBlockImgContainer}>
            <MdPersonAdd className={styles.selectActionsOnUsersPageControlBlockImg} />
          </div>
          <p className={styles.selectActionsOnUsersPageControlBlockText}>Создать нового пользователя</p>
        </div>
        <div className={styles.selectActionsOnUsersPageControlBlockItem} onClick={setCreateNewGroupComponent}>
          <div className={styles.selectActionsOnUsersPageControlBlockImgContainer}>
            <MdOutlineGroupAdd className={styles.selectActionsOnUsersPageControlBlockImg} />
          </div>
          <p className={styles.selectActionsOnUsersPageControlBlockText}>Создать группу пользователей</p>
        </div>
      </div>
    </div>
  );
}
