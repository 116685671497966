import { Parameter } from "../../Scada/Parameters/Parameter.mjs";

const ACCESS_DENIED = 1;
const ACCESS_READ = 2;
const ACCESS_ADD = 4;
const ACCESS_WRITE = 8;
const ACCESS_DELETE = 16;
const ACCESS_FULL = 32;
const ACCESS_ACK = 64;


class ParametersExtended extends Parameter {

  constructor(kernel, parameterStruct, disableTransforms = false) {
    super(kernel, parameterStruct, disableTransforms);
    this.canEdit = false;
    this.canViewParameter = true;
    this.isDeletedObject = false;
  }

  get propertyValueRoundUp() {
    if (this.systemType.type === 9) {
      return this.propertyValue.toFixed(6);
    }
    return this.propertyValue;
  }

  // отказ в доступе
  get hasAccess() {
    return this.parameterStruct.accessBits & ACCESS_DENIED
      ? false : true;
  }

  // права на чтение ( если есть права на запись, то есть права и на чтение )
  get isReadable() {
    // права на чтение -> права на запись -> полный доступ -> есть ли доступ
    return (((this.parameterStruct.accessBits & ACCESS_READ) || this.isWritable) || this.canEditAccess) && this.hasAccess;
  }

  // права на добавление
  get canAdd() {
    return ((this.parameterStruct.accessBits & ACCESS_ADD) || this.canEditAccess) && this.hasAccess;
  }

  // права на запись (модификации, в случае если корень, то берем значение из корневого элемента, если не корень, то берем из текущего геттера)
  get isWritable() {
    const canWrite = ((this.parameterStruct.accessBits & ACCESS_WRITE) || this.canEditAccess) && this.hasAccess;
    return this.parameterStruct.id === 0n ? super.isWritable : canWrite;
  }

  // права на удаление
  get canDelete() {
    return ((this.parameterStruct.accessBits & ACCESS_DELETE) || this.canEditAccess) && this.hasAccess;
  }

  // root права но не на квитирование
  get canEditAccess() {
    return this.parameterStruct.accessBits & ACCESS_FULL && this.hasAccess
      ? true : false;
  }

  // права на квитирование
  get isAcknowledgeable() {
    return (this.parameterStruct.accessBits & ACCESS_ACK !== 0 ? true : false) && this.hasAccess;
  }

  get isDeleted() {
    return this.isDeletedObject;
  }

  get parentTemplate() {
    const id = this.id;
    const parentId = this.parentId;
    if (id === 0n && id === parentId) {
      return null;
    }
    return this.kernel.getTemplateParameterById(parentId);
  }

  // возвращает путь к корню на который идет ссылка, если параметр не ссылочный, то возвращается пустой массив
  get linkPath() {
    const visitedNodes = {};
    const path = [this];
    const kernel = this.kernel;
    let node = this;

    while (node.isLink) {
      visitedNodes[node.id] = true;
      node = kernel.getParameterById(node.IDParametrLink);
      path.push(node);
      if (!node || node.id in visitedNodes) return null;
    }

    return path;
  }
}

export { ParametersExtended };
