import { memo } from 'react';

import { PreloadList } from '../components.mjs';

import { TaskTreeItem } from './ui/index.mjs';
import { preloadList } from './model/preloadList.mjs';
import styles from './treeTask.module.scss';


export default memo(function TreeTask(props) {
  const { task, setTask, setTypeComponents, activeTypeTask, setActiveTypeTask } = props;

  return (
    <div className={styles.treeTask}>
      {task.isLoaded ? (
        Object.values(task.taskList).map((taskItem) => {
          return (
            <TaskTreeItem
              key={taskItem.typeTask}
              taskItem={taskItem}
              task={task}
              setTask={setTask}
              setTypeComponents={setTypeComponents}
              activeTypeTask={activeTypeTask}
              setActiveTypeTask={setActiveTypeTask}
            />
          );
        })
      ) : (
        <PreloadList preloadSizeElement={preloadList()} />
      )}
    </div>
  );
});
