import { CodesOperationsListStruct } from '../quantum-lib/Arm/Parser/Logs/CodesOperationsListStruct.mjs';
import { LogsExtListStruct } from '../quantum-lib/Arm/Parser/Logs/LogsExtListStruct.mjs';
import { ErrorsApp } from '../utils/errors-app/ErrorsApp.mjs';

import { setWorkingMode } from './services.mjs';

export class LoggingService {

  static async getCodesOperations(){
    const response = await fetch(`${setWorkingMode()}/get-codes-operations`, {
      method: 'get',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });

    if (!response.ok) {
      throw new ErrorsApp("Произошла ошибка при получении данных!", 2);
    }
    const arrayBuffer = await response.arrayBuffer();
    
    const isLittleEndian = true;
    const offset = 0;
    const dataView = new DataView(arrayBuffer);

    const codesOperationsListStruct = new CodesOperationsListStruct();

    codesOperationsListStruct.readFrom(dataView, offset, isLittleEndian);
    return codesOperationsListStruct;
  }

  static async getLogsByQueryParameters(params){
    const response = await fetch(`${setWorkingMode()}/logs-list-by-specific-parameters`, {
      method: 'post',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify(params),
    });

    if (!response.ok) {
      throw new ErrorsApp("Произошла ошибка при получении журналов!", 2);
    }
    const arrayBuffer = await response.arrayBuffer();
    
    const isLittleEndian = true;
    const offset = 0;
    const dataView = new DataView(arrayBuffer);

    const logsExtListStruct = new LogsExtListStruct();

    logsExtListStruct.readFrom(dataView, offset, isLittleEndian);
    return logsExtListStruct;
  }
}