class UsersGroup {

  constructor(kernel, usersGroupStruct) {
    this.kernel = kernel;
    this.usersGroupStruct = usersGroupStruct;
    this.children = [];
    this.isOpen = false;
  }

  get id() {
    return this.usersGroupStruct.id;
  }

  get name() {
    return this.usersGroupStruct.name.value;
  }

  get isAdmin() {
    const flags = this.usersGroupStruct.flags;
    return flags === 1n ? true : false;
  }

  set newName(newName) {
    this.usersGroupStruct.name.value = newName;
  }

}



export { UsersGroup };

