class Handbook {
  constructor(kernel, handbookStruct) {
    this.kernel = kernel;
    this.handbookStruct = handbookStruct;
  }

  get id() {
    return this.handbookStruct.id;
  }

  get name() {
    return this.handbookStruct.name.value;
  }

  get handbookContentList() {
    return this.kernel.handbookContentListStruct.hasOwnProperty(this.handbookStruct.id) ? this.kernel.handbookContentListStruct[this.handbookStruct.id] : [];
  }
}

export { Handbook };