import { useEffect, useRef, useState } from 'react';
import { useYMaps } from '@pbe/react-yandex-maps';
import { BsArrowLeftCircle, BsPlusSquareDotted } from 'react-icons/bs';
import { AiTwotoneSave } from 'react-icons/ai';
import { HiCheck } from 'react-icons/hi';

import { Loading, LoadingInTableSave } from '../../components.mjs';
import { useKernel } from '../../../context/ContextKernel.mjs';

import styles from './gpsEditor.module.scss';

export default function GpsEditor(props) {
  const { setOpenGpsEditor, setValue, name, coordinatesPointValue, sendingData, dataSentSuccessfully, saveProperty } = props;

  const ymaps = useYMaps(['Map', 'geoObject.addon.editor', 'ObjectManager', 'Placemark']);
  const mapRefElem = useRef(null);
  const filesRef = useRef();
  const myMap = useRef(null);
  const placemarkRef = useRef();

  const { currentLicense, addNotification } = useKernel();

  const [geoJsonFileName, setGeoJsonFileName] = useState('');
  const [loading, setLoading] = useState(false);

  const getFileShema = (file) => file && { file: file, type: file.type, name: file.name };

  const parseCoordinateFromYMap = () => {
    const coordinateInYMap = coordinatesPointValue.split('|');

    if (coordinateInYMap.length >= 1 && coordinateInYMap[0] !== '') {
      const [x, y] = coordinateInYMap[0].split(',');
      return [parseFloat(x), parseFloat(y)];
    }
    return [];
  };

  const setGeoJsonCoordinates = () => {
    if (!sendingData) {
      filesRef.current.click();
    }
  };

  const getCoordinatesGps = async () => {
    let coordinatesStr;

    if (placemarkRef.current?.geometry) {
      const coordinatesGps = placemarkRef.current.geometry.getCoordinates();

      let [x, y] = coordinatesGps;

      x = parseInt(x * 1000000) / 1000000;
      y = parseInt(y * 1000000) / 1000000;

      coordinatesStr = `${x},${y}`;
      setValue(name, coordinatesStr);
      await saveProperty({ value: coordinatesStr }); // сохранение параметра в kernel и на бэке
      setOpenGpsEditor(false);
    } else {
      coordinatesStr = '';

      setValue(name, coordinatesStr);
      await saveProperty({ value: coordinatesStr }); // сохранение параметра в kernel и на бэке

      setOpenGpsEditor(false);
    }
  };

  const dataChecking = (event, files, file) => {
    //проверка на количество файлов
    if (files.length === 1) {
      setGeoJsonFileName(file.name);

      const fileName = file.name.split('.');
      const fileExtension = fileName[fileName.length - 1];

      if (fileExtension === 'json') {
        //проверка на тип файла
        const reader = new FileReader();
        let fileData;
        reader.onload = (event) => {
          fileData = JSON.parse(event.target.result);
          fileData = fileData.map((obj, i) => {
            obj.coordinates = obj.coordinates.map((coords) => {
              const [x, y] = coords;
              return [y, x];
            });
            return {
              type: 'Feature',
              id: i,
              geometry: obj,
            };
          });

          myMap.current.geoObjects.removeAll();

          const objectManager = new ymaps.ObjectManager();
          objectManager.add(fileData);
          myMap.current.geoObjects.add(objectManager);
          myMap.current.setCenter([fileData[0].geometry.coordinates[0][0], fileData[0].geometry.coordinates[0][1]]);

          const initialCoord = parseCoordinateFromYMap();

          if (initialCoord.length > 0) {
            createPlacemark(initialCoord);
          }

          setLoading(false);
        };
        reader.readAsText(files[0]);
      } else {
        event.target.value = null;
        setLoading(false);
        addNotification({ messageText: 'Выбранный вами файл содержит невалидный формат!', statusType: 2 });
      }
    } else {
      event.target.value = null;
      setLoading(false);
      addNotification({ messageText: 'Вы можете прикрепить только 1 файл лицензии за раз!', statusType: 2 });
    }
  };

  const addGeoJsonFile = (event) => {
    setLoading(true);

    const { files } = event.target;
    const file = getFileShema(files.item(0));

    dataChecking(event, files, file);
  };

  const createPlacemark = (initialCoord) => {
    const placemark = new ymaps.Placemark(initialCoord, {}, { preset: 'twirl#blueStretchyIcon', draggable: true });

    myMap.current.geoObjects.add(placemark);
    myMap.current.setCenter(placemark.geometry.getCoordinates());
    placemarkRef.current = placemark;
  };

  useEffect(() => {
    if (!ymaps || !mapRefElem.current) {
      return;
    }

    myMap.current = new ymaps.Map(mapRefElem.current, {
      center: [parseFloat(currentLicense.MAPS_COORDINATES_LATITUDE), parseFloat(currentLicense.MAPS_COORDINATES_LONGITUDE)],
      zoom: parseInt(currentLicense.MAPS_ZOOM),
    });

    const initialCoord = parseCoordinateFromYMap();

    const handler = (event) => {
      const coordinates = event.get('coords');
      createPlacemark(coordinates);
    };

    if (initialCoord.length > 0) {
      createPlacemark(initialCoord);
    } else {
      myMap.current.events.add('click', handler);
    }

    return () => {
      myMap.current.events.remove('click', handler);
    };
  }, [ymaps]);

  return (
    <>
      {loading && (
        <div className={styles.loadingContainer}>
          <Loading />
        </div>
      )}

      <div className={styles.gpsEditor}>
        <dir className={styles.mapContainer}>
          {/* карта */}
          <div ref={mapRefElem} className={styles.map} style={{ width: '100vw', height: '100vh' }}></div>
          {/* карта */}

          <div className={styles.gpsEditorControlContainer}>
            <div className={styles.gpsEditorControlRow}>
              <h3 className={styles.title}>Редактор GPS координат</h3>
              <BsArrowLeftCircle className={styles.buttonBack} onClick={() => setOpenGpsEditor(false)} />
            </div>
            <div className={styles.gpsEditorControlRow}>
              <span className={styles.uploadedFileNameText}>
                {geoJsonFileName.length > 0 ? (
                  <>
                    Вы загрузили файл
                    <strong className={styles.uploadedFileNameTextActive} title={geoJsonFileName}>
                      {geoJsonFileName}
                    </strong>
                  </>
                ) : (
                  <>Загрузите файл</>
                )}
              </span>

              <div className={styles.controlBlock}>
                <BsPlusSquareDotted className={styles.addFile} onClick={setGeoJsonCoordinates} />
                {dataSentSuccessfully ? (
                  <HiCheck className={styles.checkControl} />
                ) : sendingData ? (
                  <LoadingInTableSave />
                ) : (
                  <AiTwotoneSave className={styles.saveCoord} onClick={getCoordinatesGps} />
                )}

                <input
                  className={styles.geoJsonFiles}
                  type="file"
                  name="geoJsonFiles"
                  ref={filesRef}
                  accept=".json"
                  onChange={(event) => addGeoJsonFile(event)}
                  disabled={sendingData}
                />
              </div>
            </div>
          </div>
        </dir>
      </div>
    </>
  );
}
