import { ChangePropertiesService } from "../services/services.mjs";
import { ResponseSetParameterValueDataStruct } from '../quantum-lib/Scada/Parser/Responses/ResponseSetParameterValueDataStruct.mjs';
import { RequestSetParameterValueDataListStruct } from "../quantum-lib/Arm/Parser/Requests/RequestSetParameterValueDataListStruct.mjs";

class RemoteServersPool {
  constructor(kernel) {
    this.kernel = kernel;
  }


  async setParameterValue(newParametersValue, timestamp = new Date()) { // return {RequestSetParameterValueDataListStruct,ResponseSetParameterValueDataStruct,};
    try {
      const idConfiguration = this.kernel.getActiveConfiguration().id;

      const requestSetParameterValueDataListStruct = new RequestSetParameterValueDataListStruct(newParametersValue.map((newParameterValue) => newParameterValue.parameterStruct.encodeValue(newParameterValue.newValue, timestamp)));

      let arrayBuffer = new ArrayBuffer(requestSetParameterValueDataListStruct.getCurrentSize());
      let dataView = new DataView(arrayBuffer);
      requestSetParameterValueDataListStruct.writeTo(dataView, 0, true);

      const response = await ChangePropertiesService.setPropertiesInDataBase(arrayBuffer, idConfiguration);
      const responseSetParameterValueDataStruct = new ResponseSetParameterValueDataStruct();

      arrayBuffer = await response.arrayBuffer();
      dataView = new DataView(arrayBuffer);
      responseSetParameterValueDataStruct.readFrom(dataView, 0, true);

      return {
        requestSetParameterValueDataListStruct,
        responseSetParameterValueDataStruct,
      };

    } catch (error) {
      console.log('error: ', error);
    }
  }
}

export { RemoteServersPool };
