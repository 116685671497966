import classNames from "classnames";

import Utils from "../../../../utils/Utils.mjs";

import styles from './buildDotAlignmentSelect.module.scss';

export const BuildDotAlignmentSelect = (props) => {
  const { sendingData, isWritable, isDeleted, canEdit, dotAlignmentSelectValue, handleChangeDotAlignmentSelect } = props;
  const dotAlignment = Utils.setDotlessAlignmentValue();

  return (
    <select
      className={classNames(
        styles.reportingHourSelect,
        styles.dotAlignmentSelect,
        sendingData && styles.inputrepRtingHourSending,
        canEdit && styles.inputrepRtingHourSending,
        !isWritable && styles.inputrepRtingHourSending,
        isDeleted && styles.inputrepRtingHourSending,
      )}
      value={dotAlignmentSelectValue}
      onInput={handleChangeDotAlignmentSelect}
      disabled={sendingData || canEdit || !isWritable || isDeleted}
    >
      {dotAlignment.map((item) => {
        return (
          <option className={classNames(styles.reportingHourSelectOption, styles.dotAlignmentSelectOption)} key={item.value} value={item.value}>
            {item.text}
          </option>
        );
      })}
    </select>
  );
};