import classNames from 'classnames';
import { AiTwotoneSave } from 'react-icons/ai';

import { LoadingInTableSave } from '../components.mjs';
import Utils from '../../utils/Utils.mjs';

import styles from './savePropertiesButtonModuleRs.module.scss';

export default function SavePropertiesButtonModuleRs(props) {
  const { sendingData, handleChangeComponentTableRow, saveProperty, isValid = true } = props;

  return (
    <>
      {sendingData ? (
        <LoadingInTableSave />
      ) : (
        <button
          className={styles.savePropertiesButtonModuleRs}
          title={Utils.titleFromButtonSaveVirtualProperty(handleChangeComponentTableRow)}
          disabled={!handleChangeComponentTableRow || !isValid}
          onClick={saveProperty}
        >
          <AiTwotoneSave
            className={handleChangeComponentTableRow && isValid ? classNames(styles.saveControl, styles.saveControlActive) : styles.saveControl}
          />
        </button>
      )}
    </>
  );
}
