import Utils from "../../../utils/Utils.mjs";

export const validationScheme = (simulatorType) => {

  simulatorType = parseInt(simulatorType);
  
  if (simulatorType === 6) return {
    maxLength: {
      value: 16,
      message: 'Поле может содержать максимум 16 символов',
    },
  };
  if (simulatorType === 7) return {
    maxLength: {
      value: 64,
      message: 'Поле может содержать максимум 64 символов',
    },
  };
  if (simulatorType === 8) return {
    maxLength: {
      value: 255,
      message: 'Поле может содержать максимум 255 символов',
    },
  };

  return {
    validate: (value) => {
      return Utils.testIsNumber(value) ? null : 'Неверный формат';
    },
  };
};