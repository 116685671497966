import classNames from 'classnames';
import { useEffect, useRef } from 'react';
import { BsArrowLeftCircle } from 'react-icons/bs';

import TreeSettlementServer from '../tree-settlement-server/TreeSettlementServer';
import { useKernel } from '../../context/ContextKernel.mjs';
import { PARAMETER_CLASS_VALUE } from '../../global/variables/objectTypeVariables.mjs';

import styles from './settlementServerPopUp.module.scss';

export default function SettlementServerPopUp(props) {
  const { configuration, activeElementTree, setActiveElementTree, closePopUp, callback, propertyVisibleInTree, parameterDisabled = false } = props;
  const { kernel } = useKernel();
  const ref = useRef();

  const setStyleParameterDisabled = () => {
    if (parameterDisabled) {
      const parameterStruct = kernel.getParameterById(activeElementTree);
      const classify = parameterStruct.classify();

      return classify === PARAMETER_CLASS_VALUE ? [0, 11, 12, 13, 14, 15, 16].includes(parameterStruct.typeNode.systemType.type) : true;
    }
    return false;
  };

  const closePopupOnClick = (event) => {
    event.stopPropagation();
    closePopUp();
  };

  const closePopupOnEsc = (event) => {
    if (event.key === 'Escape') closePopUp();
  };

  useEffect(() => {
    const element = ref.current;
    element.addEventListener('click', closePopupOnClick);
    document.body.addEventListener('keydown', closePopupOnEsc);

    return () => {
      element.removeEventListener('click', closePopupOnClick);
      document.body.removeEventListener('keydown', closePopupOnEsc);
    };
  }, []);

  return (
    <div className={styles.wrap} ref={ref}>
      <div className={styles.container}>
        <div className={styles.header}>
          <h2 className={styles.title}>Выберете нужный параметр</h2>
          <BsArrowLeftCircle className={styles.buttonBack} onClick={closePopUp} />
        </div>
        <div
          className={styles.treeContainer}
          onContextMenu={(event) => {
            event.preventDefault();
          }}
        >
          <TreeSettlementServer
            configuration={configuration}
            activeElementTree={activeElementTree}
            setActiveElementTree={setActiveElementTree}
            propertyVisible={propertyVisibleInTree}
            parameterDisabled={parameterDisabled}
          />
        </div>
        <div className={styles.control}>
          <button
            className={classNames(styles.controlButton, setStyleParameterDisabled() && styles.disabled)}
            onClick={callback}
            disabled={setStyleParameterDisabled()}
          >
            Выбрать
          </button>
        </div>
      </div>
    </div>
  );
}
