
import { LRLanguage, LanguageSupport } from "@codemirror/language";

import { parser } from "./func.parser.js";
import { highlighting } from './func.highlight.js';

const parserQsSrvCalcFuncLanguage = parser.configure({
  props: [
    highlighting,
  ],
});

export const qsSrvCalcFuncLanguage = LRLanguage.define({
  parser: parserQsSrvCalcFuncLanguage,
});

export const wrapperQsSrvCalcFuncLanguage = () => new LanguageSupport(qsSrvCalcFuncLanguage);