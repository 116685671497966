import classNames from 'classnames';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { BsBorderStyle, BsDatabaseFillAdd, BsFillKeyFill } from 'react-icons/bs';
import { ImUsers } from 'react-icons/im';
import { MdAccountTree, MdEditNote, MdMenuBook, MdOutlineSettings } from 'react-icons/md';
import { FaBook } from 'react-icons/fa';
import { GoTasklist } from 'react-icons/go';
import { RiAlarmWarningFill } from 'react-icons/ri';

import { flagsParser } from '../../utils/flags-parser/flagsParser.mjs';
import { useKernel } from '../../context/ContextKernel.mjs';
import { useUserTokenDecode } from '../../hooks/hooks.mjs';

import styles from './sectionSelection.module.scss';

export default function SectionSelection() {
  const { kernel, currentLicense } = useKernel();

  const {
    hasAccessUsersModule = undefined,
    hasAccessEventLogsModule = undefined,
    hasAccessLicensesModule = undefined,
    hasAccessCalculationServerModule = undefined,
    hasAccessTaskServerModule = undefined,
    hasAccessSetpointsModule = undefined,
    hasAccessAlarmServerModule = undefined,
    hasAccessSimulatorServerModule = undefined,
    hasAccessHandbookModule = undefined,
  } = currentLicense;

  const { flags } = useUserTokenDecode();
  const {
    hasAccessRightsUsersModule,
    hasAccessRightsEventLogsModule,
    hasAccessRightsLicensesModule,
    hasAccessRightsCalculationServerModule,
    hasAccessRightsTaskServerModule,
    hasAccessRightsSetpointsModule,
    hasAccessRightsAlarmServerModule,
    hasAccessRightsSimulatorServerModule,
    hasAccessRightsHandbookModule,
  } = flagsParser(flags);

  useEffect(() => {
    kernel.timerRefreshToken(); //запуск обновления токена
    return () => {};
  }, []);

  const setLinkOnCurrentPageOrConfigurationSelectionPage = (linkOnCurrentPage = '/configuration-selection') => {
    const id = JSON.parse(localStorage.getItem('activeIdConfig'))?.id;
    return id ? linkOnCurrentPage : '/configuration-selection';
  };

  return (
    <div className={styles.sectionSelection}>
      <div className={styles.wrap}>
        <div className={styles.containerText}>
          <h1 className={styles.title}>Выберите раздел</h1>
        </div>
        <div className={styles.sectionSelectionWrap}>
          <Link
            to={setLinkOnCurrentPageOrConfigurationSelectionPage('/configuration-selection')}
            onClick={() => kernel.setNavigatePath('/property-editor')}
            onDragStart={(event) => event.preventDefault()}
          >
            <div className={styles.sectionSelectionItem}>
              <div className={styles.imgContainer}>
                <MdEditNote className={styles.icon} />
              </div>
              <p className={styles.sectionSelectionItemText}>Конфигурация</p>
            </div>
          </Link>

          <Link
            to="/users"
            className={
              !(hasAccessUsersModule === 'true' && hasAccessRightsUsersModule) === true
                ? classNames(styles.sectionSelectionItem, styles.disabled)
                : styles.sectionSelectionItem
            }
            onClick={(event) => {
              kernel.setNavigatePath('/users');
              !(hasAccessUsersModule === 'true' && hasAccessRightsUsersModule === true) && event.preventDefault();
            }}
            onDragStart={(event) => event.preventDefault()}
            title={
              !(hasAccessUsersModule === 'true' && hasAccessRightsUsersModule === true) ? 'Модуль не доступен, обратитесь в отдел продаж!' : null
            }
          >
            <div className={styles.imgContainer}>
              <ImUsers className={styles.icon} />
            </div>
            <p className={styles.sectionSelectionItemText}>Пользователи</p>
          </Link>

          <Link
            to="/action-logs"
            className={
              !(hasAccessEventLogsModule === 'true' && hasAccessRightsEventLogsModule === true)
                ? classNames(styles.sectionSelectionItem, styles.disabled)
                : styles.sectionSelectionItem
            }
            onClick={(event) => {
              kernel.setNavigatePath('/action-logs');
              !(hasAccessEventLogsModule === 'true' && hasAccessRightsEventLogsModule === true) && event.preventDefault();
            }}
            onDragStart={(event) => event.preventDefault()}
            title={
              !(hasAccessEventLogsModule === 'true' && hasAccessRightsEventLogsModule === true)
                ? 'Модуль не доступен, обратитесь в отдел продаж!'
                : null
            }
          >
            <div className={styles.imgContainer}>
              <MdMenuBook className={styles.icon} />
            </div>
            <p className={styles.sectionSelectionItemText}>Журналы</p>
          </Link>

          <Link
            to="/licenses"
            className={
              !(hasAccessLicensesModule === 'true' && hasAccessRightsLicensesModule === true)
                ? classNames(styles.sectionSelectionItem, styles.disabled)
                : styles.sectionSelectionItem
            }
            onClick={(event) => {
              kernel.setNavigatePath('/licenses');
              !(hasAccessLicensesModule === 'true' && hasAccessRightsLicensesModule === true) && event.preventDefault();
            }}
            onDragStart={(event) => event.preventDefault()}
            title={
              !(hasAccessLicensesModule === 'true' && hasAccessRightsLicensesModule === true)
                ? 'Модуль не доступен, обратитесь в отдел продаж!'
                : null
            }
          >
            <div className={styles.imgContainer}>
              <BsFillKeyFill className={styles.icon} />
            </div>
            <p className={styles.sectionSelectionItemText}>Лицензии</p>
          </Link>

          <Link
            to={setLinkOnCurrentPageOrConfigurationSelectionPage('/settlement-server')}
            className={
              !(hasAccessCalculationServerModule === 'true' && hasAccessRightsCalculationServerModule === true)
                ? classNames(styles.sectionSelectionItem, styles.disabled)
                : styles.sectionSelectionItem
            }
            onClick={(event) => {
              kernel.setNavigatePath('/settlement-server');
              !(hasAccessCalculationServerModule === 'true' && hasAccessRightsCalculationServerModule === true) && event.preventDefault();
            }}
            onDragStart={(event) => event.preventDefault()}
            title={
              !(hasAccessCalculationServerModule === 'true' && hasAccessRightsCalculationServerModule === true)
                ? 'Модуль не доступен, обратитесь в отдел продаж!'
                : null
            }
          >
            <div className={styles.imgContainer}>
              <MdAccountTree className={styles.icon} />
            </div>
            <p className={styles.sectionSelectionItemText}>Сервер расчетов</p>
          </Link>

          <Link
            to={setLinkOnCurrentPageOrConfigurationSelectionPage('/task-server')}
            className={
              !(hasAccessTaskServerModule === 'true' && hasAccessRightsTaskServerModule === true)
                ? classNames(styles.sectionSelectionItem, styles.disabled)
                : styles.sectionSelectionItem
            }
            onClick={(event) => {
              kernel.setNavigatePath('/task-server');
              !(hasAccessTaskServerModule === 'true' && hasAccessRightsTaskServerModule === true) && event.preventDefault();
            }}
            onDragStart={(event) => event.preventDefault()}
            title={
              !(hasAccessTaskServerModule === 'true' && hasAccessRightsTaskServerModule === true)
                ? 'Модуль не доступен, обратитесь в отдел продаж!'
                : null
            }
          >
            <div className={styles.imgContainer}>
              <GoTasklist className={styles.icon} />
            </div>
            <p className={styles.sectionSelectionItemText}>Сервер задач</p>
          </Link>

          <Link
            to={setLinkOnCurrentPageOrConfigurationSelectionPage('/setpoints')}
            className={
              !(hasAccessSetpointsModule === 'true' && hasAccessRightsSetpointsModule === true)
                ? classNames(styles.sectionSelectionItem, styles.disabled)
                : styles.sectionSelectionItem
            }
            onClick={(event) => {
              kernel.setNavigatePath('/setpoints');
              !(hasAccessSetpointsModule === 'true' && hasAccessRightsSetpointsModule === true) && event.preventDefault();
            }}
            onDragStart={(event) => event.preventDefault()}
            title={
              !(hasAccessSetpointsModule === 'true' && hasAccessRightsSetpointsModule === true)
                ? 'Модуль не доступен, обратитесь в отдел продаж!'
                : null
            }
          >
            <div className={styles.imgContainer}>
              <BsBorderStyle className={styles.icon} />
            </div>
            <p className={styles.sectionSelectionItemText}>Уставки</p>
          </Link>

          <Link
            to={setLinkOnCurrentPageOrConfigurationSelectionPage('/alarm')}
            className={
              !(hasAccessAlarmServerModule === 'true' && hasAccessRightsAlarmServerModule === true)
                ? classNames(styles.sectionSelectionItem, styles.disabled)
                : styles.sectionSelectionItem
            }
            onClick={(event) => {
              kernel.setNavigatePath('/alarm');
              !(hasAccessAlarmServerModule === 'true' && hasAccessRightsAlarmServerModule === true) && event.preventDefault();
            }}
            onDragStart={(event) => event.preventDefault()}
            title={
              !(hasAccessAlarmServerModule === 'true' && hasAccessRightsAlarmServerModule === true)
                ? 'Модуль не доступен, обратитесь в отдел продаж!'
                : null
            }
          >
            <div className={styles.imgContainer}>
              <RiAlarmWarningFill className={styles.icon} />
            </div>
            <p className={styles.sectionSelectionItemText}>Тревоги</p>
          </Link>

          <Link
            to={setLinkOnCurrentPageOrConfigurationSelectionPage('/simulator')}
            className={
              !(hasAccessSimulatorServerModule === 'true' && hasAccessRightsSimulatorServerModule === true)
                ? classNames(styles.sectionSelectionItem, styles.disabled)
                : styles.sectionSelectionItem
            }
            onClick={(event) => {
              kernel.setNavigatePath('/simulator');
              !(hasAccessSimulatorServerModule === 'true' && hasAccessRightsSimulatorServerModule === true) && event.preventDefault();
            }}
            onDragStart={(event) => event.preventDefault()}
            title={
              !(hasAccessSimulatorServerModule === 'true' && hasAccessRightsSimulatorServerModule === true)
                ? 'Модуль не доступен, обратитесь в отдел продаж!'
                : null
            }
          >
            <div className={styles.imgContainer}>
              <BsDatabaseFillAdd className={styles.icon} />
            </div>
            <p className={styles.sectionSelectionItemText}>Симулятор</p>
          </Link>

          <Link
            to={setLinkOnCurrentPageOrConfigurationSelectionPage('/reference-book')}
            className={
              !(hasAccessHandbookModule === 'true' && hasAccessRightsHandbookModule === true)
                ? classNames(styles.sectionSelectionItem, styles.disabled)
                : styles.sectionSelectionItem
            }
            onClick={(event) => {
              kernel.setNavigatePath('/reference-book');
              !(hasAccessHandbookModule === 'true' && hasAccessRightsHandbookModule === true) && event.preventDefault();
            }}
            onDragStart={(event) => event.preventDefault()}
            title={
              !(hasAccessHandbookModule === 'true' && hasAccessRightsHandbookModule === true)
                ? 'Модуль не доступен, обратитесь в отдел продаж!'
                : null
            }
          >
            <div className={styles.imgContainer}>
              <FaBook className={styles.icon} />
            </div>
            <p className={styles.sectionSelectionItemText}>Справочники</p>
          </Link>

          <Link to="/settings" className={styles.sectionSelectionItem} onDragStart={(event) => event.preventDefault()}>
            <div className={styles.imgContainer}>
              <MdOutlineSettings className={styles.icon} />
            </div>
            <p className={styles.sectionSelectionItemText}>Настройки</p>
          </Link>
        </div>

        <form action="https://arquantum.ru/contacts" target="_blank" className={styles.linkContainer}>
          <button type="submit" className={styles.buttonLink}>
            Отдел продаж
          </button>
        </form>
      </div>
    </div>
  );
}
