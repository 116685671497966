import { memo, useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import { LogsPreload } from '../components.mjs';
import { useLogs } from '../../context/ContextLogs.mjs';

import { TableBodyRow, TableSettingsControl } from './ui/index.mjs';
import styles from './eventLogsList.module.scss';

const setStateSettingsMenu = () => {
  const stateInLocalStorage = localStorage.getItem('settingsTableLogs');
  if (!stateInLocalStorage) {
    const newState = [
      { id: 1, columnDescription: 'Дата', columnName: 'columnDate', columnWidth: 125, isVisible: true },
      { id: 2, columnDescription: 'Время', columnName: 'columnHour', columnWidth: 100, isVisible: true },
      { id: 3, columnDescription: 'Тип события', columnName: 'columnAlarmType', columnWidth: 260, isVisible: true },
      { id: 4, columnDescription: 'Место события', columnName: 'columnEventLocation', columnWidth: 220, isVisible: true },
      { id: 5, columnDescription: 'Пользователь', columnName: 'columnUsername', columnWidth: 300, isVisible: true },
      { id: 6, columnDescription: 'Ip адрес', columnName: 'columnIpAddress', columnWidth: 145, isVisible: true },
      { id: 7, columnDescription: 'Сообщение', columnName: 'columnMessage', columnWidth: 1100, isVisible: true },
    ];

    localStorage.setItem('settingsTableLogs', JSON.stringify(newState));

    return { initialState: newState };
  }
  const stateInLocalStorageParsed = JSON.parse(stateInLocalStorage);

  return { initialState: stateInLocalStorageParsed };
};

const createHeaders = (headers) => {
  return headers.map(({ id, columnDescription, columnName, columnWidth, isVisible }) => ({
    id,
    columnDescription,
    columnName,
    columnWidth,
    isVisible,
    ref: useRef(),
  }));
};

const minCellWidth = 100; // минимальный размер ячейки

export default memo(function EventLogsList() {
  const { tableContent } = useLogs();
  const { initialState } = setStateSettingsMenu(); // начальное состояние таблицы
  const [columnTableHeadState, setColumnTableHeadState] = useState(initialState);

  const [activeIndex, setActiveIndex] = useState(); // index столбца который мы масштабируем
  const [tableHeight, setTableHeight] = useState(); // высота таблицы (для создания линии)
  const tableElement = useRef(null); // ссылка на таблицу
  const columns = createHeaders(columnTableHeadState);

  const initialCoord = useRef(null);

  const mouseDown = useCallback((event) => {
    const id = parseInt(event.currentTarget.dataset.id);
    setActiveIndex(id);
    initialCoord.current = event.clientX;
  }, []);

  const refTest = useRef({});

  const mouseMove = useCallback(
    (event) => {
      const gridColumns = columns.map(({ id, ref, isVisible, columnName }) => {
        if (ref.current && isVisible) {
          if (id === activeIndex) {
            const width = ref.current.offsetWidth + event.clientX - initialCoord.current;
            initialCoord.current = event.clientX;

            if (width >= minCellWidth) {
              refTest.current[columnName] = width;
              return `${width}px`;
            }
          }

          return `${ref.current.offsetWidth}px`;
        }
      });

      tableElement.current.style.gridTemplateColumns = `${gridColumns.join(' ')}`;
    },
    [activeIndex, columns],
  );

  const updatingStylesTable = useCallback(() => {
    const gridColumns = columns.map(({ ref, isVisible, columnName }) => {
      if (ref.current && isVisible) {
        return `${refTest.current[columnName]}px`;
      }
    });

    tableElement.current.style.gridTemplateColumns = `${gridColumns.join(' ')}`;
  }, [activeIndex, columns]);

  const removeListeners = useCallback(() => {
    window.removeEventListener('mousemove', mouseMove);
    window.removeEventListener('mouseup', removeListeners);
  }, [mouseMove]);

  const mouseUp = useCallback(() => {
    setActiveIndex(null);
    removeListeners();
    initialCoord.current = null;
  }, [setActiveIndex, removeListeners]);

  useEffect(() => {
    if (activeIndex !== null) {
      window.addEventListener('mousemove', mouseMove);
      window.addEventListener('mouseup', mouseUp);
    }

    return () => {
      removeListeners();
    };
  }, [activeIndex, mouseMove, mouseUp, removeListeners]);

  useEffect(() => {
    updatingStylesTable();
  }, [columnTableHeadState]);

  useEffect(() => {
    const gridColumns = initialState.map(({ columnWidth, columnName }) => {
      refTest.current[columnName] = columnWidth;
      return `${columnWidth}px`;
    });
    tableElement.current.style.gridTemplateColumns = `${gridColumns.join(' ')}`;
    setTableHeight(tableElement.current.offsetHeight);
    return () => {};
  }, []);

  return (
    <div className={styles.eventLogsList}>
      <div className={styles.controlsEventLogsListPanel}>
        <TableSettingsControl
          columnTableHeadState={columnTableHeadState}
          setColumnTableHeadState={setColumnTableHeadState}
          columns={columns}
          tableElement={tableElement}
        />
      </div>

      <div className={styles.eventLogsListContainer}>
        <div className={styles.eventLogsListWrap}>
          <div ref={tableElement} className={styles.logsContainer}>
            {
              !tableContent.hasData && <p className={styles.messageAboutRequestResults}>Ничего не найдено</p>
            }

            <div className={styles.head}>
              <div className={styles.row}>
                {columns.map(({ id, columnDescription, columnName, isVisible, ref }) => {
                  return (
                    isVisible && (
                      <div
                        key={id}
                        ref={ref}
                        data-id={id}
                        data-name-column={columnName}
                        className={styles.column}
                        onDragStart={(event) => event.preventDefault()}
                      >
                        <span className={styles.textHead} title={columnDescription}>
                          {columnDescription}
                        </span>
                        <span data-id={id} data-name-column={columnName} className={styles.controlsWidthColumn} onMouseDown={mouseDown}></span>
                        <span
                          style={{ height: tableHeight }}
                          className={classNames(styles.resizeHandle, activeIndex == id && styles.resizeHandleActive)}
                        ></span>
                      </div>
                    )
                  );
                })}
              </div>
            </div>

            <div className={styles.body}>
              {tableContent.sending ? (
                <LogsPreload />
              ) : (
                tableContent.rows.map((row) => {
                  return <TableBodyRow key={row.id} row={row} columnTableHeadState={columnTableHeadState} />;
                })
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
