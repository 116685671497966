import Utils from "../../../../../utils/Utils.mjs";

const TYPE_UINT64 = "u";
const TYPE_UINT32 = "a";
const TYPE_UINT16 = "b";
const TYPE_UINT8 = "c";

const TYPE_INT64 = "i";
const TYPE_INT32 = "d";
const TYPE_INT16 = "e";
const TYPE_INT8 = "h";

const TYPE_FLOAT = "f";
const TYPE_DOUBLE = "g";

const TYPE_STRING = "s";

const TYPE_TIMESTAMP = "t";


export const validationScheme = {
  [TYPE_UINT8]: {
    required: 'Поле обязательно к заполнению',
    maxLength: {
      value: 3,
      message: 'Поле может содержать максимум 3 символа',
    },
    min: { value: 0, message: 'Минимальное допустимое значение 0' },
    max: { value: 255, message: 'Максимальное допустимое значение 65535' },
    validate: (value) => {
      const isInteger = /^\d+$/.test(value);
      return Utils.testIsNumber(value) ? (isInteger ? true : 'Число должно быть целым') : 'Неверный формат. Допустимый формат ввода - целые числа';
    },
  },
  [TYPE_UINT16]: {
    required: 'Поле обязательно к заполнению',
    maxLength: {
      value: 5,
      message: 'Поле может содержать максимум 5 символов',
    },
    min: { value: 0, message: 'Минимальное допустимое значение 0' },
    max: { value: 65535, message: 'Максимальное допустимое значение 65535' },
    validate: (value) => {
      const isInteger = /^\d+$/.test(value);
      return Utils.testIsNumber(value) ? (isInteger ? true : 'Число должно быть целым') : 'Неверный формат. Допустимый формат ввода - целые числа';
    },
  },
  [TYPE_UINT32]: {
    required: 'Поле обязательно к заполнению',
    maxLength: {
      value: 10,
      message: 'Поле может содержать максимум 10 символов',
    },
    min: { value: 0, message: 'Минимальное допустимое значение 0' },
    max: { value: 4294967295, message: 'Максимальное допустимое значение 4294967295' },
    validate: (value) => {
      const isInteger = /^\d+$/.test(value);
      return Utils.testIsNumber(value) ? (isInteger ? true : 'Число должно быть целым') : 'Неверный формат. Допустимый формат ввода - целые числа';
    },
  },
  [TYPE_UINT64]: {
    required: 'Поле обязательно к заполнению',
    maxLength: {
      value: 20,
      message: 'Поле может содержать максимум 20 символов',
    },
    min: { value: 0, message: 'Минимальное допустимое значение 0' },
    max: {},
    validate: (value) => {
      const isInteger = /^\d+$/.test(value);
      return Utils.testIsNumber(value) ? (isInteger ? true : 'Число должно быть целым') : 'Неверный формат. Допустимый формат ввода - целые числа';
    },
  },

  [TYPE_INT8]: {
    required: 'Поле обязательно к заполнению',
    maxLength: {
      value: 4,
      message: 'Поле может содержать максимум 4 символа',
    },
    min: { value: -128, message: 'Минимальное допустимое значение -128' },
    max: { value: 127, message: 'Максимальное допустимое значение 127' },
    validate: (value) => {
      const isInteger = /^\d+$/.test(value);
      return Utils.testIsNumber(value) ? (isInteger ? true : 'Число должно быть целым') : 'Неверный формат. Допустимый формат ввода - целые числа';
    },
  },
  [TYPE_INT16]: {
    required: 'Поле обязательно к заполнению',
    maxLength: {
      value: 6,
      message: 'Поле может содержать максимум 6 символов',
    },
    min: { value: -32768, message: 'Минимальное допустимое значение -32768' },
    max: { value: 32767, message: 'Максимальное допустимое значение 32767' },
    validate: (value) => {
      const isInteger = /^\d+$/.test(value);
      return Utils.testIsNumber(value) ? (isInteger ? true : 'Число должно быть целым') : 'Неверный формат. Допустимый формат ввода - целые числа';
    },
  },
  [TYPE_INT32]: {
    required: 'Поле обязательно к заполнению',
    maxLength: {
      value: 12,
      message: 'Поле может содержать максимум 12 символов',
    },
    min: { value: -2147483648, message: 'Минимальное допустимое значение -2147483648' },
    max: { value: 2147483647, message: 'Максимальное допустимое значение 2147483647' },
    validate: (value) => {
      const isInteger = /^\d+$/.test(value);
      return Utils.testIsNumber(value) ? (isInteger ? true : 'Число должно быть целым') : 'Неверный формат. Допустимый формат ввода - целые числа';
    },
  },
  [TYPE_INT64]: {
    required: 'Поле обязательно к заполнению',
    maxLength: {
      value: 20,
      message: 'Поле может содержать максимум 20 символов',
    },
    min: {},
    max: {},
    validate: (value) => {
      const isInteger = /^\d+$/.test(value);
      return Utils.testIsNumber(value) ? (isInteger ? true : 'Число должно быть целым') : 'Неверный формат. Допустимый формат ввода - целые числа';
    },
  },
  [TYPE_FLOAT]: {
    required: 'Поле обязательно к заполнению',
    maxLength: {
      value: 255,
      message: 'Поле может содержать максимум 255 символов',
    },
    validate: (value) => {
      return Utils.testIsNumber(value) ? true : 'Неверный формат. Допустимый формат ввода - числа';
    },
  },
  [TYPE_DOUBLE]: {
    required: 'Поле обязательно к заполнению',
    maxLength: {
      value: 255,
      message: 'Поле может содержать максимум 255 символов',
    },
    validate: (value) => {
      return Utils.testIsNumber(value) ? true : 'Неверный формат. Допустимый формат ввода - числа';
    },
  },

  [TYPE_STRING]: {
    maxLength: {
      value: 255,
      message: 'Поле может содержать максимум 255 символов',
    },
  },
  [TYPE_TIMESTAMP]: {
    required: 'Поле обязательно к заполнению',
    maxLength: {
      value: 255,
      message: 'Поле может содержать максимум 255 символов',
    },
    validate: (value) => {
      const isInteger = /^\d+$/.test(value);
      return Utils.testIsNumber(value) ? (isInteger ? true : 'Число должно быть целым') : 'Неверный формат. Допустимый формат ввода - целые числа';
    },
  },
};


export const createValidationScheme = (type) => {
  const required = validationScheme[type]?.required || null;
  const validate = validationScheme[type]?.validate;
  const min = validationScheme[type]?.min || {};
  const max = validationScheme[type]?.max || {};
  const minLength = validationScheme[type]?.minLength || {};
  const maxLength = validationScheme[type]?.maxLength || {};

  return {
    required,
    min,
    max,
    minLength,
    maxLength,
    validate,
  };
};