import classNames from 'classnames';
import { useEffect, useRef } from 'react';

import styles from './selectBool.module.scss';

export default function SelectBool(props) {
  const { value, setValue, canEdit, register, unregister, name, isWritable, isDeleted, setHandleChangeComponentTableRow, sendingData } = props;

  const initialValue = useRef(value);

  const handleChangeSelectBoolValue = ({ target }) => {
    if (target.value.toString() === initialValue.current.toString()) {
      setHandleChangeComponentTableRow(false);
    } else {
      setHandleChangeComponentTableRow(true);
    }
    setValue(name, target.value);
  };

  useEffect(() => {
    setValue(name, value);
    return () => {
      unregister([name]);
    };
  }, []);

  useEffect(() => {
    initialValue.current = value;
    return () => {};
  }, [value]);

  return (
    <div className={styles.selectBoolContainer}>
      <div className={styles.selectBoolForm}>
        <select
          className={classNames(
            styles.selectBool,
            sendingData && styles.selectBoolSending,
            canEdit && styles.selectBoolSending,
            !isWritable && styles.selectBoolSending,
            isDeleted && styles.selectBoolSending,
          )}
          onInput={handleChangeSelectBoolValue}
          disabled={sendingData || canEdit || !isWritable || isDeleted}
          {...register(name)}
        >
          <option className={styles.selectBoolOption} value="true">
            Да
          </option>
          <option className={styles.selectBoolOption} value="false">
            Нет
          </option>
        </select>
      </div>
    </div>
  );
}
